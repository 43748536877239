import React, { useRef, useEffect, useState, useCallback } from 'react'
import gsap from 'gsap'
import { createUseStyles } from 'react-jss'
import RichText from '../../../RichText'
import theme, { vw } from '../../../../style/theme'
import { span } from '../../../../style/span'
import map from 'lodash/map'
import delay from 'lodash/delay'
import Color from 'color'
import cn from 'classnames'
import ScrollTrigger from 'gsap/ScrollTrigger'

const animateInValues = { ease: 'expo.out', yPercent: 0, opacity: 1, stagger: 0.16, duration: 1.6, visibility: 'visible' }
const animateOutValues = { ease: 'expo.out', yPercent: 20, opacity: 0, duration: 0 }

const Schedule = ({ classes, schedule, expanded }) => {
  const ref = useRef()
  const overlayRef = useRef()

  useEffect(() => {
    if (ref.current) {
      gsap.to(ref.current, { height: expanded ? 'auto' : 100, duration: 0.25 })
      gsap.to(overlayRef.current, {
        opacity: expanded ? 0 : 1,
        duration: 0.15,
        onComplete: () => {
          // We have changed the hight so refresh the scroll triggers
          delay(() => ScrollTrigger.refresh(false))
        }
      })
    }
  }, [expanded])

  return (
    <ul className={classes.schedule} ref={ref}>
      {map(schedule, (e, i) => (
        <li className={classes.scheduleListItem} key={i}>
          <span className={classes.time}>{e.time}<span className={classes.location}>{e.location}</span></span>
          <RichText className={classes.scheduleCopy} content={e.text.text} />
        </li>
      ))}
      <div className={classes.overlay} ref={overlayRef} />
    </ul>
  )
}

const EventBlock = ({ block, isActive, backgroundColor }) => {
  const {
    heading,
    text,
    schedule
  } = block

  const classes = useStyles({ backgroundColor })
  const [expanded, setExpanded] = useState()

  const rightColRef = useRef()

  useEffect(() => {
    if (rightColRef.current) {
      gsap.set(rightColRef.current.children, animateOutValues)
    }
  }, [])

  useEffect(() => {
    if (rightColRef.current) {
      if (isActive) {
        gsap.to(rightColRef.current.children, animateInValues)
      }
    }
  }, [isActive])

  const onExpandToggle = useCallback(() => setExpanded(value => !value), [])

  return (
    <section className={classes.content}>
      <div className={classes.cols}>
        <div className={classes.col} />
        <div className={cn(classes.col, classes.wrapper)} ref={rightColRef}>
          {schedule && (
            <button onClick={onExpandToggle} className={classes.toggle}>
              <svg viewBox='0 0 10 10'>
                <line x1={0} y1={5} x2={10} y2={5} />
                {!expanded && <line x1={5} y1={0} x2={5} y2={10} />}
              </svg>
            </button>
          )}
          <div className={classes.container}>
            {heading && <h5 onClick={onExpandToggle} className={classes.heading}>{heading}</h5>}
            {text && <RichText content={text.text} />}
            {schedule && (
              <Schedule classes={classes} schedule={schedule} expanded={expanded} />
            )}
          </div>
        </div>
      </div>
    </section>
  )
}

const useStyles = createUseStyles({
  content: {
    position: 'relative'
  },
  cols: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row'
    }
  },
  col: {
    [theme.breakpoints.up('md')]: {
      flex: 1,
      marginLeft: span(1, 'md'),
      marginBottom: 0
    }
  },
  wrapper: {
    display: 'flex'
  },
  container: {
    flexGrow: 1,
    flexShrink: 1,
    [theme.breakpoints.up('md')]: {
      maxWidth: span(9, 'md')
    }
  },
  inlineImage: {
    marginBottom: vw(25),
    [theme.breakpoints.up('md')]: {
      marginBottom: vw(25, 'lg')
    }
  },
  heading: {
    marginBottom: vw(30),
    [theme.breakpoints.up('md')]: {
      marginBottom: vw(30, 'lg')
    }
  },
  schedule: {
    padding: 0,
    margin: 0,
    listStyle: 'none',
    overflow: 'hidden',
    height: 100
  },
  scheduleListItem: {
    marginTop: vw(30),
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      marginTop: vw(30, 'lg')
    }
  },
  time: {
    display: 'block',
    flexGrow: 0,
    flexShrink: 0,
    extend: theme.typography.h6,
    marginBottom: vw(16),
    [theme.breakpoints.up('md')]: {
      width: '30%',
      marginRight: vw(30, 'lg'),
      marginBottom: 0
    }
  },
  location: {
    display: 'block',
    marginTop: vw(6),
    extend: theme.typography.bodyTiny,
    textTransform: 'none',
    [theme.breakpoints.up('md')]: {
      marginTop: vw(10, 'lg')
    }
  },
  toggle: {
    extend: theme.typography.h5,
    flexGrow: 0,
    flexShrink: 0,
    width: `${theme.typography.h5.lineHeight}em`,
    height: `${theme.typography.h5.lineHeight}em`,
    marginRight: vw(8),
    border: [1, 'solid', 'currentColor'],
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.up('md')]: {
      marginRight: vw(16, 'lg')
    },
    '&:hover': {
      color: theme.colors.primary
    },
    '& svg': {
      stroke: 'currentColor',
      vectorEffect: 'non-scaling-stroke',
      strokeWidth: 1,
      width: '0.5em'
    }
  },
  overlay: {
    background: ({ backgroundColor = theme.colors.white }) => `linear-gradient(${Color(backgroundColor).alpha(0).string()}, ${Color(backgroundColor).string()})`,
    position: 'absolute',
    bottom: 0,
    height: 150,
    left: 0,
    right: 0,
    opacity: 1,
    pointerEvents: 'none'
  }
}, { name: 'EventBlock' })

export default EventBlock
