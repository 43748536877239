import inDOM from 'dom-helpers/canUseDOM'
import { createSlice } from '@reduxjs/toolkit'
import { rehydrated, routeContent, transitionComplete } from '../actions'
import { current, keys } from '../../style/breakpoints'
import { dialogContentActionCreator } from './content/dialog'
import { ActionType } from 'redux-promise-middleware'

const layoutSlice = createSlice({
  name: 'layout',
  initialState: {
    breakpoint: current(),
    breakpointSet: inDOM,
    dialogOpen: false,
    menuOpen: false
  },
  reducers: {
    breakpointChanged: (state, action) => {
      state.breakpoint = action.payload
      state.breakpointSet = true
    },
    toggleDialog: (state, action) => {
      state.dialogOpen = !state.dialogOpen
    },
    toggleMenu: (state, action) => {
      state.menuOpen = !state.menuOpen
    }
  },
  extraReducers: {
    [rehydrated]: (state, action) => {
      state.breakpointSet = true
    },
    [routeContent]: (state, action) => {
      state.menuOpen = false
      state.dialogOpen = false
    },
    [`${dialogContentActionCreator}_${ActionType.Fulfilled}`]: (state, action) => {
      state.dialogOpen = true
    },
    [`${routeContent}_${ActionType.Fulfilled}`]: (state, action) => {
      state.dialogOpen = false
    },
    [transitionComplete]: (state, action) => {
      state.dialogOpen = false
    }
  }
})

export const {
  breakpointChanged,
  toggleDialog,
  toggleMenu
} = layoutSlice.actions

export const getCurrentBreakpoint = state => state.layout.breakpoint
export const isCurrentBreakpointAtLeast = (state, breakpoint) => (
  keys.indexOf(getCurrentBreakpoint(state)) >= keys.indexOf(breakpoint)
)
export const isBreakpointSet = state => state.layout.breakpointSet
export const isCurrentBreakpointMobile = state => !isCurrentBreakpointAtLeast(state, 'md')

export const isDialogOpen = state => state.layout.dialogOpen

export const isMenuOpen = state => state.layout.menuOpen

export default layoutSlice.reducer
