import React from 'react'
import { createUseStyles } from 'react-jss'
import theme from '../style/theme'
import Page from './Page'
import gsap from 'gsap'
import Dialog from './Dialog'
import MenuDialog from './MenuDialog'
import Meta from './Meta'
import SvgSymbols from './SvgSymbols'
import PageLoadingBar from './PageLoadingBar'

function App () {
  const classes = useStyles() /* eslint-disable-line */

  gsap.config({ force3D: true })

  return (
    <>
      <Meta />
      <SvgSymbols />
      <PageLoadingBar />
      <Page />
      <MenuDialog />
      <Dialog />
    </>
  )
}

const useStyles = createUseStyles({
  '@global': {
    ...theme.global
  }
}, { name: 'App' })

export default App
