import React from 'react'
import { createUseStyles } from 'react-jss'
import HighlightedText from '../../HighlightedText'
import SplitWordsAnimation from '../../SplitWordsAnimation'
import ControlPanel from './ControlPanel'
import theme, { span, vw } from '../../../style/theme'

const PostCategoryHero = ({ slice }) => {
  const classes = useStyles()
  const {
    title,
    postCategories,
    postInitiatives
  } = slice
  return (
    <div className={classes.content}>
      <div className={classes.textContainer}>
        <SplitWordsAnimation wrapped>
          <span className={classes.title}>
            <HighlightedText content={title.text} />
          </span>
        </SplitWordsAnimation>
      </div>
      <ControlPanel
        postCategories={postCategories}
        postInitiatives={postInitiatives}
      />
    </div>
  )
}

const useStyles = createUseStyles({
  sectionPadding: {
    ...theme.margins('padding')
  },
  textContainer: {
    paddingTop: vw(120),
    paddingBottom: vw(60),
    composes: ['$sectionPadding'],
    [theme.breakpoints.up('md')]: {
      paddingTop: vw(180, 'lg'),
      paddingBottom: vw(140, 'lg')
    }
  },
  title: {
    display: 'block',
    [theme.breakpoints.up('md')]: {
      // maxWidth: span(14, 'md')
    }
  }
}, { name: 'PostCategoryHero' })

export default PostCategoryHero
