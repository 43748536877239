import React from 'react'
import { createUseStyles } from 'react-jss'
import RichText from '../../RichText.js'
import theme from '../../../style/theme.js'

const PlaybookText = ({ slice }) => {
  const { text } = slice
  const classes = useStyles()

  return (
    <div>
      {slice && <RichText content={text?.text} className={classes.text} />}
    </div>
  )
}

const useStyles = createUseStyles(
  {
    text: {
      marginRight: '120px',
      marginBottom: '20px',
      textAlign: 'justify',
      [theme.breakpoints.down('md')]: {
        margin: '40px 25px'
      }
    }
  },
  { name: 'PlaybookText' }
)

export default PlaybookText
