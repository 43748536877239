import React, { useMemo } from 'react'
import cn from 'classnames'
import { createUseStyles } from 'react-jss'
import isEmpty from 'lodash/isEmpty'
import detectIt from 'detect-it'
import Link from '../../Link'
import ResponsiveImage from '../../ResponsiveImage'
import theme, { span, vw } from '../../../style/theme'
import { quart } from '../../../style/eases'
import { resolveLink } from '../../../helpers/resolveLink'

export default ({
  title,
  heroImage,
  className,
  futureMapsTags,
  preview_image: previewImage,
  preview_snippet: previewSnippet,
  linked_post: linkedPost,
  author,
  authors
}) => {
  const classes = useStyles()
  const resolvedLink = useMemo(() => resolveLink(linkedPost), [linkedPost])

  return (
    <li key={title} className={cn(className, classes.listItem)}>
      <Link to={resolvedLink.url} className={classes.link} nonLinkTag='div'>
        <div className={classes.hoverEffect}>
          {!isEmpty(heroImage) && <ResponsiveImage {...heroImage} className={classes.hoverImage} />}
        </div>
        <div className={classes.desktopEle}>
          <div className={classes.previewImage}>
            <ResponsiveImage {...previewImage} />
          </div>
          {/* <ResponsiveImage {...previewImage || featuredPost?.previewImage} aspect={isFeatured ? 1.5 : 1.4} isContain={isImageContain} className={isFeatured ? classes.featuredFutureImage : ''} /> */}
        </div>
        <span className={classes.titleWrapper}>
          <h4 className={classes.title}>{title}</h4>
          <span className={classes.playbookTags}>
            {futureMapsTags && futureMapsTags.map((item, idx) => (
              <React.Fragment key={idx}>
                <span className={classes.dep}>{item.title}</span>
                {idx < futureMapsTags.length - 1 && <span className={classes.newDot} />}
              </React.Fragment>
            ))}
          </span>
        </span>
        <span className={classes.authors}>
          {authors && authors.map((authors, i) => <span className={classes.authors} key={i}>{authors.title}</span>)}
          <span className={classes.authors}>{author}</span>
          <span className={classes.dep}>Author</span>
        </span>
        <span className={classes.description}>{previewSnippet}</span>
        <div className={classes.authorsMb}>
          <div className={classes.imageTitleWrapper}>
            <div className={classes.previewImage}>
              {/* <ResponsiveImage {...previewImage || featuredPost?.previewImage} aspect={isFeatured ? 1.5 : 1.4} isContain={isImageContain} className={isFeatured ? classes.featuredFutureImage : ''} /> */}
              <ResponsiveImage {...previewImage} />
            </div>
            <span className={classes.titleWrapperMb}>
              <h4 className={classes.title}>{title}</h4>
              <span className={classes.playbookTags}>
                {futureMapsTags && futureMapsTags.map((item, idx) => (
                  <React.Fragment key={idx}>
                    <span className={classes.dep}>{item.title}</span>
                    {idx < futureMapsTags.length - 1 && <span className={classes.newDot} />}
                  </React.Fragment>
                ))}
              </span>
            </span>
          </div>
          <div>
            <span className={classes.mobileWrapper}>
              <span className={classes.descriptionMb}>{previewSnippet}</span>
              {/* <span className={classes.authorHeading}>Authors:</span> */}
              <span>
                {/* {authors && authors.map((authors, i) => <span className={classes.authorsMbName} key={i}>{authors.title}</span>)} */}
                {/* <span className={classes.authorsMbName}>{author}</span> */}
              </span>
            </span>
          </div>
        </div>
      </Link>
    </li>
  )
}

const useStyles = createUseStyles({
  listItem: {
    fontSize: vw(14),
    // borderTop: [1, 'solid', theme.colors.border],
    borderBottom: [1, 'solid', theme.colors.border],
    position: 'relative',
    overflow: 'hidden',
    [theme.breakpoints.up('md')]: {
      fontSize: vw(14, 'lg')
    }
    // '&:last-child': {
    //   borderBottom: [1, 'solid', theme.colors.border]
    // }
  },
  desktopOnly: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'block'
    }
  },
  mobileOnly: {
    display: 'block',
    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  },
  newDot: {
    width: '6px',
    height: '6px',
    margin: '0 10px',
    borderRadius: '50%',
    backgroundColor: theme.colors.primary
    // [theme.breakpoints.down('fab')]: {
    //   display: 'none'
    // }
  },
  dep: {
    fontFamily: 'Object Sans',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '9px',
    color: theme.colors.primary,

    [theme.breakpoints.up('fab')]: {
      fontSize: '14px'
    }
  },
  link: {
    position: 'relative',
    display: 'flex',
    textDecoration: 'none',
    color: theme.colors.text,
    margin: [0, vw(-6)],
    overflow: 'hidden',
    [theme.breakpoints.up('fab')]: {
      padding: [vw(16), theme.getMargin()]
    },
    [theme.breakpoints.up('md')]: {
      minHeight: vw(180, 'lg'),
      margin: [0, vw(-30, 'vw')],
      padding: vw(30, 'lg')
    },
    '& > *': {
      position: 'relative',
      margin: [0, vw(6)],
      [theme.breakpoints.up('md')]: {
        marginRight: [0, vw(30, 'vw')]
      }
    },
    transition: `color 0.25s ${quart.inOut}, background-color 0.25s ${quart.inOut}`,
    ...(detectIt.primaryInput === 'touch'
      ? {
        '&:hover': {
          color: theme.colors.white,
          backgroundColor: theme.colors.primary,
          '& $playbookTag': {
            color: '#fff'
          },
          '& $dep': {
            color: '#fff'
          }
        }
      }
      : {
        '&:hover': {
          color: theme.colors.white,
          '& $playbookTag': {
            color: '#fff'
          },
          '& $dep': {
            color: '#fff'
          }
        }
      })
  },
  titleWrapper: {
    composes: ['$desktopOnly'],
    width: '100%',
    [theme.breakpoints.up('fab')]: {
      width: '40%'
    }
  },
  titleWrapperMb: {
    composes: ['$mobileOnly'],
    width: '100%',
    [theme.breakpoints.up('fab')]: {
      width: '40%'
    }
  },
  label: {
    display: 'block',
    opacity: 0.5
  },
  title: {
    fontSize: vw(20),
    [theme.breakpoints.up('md')]: {
      fontSize: vw(50, 'lg')
    }
  },
  authors: {
    composes: ['$desktopOnly'],
    fontSize: vw(6),
    fontWeight: 700,
    whiteSpace: 'pre-wrap',
    [theme.breakpoints.up('md')]: {
      width: span(4, 'md')
    }
  },
  authorsMb: {
    composes: ['$mobileOnly'],
    padding: [12, 0]
  },
  mobileWrapper: {
    display: 'flex',
    flexDirection: 'column'
  },
  authorHeading: {
    fontWeight: 700,
    fontSize: '11px'
  },
  authorsMbName: {
    display: 'flex',
    flexDirection: 'column',
    fontSize: '11px'
    // marginLeft: '8px'
  },
  playbookTags: {
    marginTop: '8px',
    display: 'flex',
    flexWrap: 'wrap',
    // flexDirection: 'column',
    alignItems: 'center',
    [theme.breakpoints.up('fab')]: {
      flexDirection: 'row',
      alignItems: 'center'
    }
  },
  stageColumn: {
    composes: ['$desktopOnly'],
    [theme.breakpoints.up('md')]: {
      width: span(4, 'md')
    }
  },
  description: {
    composes: ['$desktopOnly'],
    width: '50%',
    fontSize: vw(5),
    [theme.breakpoints.down('fab')]: {
      fontSize: vw(12)
    }
  },
  descriptionMb: {
    fontSize: vw(5),
    padding: [8, 0],
    [theme.breakpoints.down('fab')]: {
      fontSize: vw(12)
    }
  },
  arrow: {
    composes: ['$desktopOnly'],
    textAlign: 'right',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      width: span(1, 'md')
    }
  },
  hoverEffect: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: -2,
    backgroundColor: theme.colors.primary,
    transform: 'translateY(100%)',
    transition: `transform 0.25s ${quart.inOut}`,
    ...(detectIt.primaryInput === 'touch' ? {} : {
      '$link:hover &': {
        transform: 'translateY(0%)'
      }
    })
  },
  hoverImage: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    '&:after': {
      content: '""',
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      backgroundColor: 'rgba(34, 34, 34, 0.7)'
    }
  },
  previewImage: {
    display: 'block',
    width: vw(120),
    [theme.breakpoints.up('fab')]: {
      width: vw(40)
    },
    [theme.breakpoints.up('mb')]: {
      width: vw(40)
    }
  },
  desktopEle: {
    composes: ['$desktopOnly']
  },
  imageTitleWrapper: {
    display: 'flex',
    gap: '12px'
  }
}, { name: 'AIElevationTile' })
