import inDOM from 'dom-helpers/canUseDOM'
import { NOT_FOUND } from 'redux-first-router'
import qs from 'query-string'
import { resolveInternalLinkUrl } from '../helpers/resolveLink'
import {
  getGlobalContent,
  getPageContent,
  isPreviewMode,
  isContentBusy,
  getDialogRoutes,
  getDialogContent
} from './slices/content'
import {
  isPreviewRoute,
  getCurrentRoutePath,
  isPageNotFound,
  getCurrentQueryString
} from './slices/location'
import {
  fetchGlobalContent,
  fetchPageContent,
  fetchDialogContent,
  fetchPageNotFoundContent
} from './api'
import { routeContent, routeEmpty, redirectCreator } from './actions'
import { showLoading, hideLoading } from 'react-redux-loading-bar'

import { requestPreviewSession } from './slices/content/preview'

const contentThunk = async (dispatch, getState) => {
  const path = getCurrentRoutePath(getState())
  const currentPageContent = getPageContent(getState())
  const currentPageRoute = currentPageContent && resolveInternalLinkUrl(currentPageContent)
  const dialogRoutes = getDialogRoutes(getState())
  // We also test the current path is not the content path. If it is then the content is not opened in a dialog
  const isDialogRoute = dialogRoutes.includes(path) && currentPageRoute !== path
  const notFound = async () => {
    dispatch({ type: NOT_FOUND })
    return fetchPageNotFoundContent(dispatch, getState)
  }

  if (isPageNotFound(getState())) {
    return
  }

  const tasks = []
  if (!getGlobalContent(getState())) {
    tasks.push(fetchGlobalContent(dispatch, getState))
  }
  if (inDOM && isDialogRoute) {
    dispatch(showLoading('dialog'))
    tasks.push(fetchDialogContent(dispatch, getState, path))
  } else {
    if (path !== currentPageRoute) {
      tasks.push(fetchPageContent(dispatch, getState, path))
    }
  }

  try {
    await Promise.all(tasks)
    if (isContentBusy(getState())) {
      // This should only happen when content arrived out of sequence (see: the race middleware).
      // Another request is already in progress, so don’t do anything further for this one.
      return
    }

    const content =
      inDOM && isDialogRoute
        ? getDialogContent(getState())
        : getPageContent(getState())
    if (!content || !content.type || content.id === 'error-page-404') {
      await notFound()
    } else {
      const currentRoutePath = path
      const canonicalPath = resolveInternalLinkUrl(content)

      if (isPreviewMode(getState())) {
        if (!inDOM) {
          dispatch(requestPreviewSession())
        } else {
          // This will ensure any page refresh will still be in preview mode
          if (!isPreviewRoute(getState())) {
            window.history.replaceState(null, null, `/preview${path}`)
          }
        }
      } else {
        if (currentRoutePath !== canonicalPath) {
          const query = qs.stringify(getCurrentQueryString(getState()))
          dispatch(
            redirectCreator(
              getState(),
              canonicalPath + (query ? `?${query}` : '')
            )
          )
        }
      }
    }
    dispatch(hideLoading('dialog'))
  } catch (error) {
    console.error(error)
    await notFound()
  }
}

export default {
  [routeContent.toString()]: {
    path: '*',
    toPath: (segment) => segment,
    thunk: contentThunk
  },
  [routeEmpty.toString()]: {
    path: '*',
    toPath: (segment) => segment
  }
}
