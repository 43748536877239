import React, { forwardRef } from 'react'
import { createUseStyles } from 'react-jss'
import cn from 'classnames'
import theme, { vw } from '../../style/theme'
import { quart } from '../../style/eases'
import ResponsiveImage from '../ResponsiveImage'
import SocialLink, { FACEBOOK_TYPE, INSTAGRAM_TYPE, LINKEDIN_TYPE, TWITTER_TYPE } from '../SocialLink'
import { resolveInternalLinkUrl } from '../../helpers/resolveLink'
import Link from '../Link'

function getSocialLinkType (url) {
  if (url.indexOf('facebook') >= 0) {
    return FACEBOOK_TYPE
  }
  if (url.indexOf('twitter') >= 0) {
    return TWITTER_TYPE
  }
  if (url.indexOf('linkedin') >= 0) {
    return LINKEDIN_TYPE
  }
  if (url.indexOf('instagram') >= 0) {
    return INSTAGRAM_TYPE
  }
}

const CaptainCard = ({ photo, position, title, page, fields, socialLinks }) => {
  const classes = useStyles()
  const url = resolveInternalLinkUrl(page)
  const alt = photo.alt || `A photo of ${title}`

  return (
    <Link className={classes.saasCaptainTile} to={url} nonLinkTag='div'>
      <div className={classes.hoverEffect} />
      <ResponsiveImage className={classes.profilePhoto} {...photo} alt={alt} />
      {/* <p className={classes.SaasCaptainName}>{title}</p> */}
      {/* <p className={classes.SaasCaptainPosition}>{position}</p> */}
      <div className={classes.socialLinksContainer} onClick={(e) => e.stopPropagation()}>
        {socialLinks.map(link => {
          const type = getSocialLinkType(link.url)
          if (type) {
            return (
              <SocialLink className={classes.socialLink} key={link.url} url={link.url} type={type} />
            )
          }
        })}
      </div>
      <div className={classes.highlightedDepartment}>
        {fields.map((item, idx) => (
          <React.Fragment key={idx}>
            <span className={classes.dep}>{item}</span>
            {idx < fields.length - 1 && <span className={classes.newDot} />}
          </React.Fragment>
        ))}
      </div>
    </Link>
  )
}

const CaptainGrid = forwardRef(({ className, slice }, ref) => {
  const { groups } = slice
  const classes = useStyles()
  var teams = []

  if (groups && Array.isArray(groups) && groups.length) {
    for (let i = 0; i < groups.length; i++) {
      const group = groups[i]
      const { id, saasCaptains } = group
      var filterList = []
      if (saasCaptains) {
        const sortedSaasCaptains = saasCaptains.slice().sort((a, b) =>
          a.title.localeCompare(b.title)
        )
        for (let j = 0; j < saasCaptains.length; j++) {
          const saasCaptain = sortedSaasCaptains[j]
          filterList.push(<CaptainCard key={saasCaptain && saasCaptain.id} {...saasCaptain} />)
        }
      }
      if (filterList.length) {
        teams.push(
          <div key={id} className={classes.gridRow}>
            {/* <h4 className={classes.rowTitle}>{title}</h4> */}
            <div className={classes.saasCaptains}>{filterList}</div>
          </div>
        )
      }
    }
  }
  return <section className={cn(classes.content, className)}>{teams}</section>
})

const useStyles = createUseStyles(
  {
    content: {
      // padding: [0, theme.getMargin('min')],
      padding: [40, theme.getMargin('min')],
      position: 'relative',
      [theme.breakpoints.up('md')]: {
        // padding: [0, theme.getMargin('md'), 40],
        padding: [40, theme.getMargin('md'), 40]
      }
    },
    gridRow: {
      // marginBottom: 50,
      [theme.breakpoints.up('md')]: {
        // marginBottom: 130
      }
    },
    rowTitle: {
      extend: theme.typography.h4,
      marginBottom: vw(25),
      [theme.breakpoints.up('md')]: {
        marginBottom: vw(25, 'lg'),
        fontSize: '45px'
      }
    },
    mainTitle: {
      extend: theme.typography.h3
    },
    saasCaptains: {
      display: 'grid',
      gridTemplateColumns: '1fr',
      columnGap: `${theme.getGutter()}px`,
      rowGap: vw(25),
      [theme.breakpoints.up('fab')]: {
        gridTemplateColumns: '1fr 1fr'
      },
      [theme.breakpoints.up('md')]: {
        gridTemplateColumns: '1fr 1fr 1fr',
        columnGap: `${theme.getGutter('md')}px`,
        rowGap: vw(50, 'lg')
      },

      [theme.breakpoints.up('lg')]: {
        gridTemplateColumns: '1fr 1fr 1fr 1fr',
        columnGap: `${theme.getGutter('md')}px`,
        rowGap: vw(50, 'lg')
      }
    },
    saasCaptainTile: {
      display: 'grid',
      textDecoration: 'none',
      color: theme.colors.text,
      transition: 'color 0.3s ease',
      position: 'relative',
      backgroundColor: theme.colors.secondary,
      '&:hover p': {
        color: theme.colors.white
      },
      '&:hover svg': {
        color: theme.colors.white
      },
      [theme.breakpoints.up('md')]: {
        '&:hover': {
          color: theme.colors.white
        },
        '&:before': {
          top: `calc(-${theme.getGutter('md')}px / 2)`,
          left: `calc(-${theme.getGutter('md')}px / 2)`,
          height: `calc(100% + ${theme.getGutter('md')}px)`,
          width: `calc(100% + ${theme.getGutter('md')}px)`
        }
      }
    },
    hoverEffect: {
      position: 'absolute',
      top: `calc(-${theme.getGutter()}px / 2)`,
      left: `calc(-${theme.getGutter()}px / 2)`,
      height: `calc(100% + ${theme.getGutter()}px)`,
      width: `calc(100% + ${theme.getGutter()}px)`,
      zIndex: -1,
      overflow: 'hidden',
      // The hover effect is hidden on mobile as it take the focus and the scrolling gets locked to the parent
      // which causes issues in dialogs
      display: 'none',
      '&:before': {
        content: "''",
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: -2,
        backgroundColor: theme.colors.secondary,
        transform: 'translateY(100%)',
        transition: `transform 0.5s ${quart.inOut}`,
        '$saasCaptainTile:hover &': {
          transform: 'translateY(0%)'
        }
      },
      [theme.breakpoints.up('md')]: {
        display: 'block',
        top: `calc(-${theme.getGutter('md')}px / 2)`,
        left: `calc(-${theme.getGutter('md')}px / 2)`,
        height: `calc(100% + ${theme.getGutter('md')}px)`,
        width: `calc(100% + ${theme.getGutter('md')}px)`
      }
    },
    profilePhoto: {
      marginBottom: vw(5),
      [theme.breakpoints.up('md')]: {
        marginBottom: vw(20, 'lg')
      }
    },
    headingBox: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
        alignItems: 'flex-start'
      }
    },
    filterContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      [theme.breakpoints.down('md')]: {
        width: '100%',
        marginTop: 30
      }
    },
    label: {
      extend: theme.typography.bodySmall,
      marginRight: 50,
      whiteSpace: 'nowrap'
    },
    locationFilter: {
      [theme.breakpoints.up('md')]: {
        display: 'block'
      }
    },
    seperator: {
      height: 1,
      width: '100%',
      backgroundColor: theme.colors.grey,
      marginTop: 40,
      marginBottom: 60,
      [theme.breakpoints.down('md')]: {
        marginTop: 20,
        marginBottom: 30
      }
    },
    locationBox: {
      display: 'flex',
      alignItems: 'center'
    },
    locationIcon: {
      width: 'auto',
      height: 25,
      marginTop: 5,
      marginRight: 8
    },
    locationName: {
      color: theme.colors.textLight,
      textTransform: 'capitalize',
      marginLeft: 5,
      lineHeight: 1
    },
    /* My styling for Card */
    dep: {
      fontFamily: 'Object Sans',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '140%',
      color: theme.colors.primary,

      [theme.breakpoints.up('md')]: {
        fontSize: '14px'
      }
    },
    highlightedDepartment: {
      display: 'flex',
      alignItems: 'center',
      padding: [0, 12, 12],
      [theme.breakpoints.down('fab')]: {
        flexDirection: 'column',
        alignItems: 'flex-start'
      }
    },
    SaasCaptainName: {
      fontFamily: 'Mars Condensed',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '40px',
      lineHeight: '90%',
      marginBottom: 0,
      color: theme.colors.secondary,
      textTransform: 'uppercase',

      [theme.breakpoints.up('md')]: {
        fontFamily: 'Object Sans',
        fontSize: '23px',
        lineHeight: '140%',
        textTransform: 'capitalize'
      }
    },
    SaasCaptainPosition: {
      fontFamily: 'Object Sans',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '16px',
      lineHeight: '140%',
      marginBottom: '12px',
      color: theme.colors.grey32,
      [theme.breakpoints.up('md')]: {
        fontSize: '16px'
      },
      [theme.breakpoints.down('sm')]: {
        marginTop: vw(20, 'sm')
      }
    },
    newDot: {
      width: '4px',
      height: '4px',
      margin: '0 10px',
      borderRadius: '50%',
      backgroundColor: theme.colors.primary,
      [theme.breakpoints.down('fab')]: {
        display: 'none'
      }
    },
    dot: {
      width: '4.12px',
      height: '4.12px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginTop: '2px',
      color: theme.colors.primary
    },
    socialLinksContainer: {
      display: 'flex',
      marginBottom: '10px',
      paddingLeft: '12px'
    },
    socialLink: {
      position: 'relative',
      color: theme.colors.white,
      '&:not(:last-child)': {
        marginRight: vw(10),
        [theme.breakpoints.up('md')]: {
          marginRight: vw(10, 'lg')
        }
      }
    }
  },
  { name: 'CaptainGrid' }
)

export default CaptainGrid
