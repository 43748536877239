import React, { useMemo, forwardRef } from 'react'
import { createUseStyles } from 'react-jss'
import cn from 'classnames'
import BlockContent from '@sanity/block-content-to-react'
import map from 'lodash/map'
import merge from 'lodash/merge'
import Link from './Link'
import { resolveLink } from '../helpers/resolveLink'
import theme, { vw } from '../style/theme'

const serializers = {
  marks: {
    link: ({ mark, children }) => (
      <Link to={mark.href} target={mark.open_in_new_window ? '_blank' : undefined}>{children}</Link>
    ),
    internal_link: ({ mark, children }) => (
      <Link link={resolveLink(mark)}>{children}</Link>
    ),
    highlight: ({ mark, children }) => {
      const classes = useStyles() // eslint-disable-line
      return <span className={classes.hightlight}>{children}</span>
    },
    largeBodyFont: ({ mark, children }) => {
      const classes = useStyles() // eslint-disable-line
      return <span className={classes.largeBodyFont}>{children}</span>
    },
    label: ({ mark, children }) => {
      const classes = useStyles() // eslint-disable-line
      return <span className={classes.label}>{children}</span>
    }
  }
}

const RichText = forwardRef(({ className, content, tag = 'div', serializers: extraSerializers, children }, ref) => {
  const classes = useStyles()
  const Component = tag
  const allSerializers = useMemo(() => {
    if (extraSerializers) {
      return merge({}, serializers, extraSerializers)
    }
    return serializers
  }, [extraSerializers])
  const blocks = useMemo(() => map(content, block => ({
    _type: block.type, // BlockContent expects `_type` prop, not `type`.
    ...block
  })))
  return (
    <Component className={cn(className, classes.container)} ref={ref}>
      <BlockContent blocks={blocks} serializers={allSerializers} />
      {children}
    </Component>
  )
})

const useStyles = createUseStyles({
  container: {
    '& h1, h2, h3, h4, h5, h6': {
      margin: [0, 0, '0.6em']
    },
    '& *:last-child': {
      marginBottom: 0
    },
    '& ul': {
      margin: [32, 20],
      padding: 0,
      extend: theme.typography.body
    },
    '& li': {
      paddingBottom: 16,
      [theme.breakpoints.up('md')]: {
        paddingBottom: 30
      }
    },
    '& blockquote': {
      extend: theme.typography.h4,
      textAlign: 'center',
      quotes: '"“" "”" "‘" "’"',
      margin: [vw(50), 0],
      [theme.breakpoints.up('md')]: {
        margin: vw(50, 'lg')
      },
      '&::before': {
        content: 'open-quote',
        color: theme.colors.primary
      },
      '&::after': {
        content: 'close-quote',
        color: theme.colors.primary
      }
    }
  },
  hightlight: {
    color: theme.colors.primary
  },
  largeBodyFont: {
    extend: theme.typography.bodyPlus,
    lineHeight: 1.4
  },
  label: {
    opacity: 0.5
  }
}, { name: 'RichText' })

export default RichText
