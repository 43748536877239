import React, { useMemo } from 'react'
import { createUseStyles } from 'react-jss'
import theme, { vw } from '../../style/theme'
import cn from 'classnames'
import DropDown from '../DropDown'

const PlaybackRate = ({ className, rate, onRateChanged }) => {
  const classes = useStyles()

  const options = useMemo(() => [
    { text: '1.0x', value: 1 },
    { text: '1.2x', value: 1.2 },
    { text: '1.5x', value: 1.5 },
    { text: '1.8x', value: 1.8 },
    { text: '2.0x', value: 2 }
  ], [])

  return (
    <div className={cn(className, classes.container)}>
      <label className={classes.label}>Speed</label>
      <DropDown
        className={classes.select}
        options={options}
        onChange={onRateChanged}
        value={rate}
        classNames={{
          dropdown: classes.dropdown,
          value: classes.dropdownValue,
          content: classes.dropdownContent
        }}
      />
    </div>
  )
}

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    alignItems: 'center',
    fontSize: vw(8),
    [theme.breakpoints.up('md')]: {
      fontSize: vw(14, 'lg')
    }
  },
  label: {
    display: 'block',
    marginRight: vw(16),
    [theme.breakpoints.up('md')]: {
      marginRight: vw(16, 'lg')
    }
  },
  select: {
    backgroundColor: 'transparent',
    color: theme.colors.white,
    outline: 'none',
    border: [1, 'solid', theme.colors.white]
  },
  dropdown: {
  },
  dropdownValue: {
    backgroundColor: 'transparent',
    border: 'none',
    transition: 'background-color 0.15s ease-in-out',
    '&:hover, &.open': {
      backgroundColor: 'rgba(255, 255, 255, 0.2)',
      backdropFilter: 'blur(4px)'
    }
  },
  dropdownContent: {
    border: 'none',
    background: 'rgba(255, 255, 255, 0.2)',
    backdropFilter: 'blur(4px)',
    top: '100%',
    '& ul': {
      border: [1, 'solid', theme.colors.white]
    },
    '&.left': {
      left: -1,
      right: -1
    }
  }
}, { name: 'PlaybackRate' })

export default PlaybackRate
