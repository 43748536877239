import React, { useMemo } from 'react'
import Filters from './Filters'
import map from 'lodash/map'

const ALL_OPTION_VALUE = ''

export default ({ tags, value, setValue, ...rest }) => {
  const options = useMemo(() => {
    return [
      { value: ALL_OPTION_VALUE, text: 'All' },
      ...map(tags, tag => ({ value: tag.slug, text: tag.title }))
    ]
  }, [tags])
  return <Filters options={options} value={value} setValue={setValue} {...rest} />
}
