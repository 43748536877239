import React from 'react'
import { createUseStyles } from 'react-jss'
import theme from '../../style/theme'
import { formatDate } from '../../utils/format'
import ResponsiveImage from '../ResponsiveImage'

const PlayBookWillCoverSection = ({ slice, page }) => {
  const chaptersList = page?.chapter
  const classes = useStyles()

  return (
    chaptersList?.length > 0 ? (
      <div className={classes.parent}>
        <p className={classes.parentHeading}>Chapters in this Field Guide</p>
        <div className={classes.wrapper}>
          {chaptersList?.map((data, index) => (
            <>
              <React.Fragment key={index}>
                <a href={`/${data.slug}`}>
                  <div className={classes.coverSection}>
                    <ResponsiveImage {...data.preview_image} className={classes.chapterImage} />
                    <section className={classes.rightSection}>
                      <p className={classes.aboutHeading}>{data.title}</p>
                      <div className={classes.dateAndChapter}>
                        <span className={classes.date}>{formatDate(data.post_date)}</span>
                      </div>
                    </section>
                  </div>
                </a>
                <hr className={classes.horizontalLine} />
              </React.Fragment>
            </>
          ))}
        </div>
      </div>
    ) : ('')
  )
}

const useStyles = createUseStyles({
  parent: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: '12.44px',
    padding: [0, 32, 45, 26],
    '& p': {
      marginBottom: 0
    },
    [theme.breakpoints.up('md')]: {
      padding: [0, 32, 75.88, 0],
      rowGap: '25px'
    }
  },
  parentHeading: {
    fontFamily: 'Object Sans',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '20px',
    lineHeight: '21px',
    letterSpacing: '0.03em',
    textTransform: 'capitalize',
    [theme.breakpoints.up('md')]: {
      fontSize: '24px',
      lineHeight: '25px'
    }
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: '15px',
    [theme.breakpoints.up('md')]: {
      rowGap: '15px'
    },
    '& a': {
      color: 'inherit',
      textDecoration: 'none'
    }
  },
  coverSection: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: '10px',
    '& img': {
      width: '100%',
      height: 'auto',
      maxWidth: '100%',
      maxHeight: '100%'
    },
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row', // marginTop: '16px',
      columnGap: '32px',
      '& img': {
        width: '138px',
        height: '116px'
      }
    }
  },
  rightSection: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: '10px',
    [theme.breakpoints.up('md')]: {
      flexGrow: 1,
      justifyContent: 'space-between',
      rowGap: '56px'
    }
  },
  aboutHeading: {
    fontFamily: 'Object Sans',
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: '14px',
    lineHeight: '140%',
    [theme.breakpoints.up('md')]: {
      fontSize: '18px',
      lineHeight: '120%',
      letterSpacing: '-0.01em'
    }
  },
  dateAndChapter: {
    display: 'flex',
    columnGap: '20px'
  },
  date: {
    color: theme.colors.black,
    fontFamily: 'Object Sans',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '10px',
    lineHeight: '140%',
    zIndex: '-1',
    [theme.breakpoints.up('md')]: {
      opacity: 0.4,
      fontSize: '12px'
    }
  },
  horizontalLine: {
    border: [1, 'solid', theme.colors.grey],
    width: '100%',
    margin: 0
  },
  chapterImage: {
    zIndex: '-1',
    [theme.breakpoints.up('md')]: {
      width: '138px',
      height: '116px'
    }
  }
}, { name: 'PlayBookWillCoverSection' })

export default PlayBookWillCoverSection
