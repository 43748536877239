import React from 'react'
import { createUseStyles } from 'react-jss'
import blueRightArrow from '../../images/blueRightArrow.svg'
import theme from '../../style/theme'
import Link from '../Link'

const ToolsLink = ({ slice, page }) => {
  const { toolsList } = slice
  const type = page.type === 'playbook' ? 'Playbook' : 'Chapter'
  const classes = useStyles()

  return (
    <div className={classes.wrapper}>
      <p className={classes.heading}>Tools in this {type}</p>
      <section className={classes.blackSection}>
        <section className={classes.upperPart}>
          <p>CHECK QUESTIONS</p>
        </section>
        {toolsList?.map((list) => (
          <section className={classes.lowerPart}>
            <p>{list.toolDescription}</p>

            <div className={classes.rightPart}>
              <Link to={list.uploadFile ? list.uploadFile.url : list.docsLink}>
                <p>LINK TO TOOL</p>
              </Link>
              <img src={blueRightArrow} />
            </div>
          </section>
        ))}
      </section>
    </div>
  )
}

const useStyles = createUseStyles(
  {
    wrapper: {
      display: 'flex',
      flexDirection: 'column',
      rowGap: '24px',
      marginRight: '126.8px',
      // marginTop: '75px',
      [theme.breakpoints.down('lg')]: {
        // marginRight: '100px',
        margin: '0 34px 0 26px '
      }
    },
    blackSection: {
      padding: [30, 30, 30, 30],
      backgroundColor: theme.colors.black,
      display: 'flex',
      flexDirection: 'column',
      rowGap: '34px',
      [theme.breakpoints.up('md')]: {
        maxWidth: '764px',
        rowGap: '10.88px'
      },
      [theme.breakpoints.down('lg')]: {
        // marginRight: '100px',
        // margin: '0 34px 73px 26px',
        paddingBottom: '18px'
      }
    },
    heading: {
      marginBottom: 0,
      fontWeight: '700',
      fontSize: '24px',
      lineHeight: '25px'
    },
    upperPart: {
      '& p': {
        marginBottom: 0,
        color: theme.colors.white,
        fontFamily: 'Object Sans',
        fontStyle: 'normal',
        fontWeight: 800,
        fontSize: '16px'
      }
    },
    lowerPart: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      columnGap: '67.93px',
      '& p': {
        marginBottom: 0,
        color: theme.colors.white,
        fontFamily: 'Object Sans',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '14px'
      },
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
        rowGap: '16.85px'
      }
    },
    rightPart: {
      display: 'flex',
      columnGap: '6.5px',
      height: 'fit-content',
      width: '116.45px',
      '& p': {
        marginBottom: 0,
        whiteSpace: 'nowrap',
        color: theme.colors.primary,
        fontFamily: 'Object Sans',
        fontStyle: 'normal',
        fontWeight: 800,
        fontSize: '12.3195px'
      },
      '& img': {
        alignSelf: 'center',
        width: '8.24px',
        height: '10.04px'
      },
      [theme.breakpoints.down('md')]: {
        alignSelf: 'flex-end',
        columnGap: '4px',

        '& img': {
          width: '10.45px',
          height: '10.45px'
        }
      }
    }
  },
  { name: 'ToolsLink' }
)

export default ToolsLink
