import React from 'react'
import { createUseStyles } from 'react-jss'
import RichText from '../RichText'
import HighlightedText from '../HighlightedText'
import Button from '../Button'
import theme, { vw } from '../../style/theme'
import SplitWordsAnimation from '../SplitWordsAnimation'
import { getForegroundColorFromBackgroundColor } from '../../style/colorHelper'
import { useDarkSlice } from '../HeaderContextProvider'
import Color from 'color'

export default ({ slice }) => {
  const { title, copy, cta_link: ctaLink, cta_label: ctaLabel, color } = slice
  const foregroundColor = getForegroundColorFromBackgroundColor(color)
  const classes = useStyles({ backgroundColor: color, foregroundColor })
  const ref = useDarkSlice(Color(color).isDark())
  return (
    <section className={classes.section} ref={ref}>
      <SplitWordsAnimation wrapped><span className={classes.title}><HighlightedText content={title.text} /></span></SplitWordsAnimation>
      {copy && <RichText content={copy.text} className={classes.copy} />}
      {ctaLabel && <Button className={classes.button} link={ctaLink[0]} invert><HighlightedText content={ctaLabel.text} /></Button>}
    </section>
  )
}

const useStyles = createUseStyles({
  sectionPadding: {
    ...theme.margins('padding')
  },
  section: {
    composes: ['$sectionPadding'],
    backgroundColor: ({ backgroundColor }) => backgroundColor || theme.colors.background,
    color: ({ foregroundColor }) => foregroundColor || theme.colors.secondary,
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: vw(80),
    paddingBottom: vw(80),
    [theme.breakpoints.up('md')]: {
      paddingTop: vw(180, 'lg'),
      paddingBottom: vw(180, 'lg')
    }
  },
  title: {
    '& h2': {
      extend: theme.typography.h1,
      textAlign: 'center'
    }
  },
  copy: {
    textAlign: 'center',
    marginTop: vw(25),
    [theme.breakpoints.up('md')]: {
      marginTop: vw(35, 'lg')
    },
    '& > p': {
      fontSize: vw(14),
      [theme.breakpoints.up('md')]: {
        fontSize: vw(14, 'lg')
      }
    }
  },
  button: {
    marginTop: vw(25),
    [theme.breakpoints.up('md')]: {
      marginTop: vw(35, 'lg')
    }
  }
})
