import React, { forwardRef, useMemo } from 'react'
import cn from 'classnames'
import detectIt from 'detect-it'
import { createUseStyles } from 'react-jss'
import Link from '../Link'
import theme from '../../style/theme'
import { resolveLink } from '../../helpers/resolveLink'

const PreHeaderLink = ({ link }) => {
  const classes = useStyles()
  const resolvedLink = useMemo(() => resolveLink(link), [link])
  return (
    <div className={classes.preHeader}>
      <Link className={cn(classes.link)} link={resolvedLink}>
        <span>{link.text}</span>
      </Link>
      <div className={cn(classes.buttonIcon)}>
        <svg viewBox='0 0 22 8' fill='#FFF' xmlns='http://www.w3.org/2000/svg'>
          <path
            d='M21.354 4.354a.5.5 0 000-.708L18.172.464a.5.5 0 10-.707.708L20.293 4l-2.828 2.828a.5.5 0 10.707.708l3.182-3.182zM0 4.5h21v-1H0v1z'
          />
        </svg>
      </div>
    </div>
  )
}

const PreHeader = forwardRef(({ isDialog, preHeader }, ref) => {
  const { preHeaderLink, images } = preHeader
  const classes = useStyles()
  return (
    <>
      {preHeaderLink.length > 0 && (
        <div className={classes.preHeaderWrapper}>
          {!isDialog && preHeaderLink.length > 0 && (
            <div className={classes.preHeaderText} ref={ref}>
              {preHeaderLink && preHeaderLink.map((link, i) => (
                <PreHeaderLink key={link.id} link={link} />
              ))}
            </div>
          )}
          {!isDialog && images.length > 0 && (
            <div className={classes.imagesWrapper}>
              {images && images.map((image, i) => (
                <img key={i} className={classes.img} src={image.sizes[0].url} alt={image.alt} />
              ))}
            </div>
          )}
        </div>
      )}
    </>
  )
})

const useStyles = createUseStyles({
  preHeader: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  preHeaderText: {
    marginBottom: 12
  },
  preHeaderWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    background: theme.colors.black,
    paddingTop: 12
  },
  imagesWrapper: {
    display: 'flex',
    overflow: 'hidden',
    width: '100%',
    justifyContent: 'center'
  },
  img: {
    width: '24px',
    marginLeft: 16,
    marginBottom: -1,
    [theme.breakpoints.up('md')]: {
      width: '36px'
    }
  },
  buttonIcon: {
    marginLeft: 12,
    '& svg': {
      display: 'block',
      width: 22,
      height: 8
    },
    '&.hide': {
      visibility: 'hidden'
    }
  },
  link: {
    display: 'inline-block',
    fontSize: '2.25vw',
    lineHeight: 1.5,
    color: theme.colors.white,
    textDecoration: 'none',
    [theme.breakpoints.up('md')]: {
      fontSize: '1vw'
    },
    position: 'relative',
    '&:after': {
      content: "''",
      display: 'block',
      position: 'absolute',
      bottom: 2,
      left: 0,
      width: '100%',
      height: 1,
      backgroundColor: theme.colors.white,
      transform: 'scaleX(0)',
      transformOrigin: 'center right',
      transition: 'transform 0.3s ease'
    },
    ...(detectIt.primaryInput === 'touch' ? {} : {
      '&:hover, &.active': {
        '&:after': {
          transform: 'scaleX(1)',
          transformOrigin: 'center left'
        }
      }
    })
  }
}, { name: 'PreHeader' })

export default PreHeader
