import React from 'react'
import { createUseStyles } from 'react-jss'
import cn from 'classnames'
import theme from '../style/theme'
import { span } from '../style/span'

const TwoColumnGrid = ({ className, leftColum, rightColum }) => {
  const classes = useStyles()

  return (
    <section className={cn(classes.content, className)}>
      <div className={classes.cols}>
        <div className={classes.col}>
          {leftColum}
        </div>
        <div className={classes.col}>
          {rightColum}
        </div>
      </div>
    </section>
  )
}

const useStyles = createUseStyles({
  content: {
    padding: [50, theme.getMargin('min')],
    position: 'relative',
    [theme.breakpoints.up('md')]: {
      padding: [100, theme.getMargin('md'), 200]
    }
  },
  cols: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row'
    }
  },
  col: {
    display: 'block',
    marginBottom: 50,
    '&:last-child': {
      marginBottom: 0
    },
    [theme.breakpoints.up('md')]: {
      marginBottom: 0,
      flex: 1,
      '&:first-child': {
        maxWidth: span(6, 'md'),
        marginRight: span(2, 'md')
      },
      '&:last-child': {
        maxWidth: span(10, 'md'),
        marginLeft: span(2, 'md')
      }
    }
  }
}, { name: 'TwoColumnGrid' })

export default TwoColumnGrid
