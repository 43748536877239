import React from 'react'
import cn from 'classnames'
import { createUseStyles } from 'react-jss'
import theme from '../../../../style/theme'
import rightArrow from '../../../../images/rightArrow.svg'

const ArrowNavigation = ({ slice, ...props }) => {
  const { className, arrowData, fieldGuidePage } = props
  const breadArray = slice?.route
  const classes = useStyles()

  return (
    <section className={cn(className, classes.arrowNavigation)}>
      {arrowData
        ? arrowData.map((item, index) => (
          <React.Fragment key={index}>
            {index < arrowData.length - 1 ? (
              <a
                style={{
                  color:
                    fieldGuidePage === 'true'
                      ? theme.colors.white
                      : theme.colors.black,
                  textDecoration: 'none'
                }}
                href={`/${item.reference.slug}`}
                key={item?.reference?.title}
              >
                <span
                  className={`${classes.spanItem} ${classes.commonStyles}`}
                >
                  {item?.reference?.title}
                </span>
                <img
                  className={`${classes.arrow} ${classes.commonStyles}`}
                  style={{
                    filter: fieldGuidePage === 'true' ? 'invert(100%)' : ''
                  }}
                  src={rightArrow}
                  alt=''
                />
              </a>
            ) : (
              <span className={classes.primaryColor}>
                {item?.reference?.title}
              </span>
            )}
          </React.Fragment>
        ))
        : breadArray &&
          breadArray.map((item, index) => (
            <React.Fragment key={index}>
              {index < breadArray.length - 1 ? (
                <a
                  style={{
                    color:
                      fieldGuidePage === 'true'
                        ? theme.colors.white
                        : theme.colors.black,
                    textDecoration: 'none'
                  }}
                  href={`/${item.reference.slug}`}
                  key={item?.reference?.title}
                >
                  <span
                    className={`${classes.spanItem} ${classes.commonStyles}`}
                  >
                    {item?.reference?.title}
                  </span>
                  <img
                    className={`${classes.arrow} ${classes.commonStyles}`}
                    src={rightArrow}
                    alt=''
                  />
                </a>
              ) : (
                <span className={classes.primaryColor}>
                  {item?.reference?.title}
                </span>
              )}
            </React.Fragment>
          ))}
    </section>
  )
}

const useStyles = createUseStyles(
  {
    commonStyles: {
      marginRight: '19px',
      textTransform: 'uppercase',
      [theme.breakpoints.up('md')]: {
        marginRight: '15px',
        fontSize: '16px'
      }
    },
    primaryColor: {
      color: theme.colors.primary,
      fontWeight: 700,
      textTransform: 'uppercase',
      fontSize: '14px'
    },
    spanItem: {
      fontSize: '14px',
      fontWeight: 400
    },
    arrow: {
      width: '6.29px',
      height: '12.59px'
    }
  },
  { name: 'ArrowNavigation' }
)

export default ArrowNavigation
