import React from 'react'
import { createUseStyles } from 'react-jss'
import theme, { vw, span } from '../../../style/theme'
import ResponsiveImage from '../../ResponsiveImage'
import RichText from '../../RichText'
import TwoColumnLayout from './TwoColumnLayout'
// import ResponsiveImage from '../ResponsiveImage4'

export default ({ slice, page }) => {
  const { primary_text: primaryText, images } = slice
  const classes = useStyles()

  const image = images[0]
  console.log(image)
  return (
    <div className={classes.container}>
      <TwoColumnLayout
        leftComponents={
          <div className={classes.image}>
            <ResponsiveImage isHeightFull {...image} aspect={1.77} captionAlignment='left' />
          </div>
        }
        rightComponents={
          <div className={classes.copy}>
            {primaryText && <RichText content={primaryText.text} />}
          </div>
        }
      />
    </div>
  )
}

const useStyles = createUseStyles({
  container: {
    width: '100%',
    // marginTop: vw(20),
    marginTop: '10px',
    [theme.breakpoints.up('md')]: {
      // marginTop: 0)
    },
    '& section': {
      marginBottom: vw(50, 'lg'),
      '& > div > div': {
        marginBottom: vw(20),
        [theme.breakpoints.up('md')]: {
          marginBottom: vw(0)
        }
      }
    },

    [theme.breakpoints.up('md')]: {
      width: vw(1000, 'lg'),
      marginLeft: 'auto',
      marginRight: theme.getMargin('md')
    }
  },
  image: {
    height: '100%',
    '& img': {
      objectFit: 'contain'
    }
  },
  copy: {
    textAlign: 'justify',
    [theme.breakpoints.up('md')]: {
      maxWidth: span(9, 'md')
    }
  }
}, 'PostImageAndText')
