import { useEffect } from 'react'

export default (refs, options, callback) => {
  useEffect(() => {
    const observer = new window.IntersectionObserver(callback, options)

    refs.forEach((ref) => {
      if (ref.current) {
        observer.observe(ref.current)
      }
    })

    return () => {
      refs.forEach((ref) => {
        if (ref.current) {
          observer.unobserve(ref.current)
        }
      })
    }
  }, [refs, options, callback])
}
