import { getOptions, pathToAction, redirect, selectLocationState } from 'redux-first-router'
import { createAction } from '@reduxjs/toolkit'
import { getCurrentRoutePath, getCurrentQueryString } from '../slices/location'
import qs from 'query-string'

export const routeContent = createAction('route/content')
export const routeEmpty = createAction('route/empty')

export const navigateCreator = (state, pathName) => {
  const { querySerializer } = getOptions()
  const { routesMap } = selectLocationState(state)
  return pathToAction(pathName, routesMap, querySerializer)
}

export const redirectCreator = (state, pathName) => {
  return redirect(navigateCreator(state, pathName))
}

export const queryStringChangeCreator = (state, query, meta = {}) => {
  const path = getCurrentRoutePath(state)
  const currentQuery = getCurrentQueryString(state)
  const newQuery = {
    ...currentQuery,
    ...query
  }
  const queryString = qs.stringify(newQuery)
  const pathName = path + (queryString ? `?${queryString}` : '')
  const redirectAction = redirectCreator(state, pathName)
  return {
    ...redirectAction,
    type: routeEmpty.toString(),
    meta: {
      ...redirectAction.meta,
      ...meta
    }
  }
}
