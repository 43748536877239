import React from 'react'
import { createUseStyles } from 'react-jss'
import theme from '../../style/theme'
import ResponsiveImage from '../ResponsiveImage'
import { formatDate } from '../../utils/format'

const CaptainProfileContent = ({ page, id }) => {
  const classes = useStyles()
  const captainName = page?.title?.split(' ')[0]
  const filteredPlaybooks = page?.allPlaybooks?.filter((book) => {
    return book?.authors?.filter((author) => author?.id === id).length
  })

  return (
    filteredPlaybooks?.length > 0 &&
      <div className={classes.section}>
        <h5 className={classes.heading}>Insights by {captainName}</h5>

        <div className={classes.wrapper}>
          {filteredPlaybooks?.map((data, index) => {
            const modifiedData = {
              ...data,
              previewImage: data.preview_image,
              postDate: data.post_date
            }
            return (
              <React.Fragment key={index}>
                <a href={modifiedData?.isCompleted === true && `/${modifiedData?.slug}`}>
                  <div className={classes.coverSection}>
                    <ResponsiveImage
                      {...modifiedData?.previewImage}
                      className={classes.chapterImage}
                    />
                    <section className={classes.rightSection}>
                      <p className={classes.aboutHeading}>{modifiedData?.title}</p>
                      <p className={classes.chapterIntro}>
                        {modifiedData?.chapter?.[0]?.title}
                      </p>
                      <div className={classes.dateAndChapter}>
                        <span className={classes.date}>
                          {formatDate(modifiedData?.postDate)}
                        </span>
                      </div>
                    </section>
                  </div>
                </a>
                <hr className={classes.horizontalLine} />
              </React.Fragment>
            )
          })}
        </div>
      </div>
  )
}

const useStyles = createUseStyles(
  {
    section: {
      display: 'flex',
      flexDirection: 'column',
      '& p': {
        marginBottom: 0
      }
    },
    heading: {
      marginBottom: '35px',
      [theme.breakpoints.up('md')]: {
        marginBottom: '30px'
      }
    },
    wrapper: {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      gridAutoRows: '1fr',
      rowGap: '5.4vw',

      [theme.breakpoints.up('md')]: {
        display: 'flex',
        flexDirection: 'column',
        rowGap: '15px'
      },

      '& a': {
        color: 'inherit',
        textDecoration: 'none'
      }
    },
    coverSection: {
      display: 'flex',
      flexDirection: 'column',
      rowGap: '10px',

      [theme.breakpoints.up('md')]: {
        flexDirection: 'row',
        columnGap: '26px'
      }
    },
    rightSection: {
      display: 'flex',
      flexDirection: 'column',
      rowGap: '10px',

      [theme.breakpoints.up('md')]: {
        flexGrow: 1,
        justifyContent: 'space-between'
      }
    },
    aboutHeading: {
      fontFamily: 'Object Sans',
      fontStyle: 'normal',
      fontWeight: '700',
      fontSize: '14px',
      lineHeight: '140%',

      [theme.breakpoints.up('md')]: {
        fontSize: '20px',
        lineHeight: '140%'
      }
    },
    dateAndChapter: {
      display: 'flex',
      columnGap: '23px'
    },
    date: {
      color: theme.colors.black,
      fontFamily: 'Object Sans',
      fontStyle: 'normal',
      fontWeight: '400',
      fontSize: '10px',
      lineHeight: '140%',

      [theme.breakpoints.up('md')]: {
        fontSize: '10px'
      }
    },
    horizontalLine: {
      display: 'none',
      [theme.breakpoints.up('md')]: {
        display: 'block',
        border: [1, 'solid', theme.colors.grey],
        width: '100%',
        margin: 0
      }
    },
    chapterImage: {
      height: '28.8vw',
      width: 'auto',
      cursor: 'pointer',
      [theme.breakpoints.up('md')]: {
        width: '146px',
        height: '146px'
      }
    },
    chapterIntro: {
      display: 'block',
      // margin: [10, 0],
      fontWeight: '400',
      fontSize: '10px',
      lineHeight: '140%',
      [theme.breakpoints.up('md')]: {
        display: 'none'
      }
    }
  },
  { name: 'CaptainContent' }
)

export default CaptainProfileContent
