import React from 'react'
import cn from 'classnames'
import { createUseStyles } from 'react-jss'
import Partner from './Partner'
import theme, { span, vw } from '../../../../style/theme'

const PartnersSlice = ({ slice }) => {
  const {
    headline,
    partner_sections: partnerSections
  } = slice
  const classes = useStyles()
  return (
    <section className={classes.content}>
      <h2 className={classes.headline}>{headline}</h2>
      {partnerSections && partnerSections.length > 0 && partnerSections.map(section => {
        const {
          id,
          title,
          partners,
          layout
        } = section
        return (
          <div key={id} className={classes.partnersGroup}>
            <h3 className={classes.sectionTitle}>{title}</h3>
            <div className={cn(classes.partners, layout)}>
              {partners.map(partner => (
                <Partner key={partner.id} layout={layout} {...partner} />
              ))}
            </div>
          </div>
        )
      })}
    </section>
  )
}

const useStyles = createUseStyles({
  content: {
    padding: [vw(30), theme.getMargin('min'), vw(60)],
    position: 'relative',
    [theme.breakpoints.up('md')]: {
      padding: [50, theme.getMargin('md'), 100]
    }
  },
  headline: {
    extend: theme.typography.h3,
    marginBottom: vw(40),
    [theme.breakpoints.up('md')]: {
      marginBottom: vw(40, 'lg')
    }
  },
  partnersGroup: {
    padding: [vw(40), 0],
    [theme.breakpoints.up('md')]: {
      padding: [vw(40, 'lg'), 0]
    }
  },
  sectionTitle: {
    extend: theme.typography.h5,
    marginBottom: vw(40),
    [theme.breakpoints.up('md')]: {
      marginBottom: vw(40, 'lg')
    }
  },
  partners: {
    display: 'grid',
    '&.four-column-grid': {
      gridTemplateColumns: '1fr 1fr',
      columnGap: vw(12),
      rowGap: vw(40),
      [theme.breakpoints.up('md')]: {
        gridTemplateColumns: '1fr 1fr 1fr 1fr',
        columnGap: span(1, 'md'),
        rowGap: vw(40, 'lg')
      }
    },
    '&.six-column-grid': {
      gridTemplateColumns: '1fr 1fr 1fr',
      columnGap: vw(12),
      rowGap: vw(40),
      [theme.breakpoints.up('md')]: {
        gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr 1fr',
        columnGap: span(1, 'md'),
        rowGap: vw(40, 'lg')
      }
    },
    '&.eight-column-grid': {
      gridTemplateColumns: '1fr 1fr 1fr 1fr',
      columnGap: vw(12),
      rowGap: vw(40),
      [theme.breakpoints.up('md')]: {
        gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr',
        columnGap: span(1, 'md'),
        rowGap: vw(40, 'lg')
      }
    }
  }
}, { name: 'PartnersSlice' })

export default PartnersSlice
