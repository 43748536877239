import React, { useRef } from 'react'
import Slices from '../Slices'
import { createUseStyles } from 'react-jss'
import theme from '../../style/theme'
import cn from 'classnames'
import useStickyElement from '../../hooks/useStickyElement'

const TwoColumnSlice = ({ slice, page }) => {
  const classes = useStyles()

  const sectionRef = useRef()
  const contentRef = useRef()

  useStickyElement(sectionRef, contentRef)

  return (
    <div
      className={cn(classes.container, (slice.mobileOrder === 'reverse' ? classes.columnReverse : classes.column))}
    >
      <div className={classes.leftContainer}>
        <Slices slices={slice.left_slices} page={page} ref={sectionRef} />
      </div>
      <div className={classes.rightContainer}>
        <Slices slices={slice.right_slices} page={page} ref={contentRef} />
      </div>
    </div>
  )
}

const useStyles = createUseStyles(
  {
    container: {
      display: 'flex'
    },
    columnReverse: {
      [theme.breakpoints.down('md')]: {
        flexDirection: 'column-reverse'
      }
    },
    column: {
      [theme.breakpoints.down('md')]: {
        flexDirection: 'column'
      }
    },
    leftContainer: {
      width: '40%',
      [theme.breakpoints.down('md')]: {
        width: '100%'
      }
    },
    rightContainer: {
      width: '60%',
      [theme.breakpoints.down('md')]: {
        width: '100%'
      }
    }
  },
  { name: 'TwoColumnSlice' }
)

export default TwoColumnSlice
