import React from 'react'
import { createUseStyles } from 'react-jss'
import theme, { vw } from '../../../style/theme'
import cn from 'classnames'

export default ({ className, leftComponents, rightComponents }) => {
  const classes = useStyles()

  return (
    <section className={cn(className, classes.section)}>
      <div className={classes.content}>
        <div className={classes.lhs}>
          {leftComponents}
        </div>
        <div className={classes.rhs}>
          {rightComponents}
        </div>
      </div>
    </section>
  )
}

const useStyles = createUseStyles({
  section: {
    margin: [0, theme.getMargin(), vw(40)],
    [theme.breakpoints.up('md')]: {
      margin: [0, theme.getMargin('md'), vw(80, 'lg')]
    }
  },
  content: {
    [theme.breakpoints.up('md')]: {
      display: 'flex'
    }
  },
  lhs: {
    marginBottom: vw(60),
    [theme.breakpoints.up('md')]: {
      width: '50%',
      marginBottom: 0,
      paddingRight: vw(16, 'lg')
    }
  },
  rhs: {
    [theme.breakpoints.up('md')]: {
      width: '50%',
      paddingLeft: vw(16, 'lg')
    }
  }
}, 'PostImages')
