import React from 'react'

export default () => {
  return (
    <svg width='26px' viewBox='0 0 26 9' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M23 8L26 4.5L23 1' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <line x1='0' y1='4.5' x2='26' y2='4.5' stroke='currentColor' />
    </svg>
  )
}
