import inDOM from 'dom-helpers/canUseDOM'
import { ActionType } from 'redux-promise-middleware'
import { fetchRelatedPlaybooks } from '../../api'
import { getRelatedPlaybooksListSlice, getRelatedPlaybooksQueryStringArguments } from '../../slices/content'
import { relatedPlaybooksLoadMoreActionCreator } from '../../slices/content/relatedPlaybooks'
import { queryStringChangeCreator, rehydrated, transitionComplete } from '../../actions'
import { pageContentActionCreator } from '../../slices/content/page'

export const POSTS_PER_PAGE = 6

export default (store) => (next) => (action) => {
  if (!inDOM) {
    return next(action)
  }
  const ret = next(action)
  const postListSlice = getRelatedPlaybooksListSlice(store.getState())
  if (postListSlice) {
    const { postPage = 0 } = getRelatedPlaybooksQueryStringArguments(
      store.getState()
    )

    const omittedPostIds = []

    if (
      action.type === rehydrated.toString() ||
      action.type === `${pageContentActionCreator}_${ActionType.Fulfilled}` ||
      action.type === transitionComplete.toString()
    ) {
      fetchRelatedPlaybooks(store.dispatch, store.getState, postListSlice.id, {
        page: 0,
        limit: POSTS_PER_PAGE * (postPage + 1),
        omit: omittedPostIds
      })
    }
    if (action.type === relatedPlaybooksLoadMoreActionCreator.toString()) {
      store.dispatch(
        queryStringChangeCreator(store.getState(), { postPage: postPage + 1 })
      )
      fetchRelatedPlaybooks(
        store.dispatch,
        store.getState,
        postListSlice.id,
        {
          page: postPage + 1,
          limit: POSTS_PER_PAGE,
          omit: omittedPostIds
        },
        true
      )
    }
  }
  return ret
}
