import React from 'react'
import isEmpty from 'lodash/isEmpty'
import PressArticleList from './PressArticleList'

export default ({ slice }) => {
  const { title, articles } = slice
  if (isEmpty(articles)) return null
  return (
    <PressArticleList articles={articles} title={title} />
  )
}
