import React, { useState, useCallback, useRef, useEffect } from 'react'
import { createUseStyles } from 'react-jss'
import cn from 'classnames'
import theme, { vw } from '../../style/theme'
import gsap from 'gsap'
import ResponsiveImage from '../ResponsiveImage'
import RightSideText from './Post/RightSideText'

export default ({ slice }) => {
  const classes = useStyles()
  const { mainImage, text, images } = slice
  const { post_text_right: postTextRight, heroImage } = text
  const { primary_text: primaryText } = postTextRight
  const ref = useRef()
  const [open, setOpen] = useState(false)
  const toggleOpen = useCallback(() => {
    setOpen(open => !open)
  }, [])
  useEffect(() => {
    if (ref.current) {
      gsap.to(ref.current, { height: open ? 'auto' : '0', duration: 0.25, ease: 'sine.inOut' })
    }
  }, [open])
  const image = mainImage[0]
  const heroImg = heroImage[0]
  const lastImg = images[0]

  return (
    <section className={cn(classes.collapseWrapper)}>
      <div className={cn(classes.grouping)} onClick={toggleOpen}>
        <h5 className={classes.groupTitle}>{text.title}</h5>
        <span className={cn(classes.toggle)}>
          <svg viewBox='0 0 10 10'>
            <line x1={0} y1={5} x2={10} y2={5} />
            {!open && <line x1={5} y1={0} x2={5} y2={10} />}
          </svg>
        </span>
      </div>
      <div ref={ref} className={cn(classes.children)}>
        {image && <ResponsiveImage {...image} aspect={2} className={classes.image} />}
        {text && <h4 className={classes.collapseHeading}>{text.title}</h4>}
        {heroImg && <ResponsiveImage {...heroImg} aspect={2} className={classes.imageHero} />}
        {text && <RightSideText text={primaryText.text} />}
        {lastImg && <ResponsiveImage {...lastImg} aspect={2} className={classes.imageHero} />}
      </div>
    </section>
  )
}

const useStyles = createUseStyles({
  collapseWrapper: {
    listStyle: 'none',
    margin: 50,
    padding: 0,
    marginBottom: vw(90),
    [theme.breakpoints.up('md')]: {
      marginBottom: vw(80, 'lg')
    }
  },
  grouping: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    backgroundColor: theme.colors.offWhite,
    padding: [10, theme.getMargin()],
    margin: [-1, -theme.getMargin(), 1],
    [theme.breakpoints.up('md')]: {
      padding: 20,
      margin: [-1, -20, 1]
    }
  },
  groupTitle: {
    flexGrow: 1
  },
  imageHero: {
    marginBottom: 40,
    marginTop: 80
  },
  collapseHeading: {
    marginTop: 40,
    marginBottom: 40
  },
  toggle: {
    border: [2, 'solid', 'currentColor'],
    width: '1em',
    height: '1em',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexGrow: 0,
    flexShrink: 0,
    fontSize: vw(32),
    marginLeft: vw(8),
    [theme.breakpoints.up('md')]: {
      marginLeft: vw(8, 'lg'),
      fontSize: vw(32, 'lg')
    },
    '& > svg': {
      width: '60%',
      height: '60%',
      strokeWidth: 1,
      stroke: 'currentColor'
    }
  },
  children: {
    overflow: 'hidden',
    height: 0,
    marginTop: 50,
    marginBottom: 50
  }
}, { name: 'Collapse' })
