import React, { useMemo, useState, useEffect, useRef, useCallback } from 'react'
import gsap from 'gsap'
import { useSelector } from 'react-redux'
import { createUseStyles } from 'react-jss'
import {
  getPortfolios,
  isPortfoliosBusy
} from '../../../redux/slices/content'
import PortfolioItem from '../PortfolioList/PortfolioItem'
import TagFilters from '../ListFilters/TagFilters'
import Loading from '../../Loading'

import theme, { vw } from '../../../style/theme'
import sortBy from 'lodash/sortBy'
import compact from 'lodash/compact'
import uniqBy from 'lodash/uniqBy'
import get from 'lodash/get'
import { isCurrentBreakpointMobile } from '../../../redux/slices/layout'

const resultsAnimateInVals = {
  opacity: 1,
  yPercent: 0,
  duration: 1,
  stagger: 0.1,
  ease: 'expo.out'
}
const resultsAnimateOutVals = {
  opacity: 0,
  yPercent: 20,
  duration: 0.2,
  ease: 'expo.out'
}

const PortfolioSearch = () => {
  const [show, setShow] = useState(true)
  const [filter, setFilter] = useState('')
  const classes = useStyles()
  const portfolios = useSelector(getPortfolios) || []
  const busy = useSelector(isPortfoliosBusy)
  const isMobile = useSelector(isCurrentBreakpointMobile)

  const listRef = useRef()

  useEffect(() => {
    if (listRef.current && !isMobile) {
      if (!busy && show) {
        gsap.fromTo(listRef.current.children, resultsAnimateOutVals, resultsAnimateInVals)
      } else {
        gsap.to(listRef.current.children, resultsAnimateOutVals)
      }
    }
  }, [show, busy, isMobile])

  const setFilterHandler = useCallback((val) => {
    if (val !== filter && show) {
      if (isMobile) {
        setFilter(val)
      } else {
        setShow(false)
        setTimeout(() => {
          setFilter(val)
        }, 300)
        setTimeout(() => {
          setShow(true)
        }, 500)
      }
    }
  }, [filter, show, isMobile])

  const tags = useMemo(() => sortBy(uniqBy(compact(portfolios.map(x => x.tag)), x => x.slug), x => x.title), [portfolios])

  const portfoliosToShow = useMemo(() => {
    return filter
      ? portfolios.filter(portfolio => get(portfolio, ['tag', 'slug']) === filter)
      : portfolios
  }, [filter, portfolios])

  return (
    <section className={classes.content}>
      <div className={classes.controlPanel}>
        <div className={classes.filterContainer}>
          <div className={classes.label}>Filter by</div>
          <TagFilters tags={tags} value={filter} setValue={setFilterHandler} />
        </div>
      </div>
      {busy ? (
        <Loading />
      ) : (
        <ul className={classes.list} ref={listRef}>
          {portfoliosToShow && portfoliosToShow.map((portfolio) => (
            <PortfolioItem key={portfolio.id} className={classes.listItem} {...portfolio} />
          ))}
        </ul>
      )}
    </section>
  )
}

const useStyles = createUseStyles({
  content: {
    padding: [50, 0],
    position: 'relative',
    [theme.breakpoints.up('md')]: {
      padding: [100, 0, 200]
    }
  },
  filterContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  label: {
    extend: theme.typography.bodySmall,
    marginRight: 50,
    whiteSpace: 'nowrap'
  },
  controlPanel: {
    padding: [0, theme.getMargin('min')],
    marginBottom: vw(16),
    position: 'relative',
    [theme.breakpoints.up('md')]: {
      marginBottom: vw(32, 'lg'),
      padding: [0, theme.getMargin('md')]
    }
  },
  list: {
    minHeight: '20vh',
    margin: 0,
    padding: 0,
    listStyle: 'none',
    position: 'relative',
    [theme.breakpoints.up('md')]: {
      minHeight: '50vh',
      padding: [0, theme.getMargin('md')]
    }
  },
  listItem: {
    [theme.breakpoints.up('md')]: {
      opacity: 0
    }
  }
})

export default PortfolioSearch
