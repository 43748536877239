import React, { useMemo } from 'react'
import { createUseStyles } from 'react-jss'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'
import groupBy from 'lodash/groupBy'
import sortBy from 'lodash/sortBy'
import theme from '../../../style/theme'
import List from './List'
import Accordion, { AccordionItem } from '../../Accordion'

export default ({ careers }) => {
  const classes = useStyles()
  const grouped = useMemo(() => {
    return sortBy(groupBy(careers, c => c.tag.title), (items) => items[0].tag.title)
  }, [careers])

  if (isEmpty(careers)) return null
  return (
    <Accordion>
      {map(grouped, (groupedCareers, group) => (
        <AccordionItem key={group} title={groupedCareers[0].tag.title} defaultOpen containerClassName={classes.container}>
          <List className={classes.groupedCareersList} careers={groupedCareers} />
        </AccordionItem>
      ))}
    </Accordion>
  )
}

const useStyles = createUseStyles({
  container: {
    margin: [0, -theme.getMargin()],
    [theme.breakpoints.up('md')]: {
      margin: [0, -20]
    }
  },
  groupedCareersList: {
    borderTop: 'none'
  }
}, { name: 'CareerListing' })
