import React from 'react'
import { createUseStyles } from 'react-jss'
import cn from 'classnames'
import RichText from '../RichText'
import TwoColumnLayout from '../TwoColumnLayout'
import theme from '../../style/theme'

const RichTextSlice = ({ className, slice }) => {
  const { text, text2, columns } = slice
  const classes = useStyles()

  return columns === '2' ? (
    <TwoColumnLayout
      leftColum={<RichText content={text} />}
      rightColum={<RichText content={text2} />}
    />
  ) : (
    <section className={cn(classes.content, className)}>
      <RichText content={text} />
    </section>
  )
}

const useStyles = createUseStyles({
  content: {
    padding: [50, theme.getMargin('min')],
    position: 'relative',
    [theme.breakpoints.up('md')]: {
      padding: [100, theme.getMargin('md'), 200]
    }
  }
}, { name: 'RichTextSlice' })

export default RichTextSlice
