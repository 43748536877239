import React, { useEffect, useRef } from 'react'
import { createUseStyles } from 'react-jss'
import { useSelector } from 'react-redux'
import { getTimelineBackgroundColor } from '../../../../redux/slices/timeline'
import { span } from '../../../../style/span'
import theme, { vw } from '../../../../style/theme'
import RichText from '../../../RichText'
import SplitWordsAnimation from '../../../SplitWordsAnimation'
import gsap from 'gsap'
import Color from 'color'

const CenteredTextBlock = ({ block, isActive }) => {
  const {
    title,
    body
  } = block
  const backgroundColor = useSelector(getTimelineBackgroundColor)
  const classes = useStyles()
  const backgroundRef = useRef()
  const previousBackgroundColorRef = useRef(theme.colors.white)
  const currentBackgroundColorRef = useRef({ color: theme.colors.white })

  useEffect(() => {
    if (previousBackgroundColorRef.current !== backgroundColor) {
      const colorize = () => {
        gsap.set(backgroundRef.current, { background: `radial-gradient(${currentBackgroundColorRef.current.color} 40%, ${Color(currentBackgroundColorRef.current.color).alpha(0).string()} 73%)` })
      }
      gsap.to(currentBackgroundColorRef.current, 0.3, { color: backgroundColor, onUpdate: colorize, ease: 'quad.inOut' })
      previousBackgroundColorRef.current = backgroundColor
    }
  }, [backgroundColor])

  return (
    <div className={classes.container}>
      <div className={classes.inner}>
        <div className={classes.background} ref={backgroundRef} />
        <SplitWordsAnimation overflow='visible'><h2 className={classes.title}>{title}</h2></SplitWordsAnimation>
        {body && <RichText content={body.text} className={classes.copy} />}
      </div>
    </div>
  )
}

const useStyles = createUseStyles({
  container: {
    width: '100%',
    [theme.breakpoints.up('md')]: {
      textAlign: 'center',
      margin: [0, 'auto'],
      maxWidth: span(10, 'md'),
      paddingTop: vw(130, 'lg')
    }
  },
  inner: {
    width: '100%',
    position: 'relative'
  },
  background: {
    display: 'none',
    pointerEvents: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'block',
      position: 'absolute',
      background: `radial-gradient(${theme.colors.white} 40%, ${Color(theme.colors.white).alpha(0).string()} 73%)`,
      top: '-10vw',
      left: '50%',
      bottom: '-10vw',
      width: span(12, 'md'),
      transform: 'translateX(-50%)'
    }
  },
  title: {
    fontSize: vw(60),
    marginBottom: vw(28),
    [theme.breakpoints.up('md')]: {
      fontSize: vw(170, 'lg'),
      marginBottom: vw(60, 'lg')
    }
  },
  copy: {
    position: 'relative'
  }
}, { name: 'CenteredTextBlock' })

export default CenteredTextBlock
