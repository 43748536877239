import React, { useState, useCallback } from 'react'
import { createUseStyles } from 'react-jss'
import color from 'color'
import cn from 'classnames'
import { useSelector } from 'react-redux'
import { getFooter } from '../redux/slices/content'
import theme from '../style/theme'

const NewsletterSignup = (props) => {
  const {
    className,
    textColor = theme.colors.white,
    borderColor = theme.colors.white,
    isLandscape = false,
    buttonText = 'Sign Up',
    busy,
    subscribed,
    error,
    onSubmit
    // audienceId = null
  } = props

  const classes = useStyles({ textColor, borderColor, isLandscape })
  const [email, setEmail] = useState('')

  const { newsletter_signup_success_copy: successCopy } = useSelector(getFooter)

  const onChange = useCallback((e) => {
    setEmail(e.target.value)
  }, [])

  const textIsDark = color(textColor).isDark()

  return (
    <form className={cn(className, classes.form)} onSubmit={(e) => onSubmit(e, email)}>
      {subscribed && <span className={classes.successMessage}>{successCopy}</span>}
      {!subscribed && (
        <div className={classes.inputWrapper}>
          <label htmlFor='email'>
            <span className='srOnly'>Email</span>
            <input className={cn(classes.input, textIsDark ? 'text-dark' : 'text-light')} required name='email' type='email' placeholder='Your email address' value={email} onChange={onChange} />
          </label>
          <button className={cn(classes.button, { busy })} type='submit'>
            <span className={cn(classes.buttonText, { hide: busy })}>{buttonText}</span>
            <div className={cn(classes.buttonIcon, { hide: busy })}>
              <svg viewBox='0 0 22 8' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <path
                  d='M21.354 4.354a.5.5 0 000-.708L18.172.464a.5.5 0 10-.707.708L20.293 4l-2.828 2.828a.5.5 0 10.707.708l3.182-3.182zM0 4.5h21v-1H0v1z'
                />
              </svg>
            </div>
            {busy && <div className={cn(classes.spinner, textIsDark ? 'dark' : 'light')} />}
          </button>
        </div>
      )}
      {error && <div className={classes.errorMessage}>Oops something went wrong</div>}
    </form>
  )
}

const useStyles = createUseStyles({
  form: {
    display: 'block'
  },
  formLabel: {
    extend: theme.typography.bodyTiny
  },
  inputWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    borderBottom: '1px solid',
    color: ({ textColor }) => textColor,
    borderColor: ({ borderColor }) => borderColor
  },
  input: {
    extend: theme.typography.bodyPlus,
    flex: '1',
    display: 'block',
    padding: [12, 0],
    border: 'none',
    backgroundImage: 'none',
    backgroundColor: 'transparent',
    boxShadow: 'none',
    color: ({ textColor }) => textColor,
    lineHeight: '1',
    '&:-webkit-autofill, &:-webkit-autofill:hover, &:-webkit-autofill:focus &:-webkit-autofill, &:-webkit-autofill:hover &:-webkit-autofill:focus': {
      boxShadow: '0 0 0px 1000px transparent inset',
      transition: 'background-color 5000s ease-in-out 0s',
      caretColor: ({ textColor }) => textColor
    },
    '&.text-dark': {
      textFillColor: `${theme.colors.black}`
    },
    '&.text-light': {
      textFillColor: `${theme.colors.white}`
    },
    '&:focus, &:active': {
      outline: 'none'
    }
  },
  button: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: '8px',
    opacity: 0.5,
    position: 'relative'
  },
  buttonText: {
    display: 'block',
    marginTop: 2,
    extend: theme.typography.bodySmall,
    textTransform: 'uppercase',
    color: ({ textColor }) => textColor,
    '&.hide': {
      visibility: 'hidden'
    }
  },
  buttonIcon: {
    marginLeft: 12,
    '& svg': {
      display: 'block',
      width: 22,
      height: 8,
      '& path': {
        fill: ({ textColor }) => textColor
      }
    },
    '&.hide': {
      visibility: 'hidden'
    }
  },
  successMessage: {
    display: 'block',
    marginTop: 16,
    [theme.breakpoints.up('md')]: {
      marginTop: ({ isLandscape }) => isLandscape ? 0 : 16,
      textAlign: ({ isLandscape }) => isLandscape ? 'center' : 'left'
    }
  },
  spinner: {
    borderRadius: '50%',
    width: '5em',
    height: '5em',
    transform: 'translateZ(0)',
    animation: '$spinner 1.1s infinite linear',
    fontSize: 3,
    position: 'absolute',
    top: '50%',
    right: 0,
    '&.dark': {
      borderTop: ['1.1em', 'solid', 'rgba(0, 0, 0, 0.2)'],
      borderRight: ['1.1em', 'solid', 'rgba(0, 0, 0, 0.2)'],
      borderBottom: ['1.1em', 'solid', 'rgba(0, 0, 0, 0.2)'],
      borderLeft: ['1.1em', 'solid', 'rgba(0, 0, 0, 1)']
    },
    '&.light': {
      borderTop: ['1.1em', 'solid', 'rgba(255, 255, 255, 0.2)'],
      borderRight: ['1.1em', 'solid', 'rgba(255, 255, 255, 0.2)'],
      borderBottom: ['1.1em', 'solid', 'rgba(255, 255, 255, 0.2)'],
      borderLeft: ['1.1em', 'solid', 'rgba(255, 255, 255, 1)']
    },
    [theme.breakpoints.up('md')]: {
      width: '7.5em',
      height: '7.5em'
    }
  },
  '@keyframes spinner': {
    '0%': {
      transform: 'translate(0, -50%) rotate(0deg)'
    },
    '100%': {
      transform: 'translate(0, -50%) rotate(360deg)'
    }
  },
  errorMessage: {
    display: 'block',
    color: theme.colors.error,
    position: 'absolute'
  }
}, { name: 'NewsletterSignup' })

export default NewsletterSignup
