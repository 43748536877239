import React, { useRef, useEffect, useContext } from 'react'
import forEach from 'lodash/forEach'
import remove from 'lodash/remove'
import useWindowResize from '../hooks/useWindowResize'
import useDebouncedCallback from '../hooks/useDebouncedCallback'

export const HeaderContext = React.createContext({ })

export const useDarkSliceListenerCallback = (callback) => {
  const { callbacks } = useContext(HeaderContext).current
  useEffect(() => {
    callbacks.current.push(callback)
    return () => {
      remove(callbacks.current, callback)
    }
  }, [callback])
}

export const useDarkSlice = (isDark = true) => {
  const ref = useRef()
  const { callbacks, darkSlicesRef, inViewElements, observerRef } = useContext(HeaderContext).current
  useEffect(() => {
    if (isDark) {
      darkSlicesRef.current.push(ref)
      if (observerRef.current) {
        observerRef.current.observe(ref.current)
      }
      return () => {
        remove(darkSlicesRef.current, ref)
        if (inViewElements.current.includes(ref.current)) {
          remove(inViewElements.current, ref.current)
          forEach(callbacks.current, cb => { cb() })
        }
        if (observerRef.current) {
          observerRef.current.unobserve(ref.current)
        }
      }
    }
  }, [isDark])
  return ref
}

export default ({ children, headerRef }) => {
  const darkSlicesRef = useRef([])
  const observerRef = useRef()
  const callbacks = useRef([])
  const inViewElements = useRef([])
  const headerContextRef = useRef({
    ref: headerRef,
    darkSlicesRef,
    observerRef,
    callbacks,
    inViewElements
  })

  const resize = () => {
    const { ref: headerRef } = headerContextRef.current
    if (headerRef.current) {
      const headerHeight = headerRef.current.clientHeight
      const rootMargin = `-${(headerHeight / 2) - 1}px 0px -${window.innerHeight - (headerHeight / 2)}px 0px`
      const options = {
        root: null,
        threshold: 0,
        rootMargin
      }

      const trigger = (entries) => {
        forEach(entries, entry => {
          if (entry.isIntersecting) {
            inViewElements.current.push(entry.target)
          } else {
            remove(inViewElements.current, entry.target)
          }
        })
        forEach(callbacks.current, cb => {
          cb()
        })
      }
      observerRef.current = new window.IntersectionObserver(trigger, options)
      forEach(darkSlicesRef.current, ref => {
        observerRef.current.observe(ref.current)
      })
      return () => {
        inViewElements.current = []
        observerRef.current.disconnect()
      }
    }
  }

  useWindowResize(useDebouncedCallback(resize, 200, []))

  return (
    <HeaderContext.Provider value={headerContextRef}>
      {children}
    </HeaderContext.Provider>
  )
}
