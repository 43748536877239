import React, { useCallback, useRef } from 'react'
import { createUseStyles } from 'react-jss'
import Flickity from 'react-flickity-component'
import gsap from 'gsap'
import theme, { vw } from '../style/theme'

const preventVerticalScrolling = (flickity) => {
  // This will prevent the vertical scrolling when scrolling in flickity
  flickity.on('dragStart', () => { document.ontouchmove = e => e.preventDefault() })
  flickity.on('dragEnd', () => { document.ontouchmove = () => true })
}

export const flickityOptions = {
  cellAlign: 'left',
  pageDots: true,
  contain: true,
  freeScroll: false,
  prevNextButtons: true,
  autoPlay: false,
  arrowShape: 'M 75,50 L 25,50 L 75,50 L 25,50 L 35,40 L 25,50 L 35,60'
}

export default function ({ className, children, title, options = flickityOptions, ...rest }) {
  const classes = useStyles()
  const containerRef = useRef()
  const setFlickityRef = useCallback((flickity) => {
    if (flickity) {
      gsap.set(flickity.element, { display: 'block', overflow: 'visible' })
      // Flickity consumes the mouse move events so we need to create a customer event so the custom mouse
      // cursor continues to follow the mouse cursor
      flickity.on('dragMove', (event, pointer, moveVector) => {
        const dragMoveEvent = new window.CustomEvent('flickity-dragMove', { detail: event })
        window.dispatchEvent(dragMoveEvent)
      })
      preventVerticalScrolling(flickity)
    }
  }, [])

  return (
    <div ref={containerRef} className={className}>
      <Flickity
        options={options}
        disableImagesLoaded
        reloadOnUpdate
        static
        className={classes.flickity}
        flickityRef={setFlickityRef}
        {...rest}
      >
        {children}
      </Flickity>
    </div>
  )
}

const useStyles = createUseStyles({
  flickity: {
    outline: 'none',
    whiteSpace: 'nowrap',
    display: 'flex',
    position: 'relative',
    '& > *': {
      flexShrink: 0
    },
    '& .flickity-prev-next-button': {
      background: 'transparent',
      position: 'absolute',
      border: [1, 'solid', theme.colors.grey],
      color: theme.colors.dark,
      top: '85%',
      left: 120,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      transform: 'translateY(-50%)',
      transition: 'background-color 0.15s ease-in-out',
      width: 32,
      height: 32,
      [theme.breakpoints.up('md')]: {
        top: '88%',
        width: 50,
        height: 50
      }
    },
    '& .flickity-prev-next-button:hover': {
      backgroundColor: 'rgba(0,0,0,0.3)'
    },
    '& .flickity-prev-next-button.next': {
      left: 100,
      [theme.breakpoints.up('md')]: {
        left: 250
      }
    },
    '& .flickity-prev-next-button.previous': {
      left: 50,
      [theme.breakpoints.up('md')]: {
        left: 150
      }
    },
    '& .flickity-button:disabled': {
      opacity: 0.3,
      cursor: 'auto',
      pointerEvents: 'none'
    },
    '& .flickity-button-icon': {
      fill: 'none',
      strokeWidth: 2,
      stroke: '#000',
      width: 30,
      height: 30,
      vectorEffect: 'non-scaling-stroke',
      [theme.breakpoints.up('md')]: {
        width: 48,
        height: 48
      }
    },
    '& .flickity-page-dots': {
      display: 'flex',
      justifyContent: 'center',
      padding: 0,
      margin: 0,

      '& > *': {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItem: 'center',
        fontSize: 0,
        margin: [0, vw(2)],
        padding: [vw(20), 0],
        [theme.breakpoints.up('md')]: {
          margin: [0, vw(5, 'md')],
          padding: [vw(30, 'md'), 0]
        },
        '&:before': {
          display: 'block',
          content: '""',
          width: vw(16),
          borderBottom: [1, 'solid', theme.colors.grey],
          [theme.breakpoints.up('md')]: {
            width: vw(35, 'md')
          }
        }
      },

      '& .is-selected': {
        '&:before': {
          borderColor: theme.colors.primary
        }
      }
    }
  }
}, { name: 'Slider' })
