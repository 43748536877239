import React from 'react'
import { createUseStyles } from 'react-jss'
import ResponsiveImage from '../../ResponsiveImage.js'
import theme from '../../../style/theme.js'

const PlaybookImage = ({ slice }) => {
  const classes = useStyles()
  const { images } = slice
  return (
    <div>
      {images.map((image, index) => (
        <React.Fragment key={index}>
          <ResponsiveImage {...image} className={classes.image} />
        </React.Fragment>
      ))}
    </div>
  )
}

const useStyles = createUseStyles(
  {
    image: {
      margin: '0 120px 70px 0',
      width: 'auto',
      zIndex: '-1',
      [theme.breakpoints.down('md')]: {
        margin: '40px 25px'
      }
    }
  },
  { name: 'PlaybookIntroTitle' }
)

export default PlaybookImage
