import React from 'react'
import { createUseStyles } from 'react-jss'
import theme, { vw } from '../../../style/theme'
import ArticleItem from './ArticleItem'

export default ({ articles, title }) => {
  const classes = useStyles()
  return (
    <section className={classes.section}>
      {title && <h4 className={classes.title}>{title}</h4>}
      <ul className={classes.list}>
        {articles && articles.map((article) => (
          <ArticleItem key={article.id} {...article} />
        ))}
      </ul>
    </section>
  )
}

const useStyles = createUseStyles({
  section: {
    margin: [0, 0, vw(60)],
    [theme.breakpoints.up('md')]: {
      margin: [0, 0, vw(100, 'lg')]
    }
  },
  title: {
    margin: [0, theme.getMargin(), '0.6em'],
    [theme.breakpoints.up('md')]: {
      margin: [0, theme.getMargin('md'), theme.getMargin('md')]
    }
  },
  list: {
    margin: 0,
    padding: 0,
    listStyle: 'none',
    borderTop: [1, 'solid', theme.colors.border],
    [theme.breakpoints.up('md')]: {
      borderTop: 'none'
    }
  }
}, { name: 'PressArticleList' })
