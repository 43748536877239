import React from 'react'
import theme from '../style/theme'
import { createUseStyles } from 'react-jss'
import RichText from './RichText'
import { useDarkSlice } from './HeaderContextProvider'
import Color from 'color'

const SaasElevationHero = ({ slice }) => {
  const { heroText, heroParagraph, color } = slice
  const classes = useStyles({ color })
  const isDark = Color(color).isDark()
  const ref = useDarkSlice(!isDark)
  console.log({ heroParagraph })
  return (
    <div className={classes.sassElevationHeroConatiner} ref={ref}>
      <div className={classes.contentContainer}>
        <div>
          <RichText
            className={classes.firstHeading}
            content={heroText.title.text}
          />
        </div>
        <div>
          <p className={classes.description}>{heroText?.subtitle}</p>
          <RichText
            className={classes.subDescription}
            content={heroParagraph.text}
          />
        </div>
      </div>
    </div>
  )
}

const useStyles = createUseStyles({
  sassElevationHeroConatiner: {
    background: '#000023',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: '155px 20px 55px 20px',
    [theme.breakpoints.down('md')]: {
      padding: '120px 20px 55px 20px'
    }
  },
  contentContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    gap: '50px',
    [theme.breakpoints.up('md')]: {
      maxWidth: '45%',
      gap: '50px'
    },
    [theme.breakpoints.down('mobile')]: {
      gap: '20px'
    }
  },
  firstHeading: {
    color: 'white',
    textAlign: 'center',
    fontSize: '100px',
    fontWeight: '400',
    fontFamily: 'Mars Condensed, Arial',
    [theme.breakpoints.down('mobile')]: {
      fontSize: '16vw'
    }
  },
  description: {
    color: '#3382FF',
    textAlign: 'center',
    fontSize: '16px',
    fontWeight: '700',
    paddingBottom: '24px',
    [theme.breakpoints.down('mobile')]: {
      fontSize: '4vw',
      paddingBottom: '15px'
    }
  },
  subDescription: {
    color: '#FFF',
    textAlign: 'center',
    fontSize: '16px',
    fontWeight: '400',
    paddingBottom: '0px',
    [theme.breakpoints.down('mobile')]: {
      fontSize: '4vw'
    }
  }
}, { name: 'SaasCompassHero' })

export default SaasElevationHero
