import React from 'react'
import theme from '../style/theme'
import { createUseStyles } from 'react-jss'

const BackgroundVideo = ({ url }) => {
  const classes = useStyles()
  return (
    <>
      <div className={classes.videoWrapper}>
        <video className={classes.video} autoPlay muted loop playsInline>
          <source src={url} type='video/mp4' />
        </video>
      </div>
      <div className={classes.gradientOverlay} />
    </>
  )
}

const useStyles = createUseStyles({
  section: {
    position: 'relative',
    width: '100vw',
    height: '100vh',
    overflow: 'hidden',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('fab')]: {
      height: '90vh'
    }
  },
  video: {
    width: '100%',
    height: '100vh',
    objectFit: 'cover',
    objectPosition: 'center center'
  },
  gradientOverlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '101vh',
    background: 'linear-gradient(to top, rgba(0,0,0,0.9) 0%, rgba(0,0,0,0.3) 50%)'
  }
}, { name: 'BackgroundVideo' })

export default BackgroundVideo
