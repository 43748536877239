import React, { useCallback } from 'react'
import { createUseStyles } from 'react-jss'
import cn from 'classnames'
import theme, { vw } from '../../style/theme'
import { ReactComponent as YouTubeIcon } from '../../images/youtube.svg'
import { ReactComponent as HeadphonesIcon } from '../../images/headphones.svg'

const SourceToggleButtons = ({ className, source, onChange, invert }) => {
  const classes = useStyles({ invert })

  const onVideoSourceClick = useCallback(() => {
    onChange('video')
  }, [])

  const onAudioSourceClick = useCallback(() => {
    onChange('audio')
  }, [])

  return (
    <div className={cn(className, classes.container)}>
      <label className={classes.label}>Media type</label>
      <button className={cn(classes.toggleButton, { active: source === 'video' })} onClick={onVideoSourceClick}>Video <YouTubeIcon /></button>
      <button className={cn(classes.toggleButton, { active: source === 'audio' })} onClick={onAudioSourceClick}>Audio <HeadphonesIcon /></button>
    </div>
  )
}

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    alignItems: 'center'
  },
  label: {
    marginRight: vw(16),
    fontSize: vw(14),
    display: 'none',
    [theme.breakpoints.up('md')]: {
      fontSize: vw(14, 'lg'),
      marginRight: vw(16, 'lg'),
      display: 'block'
    }
  },
  toggleButton: {
    extend: theme.typography.h6,
    display: 'flex',
    alignItems: 'center',
    backgroundColor: 'transparent',
    fontSize: vw(14),
    padding: [vw(10), vw(16)],
    border: [1, 'solid', theme.colors.white],
    borderColor: ({ invert }) => invert ? theme.colors.black : theme.colors.white,
    color: ({ invert }) => invert ? theme.colors.black : theme.colors.white,
    transition: 'background-color 0.15s ease-in-out, color 0.15s ease-in-out',
    textTransform: 'uppercase',
    '&:not(:first-child)': {
      marginLeft: -1
    },
    '&:hover, &.active': {
      backgroundColor: theme.colors.primary,
      color: theme.colors.white
    },
    '& > svg': {
      display: 'block',
      marginLeft: 8,
      marginBottom: 1
    },
    [theme.breakpoints.up('md')]: {
      fontSize: vw(16, 'lg'),
      padding: [vw(10, 'lg'), vw(16, 'lg')]
    }
  }
}, { name: 'SourceToggleButtons' })

export default SourceToggleButtons
