import React from 'react'

export default ({ className, playing }) => {
  return (
    <svg className={className} width='61' height='60' viewBox='0 0 61 60' fill='none' xmlns='http://www.w3.org/2000/svg'>
      {playing && (
        <g>
          <path d='M19 45V15H26.5V45H19Z' fill='currentColor' />
          <path d='M34 45V15H41.5V45H34Z' fill='currentColor' />
        </g>
      )}
      {!playing && (
        <path d='M42 29.5L23.25 40.3253L23.25 18.6747L42 29.5Z' fill='currentColor' />
      )}
      <rect x='0.75' y='0.5' width='59' height='59' stroke='currentColor' />
    </svg>
  )
}
