/*eslint-disable*/
import React, { useState, useEffect } from 'react'
import { createUseStyles } from 'react-jss'
import theme, { vw } from '../../../style/theme'
import RichText from '../../RichText'
import ResponsiveImage from '../../ResponsiveImage'
import { adjacentSide } from '../../../helpers/trigonometry'
import cn from 'classnames'
import map from 'lodash/map'
import { useDarkSlice } from '../../HeaderContextProvider'
import { ReactComponent as TwitterIcon } from '../../../images/twitter.svg'
import { ReactComponent as InstagramIcon } from '../../../images/instagram.svg'
import { formatLongDate } from '../../../utils/format'
import NewsLetter from '../NewsLetter'

const PlaybookDetail = ({ slice, page, atPlayBookPage, isMobile }) => {
  const classes = useStyles({ atPlayBookPage, isMobile })

  const { createdAt: postDate, authors: authorList } = page

  const modal_title = 'Subscribe To SaaS Compass'
  const modal_description =
    'Receive new articles and resources directly in your inbox.'

  const [shareLinks, setShareLinks] = useState({})

  useEffect(() => {
    setShareLinks({
      instagram: `https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(
        window.location.href,
      )}&title=${encodeURIComponent(page.title)}&summary=&source=`,
      twitter: `https://twitter.com/intent/tweet?url=${encodeURIComponent(
        window.location.href,
      )}&text=${encodeURIComponent(page.title)}`,
      facebook: `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
        window.location.href,
      )}`,
    })
  }, [page])

  return (
    <div className={classes.container}>
      <div className={classes.fields}>
        <dl className={classes.list}>
          {/* <dt>Share</dt> */}
          <dd>
            <a
              href={shareLinks.twitter}
              className={classes.shareLink}
              rel="noopener noreferrer"
              target="_blank"
            >
              <TwitterIcon className={classes.twitterIcon} />
            </a>
            <a
              href={shareLinks.instagram}
              className={classes.shareLink}
              rel="noopener noreferrer"
              target="_blank"
            >
              <InstagramIcon className={classes.instagramIcon} />
            </a>
          </dd>
        </dl>
        {/* <NewsLetter title={modal_title} description={modal_description} className={classes.bttn} /> */}
      </div>
    </div>
  )
}

const useStyles = createUseStyles(
  {
    bttn: {
      padding: '17.4px 0',
      width: '100%',

      [theme.breakpoints.down('md')]: {
        display: 'none',
      },
    },
    container: {
      // margin: '27px 32px 0 27px', //for future change
      margin: ({ atPlayBookPage }) =>  atPlayBookPage ? '0px 32px 0 0px' : '0px 32px 0 25px',
      justifySelf: 'flex-end',
      [theme.breakpoints.up('md')]: {
        margin: ({ atPlayBookPage }) =>  atPlayBookPage ? '0px 32px 0 0px' : '110px 32px 0 0px',
        // marginLeft: '51px', //for future reference
        width: 'fit-content',
      },
    },
    shareLink: {
      color: theme.colors.black,
      display: 'inline-block',
      '&:not(:last-child)': {
        marginRight: 12,
      },
      '&:hover': {
        color: theme.colors.primary,
      },
    },
    twitterIcon: {
      width: vw(20),
      height: vw(16),
      [theme.breakpoints.up('md')]: {
        width: vw(20, 'lg'),
        height: vw(16, 'lg'),
      },
    },
    instagramIcon: {
      width: vw(16),
      height: vw(16),
      [theme.breakpoints.up('md')]: {
        width: vw(16, 'lg'),
        height: vw(16, 'lg'),
      },
    },
    list: {
      display: 'flex',
      flexWrap: 'wrap',
      width: '100%',
      [theme.breakpoints.up('md')]: {
        width: vw(350, 'lg'),
      },
      '& > dt, & > dd': {
        fontSize: vw(14),
        margin: 0,
        paddingTop: vw(16),
        paddingBottom: vw(16),
        [theme.breakpoints.up('md')]: {
          fontSize: vw(14, 'lg'),
          paddingTop: vw(16, 'lg'),
          paddingBottom: vw(16, 'lg'),
        },
      },
      '& > dt': {
        width: '40%',
        paddingRight: 16,
        color: '#9999A7',
        borderBottom: [1, 'solid', theme.colors.border],

        // '&:last-of-type': {
        //   border: 'none',
        // },
      },
      '& > dd': {
        width: '60%',
        // for future Reference
        // textAlign: 'right',
        // borderBottom: [1, 'solid', theme.colors.border],
        '& *': {
          fontSize: vw(14),
          [theme.breakpoints.up('md')]: {
            fontSize: vw(14, 'lg'),
          },
        },
      },
    },
    authorNames: {
       marginBottom: 0
    }
  },
  { name: 'PlaybookDetail' },
)

export default PlaybookDetail
