import React, { useRef, useEffect } from 'react'
import gsap from 'gsap'
import { createUseStyles } from 'react-jss'
import RichText from '../../RichText'
import theme, { vw } from '../../../style/theme'
import { span } from '../../../style/span'

const animateInValues = { ease: 'expo.out', yPercent: 0, opacity: 1, stagger: 0.16, duration: 1.6, visibility: 'visible', delay: 0.5 }
const animateOutValues = { ease: 'expo.out', yPercent: 20, opacity: 0, duration: 0 }

const EndBlock = ({ show, endContent }) => {
  const classes = useStyles()

  const ref = useRef()

  useEffect(() => {
    if (ref.current) {
      gsap.set(ref.current.children, animateOutValues)
    }
  }, [])

  useEffect(() => {
    if (ref.current) {
      if (show) {
        gsap.to(ref.current.children, animateInValues)
      }
    }
  }, [show])

  return (
    <section className={classes.content} ref={ref}>
      {endContent && <RichText className={classes.textBody} content={endContent.text} />}
    </section>
  )
}

const useStyles = createUseStyles({
  content: {
    padding: [50, 0],
    position: 'relative',
    [theme.breakpoints.up('md')]: {
      padding: [100, 0, 200],
      maxWidth: span(18, 'md'),
      margin: [0, 'auto']
    }
  },
  textBody: {
    [theme.breakpoints.up('md')]: {
      maxWidth: span(18, 'md'),
      margin: [0, 'auto'],
      textAlign: 'center'
    },
    '& p': {
      fontSize: vw(20),
      lineHeight: 1.4,
      marginBottom: vw(25),
      [theme.breakpoints.up('md')]: {
        fontSize: vw(35, 'lg'),
        marginBottom: vw(50, 'lg')
      }
    }
  }
}, { name: 'EndBlock' })

export default EndBlock
