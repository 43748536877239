import React, { useMemo } from 'react'
import { createUseStyles } from 'react-jss'
import theme, { vw, span } from '../../../style/theme'
import RichText from '../../RichText'
import CountdownTimer from '../../CountdownTimer'
import { formatLongDate } from '../../../utils/format'
import TwoColumnLayout from './TwoColumnLayout'
import Button from '../../Button'
import { resolveLink } from '../../../helpers/resolveLink'

export default ({ slice, page }) => {
  const {
    copy,
    event_date: eventDate,
    contact_phone: contactPhone,
    contact_email: contactEmail,
    venue,
    register_link: registerLink
  } = slice
  const classes = useStyles()

  const resolvedLink = useMemo(() => resolveLink(registerLink), [registerLink])

  return (
    <div className={classes.content}>
      <TwoColumnLayout
        leftComponents={
          <div className={classes.fields}>
            <h3 className={classes.detailsTitle}>Event Details</h3>
            <dl className={classes.list}>
              {venue && (
                <>
                  <dt>Venue</dt>
                  <dd>{venue}</dd>
                </>
              )}
              {eventDate && (
                <>
                  <dt>Date</dt>
                  <dd>{formatLongDate(eventDate)}</dd>
                </>
              )}
              {contactPhone && (
                <>
                  <dt>Call</dt>
                  <dd>
                    <a className={classes.link} href={`tel:${contactPhone}`}>
                      {contactPhone}
                    </a>
                  </dd>
                </>
              )}
              {contactEmail && (
                <>
                  <dt>Email</dt>
                  <dd>
                    <a className={classes.link} href={`mailto:${contactEmail}`}>
                      {contactEmail}
                    </a>
                  </dd>
                </>
              )}
            </dl>
            {resolvedLink && (
              <Button
                link={resolvedLink}
                className={classes.registerButton}
                secondaryColor={theme.colors.primary}
              >
                <span>{resolvedLink[0].text}</span>
              </Button>
            )}
          </div>
        }
        rightComponents={
          <>
            {eventDate && (
              <CountdownTimer eventDate={eventDate} />
            )}
            <div className={classes.copy}>
              {copy && <RichText content={copy.text} />}
            </div>
          </>
        }
      />
    </div>
  )
}

const useStyles = createUseStyles({
  content: {
    padding: [vw(50), 0],
    position: 'relative',
    [theme.breakpoints.up('md')]: {
      padding: [100, 0, 0]
    }
  },
  detailsTitle: {
    extend: theme.typography.bodySmall,
    fontWeight: theme.typography.fontWeight.bold,
    textTransform: 'uppercase',
    color: theme.colors.primary,
    lineHeight: 1.5
  },
  copy: {
    [theme.breakpoints.up('md')]: {
      maxWidth: span(9, 'md')
    }
  },
  registerButton: {
    width: '100%',
    padding: [10, 0, 12],
    cursor: 'pointer',
    [theme.breakpoints.up('md')]: {
      width: vw(450, 'lg')
    },
    '& span': {
      fontSize: vw(20),
      lineHeight: 1.2,
      [theme.breakpoints.up('md')]: {
        fontSize: vw(30, 'md')
      }
    }
  },
  link: {
    textDecoration: 'none',
    color: 'currentColor'
  },
  list: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: vw(450, 'lg')
    },
    '& > dt, & > dd': {
      fontSize: vw(14),
      margin: 0,
      borderStyle: 'solid',
      borderColor: theme.colors.border,
      borderWidth: [0, 0, 1, 0],
      paddingTop: vw(16),
      paddingBottom: vw(16),
      [theme.breakpoints.up('md')]: {
        fontSize: vw(14, 'lg'),
        paddingTop: vw(16, 'lg'),
        paddingBottom: vw(16, 'lg')
      }
    },
    '& > dt': {
      width: '40%',
      paddingRight: 16,
      opacity: 0.5
    },
    '& > dd': {
      width: '60%',
      textAlign: 'right'
    }
  },
  fieldValue: {
    '& a': {
      color: theme.colors.black,
      textDecoration: 'none',
      '&:hover': {
        color: theme.colors.primary
      }
    },
    '& > p': {
      fontSize: vw(14),
      [theme.breakpoints.up('md')]: {
        fontSize: vw(14, 'lg')
      }
    },
    '& ul': {
      padding: 0,
      listStyle: 'none',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      flexWrap: 'wrap',
      margin: [0, '-0.35em']
    },
    '& li': {
      padding: 0,
      margin: [0, '0.35em']
    }
  }
}, 'EventIntro')
