import React, { forwardRef } from 'react'
import detectIt from 'detect-it'
import { createUseStyles } from 'react-jss'
import cn from 'classnames'
import Link from '../Link'
import ResponsiveImage from '../ResponsiveImage'
import theme from '../../style/theme'
import { span } from '../../style/span'
import { vw } from '../../style/vw'
import { resolveInternalLinkUrl } from '../../helpers/resolveLink'
import { quart } from '../../style/eases'

const TeamMember = ({ photo, position, title, page }) => {
  const classes = useStyles()
  const url = resolveInternalLinkUrl(page)
  const alt = photo.alt || `A photo of ${title}`
  return (
    <div className={classes.teamMember}>
      <Link className={classes.link} to={url} nonLinkTag='div'>
        <div className={classes.profilePhoto}>
          <ResponsiveImage className={classes.image} {...photo} alt={alt} />
        </div>
        <div className={classes.rowContent}>
          <span className={classes.teamMemberName}>{title}</span>
          <span className={classes.teamMemberPosition}>{position}</span>
          <div className={classes.arrow}>
            <svg viewBox='0 0 27 8' fill='none' xmlns='http://www.w3.org/2000/svg'>
              <path
                d='M26.354 4.354a.5.5 0 000-.708L23.172.464a.5.5 0 10-.707.708L25.293 4l-2.828 2.828a.5.5 0 10.707.708l3.182-3.182zM0 4.5h26v-1H0v1z'
                fill='currentColor'
              />
            </svg>
          </div>
        </div>
      </Link>
    </div>
  )
}

const TeamList = forwardRef(({ className, slice }, ref) => {
  const { portfolioCompanyRef } = slice
  const { teamMembers } = portfolioCompanyRef
  const classes = useStyles()
  return (
    <section className={cn(classes.content, className)}>
      {/* <h2 className={classes.title}>{title}</h2> */}
      <div className={classes.teamMembers}>
        {teamMembers && teamMembers.map((teamMember, i) => (
          <TeamMember key={teamMember.name + (i + '')} {...teamMember} />
        ))}
      </div>
    </section>
  )
})

const useStyles = createUseStyles({
  content: {
    padding: [0, 0, vw(50)],
    position: 'relative',
    [theme.breakpoints.up('md')]: {
      padding: [0, 0, vw(80, 'lg')]
    }
  },
  title: {
    extend: theme.typography.h4,
    marginBottom: theme.getMargin(),
    padding: [0, theme.getMargin('min')],
    [theme.breakpoints.up('md')]: {
      marginBottom: theme.getMargin('md'),
      padding: [0, theme.getMargin('md')]
    }
  },
  teamMember: {
    display: 'block',
    overflow: 'hidden',
    position: 'relative',
    padding: [0, theme.getMargin('min')],
    [theme.breakpoints.up('md')]: {
      padding: [0, theme.getMargin('md')]
    },
    '&:before': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: `calc(-${theme.getGutter('md')}px)`,
      width: `calc(100% + ${theme.getGutter('md') * 2}px)`,
      height: '100%',
      right: 0,
      bottom: 0,
      backgroundColor: theme.colors.primary,
      transition: `transform 0.25s ${quart.inOut}`,
      transform: 'translateY(110%)',
      [theme.breakpoints.up('md')]: {
        left: -theme.getMargin('md'),
        right: -theme.getMargin('md')
      }
    },
    ...(detectIt.primaryInput === 'touch' ? {
    } : {
      '&:hover': {
        '&:before': {
          transform: 'translateY(0%)'
        }
      }
    })
  },
  link: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    textDecoration: 'none',
    overflow: 'hidden',
    color: theme.colors.text,
    transition: `color 0.25s ${quart.inOut}`,
    padding: [10, 0],
    [theme.breakpoints.up('md')]: {
      padding: [20, 0]
    },
    '&:last-child': {
      borderBottom: [1, 'solid', theme.colors.border]
    },
    '& > *': {
      marginRight: 20,
      [theme.breakpoints.up('md')]: {
        marginRight: 50
      },
      '&:last-child': {
        marginRight: 0
      }
    },
    ...(detectIt.primaryInput === 'touch' ? {
      '&:hover': {
        backgroundColor: theme.colors.offWhite
      }
    } : {
      '&:hover': {
        color: theme.colors.white,
        '&:before': {
          opacity: 1,
          transform: 'translateY(0%)'
        }
      }
    })
  },
  rowContent: {
    position: 'relative',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      alignItems: 'center'
    }
  },
  profilePhoto: {
    height: 90,
    width: 90,
    position: 'relative',
    [theme.breakpoints.up('md')]: {
      height: 115,
      width: 115
    }
  },
  image: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0
  },
  teamMemberName: {
    extend: theme.typography.bodyPlus,
    marginBottom: 16,
    [theme.breakpoints.up('md')]: {
      minWidth: span(6, 'md'),
      marginBottom: 0
    }
  },
  teamMemberPosition: {
    flex: 1,
    display: 'block',
    extend: theme.typography.bodySmall
  },
  arrow: {
    display: 'none',
    // color: theme.colors.primary,
    [theme.breakpoints.up('md')]: {
      display: 'block',
      height: 8,
      width: 27,
      margin: [0, 0, 0, span(1, 'md')],
      '& svg': {
        display: 'block'
      }
    }
  }
}, { name: 'TeamList' })

export default TeamList
