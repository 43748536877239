import React, { useRef } from 'react'
import cn from 'classnames'
import { createUseStyles } from 'react-jss'
import PostTile from '../Summit/PostTile'
import PostList from '../Summit/PostList'
import useStickyElement from '../../hooks/useStickyElement'
import { resolveInternalLink } from '../../helpers/resolveLink'
import theme, { vw, span } from '../../style/theme'

const FeaturedPostListSlice = ({ slice }) => {
  const {
    primary_post: primaryPost,
    secondary_posts: secondaryPosts
  } = slice
  const classes = useStyles()

  const sectionRef = useRef()
  const contentRef = useRef()

  useStickyElement(sectionRef, contentRef)

  return (
    <div className={classes.content}>
      <h2 className={classes.featuredTitle}>Featured</h2>
      <div className={classes.cols} ref={sectionRef}>
        <div className={cn(classes.col, classes.colLeft)}>
          <div className={classes.featuredWrapper} ref={contentRef}>
            <PostTile
              {...primaryPost}
              link={resolveInternalLink(primaryPost)}
              isFeatured
            />
          </div>
        </div>
        <div className={cn(classes.col, classes.colRight)}>
          <PostList posts={secondaryPosts} />
        </div>
      </div>
    </div>
  )
}

const useStyles = createUseStyles({
  content: {
    padding: [vw(30), theme.getMargin('min')],
    position: 'relative',
    [theme.breakpoints.up('md')]: {
      padding: [vw(40, 'md'), theme.getMargin('md')]
    }
  },
  cols: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row'
    }
  },
  col: {
    display: 'block',
    marginBottom: vw(50),
    '&:last-child': {
      marginBottom: 0
    },
    [theme.breakpoints.up('md')]: {
      marginBottom: 0,
      flex: 1,
      '&:first-child': {
        maxWidth: span(12, 'md'),
        marginRight: span(1, 'md')
      },
      '&:last-child': {
        maxWidth: span(10, 'md'),
        marginLeft: span(1, 'md')
      }
    }
  },
  featuredTitle: {
    extend: theme.typography.h5,
    marginBottom: vw(30),
    [theme.breakpoints.up('md')]: {
      marginBottom: vw(30, 'md')
    }
  }
}, { name: 'FeaturedPostListSlice' })

export default FeaturedPostListSlice
