import { createAction } from '@reduxjs/toolkit'
import makeFetchSlice from '../makeFetchSlice'

export const postsContentActionCreator = createAction('posts/content', (payload, loadMore) => ({
  payload,
  meta: { scope: 'posts', loadMore }
}))

export const postsLoadMoreActionCreator = createAction('posts/load_more', (payload) => ({
  payload,
  meta: { scope: 'posts' }
}))

export const postsChangeInitiativeActionCreator = createAction('posts/change_initiative', (payload) => ({
  payload,
  meta: { scope: 'posts' }
}))

export const serverErrorActionCreator = createAction('posts/serverError')

const postsSlice = makeFetchSlice('posts', postsContentActionCreator, serverErrorActionCreator)

export default postsSlice.reducer
