import React, { forwardRef } from 'react'
import detectIt from 'detect-it'
import cn from 'classnames'
import isEmpty from 'lodash/isEmpty'
import { createUseStyles } from 'react-jss'
import RichText from '../RichText'
import SocialLink from '../SocialLink'
import CompanyLogo from '../CompanyLogo'
import HighlightedText from '../HighlightedText'
import ResponsiveImage from '../ResponsiveImage'
import Button from '../Button'
import theme, { vw } from '../../style/theme'
import { span } from '../../style/span'
import ArrowUpIcon from '../../images/ArrowUpIcon'
import { useDarkSlice } from '../HeaderContextProvider'

const PortfolioHero = forwardRef(({ slice }, ref) => {
  const { portfolioCompany, show_hero_image: showHero } = slice
  const {
    title,
    bio,
    logo,
    heroImage,
    socialLinks,
    stage,
    links,
    inception
  } = portfolioCompany
  const hasHero = !isEmpty(heroImage)
  const degrees = 6.5
  const adjacentSide = Math.tan(degrees * Math.PI / 180) * 100
  const classes = useStyles({ adjacentSide, hasHero })
  const sectionRef = useDarkSlice()

  return (
    <section className={classes.section} ref={sectionRef}>
      {showHero && hasHero ? (
        <div className={classes.heroWrapper}>
          <ResponsiveImage className={classes.heroImage} {...heroImage} />
          <div className={classes.clipArea} />
        </div>
      ) : (
        <div className={classes.headerSpace} />
      )}
      <div className={classes.content}>
        <div className={classes.titleWrapper}>
          <h1 className={classes.heroTitle}>{title}</h1>
        </div>
        <div className={classes.cols}>
          <div className={cn(classes.col, classes.colLeft)}>
            <div className={classes.rows}>
              <div className={classes.row}>
                <CompanyLogo className={classes.logo} logo={logo} />
              </div>
              <div className={classes.row}>
                <span className={classes.rowLabel}>Stage</span>
                <span className={classes.stage}>{stage}</span>
              </div>
              <div className={classes.row}>
                <span className={classes.rowLabel}>Connect</span>
                <div className={classes.socialLinks}>
                  {socialLinks && socialLinks.map((socialLink, i) => (
                    <SocialLink
                      key={socialLink.id}
                      type={socialLink.title}
                      url={socialLink.url}
                    />
                  ))}
                </div>
              </div>
              <div className={cn(classes.row, classes.bottomRow)}>
                <p className={classes.inception}>{inception}</p>
                <div className={classes.links}>
                  {links && links.map((link, i) => {
                    const {
                      id,
                      cta_link: ctaLink,
                      cta_label: ctaLabel
                    } = link
                    if (!ctaLink) return null
                    return (
                      <Button key={id} className={classes.button} link={ctaLink[0]}>
                        <HighlightedText content={ctaLabel.text} />
                      </Button>
                    )
                  })}
                </div>
              </div>
            </div>
          </div>
          <div className={cn(classes.col, classes.colRight)}>
            {bio && <RichText content={bio.text} className={classes.bio} />}
          </div>
        </div>
      </div>
      <div className={classes.elevatedArrow}>
        <ArrowUpIcon />
      </div>
    </section>
  )
})

const useStyles = createUseStyles({
  section: {
    backgroundColor: theme.colors.secondary,
    color: theme.colors.white,
    position: 'relative',
    marginBottom: vw(50),
    [theme.breakpoints.up('md')]: {
      marginBottom: vw(80, 'lg')
    }
  },
  heroWrapper: {
    position: 'relative',
    paddingBottom: '130vw',
    [theme.breakpoints.up('md')]: {
      paddingBottom: '40vw'
    }
  },
  heroImage: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0
  },
  clipArea: {
    position: 'absolute',
    bottom: 0,
    clipPath: 'polygon(100% 0, 0 100%, 100% 100%)',
    width: '100%',
    height: ({ adjacentSide }) => `${adjacentSide}vw`,
    backgroundColor: theme.colors.secondary,
    zIndex: 1,
    marginBottom: -1
  },
  headerSpace: {
    ...theme.header.height('paddingTop')
  },
  content: {
    padding: [vw(50), theme.getMargin('min')],
    position: 'relative',
    [theme.breakpoints.up('md')]: {
      padding: [100, span(1, 'md'), 200],
      margin: [0, theme.getMargin('md')]
    }
  },
  titleWrapper: {
    marginBottom: 50,
    [theme.breakpoints.up('md')]: {
      marginBottom: 100
    }
  },
  heroTitle: {
    extend: theme.typography.h0
  },
  cols: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row'
    }
  },
  col: {
    display: 'block',
    marginBottom: 50,
    '&:last-child': {
      marginBottom: 0
    },
    [theme.breakpoints.up('md')]: {
      marginBottom: 0,
      flex: 1,
      '&:first-child': {
        maxWidth: span(6, 'md'),
        marginRight: span(2, 'md')
      },
      '&:last-child': {
        maxWidth: span(10, 'md'),
        marginLeft: span(2, 'md')
      }
    }
  },
  rows: {
    display: 'flex',
    flexDirection: 'column'
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: [1, 'solid', 'rgba(255, 255, 255, 0.5)'],
    padding: [16, 0],
    '&:first-child': {
      paddingTop: 0
    },
    '&:last-child': {
      paddingBottom: 0,
      borderBottom: 'none'
    }
  },
  rowLabel: {
    extend: theme.typography.bodySmall
  },
  stage: {
    extend: theme.typography.h6
  },
  socialLinks: {
    display: 'flex',
    '& a': {
      marginRight: 16,
      '&:last-child': {
        marginRight: 0
      },
      '& svg': {
        fill: theme.colors.white
      },
      opacity: 0.5,
      transition: 'opacity 0.3s ease',
      ...(detectIt.primaryInput === 'touch' ? {} : {
        '&:hover, &.active': {
          opacity: 1
        }
      })
    }
  },
  bottomRow: {
    padding: 0,
    flexDirection: 'column'
  },
  inception: {
    display: 'block',
    width: '100%',
    margin: [30, 0]
  },
  links: {
    width: '100%',
    display: 'flex',
    '& > *': {
      flex: 1,
      marginRight: 25,
      '&:last-child': {
        marginRight: 0
      }
    }
  },
  elevatedArrow: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'block',
      position: 'absolute',
      bottom: 75,
      zIndex: 11,
      right: theme.getMargin('md'),

      '& svg': {
        display: 'block',
        height: 60,
        width: 8,
        fill: theme.colors.white,

        [theme.breakpoints.up('md')]: {
          transform: `translateX(${span(-1, 'md')})`
        }
      }
    }
  }
}, { name: 'PortfolioHero' })

export default PortfolioHero
