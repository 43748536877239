import React, { useMemo } from 'react'
import ErrorBoundary from '../../ErrorBoundary'

import BlockWrapper from './Blocks/BlockWrapper'
import CenteredTextBlock from './Blocks/CenteredTextBlock'
import TimelineTwoColumnBlock from './Blocks/TimelineTwoColumnBlock'
import TimelineFiguresBlock from './Blocks/TimelineFiguresBlock'
import TimelineLogosBlock from './Blocks/TimelineLogosBlock'
import VideoBlock from './Blocks/VideoBlock'
import SideBySideTextBlock from './Blocks/SideBySideTextBlock'
import EventBlock from './Blocks/EventBlock'

const sliceComponentSelector = {
  timeline_two_column_block: TimelineTwoColumnBlock,
  timeline_figures_block: TimelineFiguresBlock,
  timeline_logos_block: TimelineLogosBlock,
  centered_text_block: CenteredTextBlock,
  video_block: VideoBlock,
  side_by_side_text_block: SideBySideTextBlock,
  event: EventBlock
}

const Blocks = ({ blocks }) => {
  const sliceComponents = useMemo(() => {
    if (!blocks) return null
    return blocks.map((block, index) => {
      const {
        timeline_label: timelineLabel,
        text_column_position: textPosition,
        time,
        location,
        type,
        color
      } = block
      const Component = sliceComponentSelector[block.type]
      if (!Component) return null
      return (
        <ErrorBoundary key={`block-${index}`}>
          <BlockWrapper
            timelineLabel={timelineLabel || time}
            timelineLabelSubLabel={location}
            textPosition={textPosition}
            index={index}
            blocks={blocks}
            type={type}
            backgroundColor={color}
          >
            <Component block={block} />
          </BlockWrapper>
        </ErrorBoundary>
      )
    })
  }, [blocks])

  return sliceComponents
}

export default Blocks
