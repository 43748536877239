import React from 'react'
import { createUseStyles } from 'react-jss'
import theme, { vw } from '../../style/theme'

import ResponsiveImage from '../ResponsiveImage'

export default ({ slice }) => {
  const classes = useStyles()

  return (
    <section className={classes.section}>
      <div className={classes.imageWrapper}>
        {slice.images.map((image, i) => (
          <div key={i}>
            <ResponsiveImage {...image} className={classes.image} />
          </div>
        ))}
      </div>
    </section>
  )
}

const useStyles = createUseStyles({
  section: {
    margin: [0, 0, vw(24)],
    [theme.breakpoints.up('md')]: {
      margin: [0, theme.getMargin('md'), vw(100, 'lg')]
    }
  },
  imageWrapper: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    gap: `${theme.getGutter()}px`,
    [theme.breakpoints.up('md')]: {
      gridTemplateColumns: '1fr 1fr'
    }
  },
  image: {
    marginLeft: '0px'
  }
}, { name: 'FRFireSideChat' })
