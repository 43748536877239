import React, { forwardRef, useMemo, useEffect, useRef } from 'react'
import { createUseStyles } from 'react-jss'
import theme, { vw } from '../../../style/theme'
import HighlightedText from '../../HighlightedText'
import ResponsiveImage from '../../ResponsiveImage'
import { adjacentSide } from '../../../helpers/trigonometry'
import { useDarkSlice } from '../../HeaderContextProvider'
import gsap from 'gsap'
import { useSelector } from 'react-redux'
import { isCurrentBreakpointMobile } from '../../../redux/slices/layout'

const ImageSlider = forwardRef(({ images, classes }, ref) => {
  const sliderRef = useRef()
  const tickerTimelineRef = useRef()
  const isMobile = useSelector(isCurrentBreakpointMobile)
  const sliderImages = useMemo(() => [...images, ...images], [images, isMobile])

  useEffect(() => {
    const duration = sliderImages.length * (isMobile ? 6 : 8)

    tickerTimelineRef.current = gsap.timeline({ repeat: -1 })
    tickerTimelineRef.current.fromTo(sliderRef.current, { x: '0%' }, {
      duration,
      ease: 'none',
      x: isMobile ? `-${100 * images.length}%` : `-${50 * images.length}%`
    })
  }, [sliderImages, isMobile])

  return (
    <div className={classes.imageSliderViewport} ref={ref}>
      <div className={classes.imageSlider} ref={sliderRef}>
        {sliderImages.map((image, i) => (
          <ResponsiveImage key={i} {...image} className={classes.image} />
        ))}
      </div>
    </div>
  )
})

export default ({ slice }) => {
  const { title, subtitle, images } = slice
  const classes = useStyles()

  const ref = useDarkSlice()

  const singleImage = images.length === 1

  return (
    <section className={classes.section}>
      {singleImage ? (
        <ResponsiveImage {...images[0]} className={classes.singleImage} ref={ref} />
      ) : (
        <ImageSlider images={images} classes={classes} ref={ref} />
      )}
      <div className={classes.content}>
        {subtitle && <p className={classes.subtitle}>{subtitle}</p>}
        <HighlightedText content={title.text} />
      </div>
    </section>
  )
}

const cutout = {
  position: 'absolute',
  content: '""',
  bottom: -0,
  width: '100%',
  clipPath: 'polygon(0 100%, 100% 100%, 100% 0%, 0 100%);',
  backgroundColor: theme.colors.white,
  height: `${adjacentSide(5)}vw`
}

const useStyles = createUseStyles({
  section: {
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      minHeight: 'auto'
    }
  },
  singleImage: {
    marginBottom: vw(60),
    flexGrow: 1,
    flexShrink: 1,
    [theme.breakpoints.up('md')]: {
      marginBottom: vw(120, 'lg')
    },
    '& picture:before': {
      paddingTop: '46%'
    },
    '&:after': {
      ...cutout
    }
  },
  imageSliderViewport: {
    flexGrow: 1,
    flexShrink: 1,
    overflow: 'hidden',
    width: '100%',
    position: 'relative',
    marginBottom: vw(60),
    minHeight: 400,
    [theme.breakpoints.up('md')]: {
      marginBottom: vw(120, 'lg')
    },
    '&:after': {
      ...cutout
    }
  },
  imageSlider: {
    display: 'flex',
    overflow: 'visible',
    whiteSpace: 'nowrap',
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    marginBottom: 1,
    [theme.breakpoints.up('md')]: {
      position: 'static'
    }
  },
  image: {
    flexGrow: 0,
    flexShrink: 0,
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '50%',
      '& picture:before': {
        paddingTop: '100%'
      }
    }
  },
  content: {
    margin: [0, theme.getMargin(), vw(60)],
    marginBottom: vw(60),
    [theme.breakpoints.up('md')]: {
      marginBottom: vw(32, 'lg'),
      maxWidth: vw(700, 'lg'),
      margin: [0, theme.getMargin('md'), vw(120, 'lg')]
    },
    '& h1': {
      fontSize: vw(60),
      [theme.breakpoints.up('md')]: {
        fontSize: vw(100, 'lg')
      }
    }

  },
  subtitle: {
    display: 'block',
    margin: [0, 0, vw(16), 0],
    [theme.breakpoints.up('md')]: {
      margin: [0, 0, vw(16, 'lg'), 0],
      maxWidth: vw(400, 'lg')
    }
  }
}, 'PostHero')
