import React, { useEffect, useRef, useMemo, useCallback } from 'react'
import cn from 'classnames'
import { createUseStyles } from 'react-jss'
import { gridConfig } from '../../style/grid'
import theme, { span, vw } from '../../style/theme'
import ResponsiveImage from '../ResponsiveImage2'
// import RichText from '../RichText'
import gsap from 'gsap'
import { useScrollVelocityListener } from '../useSmoothScroll'
import { useSelector } from 'react-redux'
import { isCurrentBreakpointMobile } from '../../redux/slices/layout'
// import SplitWordsAnimation from '../SplitWordsAnimation'
// import HighlightedText from '../HighlightedText'
import SaasCompassWedge from './SaasCompassWedge'
import get from 'lodash/get'
import useIntersectionObserverCallback from '../../hooks/useIntersectionObserverCallback'
import composeRefs from '../../helpers/composeRefs'
import { useDarkSlice } from '../HeaderContextProvider'
import Link from '../Link'
import { resolveLink } from '../../helpers/resolveLink'
// import detectIt from 'detect-it'

var imageOffsets = [2, 6]

export default ({ slice }) => {
  const { cta_link: ctaLink, cta_text: ctaText } = slice
  const title = slice?.title
  const copy = slice?.copy
  const showTab = slice?.title || slice?.copy
  const link = useMemo(() => resolveLink(get(ctaLink, [0])), [ctaLink])
  const classes = useStyles()
  const images = slice?.images || []
  const galleryRef = useRef()
  const tickerTimelineRef = useRef()
  const isMobile = useSelector(isCurrentBreakpointMobile)

  const imageList = useMemo(() => {
    return [...images, ...images]
  }, [images])

  // Layout the items in a random layout
  useEffect(() => {
    for (var i = 0; i < imageList.length; i++) {
      const item = galleryRef.current.children[i].children[0]
      var offset = imageOffsets[i % imageOffsets.length]
      item.style.transform = `translate(0,${offset}vh)`
    }
  }, [images, isMobile])

  useEffect(() => {
    const { width } = galleryRef.current.getBoundingClientRect()
    const duration = width / 150

    if (width > window.innerWidth) {
      tickerTimelineRef.current = gsap.timeline({ repeat: -1 })
      tickerTimelineRef.current.fromTo(
        galleryRef.current,
        { x: '-50%' },
        {
          duration,
          ease: 'none',
          x: '0%'
        }
      )
      return () => {
        tickerTimelineRef.current.kill()
        tickerTimelineRef.current = null
      }
    }
  }, [images])

  useScrollVelocityListener(
    useCallback((velocity) => {
      if (tickerTimelineRef.current) {
        const speed = gsap.utils.interpolate(1, 4, Math.abs(velocity) / 5)
        tickerTimelineRef.current.timeScale(speed)
      }
    }, [])
  )

  const inViewRef = useIntersectionObserverCallback(
    { threshold: 1, triggerOnce: false },
    useCallback((entries) => {
      if (tickerTimelineRef.current) {
        const inView = get(entries, [0, 'isIntersecting'])
        tickerTimelineRef.current.paused(!inView)
      }
    }, [])
  )

  const ref = useDarkSlice()
  return (
    <section className={classes.section} ref={composeRefs(ref, inViewRef)}>
      <div className={classes.container}>
        <Link link={link} nonLinkTag='div' className={classes.link}>
          <div className={classes.galleryContainer}>
            <div className={classes.gallery} ref={galleryRef}>
              {imageList.map((image, i) => (
                <div
                  className={cn(
                    classes.imageContainer,
                    image.aspect > 1 ? classes.landscape : classes.portrait
                  )}
                  key={i}
                >
                  <ResponsiveImage {...image} className={classes.image} />
                </div>
              ))}
            </div>
          </div>
          {showTab && (
            <SaasCompassWedge
              className={classes.captainWedge}
              title={title}
              copy={copy}
              ctaText={ctaText}
              backgroundColor={theme.colors.white}
              wedgeAngle={20}
            />
          )}
        </Link>
      </div>
    </section>
  )
}

const useStyles = createUseStyles(
  {
    sectionPadding: {
      ...theme.margins('padding')
    },
    link: {
      display: 'block',
      color: 'inherit'
    },
    section: {
      backgroundColor: theme.colors.secondary,
      display: 'flex',
      alignItems: 'center',
      [theme.breakpoints.up('md')]: {
        //   minHeight: '150vh'
      }
    },
    container: {
      composes: ['$sectionPadding'],
      backgroundColor: theme.colors.secondary,
      // color: theme.colors.white,
      position: 'relative',
      // minHeight: '500px',
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      overflow: 'hidden',
      paddingTop: '45px',
      [theme.breakpoints.up('fab')]: {
        // minHeight: '500px'
        paddingTop: '60px'
      },
      [theme.breakpoints.up('md')]: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingTop: '100px'
        // minHeight: '600px'
      },
      [theme.breakpoints.up('lg')]: {
        // minHeight: '650px'
      }
    },
    title: {
      flexGrow: 1,
      display: 'flex',
      alignItems: 'center',
      [theme.breakpoints.up('md')]: {
        marginTop: vw(80, 'lg'),
        flexGrow: 0,
        display: 'block'
      },
      '& h2': {
        position: 'relative',
        extend: theme.typography.h2
      }
    },
    copy: {
      position: 'relative',
      marginLeft: gridConfig.min.margin,
      flexGrow: 0,
      marginBottom: vw(50),
      [theme.breakpoints.up('md')]: {
        flexGrow: 1,
        marginLeft: 0,
        marginRight: span(3, 'md'),
        marginBottom: vw(50, 'lg'),
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'flex-end',
        maxWidth: span(12, 'md'),
        width: '100%'
      },
      '& p': {
        extend: theme.typography.bodyPlus
      }
    },
    galleryContainer: {
      // position: 'absolute',
      // top: 0,
      // bottom: 0,
      // left: 0,
      // right: 0,
      display: 'flex',
      // height: '500px',
      [theme.breakpoints.up('fab')]: {
        // height: '500px'
      },
      [theme.breakpoints.up('md')]: {
        // height: '600px'
      },
      [theme.breakpoints.up('lg')]: {
        // minHeight: '650px'
      }
    },
    gallery: {
      display: 'flex',
      whiteSpace: 'nowrap',
      height: '100%',
      overflow: 'visible',
      marginTop: '50px',
      marginBottom: '5px',
      [theme.breakpoints.up('fab')]: {
        marginTop: '50px',
        marginBottom: '15px'
      }
    },
    imageContainer: {
      backgroundColor: theme.colors.secondary, // We need to set the background color here
      position: 'relative',
      mixBlendMode: 'luminosity',
      opacity: 0.7,
      flexShrink: 0,
      display: 'flex',
      alignItems: 'flex-end',
      ...theme.margins('marginRight'),
      [theme.breakpoints.up('md')]: {
        alignItems: 'center'
      }
    },
    landscape: {
      width: vw(280),
      [theme.breakpoints.up('md')]: {
        width: vw(420, 'lg')
      }
    },
    portrait: {
      width: vw(200),
      [theme.breakpoints.up('fab')]: {
        width: vw(150, 'fab')
      },
      [theme.breakpoints.up('xs')]: {
        width: vw(150, 'xs')
      },
      [theme.breakpoints.up('md')]: {
        width: vw(275, 'lg')
      }
    },
    captainWedge: {
      transform: 'translate3d(0,0,0)',
      // width: `calc(100% - 80px - ${theme.getMargin()}px)`, //keep for future reference
      left: 0,
      right: '50%',
      top: 0,
      bottom: 'unset',
      backgroundColor: theme.colors.black,

      [theme.breakpoints.down('fab')]: {
        right: '30%'
      },

      '& > div': {
        width: '120% !important'
      },

      [theme.breakpoints.up('md')]: {
        padding: [20],
        width: span(6, 'md')
      }
    }
  },
  { name: 'CaptainsBanner' }
)
