import { createSlice } from '@reduxjs/toolkit'

const homeHeroSlice = createSlice({
  name: 'homeHero',
  initialState: {
    inView: true
  },
  reducers: {
    setInView: (state, action) => {
      state.inView = action.payload.inView
    }
  }
})

export const {
  setInView
} = homeHeroSlice.actions

export const getHomeHeroInView = state => state.homeHero.inView

export default homeHeroSlice.reducer
