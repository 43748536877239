import React, { useRef, useState, useCallback } from 'react'
import newsletterImage from '../../images/newsletter.png'
import Modal from '../Modal/Modal'
import theme from '../../style/theme'
import { createUseStyles } from 'react-jss'
import cn from 'classnames'
import { useDispatch, useSelector } from 'react-redux'
import {
  getCustomNewsletterError,
  getCustomNewsletterIsSubscribed,
  requestCustomSubscribeActionCreator
} from '../../redux/slices/customNewsletter'

const NewsLetter = (props) => {
  const { title, description, className } = props
  const ref = useRef()
  const classes = useStyles()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [position, setPosition] = useState(null)
  const [email, setEmail] = useState('')

  const dispatch = useDispatch()
  const subscribed = useSelector(getCustomNewsletterIsSubscribed)
  const error = useSelector(getCustomNewsletterError)

  const openCloseModal = () => {
    const rect = ref.current.getBoundingClientRect()
    setPosition(rect)
    setIsModalOpen(!isModalOpen)
  }

  const onSubmit = (e, email) => {
    dispatch(
      requestCustomSubscribeActionCreator({
        email,
        isSAASCompass: true
      })
    )
    e.preventDefault()
  }

  const onChange = useCallback((e) => {
    setEmail(e.target.value)
  }, [])

  return (
    <div
      ref={ref}
      style={{
        position: 'relative'
      }}
    >
      <div className={cn(className, classes.bttnContainer)}>
        <button className={classes.bttn} style={{ fontSize: '27px' }} onClick={openCloseModal}>
          Subscribe
        </button>
      </div>

      <Modal
        isModalOpen={isModalOpen}
        closeModal={openCloseModal}
        position={position}
        onSubmit={onSubmit}
      >
        <form className={classes.form} onSubmit={(e) => onSubmit(e, email)}>
          {subscribed && (
            <span className={classes.successMessage}>Thanks for Subscribing!</span>
          )}
          {!subscribed && (
            <>
              <img
                src={newsletterImage}
                alt='newsletterimage'
                className={classes.newsletterImage}
              />
              <h4 className={classes.heading}>{title}</h4>
              <p className={classes.description}>{description}</p>
              <div className={classes.inputBar}>
                <input
                  required
                  name='email'
                  type='email'
                  placeholder='Enter your email'
                  className={classes.input}
                  value={email}
                  onChange={onChange}
                />
                <button type='submit' className={classes.subscribeButton}>
                  Subscribe
                </button>
              </div>
            </>
          )}
          {error && <div className={classes.errorMessage}>Oops something went wrong</div>}
        </form>
      </Modal>
    </div>
  )
}

const useStyles = createUseStyles(
  {
    bttn: {
      fontFamily: 'Object Sans',
      fontSize: 20,
      background: theme.colors.black,
      color: theme.colors.white,
      padding: '17.4px 0px',
      width: '100%',

      textAlign: 'center',
      lineHeight: 1.5,
      [theme.breakpoints.down('fab')]: {
        padding: '8px 0px'
      }
    },
    form: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center'
    },
    newsletterImage: {
      width: '140px',
      height: '140px',
      [theme.breakpoints.down('fab')]: {
        width: '58.52px',
        height: '58.52px'
      }
    },
    heading: {
      textTransform: 'capitalize',
      margin: '15.5px 0 10px 0',
      fontWeight: '400',
      fontSize: '28px',
      lineHeight: '46px',
      [theme.breakpoints.down('fab')]: {
        fontWeight: '400',
        fontSize: '14px',
        lineHeight: '19px',
        margin: '6.4px 0 5px 0'
      }
    },
    description: {
      width: '333px',
      fontFamily: 'Object Sans',
      fontWeight: '400',
      fontSize: '14px',
      lineHeight: '23px',
      textAlign: 'center',
      marginBottom: '31.6px',
      [theme.breakpoints.down('fab')]: {
        fontWeight: '400',
        fontSize: '7px',
        lineHeight: '10px',
        marginBottom: '9.7px',
        width: '178.68px'
      }
    },
    inputBar: {
      height: '65.1px',
      background: theme.colors.white,
      border: '0.836098px solid #EFF0F7',
      boxShadow: '0px 1.6722px 5.01659px rgba(19, 18, 66, 0.07)',
      borderRadius: '50.1659px',
      display: 'flex',
      padding: '9.5px 7.8px 9.5px 40.4px',
      gap: '42.68px',
      [theme.breakpoints.down('fab')]: {
        height: '30.48px',
        borderRadius: '21.9711px',
        padding: '2.9px 2.9px 4.3px 17.5px',
        gap: '10.5px'
      }
    },
    input: {
      border: 'none',
      outline: 'none',
      color: '#6F6C90',
      fontWeight: '400',
      fontSize: '15.0498px',
      lineHeight: '17px',
      [theme.breakpoints.down('fab')]: {
        fontWeight: '400',
        fontSize: '8.7px',
        lineHeight: '7px'
      }
    },
    subscribeButton: {
      background: '#3283FF',
      padding: '13.2px 26.7px 15.75px 26.7px',
      boxShadow: '0px 2.50829px 10.0332px rgba(74, 58, 255, 0.18)',
      borderRadius: '46.8215px',
      fontWeight: '700',
      fontSize: '15.0498px',
      lineHeight: '17px',
      color: theme.colors.white,
      [theme.breakpoints.down('fab')]: {
        padding: '5.8px 15.6px 6.16px 15.6px',
        borderRadius: '20.5064px',
        fontWeight: '700',
        fontSize: '6.6px',
        lineHeight: '7px'
      }
    },
    bttnContainer: {
      marginTop: '20px'
    },
    errorMessage: {
      display: 'block',
      color: theme.colors.error,
      marginTop: '20px',
      [theme.breakpoints.down('xs')]: {
        fontSize: '10px',
        marginTop: '10px'
      }
    },
    successMessage: {
      display: 'block',
      marginTop: 16,
      textAlign: 'center',
      [theme.breakpoints.down('xs')]: {
        fontSize: '15px',
        marginTop: '30px'
      }
    }
  },
  { name: 'NewsLetter' }
)

export default NewsLetter
