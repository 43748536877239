import { createAction } from '@reduxjs/toolkit'
import makeFetchSlice from '../makeFetchSlice'

export const relatedPlaybooksContentActionCreator = createAction(
  'playbooks/related_content',
  (payload, loadMore) => ({
    payload,
    meta: { scope: 'playbooks_related', loadMore }
  })
)

export const relatedPlaybooksLoadMoreActionCreator = createAction(
  'playbooks/related_load_more',
  (payload) => ({
    payload,
    meta: { scope: 'playbooks_related' }
  })
)

export const serverErrorActionCreator = createAction('playbooks/serverError')

const relatedPlaybooksSlice = makeFetchSlice(
  'playbooks_related',
  relatedPlaybooksContentActionCreator,
  serverErrorActionCreator
)

export default relatedPlaybooksSlice.reducer
