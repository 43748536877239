import React from 'react'
import { createUseStyles } from 'react-jss'
import theme, { span } from '../../style/theme'
import RichText from '../RichText'
import isEmpty from 'lodash/isEmpty'
import { useDarkSlice } from '../HeaderContextProvider'
import cn from 'classnames'

import Timer from './Timer'

export default ({ slice }) => {
  const hasHero = !isEmpty(false)
  const degrees = 6.5
  const adjacentSide = Math.tan(degrees * Math.PI / 180) * 100
  const classes = useStyles({ adjacentSide, hasHero })
  const sectionRef = useDarkSlice()

  return (
    <section className={classes.section} ref={sectionRef}>
      <div className={classes.rows}>
        <div className={classes.cardWrapper}>
          {slice.venue && (
            <div className={classes.card}>
              <span className={classes.rowLabel}>Venue</span>
              <span className={classes.stage}>{slice.venue}</span>
            </div>
          )}
          {slice.date && (
            <div className={classes.card}>
              <span className={classes.rowLabel}>Date</span>
              <span className={cn(classes.stage, classes.date)}>
                18th-19th October
                <span className={classes.year}>2024</span>
              </span>
            </div>
          )}
          <div className={classes.card}>
            <Timer deadline={new Date(slice.CountdownTimer)} />
          </div>
        </div>
        <div className={classes.bioWrapper}>
          {slice.detailedText && (
            <RichText
              content={slice.detailedText.text}
              className={classes.bio}
            />
          )}
        </div>
      </div>
    </section>
  )
}

const useStyles = createUseStyles({

  section: {
    marginTop: 'auto',
    padding: [24],
    backgroundColor: theme.colors.secondary,
    [theme.breakpoints.up('md')]: {
      padding: [50]
    }
  },

  bio: {
    marginTop: '1rem',
    color: theme.colors.white,
    textAlign: 'center',
    [theme.breakpoints.up('md')]: {
      marginTop: '0'
    }
  },
  rows: {
    display: 'flex',
    flexDirection: 'column'
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    fontFamily: 'Object Sans',
    alignItems: 'center',
    color: 'white',
    padding: 16,
    border: '1px solid rgb(255 255 255 / 20%)',
    width: '100%',
    marginBottom: '24px',
    background: 'rgb(255 255 255 / 5%)',
    position: 'relative',
    [theme.breakpoints.up('md')]: {
      width: '30%'
    }
  },
  rowLabel: {
    fontFamily: 'Object Sans',
    color: theme.colors.primary,
    paddingBottom: '12px'
  },
  stage: {
    fontFamily: 'Object Sans',
    fontSize: '20px',
    textAlign: 'center',
    [theme.breakpoints.up('fab')]: {
      fontSize: '20px'
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: '22px'
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '24px'
    }
  },
  date: {
    textTransform: 'none',
    fontSize: '22px',
    [theme.breakpoints.up('fab')]: {
      fontSize: '22px'
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: '24px'
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '26px'
    }
  },
  year: {
    display: 'block'
  },
  cardWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      fontSize: '16px',
      flexDirection: 'row'
    }
  },
  bioWrapper: {
    width: '75%',
    margin: '0 auto',
    border: '1px solid rgb(255 255 255 / 20%)',
    background: 'rgb(255 255 255 / 5%)',
    padding: [24],
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    },
    [theme.breakpoints.up('md')]: {
      marginTop: '50px'
    }
  }

}, { name: 'founderRetreatHero' })
