import { useRef, useEffect } from 'react'
import { clipPathShapesSupported } from '../helpers/supports'

import { adjacentSide } from '../helpers/trigonometry'
import gsap from 'gsap'

const duration = 0.6

export default (open) => {
  const ref = useRef()
  const animationLocals = useRef({ y: 0, timeline: null })

  useEffect(() => {
    if (ref.current) {
      if (!animationLocals.current.timeline) {
        var timeline = gsap.timeline()
        timeline.set(ref.current, { visibility: 'visible' })
        if (clipPathShapesSupported()) {
          timeline.to(animationLocals.current, {
            duration,
            y: 1,
            modifiers: {
              y: (y) => {
                if (ref.current) {
                  const offset = (window.innerWidth * (adjacentSide(10) / 100))
                  const offsetLeft = window.innerHeight + offset
                  var valueLeft = gsap.utils.interpolate(offsetLeft, 0, y) / window.innerHeight
                  var valueRight = gsap.utils.interpolate(1, -(offset / window.innerHeight), y)
                  const clipPath = `polygon(0 ${valueLeft * 100}%, 100% ${(valueRight * 100)}%, 100% 100%, 0 100%)`
                  ref.current.style.clipPath = clipPath
                }
                return y
              }
            }
          })
        } else {
          timeline.fromTo(ref.current, { y: '100%' }, { duration, y: 0 })
          timeline.fromTo(ref.current.children[0], { y: '-100%' }, { duration, y: 0 }, 0)
        }
        animationLocals.current.timeline = timeline
      }
      animationLocals.current.timeline.reversed(!open)
      return () => {
        if (animationLocals.current.timeline) {
          animationLocals.current.timeline.kill()
        }
      }
    }
  }, [open])

  return ref
}
