import React from 'react'
import { createUseStyles } from 'react-jss'
import PlayIcon from '../../images/PlayIcon'
import SkipIcon from '../../images/SkipIcon'
import theme, { vw } from '../../style/theme'

const PlayPauseButtons = ({ playing, onSkipBack, onPlayClick, onSkipForward }) => {
  const classes = useStyles()

  return (
    <div className={classes.container}>
      <button onClick={onSkipBack}><SkipIcon className={classes.skipIcon} back /></button>
      <button onClick={onPlayClick} className={classes.play}><PlayIcon className={classes.playIcon} playing={playing} /></button>
      <button onClick={onSkipForward}><SkipIcon className={classes.skipIcon} /></button>
    </div>
  )
}

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    justifyContent: 'center',
    '& svg': {
      color: theme.colors.white
    }
  },
  play: {
    margin: [0, vw(12)],
    [theme.breakpoints.up('md')]: {
      margin: [0, vw(60, 'lg')]
    }
  },
  playIcon: {
    width: 40,
    height: 40,
    [theme.breakpoints.up('md')]: {
      width: 61,
      height: 60
    }
  },
  skipIcon: {
    width: 20,
    height: 20,
    [theme.breakpoints.up('md')]: {
      width: 41,
      height: 40
    }
  }
}, { name: 'PlayPauseButtons' })

export default PlayPauseButtons
