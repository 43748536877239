import React from 'react'
import { createUseStyles } from 'react-jss'
import theme, { vw } from '../../../style/theme'
import RichText from '../../RichText'
import Accordion, { AccordionItem } from '../../Accordion'

export default ({ slice }) => {
  const classes = useStyles()
  const { faq_title: title, faqs } = slice
  return (
    <div className={classes.container}>
      <h3 className={classes.title}>{title}</h3>
      <Accordion>
        {faqs.map(({ question, answer }) => (
          <AccordionItem key={question} title={question} className={classes.item} groupClassName={classes.group} toggleClassName={classes.toggle}>
            <RichText content={answer.text} className={classes.answer} />
          </AccordionItem>
        ))}
      </Accordion>
    </div>
  )
}

const useStyles = createUseStyles({
  container: {
  },
  title: {
    marginBottom: vw(32),
    [theme.breakpoints.up('md')]: {
      marginBottom: vw(32, 'lg')
    }
  },
  group: {
    paddingTop: vw(28),
    paddingBottom: vw(28),
    backgroundColor: theme.colors.secondary,
    [theme.breakpoints.up('md')]: {
      paddingTop: vw(28, 'lg'),
      paddingBottom: vw(28, 'lg')
    },
    '& h5': {
      fontFamily: theme.typography.body.fontFamily,
      textTransform: 'none',
      fontSize: vw(15),
      lineHeight: 1.5,
      fontWeight: 700,
      [theme.breakpoints.up('md')]: {
        fontSize: vw(16, 'lg')
      }
    }
  },
  toggle: {
    fontSize: vw(24),
    borderWidth: 1,
    [theme.breakpoints.up('md')]: {
      fontSize: vw(24, 'lg')
    }
  },
  item: {
    borderTop: [1, 'solid', 'currentColor'],
    paddingTop: 1,
    '&:last-child': {
      borderBottom: [1, 'solid', 'currentColor']
    }
  },
  answer: {
    marginBottom: 32,
    [theme.breakpoints.up('md')]: {
      marginBottom: vw(32, 'lg')
    },
    '& p': {
      extend: theme.typography.bodySmall
    }
  }
})
