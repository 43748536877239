/* eslint-env browser */
import compact from 'lodash/compact'
import last from 'lodash/last'
import merge from 'lodash/merge'
import defaultsDeep from 'lodash/defaultsDeep'
import isEmpty from 'lodash/isEmpty'
import queryString from 'query-string'
import { isPreviewMode } from './slices/content'
import { getCurrentQueryString } from './slices/location'

import {
  getContentApiUrl,
  getGlobalContentApiUrl,
  getPreviewApiUrl,
  getPortfoliosApiUrl,
  getPostsApiUrl,
  getPressApiUrl,
  getSubscriptionApiUrl,
  getFutureMapsApiUrl,
  getRelatedFutureMapsApiUrl,
  getRelatedPlaybooksApiUrl,
  getFieldGuideApiUrl
} from './slices/config'

import { globalContentActionCreator } from './slices/content/global'
import { pageContentActionCreator } from './slices/content/page'
import { portfoliosContentActionCreator } from './slices/content/portfolios'
import { postsContentActionCreator } from './slices/content/posts'
import { pressContentActionCreator } from './slices/content/press'
import { dialogContentActionCreator } from './slices/content/dialog'
import { getNewsletterData, subscribeActionCreator } from './slices/newsletter'
import { getCustomNewsletterData, customSubscribeActionCreator } from './slices/customNewsletter'
import { futureMapsContentActionCreator } from './slices/content/futureMaps'
import { relatedFutureMapsContentActionCreator } from './slices/content/relatedFutureMaps'
import { relatedPlaybooksContentActionCreator } from './slices/content/relatedPlaybooks'
import { fieldGuideContentActionCreator } from './slices/content/fieldGuide'

const toUrl = (components, query) => compact(components).join('/') + (isEmpty(query) ? '' : `?${queryString.stringify(query)}`)

/**
 * The last parameter can optionally be an options object. If a `qs` option is specified in the options object it will
 * be converted to the query string in the url
 */
export async function fetchJson (...components) {
  const options = {
    headers: {
      Accept: 'application/json'
    },
    credentials: 'omit'
  }
  if (typeof last(components) === 'object') {
    merge(options, components.pop())
  }

  const { qs, json, ...fetchOptions } = options
  if (json) {
    defaultsDeep(fetchOptions, {
      headers: {
        'content-type': 'application/json'
      },
      method: 'post',
      body: JSON.stringify(json)
    })
  }

  const response = await fetch(toUrl(components, qs), fetchOptions)
  const data = await response.json()

  if (!response.ok) {
    const error = new Error((data.error && data.error.message) || 'Failed to fetch content')
    error.status = response.status
    throw error
  }

  return data
}

export async function sendFetchPageContentRequest (dispatch, getState, {
  path, qs, actionCreator = pageContentActionCreator
} = {}) {
  const queryString = getCurrentQueryString(getState())
  const apiUrlSelector = isPreviewMode(getState()) ? getPreviewApiUrl : getContentApiUrl
  const components = [apiUrlSelector(getState())]
  const pathComponents = compact(path.split('/'))
  const options = { qs: { ...queryString, ...qs } }
  components.push(path === '/' ? 'home' : last(pathComponents))
  return dispatch(actionCreator(fetchJson(...components, options)))
}

export async function fetchPageContent (dispatch, getState, path, actionMeta) {
  return sendFetchPageContentRequest(dispatch, getState, {
    path,
    actionCreator: (promise) => merge(pageContentActionCreator(promise), { meta: actionMeta })
  })
}

export async function fetchGlobalContent (dispatch, getState) {
  const api = getGlobalContentApiUrl(getState())
  return dispatch(globalContentActionCreator(fetchJson(api)))
}

export async function fetchPageNotFoundContent (dispatch, getState) {
  const api = getGlobalContentApiUrl(getState())
  return dispatch(pageContentActionCreator(fetchJson(api, 'error-page-404')))
}

export async function fetchPortfolios (dispatch, getState, sliceId, qs) {
  const state = getState()
  const api = getPortfoliosApiUrl(state)
  return dispatch(portfoliosContentActionCreator(fetchJson(api, { qs })))
}

export async function fetchPosts (dispatch, getState, sliceId, qs, loadMore) {
  const state = getState()
  const api = getPostsApiUrl(state)
  return dispatch(postsContentActionCreator(fetchJson(api, { qs }), loadMore))
}

export async function fetchFieldGuide (dispatch, getState, sliceId, qs, loadMore) {
  const state = getState()
  const api = getFieldGuideApiUrl(state)
  return dispatch(fieldGuideContentActionCreator(fetchJson(api, { qs }), loadMore))
}

export async function fetchFutureMaps (dispatch, getState, sliceId, qs, loadMore) {
  const state = getState()
  const api = getFutureMapsApiUrl(state)
  return dispatch(futureMapsContentActionCreator(fetchJson(api, { qs }), loadMore))
}

export async function fetchRelatedFutureMaps (
  dispatch,
  getState,
  sliceId,
  qs,
  loadMore
) {
  const state = getState()
  const api = getRelatedFutureMapsApiUrl(state)

  return dispatch(
    relatedFutureMapsContentActionCreator(fetchJson(api, { qs }), loadMore)
  )
}

export async function fetchRelatedPlaybooks (
  dispatch,
  getState,
  sliceId,
  qs,
  loadMore
) {
  const state = getState()
  const api = getRelatedPlaybooksApiUrl(state)
  return dispatch(
    relatedPlaybooksContentActionCreator(fetchJson(api, { qs }), loadMore)
  )
}

export async function fetchPress (dispatch, getState, sliceId, qs, loadMore) {
  const state = getState()
  const api = getPressApiUrl(state)
  return dispatch(pressContentActionCreator(fetchJson(api, { qs }), loadMore))
}

export async function fetchDialogContent (dispatch, getState, path, actionMeta) {
  return sendFetchPageContentRequest(dispatch, getState, {
    path,
    actionCreator: (promise) => merge(dialogContentActionCreator(promise), { meta: actionMeta })
  })
}

export async function postSubscription (dispatch, getState, isCustom = false) {
  const url = getSubscriptionApiUrl(getState())
  const data = getNewsletterData(getState())
  dispatch(subscribeActionCreator(fetchJson(url, { json: data })))
}

export async function postCustomSubscription (dispatch, getState) {
  const url = getSubscriptionApiUrl(getState())
  const data = getCustomNewsletterData(getState())
  return dispatch(customSubscribeActionCreator(fetchJson(url, { json: data })))
}
