import React from 'react'
import PortfolioList from './PortfolioList'
import isEmpty from 'lodash/isEmpty'

export default ({ slice }) => {
  const { portfolios, title } = slice
  if (isEmpty(portfolios)) return null
  return (
    <PortfolioList portfolios={portfolios} title={title} />
  )
}
