import inDOM from 'dom-helpers/canUseDOM'
import { ActionType } from 'redux-promise-middleware'
import {
  transitionContentDelayed,
  transitionComplete
} from '../actions'
import { pageContentActionCreator as pageAction } from '../slices/content/page'
import gsap from 'gsap'
import delay from 'lodash/delay'
import { updateScroll } from 'redux-first-router'
import { setPageYOffset } from '../../components/useSmoothScroll'
import afterFrame from '../../helpers/afterFrame'
import { getStateStorage } from './PauseableSessionStorage'
import detectIt from 'detect-it'
import { adjacentSide } from '../../helpers/trigonometry'

var pendingContentFulfilledAction
var transitioningOut = false
const DURATION = 0.8

export const overlaySkew = 10
export const overlayOffset = adjacentSide(overlaySkew)

const updateScrollPosition = () => {
  if (detectIt.primaryInput !== 'touch') {
    const storage = getStateStorage()
    const scrollState = storage.read(window.history.state || {}, null)
    const pageScrollerElement = document.getElementById('page-scroller')
    if (scrollState) {
      const [, y] = scrollState
      setPageYOffset(pageScrollerElement, y)
    } else {
      setPageYOffset(pageScrollerElement, 0)
    }
  } else {
    updateScroll()
  }
}

const transitionOut = (store) => {
  getStateStorage().pause()

  transitioningOut = true
  const main = document.getElementById('page-container')
  const overlay = document.getElementById('page-overlay')
  const tl = gsap.timeline()
  tl.to(main.children, {
    opacity: 0,
    duration: DURATION,
    ease: 'quart.in',
    onComplete: () => {
      transitioningOut = false
      delay(() => { store.dispatch(transitionComplete()) }, 0.2)
    }
  })

  tl.to(overlay, {
    y: `-${(window.innerWidth * (overlayOffset / 2)) / window.innerHeight}%`,
    duration: DURATION,
    ease: 'quart.in'
  }, 0)
}

const transitionIn = (store) => {
  // Before we transition in, we need to update the scroll position
  updateScrollPosition()

  const main = document.getElementById('page-container')
  const overlay = document.getElementById('page-overlay')
  const tl = gsap.timeline()
  tl.to(main.children, {
    opacity: 1,
    ease: 'quart.out',
    duration: DURATION,
    onComplete: () => {
      getStateStorage().resume()
    }
  })

  tl.to(overlay, {
    y: `-${100 + (window.innerWidth * (overlayOffset / 2)) / window.innerHeight}%`,
    duration: DURATION,
    ease: 'quart.in',
    onComplete: () => {
      gsap.set(overlay, { y: '100%' })
    }
  }, 0)
}

export default store => next => action => {
  if (inDOM) {
    if (action.type === pageAction.toString()) {
      transitionOut(store)
    }

    if (action.type === `${pageAction}_${ActionType.Fulfilled}`) {
      // If we are no longer transition out then we just need to pass the action on
      if (transitioningOut) {
        pendingContentFulfilledAction = action
        return next(transitionContentDelayed())
      } else {
        afterFrame(() => transitionIn(store))
      }
    }

    if (action.type === transitionComplete.toString()) {
      if (pendingContentFulfilledAction) {
        const result = next(pendingContentFulfilledAction)
        pendingContentFulfilledAction = null
        afterFrame(() => transitionIn(store))
        return result
      }
    }
  }
  return next(action)
}
