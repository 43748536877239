import React from 'react'
import { createUseStyles } from 'react-jss'
import theme from '../../../style/theme'
import PlaybookPostList from './PlaybookPostList'

const PlaybookRelatedPost = ({ slice }) => {
  const playbooks = slice?.playbooks
  const classes = useStyles()
  return (
    <section className={classes.section}>
      <h5 className={classes.title}>Related</h5>
      <PlaybookPostList posts={playbooks} />
    </section>
  )
}

const useStyles = createUseStyles(
  {
    section: {
      margin: '100px 50px 95px 50px',
      [theme.breakpoints.down('md')]: {
        margin: '40px 25px 0 25px'
      }
    },
    title: {
      marginBottom: '15px',
      fontWeight: 400,
      fontSize: '20px',

      [theme.breakpoints.up('md')]: {
        fontFamily: 'Mars Condensed',
        fontStyle: 'normal',
        fontSize: '35px',
        lineHeight: '90%',
        marginBottom: '67.45px'
      }
    }
  },
  { name: 'PlaybookRelatedPost' }
)

export default PlaybookRelatedPost
