import React from 'react'
import cn from 'classnames'
import { createUseStyles } from 'react-jss'
import theme, { vw } from '../../style/theme'
import VideoPlayer from '../VideoPlayer'
import { useDarkSlice } from '../HeaderContextProvider'

export default ({ className, slice }) => {
  const classes = useStyles()
  const { title, image, video } = slice
  const ref = useDarkSlice()
  return (
    <section className={cn(classes.section, className)} ref={ref}>
      <VideoPlayer
        title={title}
        video={video}
        posterImage={image}
        autoPlay={false}
        muted={false}
        playsInline={false}
      />
    </section>
  )
}

const useStyles = createUseStyles({
  section: {
    position: 'relative',
    margin: [0, 0, vw(24)],
    [theme.breakpoints.up('md')]: {
      margin: [0, theme.getMargin('md'), vw(100, 'lg')]
    }
  }
})
