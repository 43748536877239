import React from 'react'
import { createUseStyles } from 'react-jss'
import theme from '../../../../style/theme'
import VideoPlayer from '../../../VideoPlayer'

const VideoBlock = ({ block }) => {
  const {
    title,
    body,
    video,
    image
  } = block
  const classes = useStyles()

  return (
    <div className={classes.container}>
      <VideoPlayer video={video} title={title} copy={body} posterImage={image} />
    </div>
  )
}

const useStyles = createUseStyles({
  container: {
    margin: [0, -theme.getMargin(), 0, -theme.getMargin() * 2],
    [theme.breakpoints.up('md')]: {
      margin: 0
    }
  }
}, { name: 'VideoBlock' })

export default VideoBlock
