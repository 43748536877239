import React from 'react'
import { createUseStyles } from 'react-jss'
import ResponsiveImage from '../../ResponsiveImage'
import theme from '../../../style/theme'
import { adjacentSide } from '../../../helpers/trigonometry'

const PlaybookHeroImage = ({ slice, atPlayBookPage }) => {
  const { image } = slice
  const classes = useStyles({ atPlayBookPage })
  return <>{image && <ResponsiveImage {...image} className={classes.image} />}</>
}

const useStyles = createUseStyles(
  {
    image: {
      flexGrow: 1,
      width: 'auto',
      marginBottom: '22px',
      zIndex: -1,

      [theme.breakpoints.up('md')]: {
        margin: [0, '-25px', 0, 0],
        width: '100%',
        height: 'auto',
        position: 'relative',
        marginBottom: ({ atPlayBookPage }) => atPlayBookPage ? '0px' : '89.39px'
      },
      '&:after': {
        position: 'absolute',
        content: '""',
        bottom: -1,
        width: '100%',
        clipPath: 'polygon(0 100%, 100% 100%, 100% 0%, 0 100%);',
        backgroundColor: theme.colors.white,
        height: `${adjacentSide(5)}vw`,
        [theme.breakpoints.up('md')]: {
          height: `${adjacentSide(2.5)}vw`
        }
      }
    }
  },
  { name: 'PlaybookHeroImage' }
)

export default PlaybookHeroImage
