import React, { forwardRef } from 'react'
import { createUseStyles } from 'react-jss'
import { useDispatch, useSelector } from 'react-redux'
import {
  getCustomNewsletterBusy,
  getCustomNewsletterError,
  getCustomNewsletterIsSubscribed,
  requestCustomSubscribeActionCreator
} from '../../redux/slices/customNewsletter'
import { getPostsQueryStringArguments, getPostListSlice } from '../../redux/slices/content'
import NewsletterSignup from '../NewsletterSignup'
import theme, { span } from '../../style/theme'

const NewsletterSignupSlice = forwardRef(({ slice }, ref) => {
  const { title, audience_id: audienceId } = slice
  const classes = useStyles()

  const dispatch = useDispatch()
  const busy = useSelector(getCustomNewsletterBusy)
  const subscribed = useSelector(getCustomNewsletterIsSubscribed)
  const error = useSelector(getCustomNewsletterError)

  const { postInitiative } = useSelector(getPostsQueryStringArguments)
  const postListSlice = useSelector(getPostListSlice)
  if (postListSlice && postInitiative) return null

  const onSubmit = (e, email) => {
    dispatch(requestCustomSubscribeActionCreator({ email, audienceId }))
    e.preventDefault()
  }

  return (
    <div className={classes.content}>
      <div className={classes.widgetWrapper}>
        <h2 className={classes.widgetTitle}>{title}</h2>
        <NewsletterSignup
          textColor={theme.colors.black}
          borderColor={theme.colors.black}
          audienceId={audienceId}
          className={classes.signupForm}
          busy={busy}
          subscribed={subscribed}
          error={error}
          onSubmit={onSubmit}
          isLandscape
        />
      </div>
    </div>
  )
})

const useStyles = createUseStyles({
  content: {
    padding: [30, theme.getMargin('min'), 60],
    position: 'relative',
    [theme.breakpoints.up('md')]: {
      padding: [70, theme.getMargin('md')]
    }
  },
  widgetWrapper: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center'
    }
  },
  widgetTitle: {
    extend: theme.typography.h5,
    marginRight: span(1),
    marginBottom: 10,
    [theme.breakpoints.up('md')]: {
      marginRight: span(1, 'md'),
      marginBottom: 0
    }
  },
  signupForm: {
    width: '100%',
    [theme.breakpoints.up('md')]: {
      maxWidth: span(12, 'md')
    }
  }
}, { name: 'NewsletterSignupSlice' })

export default NewsletterSignupSlice
