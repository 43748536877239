import inDOM from 'dom-helpers/canUseDOM'
import { ActionType } from 'redux-promise-middleware'
import { rehydrated, transitionComplete } from '../../actions'

import { pageContentActionCreator } from '../../slices/content/page'

import { fetchPortfolios } from '../../api'

import { getPortfolioSearchSlice } from '../../slices/content/selectors'

export default store => next => action => {
  if (!inDOM) {
    return next(action)
  }
  const ret = next(action)
  const portfolioSearchSlice = getPortfolioSearchSlice(store.getState())
  if (portfolioSearchSlice) {
    const slug = portfolioSearchSlice.parent.slug
    if (action.type === rehydrated.toString() ||
      action.type === `${pageContentActionCreator}_${ActionType.Fulfilled}` ||
      action.type === transitionComplete.toString()) {
      fetchPortfolios(store.dispatch, store.getState, portfolioSearchSlice.id, { slug, page: 0, limit: 1000 })
    }
  }
  return ret
}
