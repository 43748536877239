import React from 'react'
import { createUseStyles } from 'react-jss'
import PostListItem from './PostListItem'
import theme, { vw } from '../../style/theme'
import { resolveInternalLink } from '../../helpers/resolveLink'

export default ({ posts, type }) => {
  const classes = useStyles()
  return (
    <div className={classes.postList}>
      <ul className={classes.list}>
        {posts && posts.map(post => (
          <PostListItem
            key={post.slug}
            link={resolveInternalLink(post)}
            {...post}
            type={type}
          />
        ))}
      </ul>
    </div>
  )
}

const useStyles = createUseStyles({
  postList: {

  },
  list: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    columnGap: `${theme.getGutter()}px`,
    rowGap: vw(40),
    listStyle: 'none',
    margin: 0,
    padding: 0,
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      flexDirection: 'column',
      rowGap: 0
    }
  }
}, { name: 'PostTile' })
