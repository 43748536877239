import React from 'react'
import { createUseStyles } from 'react-jss'
import theme, { vw } from '../../../style/theme'
import RichText from '../../RichText'
import ResponsiveImage from '../../ResponsiveImage'
import { adjacentSide } from '../../../helpers/trigonometry'
import cn from 'classnames'
import { useDarkSlice } from '../../HeaderContextProvider'

export default ({ slice }) => {
  const { title, subtitle, image } = slice
  const classes = useStyles()

  const ref = useDarkSlice(!image)

  return (
    <section className={cn(classes.section, !image && classes.noImage)} ref={ref}>
      <div className={classes.wrapper}>
        <div className={classes.content}>
          <RichText content={title.text} className={classes.title} />
          {subtitle && <p className={classes.subtitle}>{subtitle}</p>}
        </div>
        {image && <ResponsiveImage {...image} className={classes.image} />}
      </div>
    </section>
  )
}

const useStyles = createUseStyles({
  section: {
    position: 'relative',
    margin: [0, theme.getMargin(), vw(60)],
    [theme.breakpoints.up('md')]: {
      margin: [0, theme.getMargin('md'), vw(100, 'lg')]
    }
  },
  noImage: {
    margin: [0, 0, vw(60)],
    backgroundColor: theme.colors.secondary,
    color: theme.colors.white,
    [theme.breakpoints.up('md')]: {
      margin: [0, 0, vw(100, 'lg')]
    },
    '&:after': {
      position: 'absolute',
      content: '""',
      bottom: -1,
      width: '100%',
      clipPath: 'polygon(0 100%, 100% 100%, 100% 0%, 0 100%);',
      backgroundColor: theme.colors.white,
      height: `${adjacentSide(10)}vw`,
      [theme.breakpoints.up('md')]: {
        height: `${adjacentSide(5)}vw`
      }
    },
    '& $wrapper': {
      margin: [0, theme.getMargin()],
      [theme.breakpoints.up('md')]: {
        margin: [0, theme.getMargin('md')]
      }
    }
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column-reverse',
    position: 'relative',
    minHeight: '100vh',
    justifyContent: 'center',
    [theme.breakpoints.up('md')]: {
      alignItems: 'center',
      flexDirection: 'row',
      justifyContent: 'flex-start'
    }
  },
  content: {
    padding: [0, theme.getMargin(), vw(40), 0],
    [theme.breakpoints.up('md')]: {
      padding: [0, theme.getMargin('md'), 0, 0],
      width: '50%'
    }
  },
  title: {
    marginBottom: vw(32),
    [theme.breakpoints.up('md')]: {
      marginBottom: vw(32, 'lg'),
      maxWidth: vw(600, 'lg')
    },
    '& h1': {
      fontSize: vw(60),
      [theme.breakpoints.up('md')]: {
        fontSize: vw(100, 'lg')
      }
    }
  },
  subtitle: {
    margin: 0,
    [theme.breakpoints.up('md')]: {
      maxWidth: vw(400, 'lg')
    }
  },
  image: {
    flexGrow: 1,
    margin: [0, -theme.getMargin(), vw(40)],
    width: 'auto',
    [theme.breakpoints.up('md')]: {
      margin: [0, -theme.getMargin('md'), 0, 0],
      width: '50%',
      height: '100vh',
      marginLeft: vw(16, 'lg'),
      position: 'relative'
    },
    '&:after': {
      position: 'absolute',
      content: '""',
      bottom: -1,
      width: '100%',
      clipPath: 'polygon(0 100%, 100% 100%, 100% 0%, 0 100%);',
      backgroundColor: theme.colors.white,
      height: `${adjacentSide(5)}vw`,
      [theme.breakpoints.up('md')]: {
        height: `${adjacentSide(2.5)}vw`
      }
    }
  }
}, 'PostHero')
