import React from 'react'
import { createUseStyles } from 'react-jss'
import theme, { vw } from '../../../style/theme'
import PortfolioItem from './PortfolioItem'

export default ({ portfolios, title }) => {
  const classes = useStyles()
  return (
    <section className={classes.section}>
      {title && <h3 className={classes.title}>{title}</h3>}
      <ul className={classes.list}>
        {portfolios && portfolios.map((portfolio) => (
          <PortfolioItem key={portfolio.id} {...portfolio} />
        ))}
      </ul>
    </section>
  )
}

const useStyles = createUseStyles({
  section: {
    margin: [0, 0, vw(60)],
    [theme.breakpoints.up('md')]: {
      margin: [0, theme.getMargin('md'), vw(100, 'lg')]
    }
  },
  title: {
    margin: [0, theme.getMargin(), '0.6em'],
    [theme.breakpoints.up('md')]: {
      margin: [0, 0, '1em']
    }
  },
  list: {
    margin: 0,
    padding: 0,
    listStyle: 'none'
  }
}, { name: 'PortfolioList' })
