import theme from '../../../style/theme'

export default {
  height: 1.5,
  mobileHeight: 1.25,
  imageOffset: {
    top: '-40%',
    bottom: '-40%',
    height: '180%',
    [theme.breakpoints.up('md')]: {
      top: '-15%',
      bottom: '-15%',
      height: '130%'
    }
  }
}
