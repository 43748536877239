import axios from 'axios'

export default class SendMailApi {
  handle = (body) => {
    const url =
      'https://g8zflls92k.execute-api.ap-south-1.amazonaws.com/dev/mail-service'
    axios.post(url, body, {
      headers: {
        'x-api-key': 'qzwLGOSFvMavGobysXVKyaXiLRwTifK3aWslA4Rw',
        'Content-Type': 'application/json'
      }
    }).catch(err => {
      throw Error(`Unable to send a mail: ${JSON.stringify(err)}`)
    })
  }
}
