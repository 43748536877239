import React, { useMemo } from 'react'
import { createUseStyles } from 'react-jss'
import theme, { vw } from '../../../style/theme'
import cn from 'classnames'
import Link from '../../Link'
import { resolveInternalLink } from '../../../helpers/resolveLink'

export default ({ page, className, showPostInitiative = true }) => {
  const classes = useStyles()
  const {
    parent,
    category,
    futureMapsCategory: FeatureFutureMapsCategory
    // postCategory, // used if postInitiative comes from Backend
    // postInitiative,
    // future_map_category: futureMapCategory,
  } = page
  const resolvedParentLink = useMemo(() => resolveInternalLink(parent), [parent])
  // const initiativeLink = useMemo(() => `${resolvedParentLink.url}?postInitiative=${postInitiative.slug}`, [postInitiative, resolvedParentLink])
  return (
    <div className={cn(classes.metaLinks, className)}>
      {category && (
        <Link link={resolvedParentLink} nonLinkTag='span'>
          {category.title.substring(0, category.title.length - 1)}
        </Link>
      )}
      {FeatureFutureMapsCategory && (
        <Link link={resolvedParentLink} nonLinkTag='span' className={classes.categoryOfFeaturedFuture}>
          {FeatureFutureMapsCategory.title.substring(0, FeatureFutureMapsCategory.title.length - 1)}
        </Link>
      )}
    </div>
  )
}

const useStyles = createUseStyles({
  metaLinks: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    extend: theme.typography.bodySmall,
    textTransform: 'uppercase',
    color: theme.colors.primary,
    lineHeight: 1.5,
    '& > a': {
      textDecoration: 'none',
      color: theme.colors.black,
      '&:hover': {
        // textDecoration: 'underline'
        textDecoration: 'none'
      }
    }
  },
  separator: {
    display: 'block',
    textDecoration: 'none',
    margin: [0, vw(5)],
    [theme.breakpoints.up('md')]: {
      margin: [0, vw(5, 'md')]
    }
  },
  postInitiative: {
    position: 'relative',
    display: 'inline-block'
  },
  categoryOfFeaturedFuture: {
    color: '#2F80ED !important',
    textDecoration: 'none !important'
  }
}, 'FutureCategory')
