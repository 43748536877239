import React, { useMemo } from 'react'
import ErrorBoundary from './ErrorBoundary'

import HomepageHero from './Slices/HomepageHero'
import ImageSlice from './Slices/ImageSlice'
import Manifesto from './Slices/Manifesto'
import RichTextSlice from './Slices/RichTextSlice'
import Headline from './Slices/Headline'
import NextPageSlice from './Slices/NextPageSlice'
import NewsletterSignupSlice from './Slices/NewsletterSignupSlice'
import PortfolioHero from './Slices/PortfolioHero'
import FounderRetreatHero from './Slices/FounderRetreatHero'
import JobHero from './Slices/JobHero'
import JobContentSlice from './Slices/JobContentSlice'
import Tiles from './Slices/Tiles'
import TeamGrid from './Slices/TeamGrid'
import TeamList from './Slices/TeamList'
import HeaderTextBlock from './Slices/HeaderTextBlock'
// import HeaderTextBlock2 from './Slices/HeaderTextBlock2'
import TeamMemberHero from './Slices/TeamMemberHero'
// import AttendeeGrid from './Slices/AttendeeGrid'
import TeamMemberPortfolios from './Slices/TeamMemberPortfolios'
import PortfolioSearch from './Slices/PortfolioSearch'
import CareerListing from './Slices/CareerListing'
import PortfolioCareerListing from './Slices/PortfolioCareerListing'
import PortfolioPressArticleListing from './Slices/PortfolioPressArticleListing'
import TimelineSlice from './Slices/TimelineSlice'
import TimelineSlice2 from './Slices/TimelineSlice2'
import Stories from './Slices/Stories'
import Pitch from './Slices/Pitch'
import FoundersGroup from './Slices/FoundersGroup'
import FeaturedPostSlice from './Slices/FeaturedPostSlice'
import FeaturedPostListSlice from './Slices/FeaturedPostListSlice'
import PostListSlice from './Slices/PostListSlice'
import PostCategoryHero from './Slices/PostCategoryHero'
import PressListSlice from './Slices/PressListSlice'
import ImageSlider from './Slices/ImageSlider3'
import VideoSlice from './Slices/VideoSlice'
import PlatformsSlice from './Slices/PlatformsSlice'
import PostMediaHero from './Slices/Post/PostMediaHero'
import PostHero from './Slices/Post/PostHero'
import PostHeroNew from './Slices/Post/PostHeroNew'
import PostIntro from './Slices/Post/PostIntro'
import EventIntro from './Slices/Post/EventIntro'
import PostIntroNew from './Slices/Post/PostIntroNew'
import PostImages from './Slices/Post/PostImages'
import PostText from './Slices/Post/PostText'
import PostTextNew from './Slices/Post/PostTextNew'
import EventHero from './Slices/Post/EventHero'
import PartnersSlice from './Slices/Post/PartnersSlice'
// import PartnersSlice2 from './Slices/Post/PartnersSlice2'
import AttendeesSlice from './Slices/Post/AttendeesSlice'
import AgendaSlice from './Slices/Post/AgendaSlice'
import PostImageAndText from './Slices/Post/PostImageAndText'
import PostTextTwoCol from './Slices/Post/PostTextTwoCol'
import ImageSlider2 from './Slices/ImageSlider2'
import Collapse from './Slices/Collapse'
import Table from './Slices/Table'
import FRScroll from './Slices/FRScroll'
import FRNewAgenda from './Slices/FRNewAgenda'
import FRNewAgenda2 from './Slices/FRNewAgenda2'
import NewPostSlice from './Slices/Post/NewPostSlice'
import Document from './Slices/Document'
import Embed from './Slices/Post/Embed'
// import RegisterButton from './Slices/RegisterButton'
import SaasCompassHero from './Slices/SaasCompassHero'
import CaptainsBanner from './Slices/CaptainsBanner'
import SubscribeSaasCompass from './Slices/SubscribeSaasCompass'
import HeaderWithArrowNavigation from '../components/Slices/FieldGuide/HeaderWithArrowNavigation'
import CaptainGrid from './Slices/CaptainGrid'
import CaptainMemberHero from './Slices/CaptainMemberHero'
import PlaybookGrid from './Slices/FieldGuide/PlaybookGrid'
import TwoColumnSlice from './Slices/TwoColumnSlice'
import ToolsLink from './Slices/ToolsLink'
import PlaybookAuthor from './Slices/Playbook/PlaybookAuthor'
import PlaybookHeroImage from './Slices/Playbook/PlaybookHeroImage'
import PlaybookTitle from './Slices/Playbook/PlaybookTitle'
import PlaybookSubTitle from './Slices/Playbook/PlaybookSubtitle'
import PlaybookIntroTitle from './Slices/Playbook/PlaybookIntroTitle'
import PlaybookText from './Slices/Playbook/PlaybookText'
import PlaybookDetail from './Slices/Playbook/PlaybookDetail'
import Quote from './Slices/FutureMaps/Quote'
import Tags from './Slices/FutureMaps/Tags'
import FutureMapsAuthor from './Slices/FutureMaps/FutureMapsAuthor'
// import GuideToNavigate from './Slices/Playbook/GuideToNavigate'
import FeaturedFutureMapsSlice from './Slices/FutureMapsList/FeaturedFutureMaps'
import FeaturedFutureMapsListSlice from './Slices/FutureMapsList/FeaturedFutureMapsList'
import FutureMapsListSlice from './Slices/FutureMapsList/FutureMapsList'
import PlaybookImage from './Slices/Playbook/PlaybookImage'
import WhatWillYouGetSection from './WhatWillYouGetSection/WhatWillYouGetSection'
import SaasCompassSlide from './Slices/SaasCompassSlide'
import ArrowNavigation from './Slices/FieldGuide/ArrowNavigation'
import PlayBookWillCoverSection from './PlayBookWillCoverSection/PlayBookWillCoverSection'
import RelatedFutureMapsListSlice from './Slices/FutureMapsList/RelatedFutureMapsList'
import PlaybookRelatedPost from './Slices/Playbook/PlaybookRelatedPost'
import CompletePlaybookSlice from './Slices/Playbook/CompletePlaybookSlice'
import VideoPlayerForFutureMaps from './Slices/FutureMaps/VideoPlayerForFutureMaps'
import SaasElevationHero from './SaasElevationHero'
// import SaasElevationLogo from './SaasElevationLogo'
import SaasTeam from './SaasTeam'
import FieldGuideListSlice from './Slices/FieldGuideListSlice'
import FieldGuideMedia from './Slices/Playbook/FieldGuideMedia'
import BrandVideo from './Slices/BrandVideo'
import AiElevationListSlice from './Slices/AiElevationList'
import FRNewHero from './Slices/FRNewHero'

const sliceComponentSelector = {
  homepage_hero: HomepageHero,
  image_slice: ImageSlice,
  rich_text: RichTextSlice,
  manifesto: Manifesto,
  headline: Headline,
  next_page_slice: NextPageSlice,
  newsletter_signup_slice: NewsletterSignupSlice,
  portfolio_hero_slice: PortfolioHero,
  job_hero_slice: JobHero,
  job_content_slice: JobContentSlice,
  old_agenda_slice: AgendaSlice,
  tiles: Tiles,
  team_grid_slice: TeamGrid,
  header_text_block: HeaderTextBlock,
  // header_text_block2: HeaderTextBlock2,
  team_member_hero: TeamMemberHero,
  attendee_grid_slice: AttendeesSlice,
  team_list_slice: TeamList,
  team_member_portfolios: TeamMemberPortfolios,
  portfolio_search_slice: PortfolioSearch,
  founder_retreat_hero_slice: FounderRetreatHero,
  career_listing: CareerListing,
  timeline_slice: TimelineSlice,
  timeline_slice_v2: TimelineSlice2,
  stories: Stories,
  pitch_form: Pitch,
  founder_group: FoundersGroup,
  portfolio_career_listing: PortfolioCareerListing,
  portfolio_press_article_listing: PortfolioPressArticleListing,
  featured_post_slice: FeaturedPostSlice,
  featured_post_list_slice: FeaturedPostListSlice,
  post_category_hero: PostCategoryHero,
  post_list_slice: PostListSlice,
  press_list_slice: PressListSlice,
  post_media_hero: PostMediaHero,
  post_hero_new: PostHeroNew,
  post_hero: PostHero,
  post_intro: PostIntro,
  post_intro_right: PostIntroNew,
  event_intro: EventIntro,
  post_images: PostImages,
  post_text: PostText,
  post_text_right: PostTextNew,
  post_text_two_col_right: PostTextTwoCol,
  post_images_text: PostImageAndText,
  event_schedule_slice: TimelineSlice,
  event_hero: EventHero,
  image_slider: ImageSlider,
  image_slider2: ImageSlider2,
  video_slice: VideoSlice,
  video_slice2: VideoSlice,
  platforms_slice: PlatformsSlice,
  partners_slice: PartnersSlice,
  attendees_slice: AttendeesSlice,
  collapse: Collapse,
  table: Table,
  frScroll: FRScroll,
  frNewAgenda: FRNewAgenda,
  frNewAgenda2: FRNewAgenda2,
  postContent: NewPostSlice,
  document: Document,
  embed: Embed,
  saasHero: SaasCompassHero,
  captains: CaptainsBanner,
  subscribe_to_newsLetter: SubscribeSaasCompass,
  field_guide_header: HeaderWithArrowNavigation,
  captain_grid_slice: CaptainGrid,
  captain_hero: CaptainMemberHero,
  playbook_grid_slice: PlaybookGrid,
  two_column_slice: TwoColumnSlice,
  tool_link: ToolsLink,
  playbook_authors: PlaybookAuthor,
  playbook_hero_image: PlaybookHeroImage,
  playbook_title: PlaybookTitle,
  playbook_subtitle: PlaybookSubTitle,
  playbook_intro_title: PlaybookIntroTitle,
  playbook_text: PlaybookText,
  playbook_detail: PlaybookDetail,
  playbookQuote: Quote,
  tags_list: Tags,
  future_map_authors: FutureMapsAuthor,
  // guide_to_navigate: GuideToNavigate,
  featured_future_map_slice: FeaturedFutureMapsSlice,
  featured_future_maps_list_slice: FeaturedFutureMapsListSlice,
  future_map_list_slice: FutureMapsListSlice,
  playbook_images: PlaybookImage,
  what_will_you_get: WhatWillYouGetSection,
  saas_compass_section: SaasCompassSlide,
  bread_crumbs: ArrowNavigation,
  playbook_will_cover: PlayBookWillCoverSection,
  related_future_map_list_slice: RelatedFutureMapsListSlice,
  related_playbook_slice: PlaybookRelatedPost,
  complete_playbook_slice: CompletePlaybookSlice,
  future_map_video_slice: VideoPlayerForFutureMaps,
  saasAtElevation: SaasElevationHero,
  // brand_images: SaasElevationLogo,
  saas_at_elevation_team_list: SaasTeam,
  field_guide_media: FieldGuideMedia,
  brand_video: BrandVideo,
  ai_elevation_list_slice: AiElevationListSlice,
  field_guide_list_slice: FieldGuideListSlice,
  fr_new_hero: FRNewHero
}

const Slices = ({ className, slices, page }) => {
  const sliceComponents = useMemo(() => {
    if (!slices) return null
    return slices.map((slice, index) => {
      const Component = sliceComponentSelector[slice.type]
      if (!Component) return null
      return (
        <ErrorBoundary key={`slice-${index}`}>
          <Component slice={slice} page={page} />
        </ErrorBoundary>
      )
    })
  }, [slices])

  return sliceComponents
}

export default Slices
