import React from 'react'
import { createUseStyles } from 'react-jss'
import theme from '../../../style/theme'
import twitterLogo from '../../../images/twitter.png'
import linkedinLogo from '../../../images/linkedin.png'

const FutureMapsAuthor = ({ slice, page }) => {
  const classes = useStyles()
  const authorsList = page?.authors

  return (
    <div className={classes.authorNameSection}>
      <p className={classes.authorBy}>
        Written by&nbsp;
        {authorsList?.length > 0 && authorsList.map((elem, index) => (
          <React.Fragment key={index}>
            <span>{index !== authorsList.length - 1 ? elem.name + ', ' : elem.name}</span>
          </React.Fragment>
        ))}
      </p>
      <div className={classes.logos}>
        <img src={twitterLogo} />
        <img src={linkedinLogo} />
      </div>
    </div>
  )
}

const useStyles = createUseStyles(
  {
    authorNameSection: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      paddingBottom: '16px',
      margin: '0px 51px 30px 0',
      borderBottom: '1px solid rgb(0,0,0,0.2)',
      '& p': {
        marginBottom: '0'
      },
      [theme.breakpoints.down('md')]: {
        margin: '64px 28px 30px 28px'
      }
    },
    authorBy: {
      fontFamily: 'Object Sans',
      fontStyle: 'normal',
      fontWeight: 700,
      fontSize: '14px',
      lineHeight: '140%'
    },
    logos: {
      gap: '10px',
      display: 'flex',
      '& img': {
        height: '16px',
        width: '16px'
      }
    },
    line: {
      opacity: '0.2',
      margin: '16px 0 24px 0'
    }
  },
  { name: 'FutureMapsAuthor' }
)

export default FutureMapsAuthor
