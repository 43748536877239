import React from 'react'
import { createUseStyles } from 'react-jss'
import { resolveInternalLink } from '../../../helpers/resolveLink'
import theme from '../../../style/theme'
import FutureMapsControlPanel from '../FutureMaps/FutureMapsControlPanel'
import FutureTile from './FutureTile'

const FeaturedFutureMapsSlice = ({ slice, page }) => {
  const {
    featured_post: featuredPost,
    futureMapsCategories,
    futureMapsTags
  } = slice
  const classes = useStyles()
  // const futureMapsCategory = slice?.featured_post?.futureMapsCategory
  const futureMapsCategory = featuredPost?.futureMapsCategory

  return (
    <>
      <FutureMapsControlPanel
        postCategories={futureMapsCategories}
        tags={futureMapsTags}
      />
      <div className={classes.content}>
        <FutureTile
          // {...featuredPost}
          featuredPost={featuredPost}
          category={futureMapsCategory}
          className={classes.featuredFutureMapsSlicePostTile}
          link={resolveInternalLink(featuredPost.linked_post)}
          linked_post={featuredPost.linked_post}
          isLandscape
          isFeatured
          // isImageContain={page.slug === 'future-maps'}
          isImageContain={page.slug === 'podcasts'}
        />
      </div>
    </>
  )
}

const useStyles = createUseStyles(
  {
    content: {
      padding: [30, theme.getMargin('min')],
      position: 'relative',
      [theme.breakpoints.up('md')]: {
        padding: [50, theme.getMargin('md')]
      }
    }
  },
  { name: 'FeaturedFutureMapsSlice' }
)

export default FeaturedFutureMapsSlice
