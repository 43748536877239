import React from 'react'
import { createUseStyles } from 'react-jss'
import { vw } from '../../style/vw'
import theme from '../../style/theme'
import List from './CareerListing/List'
import RichText from '../RichText'
import isEmpty from 'lodash/isEmpty'

export default ({ slice }) => {
  const { title, careers, copy } = slice
  const classes = useStyles()
  if (isEmpty(careers)) return null
  return (
    <section className={classes.section}>
      {title && (
        <div className={classes.titleContainer}>
          <h4 className={classes.title}>{title}</h4>
          {copy && <RichText content={copy.text} className={classes.copy} />}
        </div>
      )}
      <List careers={careers} className={classes.list} linkClassName={classes.link} />
    </section>
  )
}

const useStyles = createUseStyles({
  section: {
    paddingBottom: vw(50),
    position: 'relative',
    [theme.breakpoints.up('md')]: {
      paddingBottom: vw(80, 'lg')
    }
  },
  emptyResults: {
    backgroundColor: theme.colors.offWhite,
    padding: vw(18),
    [theme.breakpoints.up('md')]: {
      padding: vw(30, 'lg')
    }
  },
  titleContainer: {
    marginBottom: theme.getMargin(),
    padding: [0, theme.getMargin()],
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: theme.getMargin('md'),
      padding: [0, theme.getMargin('md')]
    }
  },
  title: {},
  copy: {
    marginLeft: 0,
    marginTop: vw(16),
    '& p': {
      extend: theme.typography.bodySmall
    },
    [theme.breakpoints.up('md')]: {
      marginTop: 0,
      marginLeft: vw(16, 'lg')
    }
  },
  list: {
    [theme.breakpoints.up('md')]: {
      margin: [0, theme.getMargin('md')]
    }
  }
}, { name: 'PortfolioCareerListing' })
