import React from 'react'
import Embed from '../../Embed'

export default ({ slice }) => {
  const { code, alignment } = slice

  return (
    <>
      <Embed code={code} alignment={alignment} />
    </>
  )
}
