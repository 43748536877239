import React from 'react'
import ResponsiveImage from '../ResponsiveImage'
import theme from '../../style/theme'
import { createUseStyles } from 'react-jss'

export default ({ slice }) => {
  console.log(slice, 'brand video')
  const { image } = slice
  const classes = useStyles()

  return (
    <div className={classes.brandVideoContainer}>
      <div className={classes.brandVideo}>
        <ResponsiveImage {...image} />
      </div>
    </div>

  )
}

const useStyles = createUseStyles({
  brandVideoContainer: {
    background: '#000023',
    position: 'relative',
    padding: '24px 105px 24px',
    [theme.breakpoints.down('fab')]: {
      padding: '40px 8px'
    }
  },
  brandVideo: {
    width: '60%',
    margin: '0 auto',
    [theme.breakpoints.down('md')]: {
      width: '95%'
    }
  }
}, { name: 'BrandVideo' })
