import React, { useState, useMemo, useEffect } from 'react'
import differenceInSeconds from 'date-fns/differenceInSeconds'
import './timer.css'
import { createUseStyles } from 'react-jss'
import theme from '../../style/theme'

const Timer = ({ deadline }) => {
  const ONE_DAY = 60 * 60 * 24
  const ONE_HOUR = 60 * 60
  const ONE_MINUTE = 60
  const [currentTime, setCurrentTime] = useState(new Date().getTime())
  const diffInSeconds = differenceInSeconds(deadline, currentTime)
  const classes = useStyles()

  const getCoundown = () => {
    if (diffInSeconds <= 1) {
      return {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0
      }
    }
    const days = Math.floor(diffInSeconds / ONE_DAY)
    const hours = Math.floor((diffInSeconds - days * ONE_DAY) / ONE_HOUR)
    const minutes = Math.floor(
      (diffInSeconds - days * ONE_DAY - hours * ONE_HOUR) / ONE_MINUTE
    )
    const seconds =
      diffInSeconds - days * ONE_DAY - hours * ONE_HOUR - minutes * ONE_MINUTE
    return {
      days,
      hours,
      minutes,
      seconds
    }
  }

  const countdown = useMemo(getCoundown, [currentTime])

  useEffect(() => {
    setInterval(() => {
      const now = new Date().getTime()
      setCurrentTime(now)
    }, 1000)
  }, [])

  return (
    <div>
      <div className={classes.timer__container} style={{ display: 'flex' }}>
        <div className={`${classes.timer} ${classes.days}`}>
          {countdown.days}
          <span className={`${classes.timer__span} ${classes.days}`}>days</span>
        </div>
        <div className={classes.semicolon}>:</div>
        <div className={classes.timer}>
          {countdown.hours}
          <span className={classes.timer__span}>hours</span>
        </div>
        <div className={classes.semicolon}>:</div>
        <div className={classes.timer}>
          {countdown.minutes}
          <span className={classes.timer__span}>minutes</span>
        </div>
        <div className={classes.semicolon}>:</div>
        <div className={classes.timer}>
          {countdown.seconds}
          <span className={classes.timer__span}>seconds</span>
        </div>
      </div>
    </div>
  )
}

export default Timer

const useStyles = createUseStyles({
  timer_app: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '100vh'
  },
  timer__container: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    color: '#fff'
  },
  timer: {
    fontSize: '28px',
    // paddingBottom: '16px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    fontFamily: 'Mars Condensed',
    fontWeight: 400,
    // lineHeight: '56px',
    letterSpacing: '-0.01em',
    textAlign: 'left',
    [theme.breakpoints.up('fab')]: {
      fontSize: '60px'
    }
  },
  // days: {
  //   color: '#3283FF !important'
  // },
  timer__span: {
    fontSize: '10px',
    color: '#3283ff',
    textTransform: 'uppercase',
    fontFamily: 'Object Sans',
    textAlign: 'center',
    lineHeight: '1rem',
    marginBottom: '1rem',
    [theme.breakpoints.up('fab')]: {
      fontSize: '14px'
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '10px'
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: '14px'
    }
  },
  semicolon: {
    fontSize: '28px',
    padding: '0 10px 30px',
    [theme.breakpoints.up('fab')]: {
      fontSize: '60px'
    }
  }
})
