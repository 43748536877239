import React from 'react'
import cn from 'classnames'
import { createUseStyles } from 'react-jss'
import theme, { span } from '../../../../style/theme'
import ResponsiveImage from '../../../ResponsiveImage'
import Link from '../../../Link'
// import { resolveInternalLinkUrl } from '../../../../helpers/resolveLink'
import { quart } from '../../../../style/eases'
import isEmpty from 'lodash/isEmpty'
import detectIt from 'detect-it'
import { vw } from '../../../../style/vw'

export default ({ title, heroImage, link, description, authors, className, playbookTags }) => {
  const classes = useStyles()

  return (
    <li key={title} className={cn(className, classes.listItem)}>
      <Link to={link} className={classes.link} nonLinkTag='div'>
        <div className={classes.hoverEffect}>
          {!isEmpty(heroImage) && <ResponsiveImage {...heroImage} className={classes.hoverImage} />}
        </div>
        <span className={classes.titleWrapper}>
          <h4 className={classes.title}>{title}</h4>
          <span className={classes.playbookTags}>
            {/* {playbookTags && playbookTags.map((playbookTag, i) => <span className={classes.playbookTag} key={i}>{playbookTag.title}</span>)} */}
            {playbookTags && playbookTags.map((item, idx) => (
              <React.Fragment key={idx}>
                <span className={classes.dep}>{item.title}</span>
                {idx < playbookTags.length - 1 && <span className={classes.newDot} />}
              </React.Fragment>
            ))}
          </span>
        </span>
        <span className={classes.authors}>
          {authors && authors.map((authors, i) => <span className={classes.authors} key={i}>{authors.title || authors.author.title}</span>)}
        </span>
        <span className={classes.description}>{description}</span>
      </Link>
      <span className={classes.authorsMb}>
        <span className={classes.authorHeading}>Authors:</span>
        <span>
          {authors && authors.map((authors, i) => <span className={classes.authorsMbName} key={i}>{authors.title || authors.author.title}</span>)}
        </span>
      </span>
    </li>
  )
}

const useStyles = createUseStyles({
  listItem: {
    fontSize: vw(14),
    // borderTop: [1, 'solid', theme.colors.border],
    borderBottom: [1, 'solid', theme.colors.border],
    position: 'relative',
    overflow: 'hidden',
    [theme.breakpoints.up('md')]: {
      fontSize: vw(14, 'lg')
    }
    // '&:last-child': {
    //   borderBottom: [1, 'solid', theme.colors.border]
    // }
  },
  newDot: {
    width: '6px',
    height: '6px',
    margin: '0 10px',
    borderRadius: '50%',
    backgroundColor: theme.colors.primary,
    [theme.breakpoints.down('fab')]: {
      display: 'none'
    }
  },
  dep: {
    fontFamily: 'Object Sans',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '9px',
    color: theme.colors.primary,

    [theme.breakpoints.up('fab')]: {
      fontSize: '14px'
    }
  },
  link: {
    position: 'relative',
    display: 'flex',
    // alignItems: 'center',
    padding: [vw(16), theme.getMargin()],
    textDecoration: 'none',
    color: theme.colors.text,
    margin: [0, vw(-6)],
    overflow: 'hidden',
    [theme.breakpoints.up('md')]: {
      minHeight: vw(180, 'lg'),
      margin: [0, vw(-30, 'vw')],
      padding: vw(30, 'lg')
    },
    '& > *': {
      position: 'relative',
      margin: [0, vw(6)],
      [theme.breakpoints.up('md')]: {
        marginRight: [0, vw(30, 'vw')]
      }
    },
    transition: `color 0.25s ${quart.inOut}, background-color 0.25s ${quart.inOut}`,
    ...(detectIt.primaryInput === 'touch'
      ? {
        '&:hover': {
          color: theme.colors.white,
          backgroundColor: theme.colors.primary,
          '& $playbookTag': {
            color: '#fff'
          }
        }
      }
      : {
        '&:hover': {
          color: theme.colors.white,
          '& $playbookTag': {
            color: '#fff'
          }
        }
      })
  },
  titleWrapper: {
    width: '40%'
  },
  label: {
    display: 'block',
    opacity: 0.5
  },
  title: {
    fontSize: vw(20),
    [theme.breakpoints.up('md')]: {
      fontSize: vw(50, 'lg')
    }
  },
  subtitle: {
    fontSize: vw(12),
    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  },
  description: {
    width: '50%',
    fontSize: vw(5),
    [theme.breakpoints.down('fab')]: {
      fontSize: vw(12)
    }
  },
  desktopOnly: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'block'
    }
  },
  mobileOnly: {
    display: 'block',
    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  },
  authors: {
    composes: ['$desktopOnly'],
    fontSize: vw(6),
    fontWeight: 700,
    whiteSpace: 'pre-wrap',
    [theme.breakpoints.up('md')]: {
      width: span(5, 'md')
    }
  },
  authorsMb: {
    composes: ['$mobileOnly'],
    // display: 'flex',
    // flexDirection: 'column',
    padding: '0 25px 15px',
    display: 'none'
  },
  authorHeading: {
    fontWeight: 700,
    fontSize: '11px'
  },
  authorsMbName: {
    display: 'flex',
    flexDirection: 'column',
    fontSize: '11px'
    // marginLeft: '8px'
  },
  playbookTags: {
    marginTop: '8px',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('fab')]: {
      flexDirection: 'row',
      alignItems: 'center'
    }
  },
  // playbookTag: {
  //   fontSize: vw(12),
  //   color: '#3283ff',
  //   display: 'inline-block',
  //   // padding: '4px',
  //   // borderRadius: '4px',
  //   // borderBottom: '2px solid #000023',
  //   // color: '#fff',
  //   [theme.breakpoints.up('fab')]: {
  //     fontSize: vw(6)
  //   },
  //   [theme.breakpoints.up('md')]: {
  //     fontSize: vw(4)
  //   }
  // },
  stageColumn: {
    composes: ['$desktopOnly'],
    [theme.breakpoints.up('md')]: {
      width: span(4, 'md')
    }
  },
  arrow: {
    composes: ['$desktopOnly'],
    textAlign: 'right',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      width: span(1, 'md')
    }
  },
  hoverEffect: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: -2,
    backgroundColor: theme.colors.primary,
    transform: 'translateY(100%)',
    transition: `transform 0.25s ${quart.inOut}`,
    ...(detectIt.primaryInput === 'touch' ? {} : {
      '$link:hover &': {
        transform: 'translateY(0%)'
      }
    })
  },
  hoverImage: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    '&:after': {
      content: '""',
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      backgroundColor: 'rgba(34, 34, 34, 0.7)'
    }
  }
}, { name: 'PlaybookItem' })
