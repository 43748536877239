import React, { forwardRef, useMemo, useCallback, useRef, useEffect } from 'react'
import { createUseStyles } from 'react-jss'
import cn from 'classnames'
import { resolveLink } from '../helpers/resolveLink'
import Link from './Link'
import theme, { vw } from '../style/theme'
import gsap from 'gsap'

export default forwardRef(({ className, link, children, invert, secondaryColor = theme.colors.secondary, ...rest }, ref) => {
  const foregroundColor = invert ? secondaryColor : theme.colors.white
  const backgroundColor = invert ? theme.colors.white : secondaryColor
  const invertedForegroundColor = invert ? theme.colors.white : secondaryColor
  const invertedBackgroundColor = invert ? secondaryColor : theme.colors.white
  const classes = useStyles({ foregroundColor, backgroundColor, invertedForegroundColor, invertedBackgroundColor })
  const buttonLink = useMemo(() => resolveLink(link), [link])
  const animationLocals = useRef({ value: 0 })
  const invertedContainerRef = useRef()
  const timelineRef = useRef()

  useEffect(() => {
    const duration = 0.15
    timelineRef.current = gsap.timeline()
    timelineRef.current.to(animationLocals.current, {
      value: 1,
      duration,
      ease: 'sine.out',
      onUpdate: () => {
        const percent = animationLocals.current.value * 100
        const clipPath = `polygon(0 ${percent}%, 100% ${percent}%, 100% 100%, 0 100%)`
        invertedContainerRef.current.style.clipPath = clipPath
      }
    })
    timelineRef.current.seek(duration)
    return () => {
      timelineRef.current.kill()
    }
  }, [])

  const onMouseEnter = useCallback(() => {
    if (timelineRef.current) {
      timelineRef.current.reversed(true)
    }
  }, [])
  const onMouseLeave = useCallback(() => {
    if (timelineRef.current) {
      timelineRef.current.reversed(false)
    }
  }, [])

  return (
    <Link
      className={cn(className, classes.button)}
      link={buttonLink}
      nonLinkTag='button'
      ref={ref}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      {...rest}
    >
      <span className={classes.buttonText}>{children}</span>
      <div className={classes.invertedContainer} aria-hidden='true' ref={invertedContainerRef}>
        <span className={classes.invertedButton}>{children}</span>
      </div>
    </Link>
  )
})

const buttonStyles = {
  display: 'block',
  extend: theme.typography.h6,
  whiteSpace: 'pre',
  textAlign: 'center'
}

const useStyles = createUseStyles({
  button: {
    position: 'relative',
    overflow: 'hidden',
    textDecoration: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: ({ foregroundColor }) => foregroundColor,
    backgroundColor: ({ backgroundColor }) => backgroundColor,
    border: [1, 'solid', 'currentColor'],
    transition: 'background-color 0.25s, color 0.25s',
    padding: [vw(6), vw(12)],
    margin: 0,
    cursor: 'pointer',
    minHeight: 42,
    [theme.breakpoints.up('fab')]: {
      padding: [vw(6), vw(24)]
    },
    [theme.breakpoints.up('md')]: {
      padding: [vw(6, 'lg'), vw(24, 'lg')]
    }
  },
  buttonText: {
    ...buttonStyles,
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& p': {
      ...buttonStyles
    }
  },
  invertedContainer: {
    overflow: 'hidden',
    color: ({ invertedForegroundColor }) => invertedForegroundColor,
    backgroundColor: ({ invertedBackgroundColor }) => invertedBackgroundColor,
    position: 'absolute',
    top: -1,
    bottom: -1,
    left: -1,
    right: -1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    clipPath: 'polygon(0 100%, 100% 100%, 100% 100%, 0 100%)'
  },
  invertedButton: {
    composes: ['$buttonText'],
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: 'none'
  }
}, { name: 'Button' })
