import React, { forwardRef } from 'react'
import cn from 'classnames'
import { createUseStyles } from 'react-jss'
import RichText from '../RichText'
import CompanyLogo from '../CompanyLogo'
import Button from '../Button'
import theme from '../../style/theme'
import { span } from '../../style/span'
import { resolveInternalLinkUrl } from '../../helpers/resolveLink'
import { useDarkSlice } from '../HeaderContextProvider'

const PortfolioHero = forwardRef(({ slice }, ref) => {
  const {
    opening_description: openingDescription,
    career
  } = slice
  const {
    title,
    location,
    portfolio
  } = career

  const {
    title: companyTitle,
    logo,
    page: companyPage,
    website
  } = (portfolio || {})

  const companyPageUrl = resolveInternalLinkUrl(companyPage)
  const sectionRef = useDarkSlice()

  const degrees = 6.5
  const adjacentSide = Math.tan(degrees * Math.PI / 180) * 100
  const classes = useStyles({ adjacentSide })
  return (
    <section className={classes.section} ref={sectionRef}>
      <div className={classes.headerSpace} />
      <div className={classes.content}>
        <div className={classes.titleWrapper}>
          <h1 className={classes.heroTitle}>{title}</h1>
        </div>
        <div className={classes.cols}>
          <div className={cn(classes.col, classes.colLeft)}>
            <div className={classes.rows}>
              <div className={classes.row}>
                <CompanyLogo className={classes.logo} logo={logo} />
              </div>
              <div className={classes.row}>
                <span className={classes.rowLabel}>Company</span>
                <span className={classes.stage}>{companyTitle}</span>
              </div>
              <div className={classes.row}>
                <span className={classes.rowLabel}>Location</span>
                <span className={classes.stage}>{location}</span>
              </div>
              {(companyPageUrl || website) && (
                <div className={classes.row}>
                  <div className={classes.links}>
                    {companyPageUrl && (
                      <Button className={classes.button} to={companyPageUrl}>
                        About {companyTitle}
                      </Button>
                    )}
                    {website && (
                      <Button className={classes.button} to={website}>
                        Visit {companyTitle} Site
                      </Button>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className={cn(classes.col, classes.colRight)}>
            <RichText content={openingDescription.text} className={classes.openingDescription} />
          </div>
        </div>
      </div>
      <div className={classes.elevatedArrow}>
        <svg viewBox='0 0 8 60' fill='none' xmlns='http://www.w3.org/2000/svg'>
          <path d='M4.354.646a.5.5 0 00-.708 0L.464 3.828a.5.5 0 00.708.708L4 1.707l2.828 2.829a.5.5 0 00.708-.708L4.354.646zM4.5 60V1h-1v59h1z' />
        </svg>
      </div>
    </section>
  )
})

const useStyles = createUseStyles({
  section: {
    backgroundColor: theme.colors.secondary,
    color: theme.colors.white,
    position: 'relative'
  },
  heroWrapper: {
    position: 'relative',
    paddingBottom: '130vw',
    [theme.breakpoints.up('md')]: {
      paddingBottom: '50vw'
    }
  },
  heroImage: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0
  },
  clipArea: {
    position: 'absolute',
    bottom: 0,
    clipPath: 'polygon(100% 0, 0 100%, 100% 100%)',
    width: '100%',
    height: ({ adjacentSide }) => `${adjacentSide}vw`,
    backgroundColor: theme.colors.secondary,
    zIndex: 1,
    marginBottom: -1
  },
  headerSpace: {
    ...theme.header.height('paddingTop')
  },
  content: {
    padding: [50, theme.getMargin('min')],
    position: 'relative',
    [theme.breakpoints.up('md')]: {
      padding: [100, span(1, 'md'), 200],
      margin: [0, theme.getMargin('md')]
    }
  },
  titleWrapper: {
    marginBottom: 50,
    [theme.breakpoints.up('md')]: {
      marginBottom: 100
    }
  },
  heroTitle: {
    extend: theme.typography.h0
  },
  cols: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row'
    }
  },
  col: {
    display: 'block',
    marginBottom: 50,
    '&:last-child': {
      marginBottom: 0
    },
    [theme.breakpoints.up('md')]: {
      marginBottom: 0,
      flex: 1,
      '&:first-child': {
        maxWidth: span(6, 'md'),
        marginRight: span(2, 'md')
      },
      '&:last-child': {
        maxWidth: span(10, 'md'),
        marginLeft: span(2, 'md')
      }
    }
  },
  rows: {
    display: 'flex',
    flexDirection: 'column'
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: [1, 'solid', 'rgba(255, 255, 255, 0.5)'],
    padding: [16, 0],
    '&:first-child': {
      paddingTop: 0
    },
    '&:last-child': {
      paddingBottom: 0,
      borderBottom: 'none'
    }
  },
  rowLabel: {
    extend: theme.typography.bodySmall
  },
  links: {
    width: '100%',
    display: 'flex',
    '& > *': {
      flex: 1,
      marginRight: 25,
      '&:last-child': {
        marginRight: 0
      }
    }
  },
  elevatedArrow: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'block',
      position: 'absolute',
      bottom: 90,
      zIndex: 11,
      left: theme.getMargin('md'),

      '& svg': {
        display: 'block',
        height: 60,
        width: 8,
        fill: theme.colors.white,
        [theme.breakpoints.up('md')]: {
          transform: `translateX(${span(1, 'md')})`
        }
      }
    }
  }
}, { name: 'PortfolioHero' })

export default PortfolioHero
