import React from 'react'
import { createUseStyles } from 'react-jss'
import cn from 'classnames'

export const FACEBOOK_TYPE = 'facebook'
export const INSTAGRAM_TYPE = 'instagram'
export const LINKEDIN_TYPE = 'linkedin'
export const TWITTER_TYPE = 'twitter'
export const YOUTUBE_TYPE = 'youtube'

const SocialLink = ({ type, url, className, svgClassName }) => {
  const classes = useStyles()
  if (!type) return null

  switch (type.toLowerCase()) {
    case FACEBOOK_TYPE:
      return (
        <a
          className={cn(className, FACEBOOK_TYPE, classes.socialLink)}
          href={url}
          target='_blank'
          rel='noopener noreferrer'
          aria-label='Open a new tab to Facebook'
        >
          <svg className={svgClassName} xmlns='http://www.w3.org/2000/svg' viewBox='0 0 245 244'>
            <path d='M245 122.746C245 54.955 190.155 0 122.5 0S0 54.955 0 122.746C0 184.01 44.796 234.792 103.36 244v-85.773H72.255v-35.481h31.103V95.703c0-30.763 18.289-47.756 46.27-47.756 13.403 0 27.422 2.398 27.422 2.398v30.207h-15.447c-15.218 0-19.963 9.462-19.963 19.168v23.026h33.974l-5.43 35.48H141.64V244C200.204 234.792 245 184.011 245 122.746' />
          </svg>
        </a>
      )
    case LINKEDIN_TYPE:
      return (
        <a
          className={cn(className, LINKEDIN_TYPE, classes.socialLink)}
          href={url}
          target='_blank'
          rel='noopener noreferrer'
          aria-label='Open a new tab to LinkedIn'
        >
          <svg viewBox='0 0 17 16' fill='none' xmlns='http://www.w3.org/2000/svg' className={svgClassName}>
            <path
              fillRule='evenodd'
              clipRule='evenodd'
              d='M.418 1.337A1.337 1.337 0 011.755 0H15.08a1.337 1.337 0 011.336 1.337v13.326a1.337 1.337 0 01-1.336 1.336H1.755a1.337 1.337 0 01-1.337-1.336V1.337zM6.751 6.1h2.167v1.088C9.23 6.562 10.03 6 11.233 6c2.304 0 2.851 1.246 2.851 3.532v4.234h-2.333v-3.713c0-1.302-.313-2.037-1.107-2.037-1.101 0-1.56.792-1.56 2.037v3.713H6.751V6.1zm-4 7.566h2.333V6H2.751v7.666zM5.418 3.5a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0z'
            />
          </svg>
        </a>
      )
    case INSTAGRAM_TYPE:
      return (
        <a
          className={cn(className, INSTAGRAM_TYPE, classes.socialLink)}
          href={url}
          target='_blank'
          rel='noopener noreferrer'
          aria-label='Open a new tab to Instagram'
        >
          <svg className={svgClassName} viewBox='0 0 25 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path d='M12.975 2.161c3.204 0 3.584.012 4.85.07 1.17.053 1.805.249 2.228.413.521.192.994.5 1.38.898.4.387.707.86.899 1.381.164.423.36 1.059.413 2.229.057 1.265.07 1.645.07 4.849s-.013 3.583-.07 4.849c-.053 1.17-.249 1.805-.413 2.228a3.978 3.978 0 01-2.277 2.278c-.424.164-1.059.36-2.229.413-1.265.057-1.645.07-4.849.07s-3.584-.013-4.85-.07c-1.17-.054-1.805-.249-2.227-.413a3.715 3.715 0 01-1.382-.898 3.715 3.715 0 01-.898-1.381c-.164-.424-.36-1.059-.413-2.229-.057-1.265-.07-1.645-.07-4.849s.013-3.584.07-4.85c.054-1.17.249-1.805.413-2.228.192-.521.5-.993.898-1.38.388-.4.86-.707 1.382-.899.422-.164 1.058-.36 2.228-.413 1.265-.057 1.645-.07 4.849-.07l-.002.002zm.002-2.164c-3.26 0-3.668.014-4.948.072-1.28.06-2.151.263-2.914.56-.8.301-1.526.774-2.126 1.384a5.891 5.891 0 00-1.384 2.126c-.296.762-.5 1.636-.557 2.913C.989 8.33.975 8.74.975 12c0 3.26.014 3.668.073 4.948.058 1.28.26 2.15.557 2.912a5.89 5.89 0 001.384 2.125c.6.61 1.325 1.083 2.126 1.385.763.297 1.636.5 2.913.558 1.277.058 1.689.072 4.948.072 3.26 0 3.668-.014 4.948-.072 1.28-.059 2.149-.261 2.91-.558a6.132 6.132 0 003.51-3.51c.298-.763.5-1.636.559-2.913.058-1.277.072-1.689.072-4.948 0-3.26-.014-3.668-.072-4.948-.059-1.28-.261-2.149-.558-2.91a5.884 5.884 0 00-1.384-2.126A5.891 5.891 0 0020.835.629c-.762-.297-1.636-.5-2.913-.558C16.645.013 16.234 0 12.975 0l.002-.002z' />
            <path d='M12.975 5.838a6.162 6.162 0 100 12.324 6.162 6.162 0 000-12.324zm0 10.163a4.002 4.002 0 110-8.003 4.002 4.002 0 010 8.003zM19.38 7.034a1.44 1.44 0 100-2.88 1.44 1.44 0 000 2.88z' />
          </svg>
        </a>
      )
    case TWITTER_TYPE:
      return (
        <a
          className={cn(className, TWITTER_TYPE, classes.socialLink)}
          href={url}
          target='_blank'
          rel='noopener noreferrer'
          aria-label='Open a new tab to Twitter'
        >
          <svg viewBox='0 0 38 31' xmlns='http://www.w3.org/2000/svg' className={svgClassName}>
            <path
              d='M.71 27.328l.207.123c3.328 1.993 7.1 3.049 10.903 3.049h.137c7.986-.052 13.05-3.817 15.893-6.967 3.807-4.216 5.965-10.034 5.638-15.185l-.03-.444.39-.213a7.712 7.712 0 003.266-3.483l.075-.166-.39.194a12.11 12.11 0 01-1.133.432l-2.294.751 1.536-1.86a7.607 7.607 0 001.34-2.4l.055-.168-.149.092c-.017.011-2.092 1.283-4.3 1.8l-.374.089-.277-.271A7.685 7.685 0 0025.843.5c-4.782 0-7.637 3.882-7.637 7.637 0 .092.022.378.054.69l.079.822-.822-.054a20.08 20.08 0 01-14.51-7.748l-.059-.078-.047.085c-2.241 4.08-.388 7.451.51 8.712l1.224 1.713-2.004-.64a7.16 7.16 0 01-.7-.266l-.098-.043.005.108a7.98 7.98 0 003.12 5.897l2.74 2.103-3.315-.867.04.114c.823 2.455 2.813 4.365 5.318 5.112l1.577.47-1.433.81c-3.727 2.103-7.406 2.268-8.445 2.268-.248 0-.409-.006-.506-.01l-.224-.007z'
              fillRule='nonzero'
            />
          </svg>
        </a>
      )
    case YOUTUBE_TYPE:
      return (
        <a
          className={cn(className, TWITTER_TYPE, classes.socialLink)}
          href={url}
          target='_blank'
          rel='noopener noreferrer'
          aria-label='Open a new tab to Twitter'
        >
          <svg viewBox='0 0 25 17' xmlns='http://www.w3.org/2000/svg' className={svgClassName}>
            <path id='Vector' d='M9.62963 11.875L15.8769 8.3125L9.62963 4.75V11.875ZM23.5444 2.57688C23.7009 3.135 23.8093 3.88312 23.8815 4.83312C23.9657 5.78312 24.0019 6.6025 24.0019 7.315L24.0741 8.3125C24.0741 10.9131 23.8815 12.825 23.5444 14.0481C23.2435 15.1169 22.5454 15.8056 21.462 16.1025C20.8963 16.2569 19.8611 16.3638 18.2722 16.435C16.7074 16.5181 15.275 16.5538 13.9509 16.5538L12.037 16.625C6.99352 16.625 3.85185 16.435 2.61204 16.1025C1.5287 15.8056 0.830556 15.1169 0.52963 14.0481C0.373148 13.49 0.264815 12.7419 0.192593 11.7919C0.108333 10.8419 0.0722221 10.0225 0.0722221 9.31L0 8.3125C0 5.71187 0.192593 3.8 0.52963 2.57688C0.830556 1.50813 1.5287 0.819375 2.61204 0.5225C3.17778 0.368125 4.21296 0.26125 5.80185 0.19C7.36667 0.106875 8.79907 0.0712499 10.1231 0.0712499L12.037 0C17.0806 0 20.2222 0.19 21.462 0.5225C22.5454 0.819375 23.2435 1.50813 23.5444 2.57688Z' />
          </svg>
        </a>
      )
    default:
      return null
  }
}

const useStyles = createUseStyles({
  socialLink: {
    display: 'block',
    '& svg': {
      display: 'block',
      height: 16,
      width: 16,
      fill: 'currentColor'
    }
  }
}, { name: 'SocialLink' })

export default SocialLink
