import inDOM from 'dom-helpers/canUseDOM'
import { ActionType } from 'redux-promise-middleware'
import { rehydrated, queryStringChangeCreator, transitionComplete } from '../../actions'

import { pageContentActionCreator } from '../../slices/content/page'
import { postsLoadMoreActionCreator, postsChangeInitiativeActionCreator } from '../../slices/content/posts'

import { fetchPosts } from '../../api'

import {
  getPostListSlice,
  getFeaturedPostSlice,
  getFeaturedPostListSlice,
  getPostsQueryStringArguments
} from '../../slices/content/selectors'

export const POSTS_PER_PAGE = 6

export default store => next => action => {
  if (!inDOM) {
    return next(action)
  }
  const ret = next(action)
  const postListSlice = getPostListSlice(store.getState())
  if (postListSlice) {
    const {
      postCategory,
      postInitiatives
    } = postListSlice
    const {
      postPage = 0,
      postInitiative = ''
    } = getPostsQueryStringArguments(store.getState())
    const hasCategory = postCategory && postCategory.id
    const matchingInitiative = postInitiative && postInitiatives && postInitiatives.find(initiative => initiative.slug === postInitiative)
    let omittedPostIds = []
    if (matchingInitiative) {
      omittedPostIds.push(matchingInitiative.featuredPost.id)
    } else {
      const featuredPostSlice = getFeaturedPostSlice(store.getState())
      if (featuredPostSlice) {
        omittedPostIds.push(featuredPostSlice.featured_post.id)
      }
      const featuredPostListSlice = getFeaturedPostListSlice(store.getState())
      if (featuredPostListSlice) {
        omittedPostIds.push(featuredPostListSlice.primary_post.id)
        omittedPostIds = omittedPostIds.concat(featuredPostListSlice.secondary_posts.map(post => post.id))
      }
    }
    if (action.type === rehydrated.toString() ||
      action.type === postsChangeInitiativeActionCreator.toString() ||
      action.type === `${pageContentActionCreator}_${ActionType.Fulfilled}` ||
      action.type === transitionComplete.toString()) {
      fetchPosts(store.dispatch, store.getState, postListSlice.id, {
        page: 0,
        limit: POSTS_PER_PAGE * (postPage + 1),
        postCategoryId: hasCategory ? postCategory.id : null,
        postInitiativeId: matchingInitiative ? matchingInitiative.id : null,
        omit: omittedPostIds
      })
    }
    if (action.type === postsLoadMoreActionCreator.toString()) {
      store.dispatch(queryStringChangeCreator(store.getState(), { postPage: postPage + 1 }))
      fetchPosts(store.dispatch, store.getState, postListSlice.id, {
        page: postPage + 1,
        limit: POSTS_PER_PAGE,
        postCategoryId: hasCategory ? postCategory.id : null,
        postInitiativeId: matchingInitiative ? matchingInitiative.id : null,
        omit: omittedPostIds
      }, true)
    }
  }
  return ret
}
