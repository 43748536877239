import React from 'react'
import { createUseStyles } from 'react-jss'
import theme from '../../style/theme'

import ResponsiveImage from '../ResponsiveImage3'
import { useDarkSlice } from '../HeaderContextProvider'
import RichText from '../RichText'

export default ({ slice }) => {
  const firstImage = slice.firstImage
  const classes = useStyles()
  const sectionRef = useDarkSlice()
  return (
    <section className={classes.section} ref={sectionRef}>
      <div className={classes.text}>
        <>
          <RichText content={slice.heading.text} />
          {slice.subtitle && <p className={classes.para}>{slice.subtitle}</p>}
        </>
      </div>
      <div className={classes.imageWrapper}>
        <ResponsiveImage {...firstImage} aspect={1.33} />
      </div>
    </section>
  )
}

const useStyles = createUseStyles({
  section: {
    backgroundColor: theme.colors.secondary,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      padding: '50px',
      gap: '32px'
    }
  },
  text: {
    width: '100vw',
    color: theme.colors.white,
    padding: '24px',
    // paddingBottom: '0px',
    textAlign: 'center',
    [theme.breakpoints.up('md')]: {
      width: '45vw',
      padding: '0px',
      textAlign: 'left'
    }
  },
  para: {
    fontSize: '16px',
    marginBottom: '0px',
    color: theme.colors.primary,
    marginTop: '16px',
    [theme.breakpoints.up('fab')]: {
      fontSize: '24px'
    },
    [theme.breakpoints.up('xs')]: {
      fontSize: '28px'
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '32px'
    }
  },
  imageWrapper: {
    width: '100vw',
    display: 'flex',
    fontSize: '0px',
    padding: '24px',
    [theme.breakpoints.up('md')]: {
      width: '55vw',
      padding: '0px'
    }
  }
}, { name: 'FRNewAgenda' })
