export const WhatWillYouGetData = [
  {
    heading: 'What will you Get',
    chapterNumber: '01',
    type: 'Chapter'
  },
  {
    heading: 'Tools in Playbook ',
    chapterNumber: '03',
    type: 'Tools'
  }
]
