import React, { useCallback, useMemo } from 'react'
import { createUseStyles } from 'react-jss'
import { useDispatch, useSelector } from 'react-redux'
import cn from 'classnames'
import {
  getFutureMapsQueryStringArguments,
  getPageSlug,
  getState
} from '../../../redux/slices/content'

import Link from '../../Link'
import { resolveInternalLink } from '../../../helpers/resolveLink'
import theme, { span, vw } from '../../../style/theme'
import { queryStringChangeCreator } from '../../../redux/actions'
import DropDown from '../../DropDown'
import { futureMapsChangeInitiativeActionCreator } from '../../../redux/slices/content/futureMaps'

const FutureMapsControlPanel = ({ postCategories, tags }) => {
  const classes = useStyles()
  const currentSlug = useSelector(getPageSlug)
  const dispatch = useDispatch()
  const { futureMapsTag } = useSelector(getFutureMapsQueryStringArguments)
  const state = useSelector(getState)

  const onChange = useCallback(({ target }) => {
    dispatch(queryStringChangeCreator(state, { futureMapsTag: target.value }))
    dispatch(futureMapsChangeInitiativeActionCreator())
  }, [])

  const initiativeOptions = useMemo(
    () =>
      tags.map((initiative) => ({
        text: initiative.title,
        value: initiative.title
      })),
    [currentSlug, tags]
  )

  return (
    <div className={classes.controlPanel}>
      <div className={classes.categoryOptions}>
        <div className={classes.options}>
          {postCategories &&
            postCategories.map((category) => {
              const isActive = currentSlug === category.categoryPage.slug
              const priority = category.priority
              const categoryPageLink = resolveInternalLink(
                category.categoryPage
              )
              if (categoryPageLink && priority > 0) {
                return (
                  <Link
                    key={category.slug}
                    className={cn(
                      classes.categoryOption,
                      isActive ? 'active' : '',
                      category.title === 'AI @ Elevation ' ? classes.textBold : ''
                    )}
                    link={categoryPageLink}
                  >
                    <span>{category.title}</span>
                  </Link>
                )
              } else {
                return null
              }
            })}
        </div>
      </div>

      <div className={classes.initiativeOptions}>
        <DropDown
          className={classes.initiativeDropdown}
          options={initiativeOptions}
          onChange={onChange}
          value={futureMapsTag}
          placeholderText='Filter'
        />
      </div>
    </div>
  )
}

const line = {
  content: '""',
  position: 'absolute',
  backgroundColor: theme.colors.grey,
  left: 0,
  right: 0,
  height: 1,
  bottom: 0,
  zIndex: 1
}

const useStyles = createUseStyles(
  {
    controlPanel: {
      display: 'flex',
      flexDirection: 'column',
      position: 'relative',
      [theme.breakpoints.up('md')]: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        margin: [0, theme.getMargin('md')],
        '&:after': {
          ...line
        }
      }
    },
    categoryOptions: {
      display: 'flex',
      marginBottom: 0,
      marginLeft: theme.getMargin('min'),
      position: 'relative',
      '&:after': {
        ...line
      },
      width: `calc(100% - ${theme.getMargin('min')}px)`,
      [theme.breakpoints.up('md')]: {
        marginBottom: 0,
        marginLeft: 0,
        '&:after': {
          display: 'none'
        }
      }
    },
    options: {
      display: 'flex',
      overflow: 'auto',
      alignItems: 'center',
      padding: [vw(16), 0],
      [theme.breakpoints.up('md')]: {
        padding: [vw(30, 'lg'), 0]
      }
    },
    categoryOption: {
      textDecoration: 'none',
      color: theme.colors.text,
      marginRight: 30,
      transition: 'color 0.15s ease-in-out',
      display: 'block',
      position: 'relative',
      '& span': {
        extend: theme.typography.bodySmall,
        textTransform: 'uppercase',
        [theme.breakpoints.up('md')]: {
          fontSize: 12
        }
      },
      '&:hover, &.active': {
        color: theme.colors.primary
      },
      [theme.breakpoints.up('md')]: {
        marginRight: 60
      },
      '&.active:after': {
        content: '""',
        position: 'absolute',
        backgroundColor: theme.colors.primary,
        left: 0,
        right: 0,
        height: 1,
        bottom: vw(-16),
        zIndex: 2,
        [theme.breakpoints.up('md')]: {
          bottom: vw(-30, 'lg')
        }
      }
    },
    initiativeOptions: {
      margin: [30, theme.getMargin('min'), 0],
      zIndex: 3,
      [theme.breakpoints.up('md')]: {
        marginTop: 0,
        padding: 0
      }
    },
    initiativeDropdown: {
      maxWidth: span(1),
      marginLeft: 'auto'
    },
    textBold: {
      fontWeight: 700,
      '& span': {
        fontWeight: 700
      }
    }
  },
  { name: 'FutureMapsControlPanel' }
)

export default FutureMapsControlPanel
