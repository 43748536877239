import React from 'react'
import { createUseStyles } from 'react-jss'
import theme, { span, vw } from '../style/theme'
import RichText from './RichText'
import get from 'lodash/get'
import cn from 'classnames'
import Timer from './Slices/Timer'

const Wedge = ({ className, title, copy, ctaText, backgroundColor, hideCopyOnMobile, wedgeAngle = 10, timer }) => {
  const classes = useStyles({ backgroundColor, wedgeAngle })

  const isRichText = get(ctaText, ['text'])
  const isWhiteBackground = backgroundColor === '#FFFFFF'

  return (
    <div className={cn(classes.wedge, className)}>
      <div className={classes.background}>
        <div className={classes.backgroundInner} />
      </div>
      {title && <span className={classes.title}>{title}</span>}
      {copy && <p className={cn(classes.copy, { [classes.hideCopyOnMobile]: hideCopyOnMobile }, isWhiteBackground && classes.black)}>{copy}</p>}
      {ctaText && (isRichText ? <RichText content={ctaText.text} className={classes.cta} /> : <span className={classes.cta}><p>{ctaText}</p></span>)}
      {timer && <Timer deadline={new Date(timer)} />}
    </div>
  )
}

const useStyles = createUseStyles({
  wedge: {
    padding: theme.getMargin(),
    position: 'absolute',
    right: 0,
    bottom: 0,
    [theme.breakpoints.up('md')]: {
      padding: theme.getMargin('md')
    },
    '& > *': {
      position: 'relative'
    }
  },
  background: {
    position: 'absolute',
    top: 0,
    bottom: -1,
    width: '200%',
    left: 0,
    transformOrigin: 'center'
  },
  backgroundInner: {
    backgroundColor: ({ backgroundColor }) => backgroundColor,
    transform: ({ wedgeAngle }) => `skewX(-${wedgeAngle}deg)`,
    transformOrigin: 'center top',
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0
  },
  title: {
    display: 'block',
    textTransform: 'uppercase',
    color: theme.colors.primary,
    marginBottom: vw(16),
    extend: theme.typography.h3,
    fontSize: vw(20),
    lineHeight: 0.9,
    '&:hover': {
      color: theme.colors.primary
    },
    [theme.breakpoints.up('md')]: {
      fontSize: vw(28, 'lg'),
      marginBottom: vw(16, 'lg')
    }
  },
  copy: {
    extend: theme.typography.bodySmall,
    marginBottom: vw(16),
    [theme.breakpoints.up('md')]: {
      marginBottom: vw(16, 'lg')
    }
  },
  black: {
    color: theme.colors.text
  },
  hideCopyOnMobile: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'block'
    }
  },
  cta: {
    '& > p, & > span': {
      display: 'inline',
      fontSize: vw(10),
      extend: theme.typography.bodySmall,
      fontWeight: theme.typography.fontWeight.bold,
      textTransform: 'uppercase',
      borderBottom: [1, 'solid', 'currentColor'],
      [theme.breakpoints.up('md')]: {
        fontSize: vw(12, 'lg')
      }
    }
  }
}, { name: 'Wedge' })

export default Wedge
