import { requestCustomSubscribeActionCreator } from '../slices/customNewsletter'
import { postCustomSubscription } from '../api'

export default (store) => {
  return next => (action) => {
    const ret = next(action)
    if (action.type === requestCustomSubscribeActionCreator.toString()) {
      postCustomSubscription(store.dispatch, store.getState, true)
    }
    return ret
  }
}
