import React, { useEffect, useRef, useMemo, useCallback } from 'react'
import cn from 'classnames'
import { createUseStyles } from 'react-jss'
import { gridConfig } from '../../style/grid'
import theme, { span, vw } from '../../style/theme'
import ResponsiveImage from '../ResponsiveImage'
import RichText from '../RichText'
import gsap from 'gsap'
import { useScrollVelocityListener } from '../useSmoothScroll'
import { useSelector } from 'react-redux'
import { isCurrentBreakpointMobile } from '../../redux/slices/layout'
import forEach from 'lodash/forEach'
import SplitWordsAnimation from '../SplitWordsAnimation'
import HighlightedText from '../HighlightedText'
import get from 'lodash/get'
import useIntersectionObserverCallback from '../../hooks/useIntersectionObserverCallback'
import composeRefs from '../../helpers/composeRefs'
import { useDarkSlice } from '../HeaderContextProvider'

var imageOffsets = [-24, 0, -8, 15, -12, 10]

export default ({ slice }) => {
  const classes = useStyles()
  const { title, copy, images } = slice
  const galleryRef = useRef()
  const tickerTimelineRef = useRef()
  const isMobile = useSelector(isCurrentBreakpointMobile)

  const imageList = useMemo(() => {
    return [...images, ...images]
  }, [images])

  // Layout the items in a random layout
  useEffect(() => {
    for (var i = 0; i < images.length; i++) {
      var items = [galleryRef.current.children[i].children[0], galleryRef.current.children[i + images.length].children[0]]
      forEach(items, item => {
        var offset = imageOffsets[i % imageOffsets.length]
        item.style.transform = `translate(0,${offset}vh)`
      })
    }
  }, [images, isMobile])

  useEffect(() => {
    const { width } = galleryRef.current.getBoundingClientRect()
    const duration = width / 150

    if (width > window.innerWidth) {
      tickerTimelineRef.current = gsap.timeline({ repeat: -1 })
      tickerTimelineRef.current.fromTo(galleryRef.current, { x: '-50%' }, {
        duration,
        ease: 'none',
        x: '0%'
      })
      return () => {
        tickerTimelineRef.current.kill()
        tickerTimelineRef.current = null
      }
    }
  }, [images])

  useScrollVelocityListener(useCallback((velocity) => {
    if (tickerTimelineRef.current) {
      const speed = gsap.utils.interpolate(1, 4, Math.abs(velocity) / 5)
      tickerTimelineRef.current.timeScale(speed)
    }
  }, []))

  const inViewRef = useIntersectionObserverCallback(
    { threshold: 0, triggerOnce: false },
    useCallback((entries) => {
      if (tickerTimelineRef.current) {
        const inView = get(entries, [0, 'isIntersecting'])
        tickerTimelineRef.current.paused(!inView)
      }
    }, [])
  )

  const ref = useDarkSlice()

  return (
    <section className={classes.section} ref={composeRefs(ref, inViewRef)}>
      <div className={classes.container}>
        <div className={classes.galleryContainer}>
          <div className={classes.gallery} ref={galleryRef}>
            {imageList.map((image, i) => (
              <div className={cn(classes.imageContainer, image.aspect > 1 ? classes.landscape : classes.portrait)} key={i}>
                <ResponsiveImage {...image} className={classes.image} />
              </div>
            ))}
          </div>
        </div>
        <SplitWordsAnimation wrapped overflow='visible'><span className={classes.title}><HighlightedText content={title.text} /></span></SplitWordsAnimation>
        <RichText content={copy.text} className={classes.copy} />
        {/* <p>{title}</p> */}
        {/* <p>{copy}</p> */}
      </div>
    </section>
  )
}

const useStyles = createUseStyles({
  sectionPadding: {
    ...theme.margins('padding')
  },
  section: {
    backgroundColor: theme.colors.secondary,
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.up('md')]: {
      minHeight: '150vh'
    }
  },
  container: {
    composes: ['$sectionPadding'],
    backgroundColor: theme.colors.secondary,
    color: theme.colors.white,
    position: 'relative',
    minHeight: '100vh',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      justifyContent: 'space-between'
    }
  },
  title: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.up('md')]: {
      marginTop: vw(80, 'lg'),
      flexGrow: 0,
      display: 'block'
    },
    '& h2': {
      position: 'relative',
      extend: theme.typography.h2
    }
  },
  copy: {
    position: 'relative',
    marginLeft: gridConfig.min.margin,
    flexGrow: 0,
    marginBottom: vw(50),
    [theme.breakpoints.up('md')]: {
      flexGrow: 1,
      marginLeft: 0,
      marginRight: span(3, 'md'),
      marginBottom: vw(50, 'lg'),
      display: 'flex',
      alignItems: 'flex-end',
      justifyContent: 'flex-end',
      maxWidth: span(12, 'md'),
      width: '100%'
    },
    '& p': {
      extend: theme.typography.bodyPlus
    }
  },
  galleryContainer: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    display: 'flex'
  },
  gallery: {
    display: 'flex',
    whiteSpace: 'nowrap',
    height: '100%',
    overflow: 'visible'
  },
  imageContainer: {
    backgroundColor: theme.colors.secondary, // We need to set the background color here
    position: 'relative',
    mixBlendMode: 'luminosity',
    opacity: 0.6,
    flexShrink: 0,
    display: 'flex',
    alignItems: 'center',
    ...theme.margins('marginRight')
  },
  image: {
    filter: 'grayscale(1)',
    mixBlendMode: 'luminosity',
    opacity: 0.8
  },
  landscape: {
    width: vw(280),
    [theme.breakpoints.up('md')]: {
      width: vw(420, 'lg')
    }
  },
  portrait: {
    width: vw(220),
    [theme.breakpoints.up('md')]: {
      width: vw(320, 'lg')
    }
  }
}, { name: 'Manifesto' })
