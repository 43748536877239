import React from 'react'
import { createUseStyles } from 'react-jss'
import RichText from '../../RichText.js'
import theme from '../../../style/theme.js'

const PlaybookTitle = ({ slice }) => {
  const classes = useStyles()
  return <div>{slice && <RichText content={slice.text} className={classes.heading} />}</div>
}

const useStyles = createUseStyles(
  {
    heading: {
      margin: '171px 22.2px 33.5px 55.6px',
      [theme.breakpoints.down('md')]: {
        margin: '40px 44px 0 25px'
      }
    }
  },
  { name: 'PlaybookTitle' }
)

export default PlaybookTitle
