import React, { forwardRef } from 'react'
import { createUseStyles } from 'react-jss'
import cn from 'classnames'
import map from 'lodash/map'
import get from 'lodash/get'
import LinkOutIcon from '../../../images/LinkOutIcon'
import { resolveLink, resolveInternalLinkUrl } from '../../../helpers/resolveLink'
import Link from '../../Link'
import theme, { span, vw } from '../../../style/theme'
import { quart } from '../../../style/eases'

export default forwardRef(({ careers, className, linkClassName }, ref) => {
  const classes = useStyles()
  return (
    <ul className={cn(classes.list, className)} ref={ref}>
      {map(careers, ({ id, title, portfolio, location, link, page }, i) => {
        const portfolioTitle = get(portfolio, ['title'])
        return (
          <li key={title + i} className={classes.item}>
            <Link to={page && resolveInternalLinkUrl(page)} link={!page && resolveLink(link)} className={cn(classes.link, linkClassName)} nonLinkTag='div'>
              <span className={classes.title}>{title}</span>
              <span className={classes.portfolioTitle}>{portfolioTitle}</span>
              <span className={classes.location}>{location}</span>
              <span className={classes.linkButton}>
                <LinkOutIcon className={classes.linkOutIcon} />
              </span>
            </Link>
          </li>
        )
      })}
    </ul>
  )
})

const useStyles = createUseStyles({
  list: {
    listStyle: 'none',
    padding: 0,
    margin: 0,
    borderTop: [1, 'solid', theme.colors.border],
    [theme.breakpoints.up('md')]: {
      borderTop: 'none'
    }
  },
  item: {
    display: 'block',
    overflow: 'hidden',
    position: 'relative',
    [theme.breakpoints.up('md')]: {
      padding: [0, theme.getGutter('md')]
    },
    '&:before': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: `calc(-${theme.getGutter('md')}px)`,
      width: `calc(100% + ${theme.getGutter('md') * 2}px)`,
      height: '100%',
      right: 0,
      bottom: 0,
      backgroundColor: theme.colors.primary,
      transition: `transform 0.25s ${quart.inOut}`,
      transform: 'translateY(110%)'
    },
    '&:hover': {
      '&:before': {
        transform: 'translateY(0%)'
      }
    },
    '&:last-child': {
      marginBottom: vw(20),
      [theme.breakpoints.up('md')]: {
        marginBottom: vw(20, 'md')
      }
    }
  },
  link: {
    color: 'currentColor',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    textDecoration: 'none',
    padding: [vw(30), theme.getMargin()],
    alignItems: 'center',
    position: 'relative',
    transition: `color 0.25s ${quart.inOut}`,
    borderBottom: [1, 'solid', theme.colors.border],

    [theme.breakpoints.up('md')]: {
      padding: [vw(30, 'lg'), 0],
      flexWrap: 'nowrap'
    },
    '&:hover': {
      color: theme.colors.white
    },
    '& > *': {
      position: 'relative'
    }
  },
  column: {
    display: 'block',
    width: '100%'
  },
  title: {
    composes: ['$column'],
    width: '100%',
    extend: theme.typography.body,
    marginBottom: vw(15),
    [theme.breakpoints.up('md')]: {
      width: span(8, 'md'),
      marginBottom: 0
    }
  },
  portfolioTitle: {
    width: 'auto',
    extend: theme.typography.bodySmall,
    [theme.breakpoints.up('md')]: {
      width: span(8, 'md')
    }
  },
  location: {
    width: 'auto',
    extend: theme.typography.bodySmall,
    [theme.breakpoints.up('md')]: {
      width: span(6, 'md')
    }
  },
  linkText: {
    display: 'inline-block',
    extend: theme.typography.bodySmall,
    marginRight: vw(8),
    paddingTop: vw(4, 'lg'),
    [theme.breakpoints.up('md')]: {
      marginRight: vw(8, 'lg')
    }
  },
  linkButton: {
    order: 9,
    display: 'none',
    color: 'currentColor',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      width: span(2, 'md')
    },
    '& svg': {
      display: 'block',
      width: vw(12, 'lg'),
      height: vw(12, 'lg'),
      '& .arrow': {
        fill: 'currentColor'
      },
      '& .box': {
        stroke: 'currentColor'
      }
    }
  }
}, { name: 'CareerListing' })
