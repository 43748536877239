import React from 'react'
import { createUseStyles } from 'react-jss'
import RichText from '../../RichText.js'
import theme from '../../../style/theme.js'

const PlaybookSubTitle = ({ slice }) => {
  const classes = useStyles()
  return <div>{slice && <RichText content={slice.text} className={classes.subTitle} />}</div>
}

const useStyles = createUseStyles(
  {
    subTitle: {
      margin: '0 105.73px 52px 55.6px',
      [theme.breakpoints.down('md')]: {
        margin: '24px 37px 0 25px'
      }
    }
  },
  { name: 'PlaybookTitle' }
)

export default PlaybookSubTitle
