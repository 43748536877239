import React, { useMemo, useCallback } from 'react'
import range from 'lodash/range'
import { useSelector, useDispatch } from 'react-redux'
import { createUseStyles } from 'react-jss'
import Loading from '../../Loading'
import theme, { vw } from '../../../style/theme'
import LoadMorePlus from '../../../images/LoadMorePlus'
import {
  getFutureMaps,
  getFutureMapsQueryStringArguments,
  getFutureMapsTotal,
  isFutureMapsBusy,
  isFutureMapsLoaded
} from '../../../redux/slices/content'
import { futureMapsLoadMoreActionCreator } from '../../../redux/slices/content/futureMaps'
import { resolveInternalLinkUrl } from '../../../helpers/resolveLink'
import { POSTS_PER_PAGE } from '../../../redux/middlewares/content/posts'
import FutureTile from './FutureTile'

const FutureMapsListSlice = () => {
  const classes = useStyles()
  const posts = useSelector(getFutureMaps)
  const total = useSelector(getFutureMapsTotal)
  const loaded = useSelector(isFutureMapsLoaded)
  const { postsPage: index } = useSelector(getFutureMapsQueryStringArguments)

  const busy = useSelector(isFutureMapsBusy)
  const dispatch = useDispatch()

  const loadMore = useCallback(() => {
    dispatch(futureMapsLoadMoreActionCreator())
  }, [])

  const postTiles = useMemo(() => {
    const expectedTiles = POSTS_PER_PAGE * (index + 1)
    var tiles = []
    if (posts) {
      tiles = posts.map((post, i) => {
        return {
          ...post,
          link: resolveInternalLinkUrl(post)
        }
      })
    }
    // if the pages have not loaded or it is busy getting the pages, then show the placeholders
    if (busy || !loaded) {
      const currentPageLength = posts ? posts.length : 0
      tiles = [
        ...tiles,
        ...range(expectedTiles - currentPageLength).map((x, i) => ({
          placeholder: true
        }))
      ]
    }
    return tiles
  }, [index, posts])

  const hasMore = !posts || total > posts.length

  return (
    <div className={classes.content}>
      <div className={classes.list}>
        {postTiles && postTiles?.map((post, i) => {
          const modifiedPost = {
            ...post,
            linkedPost: post.linked_post,
            futureMapCategory: post.future_map_category
          }
          return (
            <>
              {/* <a key={i} href={`/${modifiedPost.linkedPost.slug}`} className={classes.removeAnchorStyling}> */}
              {/* <PostTile key={post.id || i} className={classes.postListItem} {...post} /> */}
              <FutureTile {...modifiedPost} key={modifiedPost.linkedPost.id || i} className={classes.postListItem} {...modifiedPost.linkedPost} category={modifiedPost?.futureMapCategory} />
              {/* </a> */}
            </>
          )
        })}
      </div>
      {busy && <Loading />}
      {hasMore && (
        <div className={classes.listActions}>
          <button
            disabled={busy}
            className={classes.loadMoreButton}
            onClick={loadMore}
          >
            <LoadMorePlus />
            <span>Load More</span>
          </button>
        </div>
      )}
    </div>
  )
}

const useStyles = createUseStyles(
  {
    featuredPostWrapper: {
      padding: [30, theme.getMargin('min')],
      position: 'relative',
      [theme.breakpoints.up('md')]: {
        padding: [50, theme.getMargin('md')]
      }
    },
    content: {
      padding: [30, theme.getMargin('min'), 60],
      position: 'relative',
      [theme.breakpoints.up('md')]: {
        padding: [50, theme.getMargin('md'), 100]
      }
    },
    list: {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      columnGap: vw(20),
      rowGap: vw(60),
      [theme.breakpoints.up('md')]: {
        gridTemplateColumns: '1fr 1fr 1fr',
        columnGap: vw(25, 'lg'),
        rowGap: vw(80, 'lg')
      }
    },
    listActions: {
      display: 'flex',
      justifyContent: 'center',
      padding: [30, theme.getMargin('min')],
      [theme.breakpoints.up('md')]: {
        padding: [50, theme.getMargin('md')]
      }
    },
    loadMoreButton: {
      display: 'flex',
      alignItems: 'center',
      '& svg': {
        width: 30,
        height: 30,
        marginRight: 10
      },
      '& span': {
        paddingTop: 2,
        extend: theme.typography.bodySmall
      }
    },
    postListItem: {
      cursor: 'pointer'
    },
    removeAnchorStyling: {
      color: 'inherit',
      textDecoration: 'none'
    }
  },
  { name: 'FutureMapsListSlice' }
)

export default FutureMapsListSlice
