import React, { useState } from 'react'
import { createUseStyles } from 'react-jss'
import CardBackground from '../images/cardBackground.png'
// import EllipseIcon from '../images/Ellipse.png'
// import border from '../images/border.svg'
import ResponsiveImage from './ResponsiveImage'
import { resolveInternalLinkUrl } from '../helpers/resolveLink'
import Link from './Link'

const SaasTeamCard = ({ name, position, profileImage, secondaryImage, teamMember }) => {
  const classes = useStyles()
  const [isHovered, setIsHovered] = useState(false)
  const url = resolveInternalLinkUrl(teamMember[0].page)

  return (
    <Link className={classes.SaaSTeamCardTile} to={url} nonLinkTag='div'>
      <div className={classes.card} onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
        <div className={isHovered ? classes.profileImageContainer2 : classes.profileImageContainer}>
          <ResponsiveImage {...profileImage} className={classes.profileImage} />
          <ResponsiveImage {...secondaryImage} className={classes.secondaryImage} />
        </div>
        <div className={classes.cardContent}>
          <h1 className={classes.name}>{name}</h1>
          <div className={classes.designationContainer}>
            <p className={classes.designation}>{position}</p>
            {/* <img src={border} alt='border' /> */}
          </div>
          {/* {pastCompany.length > 0 && (
            <div className={classes.expericenceConatiner}>
              <img src={EllipseIcon} alt='elipse' />
              <p className={classes.expericence}>Past experience </p>
              <img src={EllipseIcon} alt='elipse' />
            </div>
          )}
          <div className={classes.companyLogoContainer}>
            {pastCompany?.map((item, i) => (
              <div className={classes.companyLogo} key={i}>
                <ResponsiveImage {...item.teamMemberCompanyImage} />
              </div>
            ))}
          </div> */}
        </div>
      </div>
    </Link>
  )
}

const useStyles = createUseStyles(
  {
    SaaSTeamCardTile: {
      textDecoration: 'none'
    },
    card: {
      minWidth: '266px',
      minHeight: '375px',
      background: 'linear-gradient(0deg, #00236D 40.64%, rgba(0, 35, 109, 0.17) 57.69%, rgba(0, 35, 109, 0.00) 68.84%, rgba(0, 35, 109, 0.00) 82.75%, rgba(0, 35, 109, 0.00) 107.46%)',
      cursor: 'pointer',
      marginBottom: '30px',
      marginTop: '20px',
      '&:hover': {
        '& $profileImage': {
          opacity: '0',
          transition: 'opacity 0.4s'
        },
        '& $secondaryImage': {
          opacity: '1'
        }
      }
    },
    designationContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      gap: '9px'
    },
    profileImageContainer: {
      height: '250px',
      position: 'relative',
      zIndex: '-1',
      display: 'flex',
      justifyContent: 'center'
    },
    profileImageContainer2: {
      height: '250px',
      position: 'relative',
      zIndex: '-10',
      display: 'flex',
      justifyContent: 'center',
      backgroundImage: `url(${CardBackground})`,
      top: '22px'
    },
    profileImage: {
      opacity: '1',
      position: 'absolute',
      transition: 'height 1s ease-in-out'
    },
    secondaryImage: {
      height: '315px',
      position: 'relative',
      bottom: '95px',
      maxWidth: '209px',
      opacity: '0'
    },
    cardContent: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      gap: '9px',
      paddingBottom: '15px',
      position: 'realtive',
      zIndex: '1'
    },
    name: {
      color: '#FFF',
      textAlign: 'center',
      fontSize: '42.5px',
      fontStyle: 'normal',
      fontWeight: '400',
      lineHeight: '90%',
      textTransform: 'uppercase'
    },
    designation: {
      color: '#FFF',
      textAlign: 'center',
      fontSize: '16px',
      fontStyle: 'normal',
      fontWeight: '400',
      lineHeight: 'normal',
      marginBottom: '0px'
    },
    expericence: {
      color: '#FFF',
      textAlign: 'center',
      fontSize: '10px',
      fontStyle: 'normal',
      fontWeight: '400',
      marginBottom: '0px'
    },
    expericenceConatiner: {
      display: 'flex',
      alignItems: 'center',
      gap: '5px'
    },
    companyLogoContainer: {
      display: 'flex',
      gap: '33px',
      alignItems: 'center',
      justifyContent: 'center'
    },
    companyLogo: {
      minWidth: '75px'
    }
  }, { name: 'SaasTeamCard' }
)

export default SaasTeamCard
