import React, { useRef, useCallback, useState, useEffect } from 'react'
import { createUseStyles } from 'react-jss'
import cn from 'classnames'
import theme, { vw } from '../style/theme'
import gsap from 'gsap'

export const AccordionItem = ({
  className,
  containerClassName,
  groupClassName,
  toggleClassName,
  title,
  children,
  defaultOpen = false
}) => {
  const classes = useStyles()
  const [open, setOpen] = useState(defaultOpen)
  const ref = useRef()
  const toggleOpen = useCallback(() => {
    setOpen(open => !open)
  }, [])
  useEffect(() => {
    if (ref.current) {
      gsap.to(ref.current, { height: open ? 'auto' : '0', duration: 0.25, ease: 'sine.inOut' })
    }
  }, [open])
  return (
    <li key={title} className={className}>
      <div className={cn(groupClassName, classes.grouping)} onClick={toggleOpen}>
        <h5 className={classes.groupTitle}>{title}</h5>
        <span className={cn(toggleClassName, classes.toggle)}>
          <svg viewBox='0 0 10 10'>
            <line x1={0} y1={5} x2={10} y2={5} />
            {!open && <line x1={5} y1={0} x2={5} y2={10} />}
          </svg>
        </span>
      </div>
      <div ref={ref} className={cn(containerClassName, classes.children)}>{children}</div>
    </li>
  )
}

export default ({ className, children }) => {
  const classes = useStyles()
  return (
    <ul className={cn(className, classes.groupList)}>
      {children}
    </ul>
  )
}

const useStyles = createUseStyles({
  groupList: {
    listStyle: 'none',
    margin: 0,
    padding: 0,
    marginBottom: vw(80),
    [theme.breakpoints.up('md')]: {
      marginBottom: vw(80, 'lg')
    }
  },
  grouping: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    backgroundColor: theme.colors.offWhite,
    padding: [10, theme.getMargin()],
    margin: [-1, -theme.getMargin(), 1],
    [theme.breakpoints.up('md')]: {
      padding: 20,
      margin: [-1, -20, 1]
    }
  },
  groupTitle: {
    flexGrow: 1
  },
  toggle: {
    border: [2, 'solid', 'currentColor'],
    width: '1em',
    height: '1em',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexGrow: 0,
    flexShrink: 0,
    fontSize: vw(32),
    marginLeft: vw(8),
    [theme.breakpoints.up('md')]: {
      marginLeft: vw(8, 'lg'),
      fontSize: vw(32, 'lg')
    },
    '& > svg': {
      width: '60%',
      height: '60%',
      strokeWidth: 1,
      stroke: 'currentColor'
    }
  },
  children: {
    overflow: 'hidden',
    height: 0
  }
}, { name: 'Accordion' })
