import React, { useState, useEffect } from 'react'
import { createUseStyles } from 'react-jss'
import map from 'lodash/map'
import theme, { vw, span } from '../../../style/theme'
import RichText from '../../RichText'
import { ReactComponent as TwitterIcon } from '../../../images/twitter.svg'
import { ReactComponent as InstagramIcon } from '../../../images/instagram.svg'
import { formatLongDate } from '../../../utils/format'
import PostCategory from './PostCategory'
import TwoColumnLayout from './TwoColumnLayout'

export default ({ slice, page }) => {
  const { custom_meta_fields: customFields, copy } = slice
  const { post_date: postDate, author } = page
  const classes = useStyles()
  const [shareLinks, setShareLinks] = useState({})

  useEffect(() => {
    setShareLinks({
      instagram: `https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(window.location.href)}&title=${encodeURIComponent(page.title)}&summary=&source=`,
      twitter: `https://twitter.com/intent/tweet?url=${encodeURIComponent(window.location.href)}&text=${encodeURIComponent(page.title)}`,
      facebook: `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(window.location.href)}`
    })
  }, [page])

  return (
    <TwoColumnLayout
      leftComponents={
        <div className={classes.fields}>
          <PostCategory page={page} className={classes.postCategory} />
          <dl className={classes.list}>
            {postDate && (
              <>
                <dt>Published</dt>
                <dd>{formatLongDate(postDate)}</dd>
              </>
            )}
            {author && (
              <>
                <dt>Author</dt>
                <dd>{author}</dd>
              </>
            )}
            <dt>Share</dt>
            <dd>
              <a className={classes.shareLink} href={shareLinks.twitter} rel='noopener noreferrer' target='_blank'><TwitterIcon className={classes.twitterIcon} /></a>
              <a className={classes.shareLink} href={shareLinks.instagram} rel='noopener noreferrer' target='_blank'><InstagramIcon className={classes.instagramIcon} /></a>
            </dd>
            {map(customFields, (field, i) => (
              <React.Fragment key={i}>
                <dt>{field.label}</dt>
                <dd><RichText content={field.value.text} className={classes.fieldValue} /></dd>
              </React.Fragment>
            ))}
          </dl>
        </div>
      }
      rightComponents={
        <div className={classes.copy}>
          {copy && <RichText content={copy.text} />}
        </div>
      }
    />
  )
}

const useStyles = createUseStyles({
  postCategory: {
    fontWeight: theme.typography.fontWeight.bold
  },
  copy: {
    [theme.breakpoints.up('md')]: {
      maxWidth: span(9, 'md')
    }
  },
  list: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: vw(450, 'lg')
    },
    '& > dt, & > dd': {
      fontSize: vw(14),
      margin: 0,
      paddingTop: vw(16),
      paddingBottom: vw(16),
      [theme.breakpoints.up('md')]: {
        fontSize: vw(14, 'lg'),
        paddingTop: vw(16, 'lg'),
        paddingBottom: vw(16, 'lg')
      }
    },
    '& > dt': {
      width: '40%',
      paddingRight: 16,
      color: '#9999A7',
      borderBottom: [1, 'solid', theme.colors.border]
    },
    '& > dd': {
      width: '60%',
      textAlign: 'right',
      borderBottom: [1, 'solid', theme.colors.border],
      '& *': {
        fontSize: vw(14),
        [theme.breakpoints.up('md')]: {
          fontSize: vw(14, 'lg')
        }
      }
    }
  },
  fieldValue: {
    '& a': {
      color: theme.colors.black,
      textDecoration: 'none',
      '&:hover': {
        color: theme.colors.primary
      }
    },
    '& > p': {
      fontSize: vw(14),
      [theme.breakpoints.up('md')]: {
        fontSize: vw(14, 'lg')
      }
    },
    '& ul': {
      padding: 0,
      listStyle: 'none',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      flexWrap: 'wrap',
      margin: [0, '-0.35em']
    },
    '& li': {
      padding: 0,
      margin: [0, '0.35em']
    }
  },
  shareLink: {
    color: theme.colors.black,
    display: 'inline-block',
    '&:not(:last-child)': {
      marginRight: 12
    },
    '&:hover': {
      color: theme.colors.primary
    }
  },
  twitterIcon: {
    width: vw(20),
    height: vw(16),
    [theme.breakpoints.up('md')]: {
      width: vw(20, 'lg'),
      height: vw(16, 'lg')
    }
  },
  instagramIcon: {
    width: vw(16),
    height: vw(16),
    [theme.breakpoints.up('md')]: {
      width: vw(16, 'lg'),
      height: vw(16, 'lg')
    }
  }
}, 'PostIntro')
