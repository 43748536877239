import * as fonts from './fonts'
import * as breakpoints from './breakpoints'
import { vw } from './vw'

export const fontWeight = {
  thin: 100,
  extraLight: 200,
  light: 300,
  regular: 400,
  medium: 500,
  semiBold: 600,
  bold: 700,
  extraBold: 800,
  black: 900
}

export const baseHeadingsStyles = {
  fontFamily: fonts.headings,
  fontWeight: fonts.headingsFontWeight,
  textTransform: 'uppercase',
  fontDisplay: 'swap'
}

export const baseBodyStyles = {
  fontFamily: fonts.body,
  fontWeight: fonts.bodyFontWeight,
  fontDisplay: 'swap'
}

export const h0 = {
  ...baseHeadingsStyles,
  fontSize: vw(60),
  lineHeight: 0.9,
  letterSpacing: '-0.01em',
  [breakpoints.up('md')]: {
    fontSize: vw(170, 'lg')
  }
}

export const h1 = {
  ...baseHeadingsStyles,
  fontSize: vw(60),
  lineHeight: 0.9,
  [breakpoints.up('md')]: {
    fontSize: vw(140, 'lg')
  }
}

export const h2 = {
  ...baseHeadingsStyles,
  fontSize: vw(60),
  lineHeight: 0.9,
  [breakpoints.up('md')]: {
    fontSize: vw(100, 'lg')
  }
}

export const h3 = {
  ...baseHeadingsStyles,
  fontSize: vw(50),
  lineHeight: 0.9,
  [breakpoints.up('md')]: {
    fontSize: vw(70, 'lg')
  }
}

export const h4 = {
  ...baseHeadingsStyles,
  fontSize: vw(35),
  lineHeight: 0.9,
  [breakpoints.up('md')]: {
    fontSize: vw(50, 'lg')
  }
}

export const h5 = {
  ...baseHeadingsStyles,
  fontSize: vw(20),
  lineHeight: 0.9,
  [breakpoints.up('md')]: {
    fontSize: vw(35, 'lg')
  }
}

export const h6 = {
  ...baseHeadingsStyles,
  fontSize: 20,
  lineHeight: 0.9,
  [breakpoints.up('md')]: {
    fontSize: vw(20, 'lg')
  }
}

export const body = {
  ...baseBodyStyles,
  fontSize: vw(15),
  lineHeight: 1.4,
  [breakpoints.up('md')]: {
    fontSize: vw(16, 'lg')
  }
}

export const bodyPlus = {
  ...baseBodyStyles,
  fontSize: vw(16),
  lineHeight: 1.4,
  [breakpoints.up('md')]: {
    fontSize: vw(20, 'lg')
  }
}

export const bodySmall = {
  ...baseBodyStyles,
  fontSize: vw(13),
  lineHeight: 1.4,
  [breakpoints.up('md')]: {
    fontSize: vw(14, 'lg')
  }
}

export const bodyTiny = {
  ...baseBodyStyles,
  fontSize: vw(10),
  lineHeight: 1.4,
  [breakpoints.up('md')]: {
    fontSize: vw(10, 'lg')
  }
}
export const bodyTiny2 = {
  ...baseBodyStyles,
  fontSize: vw(10),
  lineHeight: 1.4,
  [breakpoints.up('md')]: {
    fontSize: vw(12, 'lg')
  }
}
