/*eslint-disable*/
import React from 'react'
import RichText from '../../RichText'
import { createUseStyles } from 'react-jss'
import theme from '../../../style/theme'

const Quote = ({ slice }) => {
  const classes = useStyles()
  return (
    <div className={classes.quoteContainer}>
      <RichText
        content={slice.primary_text.text}
        classname={classes.quoteContainer}
      />
    </div>
  )
}

const useStyles = createUseStyles(
  {
    quoteContainer: {
      margin: '0px 70px 0px 0',
      [theme.breakpoints.down('md')]: {
        marginLeft: '25px',
      }
    },
    heading: {
      fontSize: '60px',
      fontWeight: 400,   
      
      [theme.breakpoints.down('fab')]: {
         fontSize: '35px',
      }
    }
  },
  { name: 'Quote' }
)

export default Quote
