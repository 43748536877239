import React, { useMemo, useCallback, useState } from 'react'
import range from 'lodash/range'
import { useSelector, useDispatch } from 'react-redux'
import { createUseStyles } from 'react-jss'
import Loading from '../../Loading'
import theme, { span } from '../../../style/theme'
import LoadMorePlus from '../../../images/LoadMorePlus'
import {
  getFutureMaps,
  getFutureMapsQueryStringArguments,
  getFutureMapsTotal,
  isFutureMapsBusy,
  isFutureMapsLoaded
} from '../../../redux/slices/content'
import { futureMapsLoadMoreActionCreator } from '../../../redux/slices/content/futureMaps'
import { resolveInternalLinkUrl } from '../../../helpers/resolveLink'
import { POSTS_PER_PAGE } from '../../../redux/middlewares/content/posts'
import AIElevationTile from './AIElevationTile'
import DropDown from '../../DropDown'
// import { convertCase } from '../../../helpers/string'

const AiElevationListSlice = () => {
  const classes = useStyles()
  const posts = useSelector(getFutureMaps)
  const total = useSelector(getFutureMapsTotal)
  const loaded = useSelector(isFutureMapsLoaded)
  const { postsPage: index } = useSelector(getFutureMapsQueryStringArguments)
  // const [filterByAuthor, setFilterByAuthor] = useState('')
  const [filterByTags, setFilterByTags] = useState('')
  // const authorFilterOptions = []
  // const uniqueAuthorsSet = new Set()
  const tagsFilterOptions = []
  const uniqueTagsSet = new Set()

  const busy = useSelector(isFutureMapsBusy)
  const dispatch = useDispatch()

  const loadMore = useCallback(() => {
    dispatch(futureMapsLoadMoreActionCreator())
  }, [dispatch])

  const postTiles = useMemo(() => {
    const expectedTiles = POSTS_PER_PAGE * (index + 1)
    var tiles = []
    if (posts) {
      tiles = posts.map((post, i) => {
        return {
          ...post,
          link: resolveInternalLinkUrl(post)
        }
      })
    }
    if (busy || !loaded) {
      const currentPageLength = posts ? posts.length : 0
      tiles = [
        ...tiles,
        ...range(expectedTiles - currentPageLength).map((x, i) => ({
          placeholder: true
        }))
      ]
    }
    return tiles
  }, [busy, index, loaded, posts])

  const hasMore = !posts || total > posts.length

  // const onChange = useCallback(({ target }) => {
  //   setFilterByAuthor(target.value)
  // }, [setFilterByAuthor])

  // if (postTiles && Array.isArray(postTiles) && postTiles.length) {
  //   for (let i = 0; i < postTiles.length; i++) {
  //     const authors = postTiles[i]
  //     const { author } = authors
  //     uniqueAuthorsSet.add(author)
  //   }
  // }

  // const uniqueAuthorsList = Array.from(uniqueAuthorsSet)

  // const filteredPostTiles = useMemo(() => {
  //   if (!filterByAuthor) {
  //     return postTiles
  //   }
  //   return postTiles.filter(post => post.author === filterByAuthor)
  // }, [filterByAuthor, postTiles])

  // uniqueAuthorsList.sort()
  // authorFilterOptions.push({ text: 'FILTER', value: '' })
  // if (uniqueAuthorsList && uniqueAuthorsList.length) {
  //   for (let i = 0; i < uniqueAuthorsList.length; i++) {
  //     authorFilterOptions.push({
  //       text: convertCase(uniqueAuthorsList[i]),
  //       value: uniqueAuthorsList[i]
  //     })
  //   }
  // }

  const onTagChange = useCallback(({ target }) => {
    setFilterByTags(target.value)
  }, [setFilterByTags])

  if (postTiles && Array.isArray(postTiles) && postTiles.length) {
    for (let i = 0; i < postTiles.length; i++) {
      const { futureMapsTags } = postTiles[i]
      if (futureMapsTags && Array.isArray(futureMapsTags) && futureMapsTags.length) {
        for (let j = 0; j < futureMapsTags.length; j++) {
          const playbookTag = futureMapsTags[j]
          const tag = playbookTag.title
          uniqueTagsSet.add(tag)
        }
      }
    }
  }

  const uniqueTagsList = Array.from(uniqueTagsSet)

  const filteredTagsPostTiles = useMemo(() => {
    if (!filterByTags) {
      return postTiles
    }

    return postTiles.filter(post => post.futureMapsTags.some(tags => tags.title === filterByTags))
  }, [filterByTags, postTiles])

  uniqueTagsList.sort()
  tagsFilterOptions.push({ text: 'FILTER', value: '' })
  if (uniqueTagsList && uniqueTagsList.length) {
    for (let i = 0; i < uniqueTagsList.length; i++) {
      tagsFilterOptions.push({
        text: (uniqueTagsList[i]),
        value: uniqueTagsList[i]
      })
    }
  }

  console.log({ filteredTagsPostTiles })

  return (
    <div className={classes.content}>
      <div className={classes.dropdownWrapper}>
        <div className={classes.dropdownWidth}>
          <DropDown
            className={classes.locationFilter}
            placeholderText='Filter'
            options={tagsFilterOptions}
            onChange={onTagChange}
            value={filterByTags}
          />
        </div>
        {/* <div className={classes.dropdownWidth}>
          <DropDown
            className={classes.locationFilter}
            placeholderText='Filter'
            options={authorFilterOptions}
            onChange={onChange}
            value={filterByAuthor}
          />
        </div> */}
      </div>
      <div classes={classes.line} />
      <div className={classes.list}>
        {filteredTagsPostTiles && filteredTagsPostTiles?.map((post, i) => {
          const modifiedPost = {
            ...post,
            linkedPost: post.linked_post,
            futureMapCategory: post.future_map_category
          }
          return (
            <>
              <AIElevationTile {...modifiedPost} key={modifiedPost.linkedPost.id || i} className={classes.postListItem} {...modifiedPost.linkedPost} category={modifiedPost?.futureMapCategory} />
            </>
          )
        })}
      </div>
      {busy && <Loading />}
      {hasMore && (
        <div className={classes.listActions}>
          <button
            disabled={busy}
            className={classes.loadMoreButton}
            onClick={loadMore}
          >
            <LoadMorePlus />
            <span>Load More</span>
          </button>
        </div>
      )}
    </div>
  )
}

const useStyles = createUseStyles(
  {
    featuredPostWrapper: {
      padding: [30, theme.getMargin('min')],
      position: 'relative',
      [theme.breakpoints.up('md')]: {
        padding: [50, theme.getMargin('md')]
      }
    },
    content: {
      padding: [30, theme.getMargin('min'), 60],
      position: 'relative',
      [theme.breakpoints.up('md')]: {
        padding: [50, theme.getMargin('md'), 100]
      }
    },
    dropdownWidth: {
      width: '180px'
    },
    dropdownWrapper: {
      marginBottom: '76px',
      borderBottom: [2, 'solid', theme.colors.border],
      paddingBottom: '24px'
    },
    list: {
      margin: 0,
      padding: 0,
      listStyle: 'none'
    },
    listActions: {
      display: 'flex',
      justifyContent: 'center',
      padding: [30, theme.getMargin('min')],
      [theme.breakpoints.up('md')]: {
        padding: [50, theme.getMargin('md')]
      }
    },
    loadMoreButton: {
      display: 'flex',
      alignItems: 'center',
      '& svg': {
        width: 30,
        height: 30,
        marginRight: 10
      },
      '& span': {
        paddingTop: 2,
        extend: theme.typography.bodySmall
      }
    },
    postListItem: {
      cursor: 'pointer'
    },
    removeAnchorStyling: {
      color: 'inherit',
      textDecoration: 'none'
    }
  },
  { name: 'AiElevationListSlice' }
)

export default AiElevationListSlice
