import React, { forwardRef, useMemo } from 'react'
import { resolveLink } from '../../helpers/resolveLink'
import NextPage from '../NextPage'

const NextPageSlice = forwardRef(({ slice }, ref) => {
  const {
    title,
    copy,
    link,
    color
  } = slice
  const resolvedLink = useMemo(() => resolveLink(link), [link])
  if (!resolvedLink) return null
  return (
    <NextPage title={title} copy={copy} url={resolvedLink.url} color={color} />
  )
})

export default NextPageSlice
