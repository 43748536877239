import React, { useRef, useState, useCallback, useEffect } from 'react'
import { createUseStyles } from 'react-jss'
import cn from 'classnames'
import useHlsPlayer from '../hooks/useHlsPlayer'
import ResponsiveImage from './ResponsiveImage'
import { ReactComponent as PlayIcon } from '../images/play.svg'
import theme, { span, vw } from '../style/theme'
import useIntersectionObserverCallback from '../hooks/useIntersectionObserverCallback'
import get from 'lodash/get'

const getVideoAspect = (video) => {
  const aspect = get(video, ['data', 'aspect_ratio'], '16:9').split(':')
  return aspect[1] / aspect[0]
}

const VideoPlayer = ({ className, title, copy, video, posterImage, autoPlay, muted, playsInline, classNames = {} }) => {
  const aspect = getVideoAspect(video)
  const classes = useStyles({ aspect })
  const { url } = video
  const [playing, setPlaying] = useState(false)
  const playerRef = useRef()

  useHlsPlayer(playerRef, url)

  const handleVideoClick = useCallback(() => {
    setPlaying(value => !value)
  }, [])

  useEffect(() => {
    if (playerRef.current) {
      if (playing) {
        playerRef.current.play()
      } else {
        playerRef.current.pause()
      }
    }
  }, [playing])

  var ref = useIntersectionObserverCallback({ threshold: 0 }, useCallback((entries) => {
    const inView = get(entries, [0, 'isIntersecting'])
    if (!inView) {
      setPlaying(false)
    }
  }, []))

  return (
    <div className={cn(classes.videoContainer, className)} ref={ref}>
      <video
        ref={playerRef}
        src={url}
        className={cn(classes.video, { playing })}
        controls
        autoPlay={autoPlay}
        muted={muted}
        playsInline={playsInline}
      />
      <button className={cn(classes.playButtonContainer, { playing })} onClick={handleVideoClick} aria-label={`Play Video ${title}`}>
        <ResponsiveImage {...posterImage} className={classes.videoPoster} aspect={1} />
        <PlayIcon className={classes.playIcon} />
        {title && (
          <div className={classes.content}>
            <h3 className={cn(classes.title, classNames.title)}>{title}</h3>
            {copy && <p className={classes.copy}>{copy}</p>}
            <svg className={classes.contentBackground} viewBox='0 0 400 400' preserveAspectRatio='none'>
              <path d='M 0,0 L 400,400 L 0,400 z' />
            </svg>
          </div>
        )}
      </button>
    </div>
  )
}

const useStyles = createUseStyles({
  videoContainer: {
    position: 'relative',
    width: '100%',
    paddingTop: ({ aspect }) => `${aspect * 100}%`
  },
  video: {
    outline: 'none',
    width: '100%',
    display: 'block',
    opacity: 0,
    transition: 'opacity 0.15s ease-in-out',
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    '&.playing': {
      opacity: 1
    }
  },
  playButtonContainer: {
    outline: 'none',
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    opacity: 1,
    pointerEvents: 'all',
    cursor: 'pointer',
    border: 'none',
    backgroundColor: 'transparent',
    padding: 0,
    margin: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    transition: 'opacity 0.15s ease-in-out',
    '&.playing': {
      opacity: 0,
      pointerEvents: 'none'
    }
  },
  videoPoster: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0
  },
  playIcon: {
    position: 'relative',
    width: vw(53),
    height: vw(59),
    [theme.breakpoints.up('md')]: {
      width: vw(106, 'lg'),
      height: vw(118, 'lg')
    }
  },
  content: {
    textAlign: 'left',
    padding: [16, 16, 16, 25],
    position: 'absolute',
    left: 0,
    bottom: -1,
    width: '40%',
    backgroundColor: theme.colors.white,
    [theme.breakpoints.up('md')]: {
      padding: vw(50, 'lg'),
      width: span(6, 'md')
    }
  },
  contentBackground: {
    position: 'absolute',
    bottom: 0,
    top: 0,
    right: -31,
    width: 32,
    height: '101%',
    fill: theme.colors.white,
    [theme.breakpoints.up('md')]: {
      right: -79,
      width: 80
    }
  },
  title: {
    textTransform: 'uppercase',
    extend: theme.typography.h5
  },
  copy: {
    extend: theme.typography.bodySmall,
    display: 'block',
    fontSize: vw(12),
    margin: [vw(8), 0, 0],
    maxHeight: `${theme.typography.bodySmall.lineHeight * 3}em`,
    overflow: 'hidden',
    [theme.breakpoints.up('md')]: {
      fontSize: vw(14, 'lg'),
      margin: [vw(16, 'lg'), 0, 0]
    }
  }
}, { name: 'VideoPlayer' })

export default VideoPlayer
