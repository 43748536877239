import React, { useEffect, useRef } from 'react'
import detectIt from 'detect-it'
import gsap from 'gsap'
import { createUseStyles } from 'react-jss'
import cn from 'classnames'
import { useSelector } from 'react-redux'
import theme, { vw } from '../../style/theme'
import useBodyScrollLock from '../../hooks/useBodyScrollLock'
import usePrevious from '../../hooks/usePrevious'
import { isMenuOpen } from '../../redux/slices/layout'
import { getFooter, getSocialLinks, getMenuItems } from '../../redux/slices/content'
import { quart } from '../../style/eases'
import { span } from '../../style/span'
import { resolveLink } from '../../helpers/resolveLink'
import Link from '../Link'
import Logo from '../Logo'
import SocialLink from '../SocialLink'
import MenuNewsletterSignup from './MenuNewsletterSignup'
import useSwipeAnimation from '../useSwipeAnimation'

const menuAnimateInVals = {
  opacity: 1,
  yPercent: 0,
  duration: 1.2,
  stagger: 0.1,
  skewY: 0,
  ease: 'expo.out'
}
const infoAnimateInVals = {
  opacity: 1,
  yPercent: 0,
  duration: 1,
  delay: 0.6,
  ease: 'expo.out'
}
const menuAnimateOutVals = {
  opacity: 0,
  yPercent: 100,
  duration: 1,
  skewY: -10,
  ease: 'sine.out'
}
const infoAnimateOutVals = {
  opacity: 0,
  yPercent: 20,
  duration: 1,
  ease: 'sine.out'
}
const menuAnimateCloseVals = {
  opacity: 0,
  yPercent: -100,
  duration: 1,
  skewY: -10,
  ease: 'sine.out'
}
const infoAnimateCloseVals = {
  opacity: 0,
  yPercent: -20,
  duration: 1,
  ease: 'sine.out'
}

const MenuDialog = () => {
  const classes = useStyles()
  const open = useSelector(isMenuOpen)
  const wasOpen = usePrevious(open)
  const menuItems = useSelector(getMenuItems)
  const {
    copyright,
    email,
    legal_links: legalLinks,
    phone
  } = useSelector(getFooter)
  const socialLinks = useSelector(getSocialLinks)
  const ref = useBodyScrollLock(open)
  const navRef = useRef()
  const infoRef = useRef()

  const dialogRef = useSwipeAnimation(open)

  useEffect(() => {
    if (navRef.current) {
      if (open) {
        gsap.to(navRef.current.children, menuAnimateInVals)
        gsap.to(infoRef.current, infoAnimateInVals)
      } else {
        if (wasOpen) {
          gsap.to(navRef.current.children, menuAnimateCloseVals)
          gsap.to(infoRef.current, infoAnimateCloseVals)
        } else {
          gsap.to(navRef.current.children, menuAnimateOutVals)
          gsap.to(infoRef.current, infoAnimateOutVals)
        }
      }
    }
  }, [open, wasOpen])

  return (
    <div className={cn(classes.container, { open })} ref={dialogRef}>
      <div className={classes.logoContainer}>
        <Link className={classes.logoWrapper} to='/'>
          <Logo />
        </Link>
      </div>
      <div className={classes.innerContainer} ref={ref}>
        <div className={classes.menuContainer}>
          <div className={classes.cols}>
            <div className={cn(classes.col, classes.navLinks)} ref={navRef}>
              {menuItems && menuItems.map((link) => (
                <div key={link.id}>
                  {link.text === 'Stairway' ? <span className={classes.newLink}>new</span> : ''}
                  <Link key={link.id} className={classes.navLink} link={resolveLink(link)}>
                    <span>{link.text}</span>
                  </Link>
                </div>
              ))}
            </div>
            <div className={cn(classes.col, classes.infoCol)} ref={infoRef}>
              <div className={classes.newsletterWrapper}>
                <MenuNewsletterSignup title='Sign up for updates' />
              </div>
              <div className={cn(classes.socialLinks)}>
                {socialLinks && socialLinks.map((socialLink, i) => (
                  <SocialLink
                    key={socialLink.id}
                    type={socialLink.title}
                    url={socialLink.url}
                    className={classes.socialLink}
                  />
                ))}
              </div>
              <div className={classes.innerCols}>
                <div className={cn(classes.contactLinks)}>
                  <a
                    className={cn(classes.link, classes.contactLink)}
                    href={`tel:${phone}`}
                  >
                    {phone}
                  </a>
                  <a
                    className={cn(classes.link, classes.contactLink)}
                    href={`mailto:${email}`}
                  >
                    {email}
                  </a>
                </div>
                <div className={cn(classes.legalLinks)}>
                  {legalLinks && legalLinks.map((link, i) => (
                    <Link key={link.id} className={cn(classes.link, classes.legalLink)} link={resolveLink(link)}>
                      <span>{link.text}</span>
                    </Link>
                  ))}
                  <p className={classes.copyright}>{copyright}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const useStyles = createUseStyles({
  container: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100vh',
    color: theme.colors.white,
    backgroundColor: theme.colors.secondary,
    zIndex: theme.zIndex.menu,
    pointerEvents: 'none',
    visibility: 'hidden',
    '&.open': {
      pointerEvents: 'all'
    }
  },
  logoContainer: {
    zIndex: 1,
    display: 'flex',
    alignItems: 'center',
    position: 'absolute',
    ...theme.header.height('height'),
    top: 0,
    left: 0,
    padding: [25],
    [theme.breakpoints.up('md')]: {
      padding: [25, 50]
    },
    pointerEvents: 'none',
    '& > *': {
      pointerEvents: 'all'
    }
  },
  logoWrapper: {
    display: 'block',
    width: 128,
    height: 30,
    color: 'inherit',
    [theme.breakpoints.up('md')]: {
      width: 200,
      height: 45,
      marginRight: 48
    }
  },
  innerContainer: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'auto',
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    ...theme.header.height('paddingTop'),
    [theme.breakpoints.up('md')]: {
      alignItems: 'center',
      justifyContent: 'center',
      padding: [100, 50]
    }
  },
  menuContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    padding: [0, 25, 40],
    [theme.breakpoints.up('md')]: {
      flex: 'auto',
      padding: 0,
      alignItems: 'center',
      justifyContent: 'center'
    }
  },
  cols: {
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'flex-end'
    }
  },
  col: {
    display: 'block',
    marginBottom: 50,
    '&:last-child': {
      marginBottom: 0
    },
    [theme.breakpoints.up('md')]: {
      marginBottom: 0,
      flex: 1,
      '&:first-child': {
        marginRight: span(2, 'md')
      },
      '&:last-child': {
        marginLeft: span(2, 'md')
      }
    }
  },
  innerCols: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      '& > *': {
        flex: 1,
        '&:first-child': {
          marginRight: span(1, 'md')
        },
        '&:last-child': {
          marginLeft: span(1, 'md')
        }
      }
    }
  },
  infoCol: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      justifyContent: 'flex-end'
    }
  },
  newsletterWrapper: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'block',
      marginBottom: 60
    }
  },
  newsletterLabel: {
    extend: theme.typography.h5,
    marginBottom: 40
  },
  contactLinks: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start'
  },
  contactLink: {
    extend: theme.typography.bodySmall,
    marginBottom: 8
  },
  link: {
    display: 'inline-block',
    color: theme.colors.white,
    textDecoration: 'none',
    whiteSpace: 'nowrap',
    position: 'relative',
    '&:after': {
      content: "''",
      display: 'block',
      position: 'absolute',
      bottom: 2,
      left: 0,
      width: '100%',
      height: 1,
      backgroundColor: theme.colors.white,
      transform: 'scaleX(0)',
      transformOrigin: 'center right',
      transition: 'transform 0.3s ease'
    },
    ...(detectIt.primaryInput === 'touch' ? {} : {
      '&:hover, &.active': {
        '&:after': {
          transform: 'scaleX(1)',
          transformOrigin: 'center left'
        }
      }
    })
  },
  socialLinks: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 40,
    '& a': {
      marginRight: 24,
      '&:last-child': {
        marginRight: 0
      },
      '& svg': {
        fill: theme.colors.white
      },
      opacity: 0.5,
      transition: 'opacity 0.3s ease',
      ...(detectIt.primaryInput === 'touch' ? {} : {
        '&:hover, &.active': {
          opacity: 1
        }
      })
    }
  },
  socialLink: {
    color: theme.colors.white
  },
  copyright: {
    extend: theme.typography.bodyTiny,
    marginBottom: 0
  },
  legalLink: {
    extend: theme.typography.bodyTiny
  },
  navLinks: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: [30, 0, 60],
    marginBottom: 0,
    [theme.breakpoints.up('md')]: {
      alignItems: 'flex-end',
      padding: 0
    }
  },
  navLink: {
    display: 'inline-block',
    extend: theme.typography.h2,
    color: theme.colors.white,
    textDecoration: 'none',
    whiteSpace: 'nowrap',
    fontSize: vw(60),
    lineHeight: 1.3,
    transition: `color 0.25s ${quart.inOut}`,
    [theme.breakpoints.up('md')]: {
      fontSize: vw(120, 'lg'),
      textAlign: 'right',
      lineHeight: 1.1
    },
    ...(detectIt.primaryInput === 'touch' ? {} : {
      '&:hover, &.active': {
        color: theme.colors.primary
      }
    })
  },
  newLink: {
    marginBottom: '-12px',
    color: theme.colors.primary,
    marginRight: '50px',
    textAlign: 'center'
  }
}, { name: 'MenuDialog' })

export default MenuDialog
