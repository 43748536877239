import React from 'react'
import { createUseStyles } from 'react-jss'
import RichText from '../../RichText'
import TwoColumnLayout from './TwoColumnLayout'
import theme, { span } from '../../../style/theme'

export default ({ slice, page }) => {
  const {
    primary_text: primaryText,
    secondary_text: secondaryText
  } = slice
  const classes = useStyles()

  return (
    <TwoColumnLayout
      leftComponents={
        <div className={classes.leftCol}>
          {secondaryText && <RichText content={secondaryText.text} />}
        </div>
      }
      rightComponents={
        <div className={classes.rightCol}>
          {primaryText && <RichText content={primaryText.text} />}
        </div>
      }
    />
  )
}

const useStyles = createUseStyles({
  leftCol: {
    [theme.breakpoints.up('md')]: {
      maxWidth: span(11, 'md')
    }
  },
  rightCol: {
    [theme.breakpoints.up('md')]: {
      maxWidth: span(9, 'md')
    }
  }
}, 'PostText')
