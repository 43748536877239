import React from 'react'
import get from 'lodash/get'
import ResponsiveImage from './ResponsiveImage'
import { createUseStyles } from 'react-jss'

const CompanyLogo = ({ logo }) => {
  const aspect = get(logo, ['aspect'], 1)
  const classes = useStyles({ aspect })

  return (
    <ResponsiveImage className={classes.logo} {...logo} />
  )
}

const useStyles = createUseStyles({
  logo: {
    display: 'block',
    '& picture:before': {
      paddingTop: 0
    },
    '& img': {
      height: 50,
      width: 'auto',
      maxWidth: 140,
      position: 'static',
      objectFit: 'contain',
      fontFamily: '"object-fit: contain;"'
    }
  }
}, { name: 'CompanyLogo' })

export default CompanyLogo
