import { createAction } from '@reduxjs/toolkit'
import makeFetchSlice from '../makeFetchSlice'

export const portfoliosContentActionCreator = createAction('portfolios/content', (payload) => ({
  payload,
  meta: { scope: 'portfolios' }
}))

export const serverErrorActionCreator = createAction('portfolios/serverError')

const portfoliosSlice = makeFetchSlice('portfolios', portfoliosContentActionCreator, serverErrorActionCreator)

export default portfoliosSlice.reducer
