import React, { forwardRef } from 'react'
import { createUseStyles } from 'react-jss'
import { useDispatch, useSelector } from 'react-redux'
import {
  getNewsletterBusy,
  getNewsletterError,
  getNewsletterIsSubscribed,
  requestSubscribeActionCreator
} from '../../redux/slices/newsletter'
import NewsletterSignup from '../NewsletterSignup'
import theme from '../../style/theme'

const MenuNewsletterSignup = forwardRef((props, ref) => {
  const { title } = props
  const classes = useStyles()

  const dispatch = useDispatch()
  const busy = useSelector(getNewsletterBusy)
  const subscribed = useSelector(getNewsletterIsSubscribed)
  const error = useSelector(getNewsletterError)
  const onSubmit = (e, email) => {
    dispatch(requestSubscribeActionCreator({ email }))
    e.preventDefault()
  }

  return (
    <>
      <h5 className={classes.newsletterLabel}>
        {title}
      </h5>
      <NewsletterSignup
        textColor={theme.colors.white}
        borderColor={theme.colors.white}
        className={classes.signupForm}
        busy={busy}
        subscribed={subscribed}
        error={error}
        onSubmit={onSubmit}
      />
    </>
  )
})

const useStyles = createUseStyles({
  newsletterLabel: {
    extend: theme.typography.h5,
    marginBottom: 40
  }
}, { name: 'MenuNewsletterSignup' })

export default MenuNewsletterSignup
