import React, { useCallback, useContext, useRef } from 'react'
import { createUseStyles } from 'react-jss'
import theme, { vw } from '../../style/theme'

import { SmoothScrollContext } from '../useSmoothScroll'

export default ({ slice }) => {
  const classes = useStyles()
  const firstSectionRef = useRef()
  // const secondSectionRef = useRef()
  const { title, content, tableOfContents } = slice
  console.log(slice, 'find the text is here please check')
  console.log(content, 'content')
  console.log(tableOfContents, 'tableOfContents')

  const scrollContext = useContext(SmoothScrollContext)
  const scrollToFirstSectionCallback = useCallback(() => {
    scrollContext.current.setPageYOffset(firstSectionRef.current.offsetTop - 100, 1000)
  }, [scrollContext.current])
  // const scrollToSecondSectionCallback = useCallback(() => {
  //   scrollContext.current.setPageYOffset(secondSectionRef.current.offsetTop - 100, 1000)
  // }, [scrollContext.current])

  return (
    <section>
      <div>
        {<h4 className={classes.collapseHeading}>{title}</h4>}
      </div>
      <ul>
        {tableOfContents.map((item) => (
          <li key={item.slug.current}>
            <a onClick={scrollToFirstSectionCallback} href={`#${item.slug}`}>{item.title}</a>
          </li>
        ))}
      </ul>
      <div>
        {content.map((block, index) => (
          <div ref={firstSectionRef} key={index}>
            {/* You can handle different block types here */}
            {block._type === 'block' && <p>{block.children[0].text}</p>}
          </div>
        ))}
      </div>
    </section>
  )
}

const useStyles = createUseStyles({
  collapseWrapper: {
    listStyle: 'none',
    margin: 50,
    padding: 0,
    marginBottom: vw(90),
    [theme.breakpoints.up('md')]: {
      marginBottom: vw(80, 'lg')
    }
  },
  grouping: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    backgroundColor: theme.colors.offWhite,
    padding: [10, theme.getMargin()],
    margin: [-1, -theme.getMargin(), 1],
    [theme.breakpoints.up('md')]: {
      padding: 20,
      margin: [-1, -20, 1]
    }
  },
  groupTitle: {
    flexGrow: 1
  },
  imageHero: {
    marginBottom: 40,
    marginTop: 80
  },
  collapseHeading: {
    marginTop: 40,
    marginBottom: 40
  },
  toggle: {
    border: [2, 'solid', 'currentColor'],
    width: '1em',
    height: '1em',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexGrow: 0,
    flexShrink: 0,
    fontSize: vw(32),
    marginLeft: vw(8),
    [theme.breakpoints.up('md')]: {
      marginLeft: vw(8, 'lg'),
      fontSize: vw(32, 'lg')
    },
    '& > svg': {
      width: '60%',
      height: '60%',
      strokeWidth: 1,
      stroke: 'currentColor'
    }
  },
  children: {
    overflow: 'hidden',
    height: 0,
    marginTop: 50,
    marginBottom: 50
  }
}, { name: 'Table' })
