import React, { useMemo } from 'react'
import FullWidthImage from '../FullWidthImage'
import get from 'lodash/get'
import { resolveLink } from '../../helpers/resolveLink'

export default ({ className, slice }) => {
  const {
    image,
    title,
    copy,
    cta_text: ctaText,
    cta_link: ctaLink
  } = slice
  const link = useMemo(() => resolveLink(get(ctaLink, [0])), [ctaLink])
  return (
    <FullWidthImage
      containerComponent='section'
      image={image}
      title={title}
      copy={copy}
      linkText={ctaText}
      link={link}
    />
  )
}
