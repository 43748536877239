import React, { useCallback, useRef, useState } from 'react'
import { createUseStyles } from 'react-jss'
import cn from 'classnames'
import gsap from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'
import { useScrollReadyListener } from '../../../useSmoothScroll'
import theme, { vw } from '../../../../style/theme'
import { span } from '../../../../style/span'
import { TEXT_POSITION_RIGHT } from '..'
import { useDispatch } from 'react-redux'
import { blockReached } from '../../../../redux/slices/timeline'
import detectIt from 'detect-it'

const TYPES_WITH_NO_MARKER = ['video_block']

const BlockWrapper = (props) => {
  const {
    children,
    timelineLabel,
    timelineLabelSubLabel,
    textPosition,
    index,
    blocks,
    type
  } = props
  const dispatch = useDispatch()
  const [isActive, setIsActive] = useState(false)
  const position = type === 'side_by_side_text_block' || type === 'event' ? TEXT_POSITION_RIGHT : textPosition ? textPosition.toLowerCase() : null
  const classes = useStyles({ active: isActive, textPosition: position })

  const blockRef = useRef()

  useScrollReadyListener(useCallback(({ scrollElement }) => {
    if (blockRef.current) {
      const tl = gsap.timeline()
      const scrollTrigger = ScrollTrigger.create({
        scroller: scrollElement,
        trigger: blockRef.current,
        pin: false,
        start: 'top 75%',
        end: 'bottom 75%',
        scrub: detectIt.primaryInput === 'touch' ? 2 : 1,
        animation: tl,
        onEnter: () => {
          if (blockRef.current) {
            dispatch(blockReached({ blockIndex: index, backgroundColor: blocks[index].color }))
            setIsActive(true)
          }
        },
        onLeaveBack: () => {
          if (blockRef.current) {
            const nextIndex = Math.max(index - 1, 0)
            dispatch(blockReached({ blockIndex: nextIndex, backgroundColor: blocks[nextIndex].color }))
            setIsActive(false)
          }
        }
      })
      return () => {
        tl.kill()
        scrollTrigger.kill()
      }
    }
  }, []))

  const childElements = React.Children.map(children, child =>
    React.cloneElement(child, {
      ...child.props,
      isActive
    })
  )

  const withMarker = !TYPES_WITH_NO_MARKER.includes(type)

  return (
    <section className={cn(classes.blockWrapper, type)} ref={blockRef}>
      <div className={cn(classes.container, { withMarker })}>
        {withMarker && (
          <p className={classes.timelineLabel}>
            {timelineLabel}
            {timelineLabelSubLabel && <span className={classes.subLabel}>{timelineLabelSubLabel}</span>}
          </p>
        )}
        {childElements}
      </div>
    </section>
  )
}

const useStyles = createUseStyles({
  blockWrapper: {
    position: 'relative',
    marginBottom: vw(100),
    zIndex: 4,
    [theme.breakpoints.up('md')]: {
      marginBottom: vw(180, 'lg')
    },
    '&:last-child.event': {
      marginBottom: 0
    },
    '&.event $timelineLabel': {
      extend: theme.typography.bodyTiny,
      textTransform: 'uppercase',
      marginBottom: vw(24),
      [theme.breakpoints.up('md')]: {
        marginBottom: 0,
        textAlign: 'right'
      }
    }
  },
  container: {
    position: 'relative',
    paddingLeft: 25,
    [theme.breakpoints.up('md')]: {
      paddingLeft: 0
    },
    '&.withMarker:before': {
      display: 'block',
      content: "''",
      position: 'absolute',
      top: 0,
      left: 0,
      transform: 'translateX(-50%)',
      height: 11,
      width: 11,
      border: [1, 'solid'],
      borderColor: ({ active }) => active ? theme.colors.primary : theme.colors.secondary,
      backgroundColor: ({ active }) => active ? theme.colors.primary : theme.colors.white,
      transition: 'border-color 0.3s ease-out, background-color 0.3s ease-out',
      zIndex: 2,
      [theme.breakpoints.up('md')]: {
        left: '50%'
      }
    }
  },
  timelineLabel: {
    extend: theme.typography.bodyTiny2,
    textTransform: 'uppercase',
    marginBottom: vw(10),
    display: 'block',
    [theme.breakpoints.up('md')]: {
      margin: 0,
      position: 'absolute',
      top: 0,
      display: 'block',
      left: '50%',
      transform: ({ textPosition }) => textPosition === TEXT_POSITION_RIGHT ? 'translateX(calc(-100% - 50px))' : 'translateX(50px)'
    }
  },
  subLabel: {
    display: 'block'
  }
}, { name: 'BlockWrapper' })

export default BlockWrapper
