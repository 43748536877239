import React from 'react'
import { useSelector } from 'react-redux'
import { isCurrentBreakpointMobile } from '../../../redux/slices/layout'
import DropdownFilters from './DropdownFilters'
import ToggleFilters from './ToggleFilters'

export default ({ ...props }) => {
  const isMobile = useSelector(isCurrentBreakpointMobile)
  return isMobile ? (
    <DropdownFilters {...props} />
  ) : (
    <ToggleFilters {...props} />
  )
}
