import React, { useState, useEffect } from 'react'
import { createUseStyles } from 'react-jss'
import theme, { vw } from '../../../style/theme'
import RichText from '../../RichText'
import ResponsiveImage from '../../ResponsiveImage'
import { adjacentSide } from '../../../helpers/trigonometry'
import cn from 'classnames'
import map from 'lodash/map'
import { useDarkSlice } from '../../HeaderContextProvider'
import { ReactComponent as TwitterIcon } from '../../../images/twitter.svg'
import { ReactComponent as InstagramIcon } from '../../../images/instagram.svg'
import { formatLongDate } from '../../../utils/format'

export default ({ slice, page }) => {
  const { title, subtitle, image } = slice
  const classes = useStyles()

  const ref = useDarkSlice(!image)

  const { custom_meta_fields: customFields } = slice
  const { post_date: postDate } = page
  const [shareLinks, setShareLinks] = useState({})

  useEffect(() => {
    setShareLinks({
      instagram: `https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(window.location.href)}&title=${encodeURIComponent(page.title)}&summary=&source=`,
      twitter: `https://twitter.com/intent/tweet?url=${encodeURIComponent(window.location.href)}&text=${encodeURIComponent(page.title)}`,
      facebook: `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(window.location.href)}`
    })
  }, [page])
  return (
    <section
      className={cn(classes.section, !image && classes.noImage)}
      ref={ref}
    >
      <div className={classes.wrapper}>
        <div className={classes.content}>
          <div className={classes.pathRow}>
            <span>{page?.postCategory?.title}</span>
            <div className={classes.dotContainer}>
              <p className={classes?.dot} />
            </div>
            <span>{page?.postInitiative?.title}</span>
          </div>
          <RichText content={title.text} className={classes.title} />
          {subtitle && <p className={classes.subtitle}>{subtitle}</p>}
          <div className={classes.authorContainer}>
            <span>By</span>
            {map(customFields, (field, i) => (
              <React.Fragment key={i}>
                <dd className={classes.authorName}>
                  <RichText
                    content={field.value.text}
                    className={classes.fieldValue}
                  />
                </dd>
              </React.Fragment>
            ))}
          </div>

          <div className={classes.fields}>
            <dl className={classes.list}>
              {postDate && (
                <>
                  <dt>Published</dt>
                  <dd>{formatLongDate(postDate)}</dd>
                </>
              )}
              <dt>Share</dt>
              <dd>
                <a
                  href={shareLinks.twitter}
                  className={classes.shareLink}
                  rel='noopener noreferrer'
                  target='_blank'
                >
                  <TwitterIcon className={classes.twitterIcon} />
                </a>
                <a
                  href={shareLinks.instagram}
                  className={classes.shareLink}
                  rel='noopener noreferrer'
                  target='_blank'
                >
                  <InstagramIcon className={classes.instagramIcon} />
                </a>
              </dd>
            </dl>
          </div>
        </div>
        {image && <ResponsiveImage {...image} className={classes.image} />}
      </div>
    </section>
  )
}

const useStyles = createUseStyles({
  section: {
    position: 'relative',
    margin: [0, 0, 0],
    [theme.breakpoints.up('md')]: {
      margin: [0, '25px', vw(80, 'lg')],
      padding: 0,
      marginBottom: vw(-20, 'lg')
    }
  },

  noImage: {
    margin: [0, 0, vw(60)],
    backgroundColor: theme.colors.secondary,
    color: theme.colors.white,
    [theme.breakpoints.up('md')]: {
      margin: [0, 0, vw(100, 'lg')]
    },
    '&:after': {
      position: 'absolute',
      content: '""',
      bottom: -1,
      width: '100%',
      clipPath: 'polygon(0 100%, 100% 100%, 100% 0%, 0 100%);',
      backgroundColor: theme.colors.white,
      height: `${adjacentSide(10)}vw`,
      [theme.breakpoints.up('md')]: {
        height: `${adjacentSide(5)}vw`
      }
    },
    '& $wrapper': {
      margin: [0, theme.getMargin()],
      [theme.breakpoints.up('md')]: {
        margin: [0, theme.getMargin('md')]
      }
    }
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column-reverse',
    position: 'relative',
    minHeight: '100vh',
    justifyContent: 'center',
    [theme.breakpoints.up('md')]: {
      alignItems: 'center',
      flexDirection: 'row',
      justifyContent: 'flex-start'
    }
  },
  content: {
    padding: '20px',
    [theme.breakpoints.up('md')]: {
      padding: [0, theme.getMargin('md'), 0, '50px'],
      width: '50%',
      marginTop: vw(10)
    }
  },

  pathRow: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',

    '& p': {
      position: 'absolute',
      height: '4px',
      width: '4px',
      backgroundColor: theme.colors.primary,
      borderRadius: '50%',
      top: '50%',
      transform: 'translateY(-50%)',
      marginBottom: 0,
      color: '#9999A7'
    },

    '& span': {
      textTransform: 'Uppercase',
      fontWeight: theme.typography.fontWeight.bold,
      color: theme.colors.primary
    }
  },

  dotContainer: {
    position: 'relative',
    marginLeft: '12px',
    marginRight: '20px'
  },

  title: {
    marginBottom: vw(32),
    [theme.breakpoints.up('md')]: {
      marginBottom: vw(32, 'lg')
    },
    '& h1': {
      fontSize: vw(60),
      [theme.breakpoints.up('md')]: {
        fontSize: vw(100, 'lg')
      }
    }
  },
  subtitle: {
    margin: 0,
    [theme.breakpoints.up('md')]: {
      maxWidth: vw(400, 'lg')
    }
  },

  authorContainer: {
    display: 'flex',
    marginTop: '24px',
    alignItems: 'flex-start',

    '& span': {
      color: '#9999A7',
      fontSize: '4vw',
      [theme.breakpoints.up('md')]: {
        fontSize: '1.1111vw',
        lineHeight: '1.4'
      }
    },
    '& dd': {
      margin: 0,
      marginLeft: '8px',
      color: '#9999A7',

      '& a': {
        color: '#9999A7',
        textDecoration: 'none'
      }
    }
  },
  author: {
    marginTop: vw(6),
    color: '#9999A7',
    [theme.breakpoints.up('md')]: {
      maxWidth: vw(400, 'lg')
    }
  },
  image: {
    flexGrow: 1,
    width: 'auto',
    [theme.breakpoints.up('md')]: {
      margin: [vw(-30), '-25px', 0, 0],
      width: '50%',
      height: '86vh',
      position: 'relative'
    },
    '&:after': {
      position: 'absolute',
      content: '""',
      bottom: -1,
      width: '100%',
      clipPath: 'polygon(0 100%, 100% 100%, 100% 0%, 0 100%);',
      backgroundColor: theme.colors.white,
      height: `${adjacentSide(5)}vw`,
      [theme.breakpoints.up('md')]: {
        height: `${adjacentSide(2.5)}vw`
      }
    }
  },
  shareLink: {
    color: theme.colors.black,
    display: 'inline-block',
    '&:not(:last-child)': {
      marginRight: 12
    },
    '&:hover': {
      color: theme.colors.primary
    }
  },
  twitterIcon: {
    width: vw(20),
    height: vw(16),
    [theme.breakpoints.up('md')]: {
      width: vw(20, 'lg'),
      height: vw(16, 'lg')
    }
  },
  instagramIcon: {
    width: vw(16),
    height: vw(16),
    [theme.breakpoints.up('md')]: {
      width: vw(16, 'lg'),
      height: vw(16, 'lg')
    }
  },
  list: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: vw(350, 'lg')
    },
    '& > dt, & > dd': {
      fontSize: vw(14),
      margin: 0,
      paddingTop: vw(16),
      paddingBottom: vw(16),
      [theme.breakpoints.up('md')]: {
        fontSize: vw(14, 'lg'),
        paddingTop: vw(16, 'lg'),
        paddingBottom: vw(16, 'lg')
      }
    },
    '& > dt': {
      width: '40%',
      paddingRight: 16,
      color: '#9999A7',
      borderBottom: [1, 'solid', theme.colors.border],

      '&:last-of-type': {
        border: 'none'
      }
    },
    '& > dd': {
      width: '60%',
      textAlign: 'right',
      borderBottom: [1, 'solid', theme.colors.border],
      '&:last-of-type': {
        border: 'none'
      },
      '& *': {
        fontSize: vw(14),
        [theme.breakpoints.up('md')]: {
          fontSize: vw(14, 'lg')
        }
      }
    }
  }
}, 'PostHeroNew')
