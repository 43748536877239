import { createAction } from '@reduxjs/toolkit'
import makeFetchSlice from '../makeFetchSlice'

export const futureMapsContentActionCreator = createAction(
  'future_maps/content',
  (payload, loadMore) => ({
    payload,
    meta: { scope: 'future_maps', loadMore }
  })
)

export const futureMapsLoadMoreActionCreator = createAction(
  'future_maps/load_more',
  (payload) => ({
    payload,
    meta: { scope: 'future_maps' }
  })
)

export const futureMapsChangeInitiativeActionCreator = createAction(
  'future_maps/change_initiative',
  (payload) => ({
    payload,
    meta: { scope: 'future_maps' }
  })
)

export const serverErrorActionCreator = createAction('future_maps/serverError')

const futureMapsSlice = makeFetchSlice(
  'future_maps',
  futureMapsContentActionCreator,
  serverErrorActionCreator
)

export default futureMapsSlice.reducer
