import React from 'react'
import { createUseStyles } from 'react-jss'
import { useSelector } from 'react-redux'
import { getPostsQueryStringArguments } from '../../redux/slices/content'
import PostTile from '../Summit/PostTile'
import { resolveInternalLink } from '../../helpers/resolveLink'
import theme from '../../style/theme'

const FeaturedPostSlice = ({ slice, page }) => {
  const { featured_post: featuredPost } = slice
  const classes = useStyles()

  const { postInitiative } = useSelector(getPostsQueryStringArguments)

  if (postInitiative) return null

  return (
    <div className={classes.content}>
      <PostTile
        {...featuredPost}
        link={resolveInternalLink(featuredPost)}
        isLandscape
        isFeatured
        isImageContain={page.slug === 'podcasts'}
      />
    </div>
  )
}

const useStyles = createUseStyles({
  content: {
    padding: [30, theme.getMargin('min')],
    position: 'relative',
    [theme.breakpoints.up('md')]: {
      padding: [50, theme.getMargin('md')]
    }
  }
}, { name: 'FeaturedPostSlice' })

export default FeaturedPostSlice
