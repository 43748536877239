import React, { useRef, useEffect, useCallback, useContext } from 'react'

import offsetTop from '../utils/offsetTop'
import useWindowResize from '../hooks/useWindowResize'
import useDebouncedCallback from '../hooks/useDebouncedCallback'
import { SmoothScrollContext } from './useSmoothScroll'
import gsap from 'gsap'
import detectIt from 'detect-it'

const ParallaxElement = ({ children, className, ratio = 1, scale = 1, rotateInit = 0, rotate, ease = 1, origin = 'center' }) => {
  const el = useRef()
  const scrollContext = useContext(SmoothScrollContext)

  const viewportWidth = useRef(0)
  const viewportHeight = useRef(0)
  const a = useRef(rotateInit)
  const y = useRef(0)
  const oy = useRef(null)
  const top = useRef(0)
  const topMod = useRef(0)
  const height = useRef(0)
  const r = +ratio - 1
  // const ratioHeight = useRef(0)

  const triggerParallax = useCallback((force) => {
    if (r) {
      // if (easing) {
      //  const sign = ((r > 0) - (r < 0)) || +r
      //  r = sign * easing(Math.abs(r))
      // }
      const initY = top.current - scrollContext.current.getPageYOffset()
      const destY = (initY - topMod.current) * r

      y.current += (destY - y.current) * ease

      if (rotate) {
        const destRotation = -rotate * initY / viewportHeight.current + rotateInit
        a.current += (destRotation - a.current) * ease
      }

      const roundedY = (y.current * 100 | 0) / 100
      const newY = initY + roundedY
      const isIn = (newY + height.current > 0 && newY < viewportHeight.current) ||
        (initY + height.current > 0 && initY < viewportHeight.current)

      if (el.current && (force || isIn) && roundedY !== oy.current) {
        const scaleTransformation = scale !== 1 ? ` scale(${scale})` : ''
        const rotateTransformation = a.current ? ` rotate(${a.current}deg)` : ''
        el.current.style.transform = `translate3d(0,${roundedY}px,0)${rotateTransformation}${scaleTransformation}`
      }

      oy.current = roundedY
    }
  }, [])

  const handleResize = () => {
    const dim = detectIt.primaryInput === 'touch'
      ? { innerWidth: viewportWidth.current || window.innerWidth, innerHeight: viewportHeight.current || window.innerHeight }
      : { innerWidth: window.innerWidth, innerHeight: window.innerHeight }
    viewportWidth.current = dim.innerWidth
    viewportHeight.current = dim.innerHeight

    // parallax
    if (r && el.current) {
      top.current = offsetTop(el.current)
      height.current = el.current.offsetHeight
      topMod.current = viewportHeight.current < height.current ? top.current % viewportHeight.current : 0
      if (origin === 'center') topMod.current = topMod.current / 2 + viewportHeight.current - height.current >> 1
      else if (origin === 'bottom') topMod.current += viewportHeight.current
    }
  }

  useEffect(() => {
    handleResize()
    triggerParallax(true)
  }, [triggerParallax])

  useWindowResize(useDebouncedCallback(handleResize), 200, [])

  useEffect(() => {
    gsap.ticker.add(triggerParallax)
    return () => {
      gsap.ticker.remove(triggerParallax)
    }
  }, [triggerParallax])

  return (
    <div ref={el} className={className}>{children}</div>
  )
}

export default ParallaxElement
