import React, { useMemo } from 'react'
import cn from 'classnames'
import { createUseStyles } from 'react-jss'
import map from 'lodash/map'
import theme, { vw } from '../../style/theme'
import ResponsiveImage from '../ResponsiveImage'
import Slider, { flickityOptions } from '../Slider'

export default ({ className, slice }) => {
  const classes = useStyles()
  const { title, images, auto_scroll: autoScroll } = slice
  const options = useMemo(() => ({
    ...flickityOptions,
    autoPlay: autoScroll
  }), [autoScroll])

  return (
    <section className={cn(classes.imageSlider, className)}>
      <div className={classes.container}>
        <h3 className={classes.title}>{title}</h3>
        <Slider className={classes.slider} options={options}>
          {map(images, (image, i) => (
            <ResponsiveImage key={i} {...image} aspect={2} className={classes.image} />
          ))}
        </Slider>
      </div>
    </section>
  )
}

const useStyles = createUseStyles({
  imageSlider: {
    position: 'relative',
    overflow: 'hidden'
  },
  container: {
    margin: [0, theme.getMargin(), vw(60)],
    [theme.breakpoints.up('md')]: {
      margin: [0, theme.getMargin('md'), vw(100, 'lg')]
    }
  },
  title: {
    extend: theme.typography.h4,
    marginBottom: vw(40),
    [theme.breakpoints.up('md')]: {
      marginBottom: vw(40, 'lg')
    }
  },
  slider: {
    margin: [0, -theme.getMargin()],
    [theme.breakpoints.up('md')]: {
      margin: 0
    }
  },
  image: {
    [theme.breakpoints.up('md')]: {
      marginRight: theme.getMargin('md')
    }
  }
})
