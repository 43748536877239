import React from 'react'
import { createUseStyles } from 'react-jss'
import theme from '../../style/theme'
import Link from '../Link'
import { resolveInternalLinkUrl } from '../../helpers/resolveLink'
import saasArrow from '../../images/saasArrow.svg'
import ResponsiveImage from '../ResponsiveImage'
import RichText from '../RichText'
const SaasCompassHero = ({ slice }) => {
  // const { title } = slice.hero_text // keep it for future purpose
  const { heroSectionImage, description } = slice
  const classes = useStyles()

  return (
    <div className={classes.container}>
      <div className={classes.containerOfHero}>
        <ResponsiveImage {...heroSectionImage} className={classes.saasHeroImage} />
        <RichText content={description.text} className={classes.description} />
      </div>
      <Link
        to={resolveInternalLinkUrl(slice.whySaasLink.reference)}
        nonLinkTag='p'
        className={classes.sassBtn}
      >
        <span className={classes.saasBtnText}>{slice.whySaasLink.text}</span>
        <img src={saasArrow} className={classes.btnArrow} />
      </Link>
    </div>
  )
}

const useStyles = createUseStyles(
  {
    container: {
      // padding: [150, 31, 75, 25],
      padding: [100, 31, 25, 25],
      display: 'flex',
      flexDirection: 'column',
      rowGap: '16px',
      background: theme.colors.offWhite,
      alignItems: 'center',

      [theme.breakpoints.up('md')]: {
        // padding: [203, 0, 83, 50],
        padding: [110, 0, 40, 50],
        rowGap: '14px'
      }
    },
    headingHero: {
      fontStyle: 'italic'
    },

    containerOfHero: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center'
    },

    description: {
      marginBottom: '24px',
      marginTop: '24px',
      fontFamily: 'Object Sans Lucida Sans Tahoma, sans-serif',
      fontStyle: 'normal',
      fontSize: '20px',
      lineHeight: '138%',
      maxWidth: '610px',
      fontWeight: 150,
      textAlign: 'center',

      [theme.breakpoints.up('md')]: {
        fontSize: '24px'
      }
    },
    sassBtn: {
      display: 'flex',
      columnGap: '12.5px',
      justifyContent: 'space-between',
      padding: '8.17px 20px 8.17px 16.5px',
      background: '#3283FF',
      color: theme.colors.white,
      width: 'fit-content',
      textDecoration: 'none',
      alignItems: 'center',
      '&:hover': {
        transform: 'scale(1.05)',
        backgroundColor: theme.colors.black
      },

      '& span': {
        fontFamily: 'Object Sans',
        fontSize: '14px',
        lineHeight: '138%',
        fontStyle: 'normal',
        fontWeight: 600
      }
    },
    saasBtnText: {
      marginTop: '4px',
      textTransform: 'uppercase'
    },
    btnArrow: {
      width: '17px',
      height: '17px',
      '&:hover': {
        transform: 'translateY(-20px)'
      }
    },
    saasLink: {
      fontFamily: 'Object Sans',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '17px',
      textAlign: 'center',

      [theme.breakpoints.down('md')]: {
        fontSize: '16px'
      }
    },
    hereLink: {
      color: '#3283FF',
      textDecoration: 'underline'
    },
    blueText: {
      color: '#3283FF'
    },
    saasHeroImage: {
      maxWidth: '487px',
      width: '100%',
      height: '286px',

      [theme.breakpoints.down('xs')]: {
        width: '100%',
        height: '100%'
      }
    }
  },
  { name: 'SaasCompassHero' }
)

export default SaasCompassHero
