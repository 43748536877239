import React, { useRef, useEffect } from 'react'
import gsap from 'gsap'
import { createUseStyles } from 'react-jss'
import RichText from '../../../RichText'
import ResponsiveImage from '../../../ResponsiveImage'
import theme, { vw } from '../../../../style/theme'
import { span } from '../../../../style/span'
import { TEXT_POSITION_RIGHT } from '..'
import NumberTicker from './NumberTicker'

export const nFormatter = (num) => {
  if (num < 10) return { number: `0${num}`, affix: null }
  if (num >= 1000000000) return { number: `${(num / 1000000000)}`, affix: 'b' }
  if (num >= 1000000) return { number: `${(num / 1000000)}`, affix: 'm' }
  if (num >= 10000) return { number: `${(num / 1000)}`, affix: 'k' }
  return { number: num, affix: null }
}

const animateInValues = { ease: 'expo.out', yPercent: 0, opacity: 1, stagger: 0.16, duration: 1.6, visibility: 'visible' }
const animateOutValues = { ease: 'expo.out', yPercent: 20, opacity: 0, duration: 0 }

const TimelineFiguresBlock = ({ block, isActive }) => {
  const {
    headings,
    text,
    text_column_position: textPosition,
    size,
    image,
    image_position: imagePosition,
    figures
  } = block

  const classes = useStyles({
    size: size ? size.toLowerCase() : null,
    textPosition: textPosition ? textPosition.toLowerCase() : null,
    imagePosition: imagePosition ? imagePosition.toLowerCase() : null
  })

  const leftColRef = useRef()
  const rightColRef = useRef()

  useEffect(() => {
    if (leftColRef.current && rightColRef.current) {
      gsap.set(leftColRef.current.children, animateOutValues)
      gsap.set(rightColRef.current.children, animateOutValues)
    }
  }, [])

  useEffect(() => {
    if (leftColRef.current && rightColRef.current) {
      if (isActive) {
        gsap.to(leftColRef.current.children, animateInValues)
        gsap.to(rightColRef.current.children, animateInValues)
      }
    }
  }, [isActive])

  return (
    <section className={classes.content}>
      <div className={classes.cols}>
        <div className={classes.col} ref={leftColRef}>
          <div />
          {image && (
            <div className={classes.container}>
              <ResponsiveImage className={classes.image} {...image} />
            </div>
          )}
        </div>
        <div className={classes.col}>
          <div className={classes.container} ref={rightColRef}>
            <RichText className={classes.headings} content={headings.text} />
            <RichText className={classes.bodyText} content={text.text} />
            {figures && figures.length > 0 && (
              <div className={classes.figures}>
                {figures.map(figure => {
                  const {
                    id,
                    description,
                    metric,
                    number
                  } = figure
                  const formattedNumber = nFormatter(number)
                  return (
                    <div key={id} className={classes.figure}>
                      <NumberTicker className={classes.number} {...formattedNumber} />
                      <span className={classes.metric}>{metric}</span>
                      <p className={classes.description}>{description}</p>
                    </div>
                  )
                })}
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  )
}

const useStyles = createUseStyles({
  content: {
    position: 'relative'
  },
  cols: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    paddingTop: vw(40),
    [theme.breakpoints.up('md')]: {
      flexDirection: ({ textPosition }) => textPosition === TEXT_POSITION_RIGHT ? 'row' : 'row-reverse',
      paddingTop: vw(50, 'lg')
    }
  },
  col: {
    '&:first-child': {
      marginBottom: vw(25)
    },
    [theme.breakpoints.up('md')]: {
      flex: 1,
      display: 'flex',
      '&:first-child': {
        marginBottom: 0,
        marginLeft: ({ textPosition }) => textPosition === TEXT_POSITION_RIGHT ? 0 : span(1.5, 'md'),
        marginRight: ({ textPosition }) => textPosition === TEXT_POSITION_RIGHT ? span(1.5, 'md') : 0,
        justifyContent: ({ textPosition }) => textPosition === TEXT_POSITION_RIGHT ? 'flex-end' : 'flex-start'
      },
      '&:last-child': {
        marginRight: ({ textPosition }) => textPosition === TEXT_POSITION_RIGHT ? 0 : span(1.5, 'md'),
        marginLeft: ({ textPosition }) => textPosition === TEXT_POSITION_RIGHT ? span(1.5, 'md') : 0,
        justifyContent: ({ textPosition }) => textPosition === TEXT_POSITION_RIGHT ? 'flex-start' : 'flex-end'
      }
    }
  },
  container: {
    [theme.breakpoints.up('md')]: {
      width: span(8, 'md')
    }
  },
  inlineImage: {
    marginBottom: vw(25),
    [theme.breakpoints.up('md')]: {
      marginBottom: vw(25, 'lg')
    }
  },
  headings: {
    marginBottom: vw(30),
    [theme.breakpoints.up('md')]: {
      marginBottom: vw(50, 'lg')
    },
    '& h1, h2, h3, h4, h5': {
      marginBottom: vw(28),
      [theme.breakpoints.up('md')]: {
        marginBottom: vw(28, 'lg')
      }
    },
    '& h3': {
      marginBottom: vw(35),
      [theme.breakpoints.up('md')]: {
        marginBottom: vw(35, 'lg')
      }
    },
    '& h6': {
      marginBottom: vw(10),
      [theme.breakpoints.up('md')]: {
        marginBottom: vw(10, 'lg')
      }
    }
  },
  bodyText: {
    marginBottom: vw(10),
    [theme.breakpoints.up('md')]: {
      marginBottom: vw(10, 'lg')
    }
  },
  figures: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    columnGap: theme.getMargin(),
    rowGap: vw(50),
    [theme.breakpoints.up('md')]: {
      columnGap: span(1.5, 'md'),
      rowGap: vw(50, 'lg')
    }
  },
  figure: {
    display: 'flex',
    flexDirection: 'column'
  },
  number: {
    extend: theme.typography.h2,
    marginBottom: vw(15),
    [theme.breakpoints.up('md')]: {
      marginBottom: vw(15, 'lg')
    }
  },
  metric: {
    extend: theme.typography.h5,
    marginBottom: vw(20),
    [theme.breakpoints.up('md')]: {
      marginBottom: vw(20, 'lg')
    }
  },
  description: {
    marginBottom: 0
  }
}, { name: 'TimelineFiguresBlock' })

export default TimelineFiguresBlock
