import { useRef, useEffect } from 'react'
import { clipPathShapesSupported } from '../helpers/supports'

import { adjacentSide } from '../helpers/trigonometry'
import gsap from 'gsap'

const duration = 0.8

const getAnimationValues = (y) => {
  const offset = (window.innerWidth * (adjacentSide(10) / 100))
  const offsetLeft = window.innerHeight + offset
  var left = gsap.utils.interpolate(offsetLeft, 0, y) / window.innerHeight
  var right = gsap.utils.interpolate(1, -(offset / window.innerHeight), y)
  return {
    left,
    right
  }
}

export default (open) => {
  const ref = useRef()
  const animationLocals = useRef({ y: 0, timeline: null })

  useEffect(() => {
    if (ref.current) {
      var timeline = gsap.timeline()
      if (open) {
        timeline.set(ref.current, { visibility: 'visible' })
      }
      if (clipPathShapesSupported()) {
        timeline.to(animationLocals.current, {
          duration,
          y: open ? 1 : 0,
          modifiers: {
            y: (y) => {
              if (ref.current) {
                const { left, right } = getAnimationValues(y)
                if (open) {
                  const clipPath = `polygon(0 ${left * 100}%, 100% ${(right * 100)}%, 100% 100%, 0 100%)`
                  ref.current.style.clipPath = clipPath
                } else {
                  const clipPath = `polygon(0 0%, 100% 0%, 100% ${(1 - left) * 100}%, 0 ${((1 - right) * 100)}%)`
                  ref.current.style.clipPath = clipPath
                }
              }
              return y
            }
          }
        })
      } else {
        timeline.to(animationLocals.current, {
          duration,
          y: open ? 1 : 0
        })
      }
      animationLocals.current.timeline = timeline
      return () => {
        if (animationLocals.current.timeline) {
          animationLocals.current.timeline.kill()
        }
      }
    }
  }, [open])

  return ref
}
