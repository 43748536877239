import React, { useMemo } from 'react'
import { createUseStyles } from 'react-jss'
import get from 'lodash/get'
import cn from 'classnames'
import isEmpty from 'lodash/isEmpty'
import theme, { vw } from '../../../style/theme'
import PostTile from '../../Summit/PostTile'
import { resolveInternalLink } from '../../../helpers/resolveLink'

export default ({ page }) => {
  const classes = useStyles()
  const relatedPosts = get(page, ['related'])

  const links = useMemo(() => relatedPosts && relatedPosts.map && relatedPosts.map(post => resolveInternalLink(post), [relatedPosts]))

  if (isEmpty(relatedPosts)) return null

  return (
    <section className={classes.section}>
      <h5 className={classes.title}>Related</h5>
      <div className={classes.grid}>
        {relatedPosts.map && relatedPosts.map((post, i) => (
          <PostTile key={post.id} {...post} link={links[i]} className={cn(i === 3 && classes.lastTile)} />
        ))}
      </div>
    </section>
  )
}

const useStyles = createUseStyles({
  section: {
    margin: [0, theme.getMargin(), vw(40)],
    [theme.breakpoints.up('md')]: {
      margin: [0, theme.getMargin('md'), vw(80, 'lg')]
    }
  },
  title: {
    marginBottom: vw(20),
    [theme.breakpoints.up('md')]: {
      marginBottom: vw(40, 'lg')
    }
  },
  grid: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    columnGap: 16,
    rowGap: vw(40),
    [theme.breakpoints.up('md')]: {
      columnGap: 32,
      gridTemplateColumns: '1fr 1fr 1fr',
      rowGap: vw(80, 'lg')
    }
  },
  lastTile: {
    display: 'block',
    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  }
}, 'RelatedPosts')
