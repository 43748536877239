import React, { useCallback, useMemo } from 'react'
import { createUseStyles } from 'react-jss'
import cn from 'classnames'
import theme from '../../../style/theme'
import { quart } from '../../../style/eases'
import map from 'lodash/map'
import isEmpty from 'lodash/isEmpty'
import DropdownFilters from './DropdownFilters'

const ToggleButton = ({ children, value, active, setValue }) => {
  const classes = useStyles()
  const onClick = useCallback(() => {
    setValue(value)
  }, [setValue, value])
  return (
    <button className={cn(classes.button, { active })} onClick={onClick}>
      {children}
    </button>
  )
}

const ToggleFilters = ({ className, options, value, setValue, maxItems = 100 }) => {
  const classes = useStyles()

  const dropdownItems = useMemo(() => {
    var otherOptions = options.slice(maxItems)
    if (isEmpty(otherOptions)) return otherOptions
    return [
      { text: 'More...', value: '' },
      ...otherOptions
    ]
  }, [options])

  const toggleItems = useMemo(() => {
    return options.slice(0, maxItems)
  }, [options])

  if (isEmpty(options)) return null

  return isEmpty(options) ? null : (
    <div className={cn(classes.filters, className)}>
      {map(toggleItems, ({ text, value: optionValue }, i) => (
        <ToggleButton key={i} active={optionValue === value} value={optionValue} setValue={setValue}>{text}</ToggleButton>
      ))}
      <DropdownFilters options={dropdownItems} value={value} setValue={setValue} />
    </div>
  )
}

const useStyles = createUseStyles({
  filters: {
    display: 'flex',
    alignItems: 'center'
  },
  button: {
    extend: theme.typography.bodySmall,
    padding: [6, 10],
    overflow: 'hidden',
    transition: `color 0.25s ${quart.inOut}`,
    position: 'relative',
    marginRight: 25,
    '&:last-child': {
      marginRight: 0
    },
    '&:hover, &.active': {
      color: theme.colors.white,
      '&:before': {
        transform: 'translateY(0%)'
      }
    },
    '&:before': {
      content: "''",
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      zIndex: -1,
      backgroundColor: theme.colors.primary,
      transform: 'translateY(100%)',
      transition: `transform 0.25s ${quart.inOut}`
    }
  }
})

export default ToggleFilters
