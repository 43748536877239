import { createAction } from '@reduxjs/toolkit'
import makeFetchSlice from '../makeFetchSlice'

export const fieldGuideContentActionCreator = createAction('playbook/content', (payload, loadMore) => ({
  payload,
  meta: { scope: 'playbook', loadMore }
}))

export const FieldGuideLoadMoreActionCreator = createAction('playbook/load_more', (payload) => ({
  payload,
  meta: { scope: 'playbook' }
}))

export const serverErrorActionCreator = createAction('playbook/serverError')

const fieldGuideSlice = makeFetchSlice('posts', fieldGuideContentActionCreator, serverErrorActionCreator)

export default fieldGuideSlice.reducer
