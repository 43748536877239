import React, { useMemo } from 'react'
import { createUseStyles } from 'react-jss'
import theme, { vw } from '../../../style/theme'
import cn from 'classnames'
import Link from '../../Link'
import { resolveInternalLink } from '../../../helpers/resolveLink'

export default ({ page, className, showPostInitiative = true }) => {
  const classes = useStyles()
  const { postCategory, postInitiative, parent } = page
  const resolvedParentLink = useMemo(() => resolveInternalLink(parent), [parent])
  const initiativeLink = useMemo(() => `${resolvedParentLink.url}?postInitiative=${postInitiative.slug}`, [postInitiative, resolvedParentLink])

  return (
    <div className={cn(classes.metaLinks, className)}>
      {postCategory && (
        <Link link={resolvedParentLink} nonLinkTag='span'>
          {postCategory.title.substring(0, postCategory.title.length - 1)}
        </Link>
      )}
      {showPostInitiative && postInitiative && postCategory && (
        <span className={classes.separator}>•</span>
      )}
      {showPostInitiative && postInitiative && (
        <Link to={initiativeLink} className={classes.postInitiative} nonLinkTag='span'>
          {postInitiative.title}
        </Link>
      )}
    </div>
  )
}

const useStyles = createUseStyles({
  metaLinks: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    extend: theme.typography.bodySmall,
    textTransform: 'uppercase',
    color: theme.colors.primary,
    lineHeight: 1.5,
    '& > a': {
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'underline'
      }
    }
  },
  separator: {
    display: 'block',
    textDecoration: 'none',
    margin: [0, vw(5)],
    [theme.breakpoints.up('md')]: {
      margin: [0, vw(5, 'md')]
    }
  },
  postInitiative: {
    position: 'relative',
    display: 'inline-block'
  }
}, 'PostCategory')
