import React from 'react'
import { createUseStyles } from 'react-jss'
import theme from '../../style/theme.js'
import RichText from '../RichText.js'
import NewsLetter from './NewsLetter.js'

const SubscribeSaasCompass = ({ slice }) => {
  const classes = useStyles()
  const { title, copy, color } = slice
  const modalTitle = 'Subscribe To SaaS Compass'
  const modalDescription =
    'Receive new articles and resources directly in your inbox.'
  return (
    <div className={classes.container} style={{ background: color }}>
      {title && <RichText className={classes.heading} content={title.text} />}
      {copy && <RichText content={copy.text} className={classes.subHeading} />}
      <NewsLetter title={modalTitle} description={modalDescription} className={classes.modal} />
    </div>
  )
}

const useStyles = createUseStyles(
  {
    container: {
      padding: '162.8px 369.3px 172.28px 368.6px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      [theme.breakpoints.down('lg')]: {
        paddingLeft: '300px',
        paddingRight: '300px'
      },
      [theme.breakpoints.down('md')]: {
        paddingLeft: '120px',
        paddingRight: '120px'
      },
      [theme.breakpoints.down('xs')]: {
        paddingLeft: '100px',
        paddingRight: '100px'
      },
      [theme.breakpoints.down('fab')]: {
        padding: '99.3px 25px 96.7px 25px'
      }
    },
    heading: {
      marginBottom: '40px',
      textAlign: 'center',
      fontSize: '100px',
      [theme.breakpoints.down('fab')]: {
        marginBottom: '25px'
      }
    },
    subHeading: {
      width: '40vw',
      textAlign: 'center',
      marginBottom: '30px',
      textTransform: 'capitalize',
      [theme.breakpoints.down('md')]: {
        width: '50vw'
      },
      [theme.breakpoints.down('fab')]: {
        marginBottom: '33px',
        width: '75vw',
        textTransform: 'capitalize'
      }
    },
    modal: {
      '& button': {
        padding: '8px 40px',
        fontFamily: 'Mars Condensed',
        textTransform: 'uppercase',
        fontWeight: '400',
        fontSize: '20px',
        letterSpacing: '0.03em',
        [theme.breakpoints.down('fab')]: {
          padding: '8px 22px'
        }
      }
    }
  },
  { name: 'SubscribeSaasCompass' }
)

export default SubscribeSaasCompass
