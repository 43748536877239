import React, { useRef, useEffect } from 'react'
import gsap from 'gsap'
import { createUseStyles } from 'react-jss'
import RichText from '../../../RichText'
import theme, { vw, span } from '../../../../style/theme'

export const BLOCK_SIZE_SMALL = 'small'
export const BLOCK_SIZE_LARGE = 'large'
export const IMAGE_INLINE = 'inline'
export const IMAGE_OPPOSITE = 'opposite'

const animateInValues = { ease: 'expo.out', yPercent: 0, autoAlpha: 1, stagger: 0.16, duration: 1.6 }
const animateOutValues = { ease: 'expo.out', yPercent: 20, autoAlpha: 0, duration: 0 }

export default ({ block, isActive }) => {
  const {
    title,
    left_text: leftText,
    right_text: rightText
  } = block
  const classes = useStyles()

  const containerRef = useRef()

  useEffect(() => {
    if (containerRef.current) {
      gsap.set(containerRef.current.children, animateOutValues)
    }
  }, [])

  useEffect(() => {
    if (containerRef) {
      if (isActive) {
        gsap.to(containerRef.current.children, animateInValues)
      }
    }
  }, [isActive])

  return (
    <section className={classes.content} ref={containerRef}>
      <h3 className={classes.title}>{title}</h3>
      <div className={classes.cols}>
        <div className={classes.col}>
          <RichText className={classes.bodyText} content={leftText.text} />
        </div>
        <div className={classes.col}>
          <RichText className={classes.bodyText} content={rightText.text} />
        </div>
      </div>
    </section>
  )
}

const useStyles = createUseStyles({
  content: {
    position: 'relative',
    '& > *': {
      opacity: 0
    },
    [theme.breakpoints.up('md')]: {
      margin: [0, span(2.5, 'md')]
    }
  },
  title: {
    paddingTop: vw(40),
    [theme.breakpoints.up('md')]: {
      paddingTop: vw(80, 'lg'),
      width: `calc(50% - ${span(1.5, 'md')})`
    }
  },
  cols: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    paddingTop: vw(40),
    [theme.breakpoints.up('md')]: {
      paddingTop: vw(40, 'lg'),
      flexDirection: 'row'
    }
  },
  col: {
    '&:first-child': {
      marginBottom: vw(40)
    },
    [theme.breakpoints.up('md')]: {
      flex: 1,
      display: 'flex',
      '&:first-child': {
        marginBottom: 0,
        marginRight: span(1.5, 'md')
      },
      '&:last-child': {
        marginLeft: span(1.5, 'md')
      }
    }
  }
}, { name: 'SideBySIdeTextBlock' })
