import React from 'react'
import { createUseStyles } from 'react-jss'
import theme, { vw } from '../style/theme'

export default ({ code, alignment }) => {
  const classes = useStyles()

  return (
    <section className={alignment === 'Field Guide' ? classes.leftContainer : classes.container}>
      <div className={classes.videoWrapper}>
        <iframe
          style={{ position: 'absolute', border: 'none', width: '100%', height: '100%', left: '0', top: '0' }}
          src={code}
          seamless='seamless'
          scrolling='no'
          frameBorder='0'
          allowTransparency='true'
          allowFullScreen='true'
        />
      </div>
    </section>
  )
}

const useStyles = createUseStyles({
  container: {
    width: '100%',
    marginLeft: 'auto',
    [theme.breakpoints.up('md')]: {
      width: vw(1000, 'lg'),
      marginRight: theme.getMargin('md'),
      marginBottom: '20px'
    }
  },
  leftContainer: {
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: vw(750, 'lg'),
      marginRight: theme.getMargin('md'),
      marginBottom: '20px'
    }
  },
  videoWrapper: {
    position: 'relative',
    paddingBottom: '56.25%',
    overflow: 'hidden',
    width: '100%'
  }

}, 'Embed')
