import React, { useCallback, useContext, useMemo, useState, useRef } from 'react'
import { createUseStyles } from 'react-jss'
import filter from 'lodash/filter'
import uniqBy from 'lodash/uniqBy'
import map from 'lodash/map'
import compact from 'lodash/compact'
import isEmpty from 'lodash/isEmpty'
import sortBy from 'lodash/sortBy'
import theme, { span, vw } from '../../../style/theme'
import GroupedList from './GroupedList'
import List from './List'
import RichText from '../../RichText'
import TagFilters from '../ListFilters/TagFilters'
import DropdownFilters from '../ListFilters/DropdownFilters'
import { SmoothScrollContext } from '../../useSmoothScroll'

const DownArrow = ({ className }) => {
  return (
    <svg className={className} viewBox='0 0 8 21' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M3.64645 20.3536C3.84171 20.5488 4.15829 20.5488 4.35355 20.3536L7.53553 17.1716C7.7308 16.9763 7.7308 16.6597 7.53553 16.4645C7.34027 16.2692 7.02369 16.2692 6.82843 16.4645L4 19.2929L1.17157 16.4645C0.97631 16.2692 0.659728 16.2692 0.464465 16.4645C0.269203 16.6597 0.269203 16.9763 0.464465 17.1716L3.64645 20.3536ZM3.5 -2.18557e-08L3.5 20L4.5 20L4.5 2.18557e-08L3.5 -2.18557e-08Z' fill='currentColor' />
    </svg>
  )
}

export default ({ slice }) => {
  const portfolioAnchorRef = useRef()
  const elevationAnchorRef = useRef()
  const classes = useStyles()
  const {
    portfolio_positions_title: portfolioPositionsTitle,
    elevation_positions_title: elevationPositionsTitle,
    contact_copy: contactCopy,
    copy,
    careers,
    filter_tags: filterTags
  } = slice
  const [tagFilter, setTagFilter] = useState('')
  const [portfolioFilter, setPortfolioFilter] = useState('')

  const portfolioPositions = useMemo(() => {
    return filter(careers, c => c.portfolio && c.portfolio.title.toLowerCase() !== 'elevation')
  }, [careers])

  const elevationPositions = useMemo(() => {
    return filter(careers, c => !c.portfolio || c.portfolio.title.toLowerCase() === 'elevation')
  }, [careers])

  const portfolioPositionTags = useMemo(() => {
    return uniqBy(compact([
      ...filterTags,
      ...sortBy(portfolioPositions, x => x.tag.title).map(x => x.tag)
    ]), x => x.slug)
  }, [portfolioPositions, filterTags])

  const portfolios = useMemo(() => {
    return uniqBy(portfolioPositions.map(x => x.portfolio), x => x.title)
  }, [portfolioPositions])

  const portfolioOptions = useMemo(() => {
    return [
      { text: 'Entire Portfolio', value: '' },
      ...map(portfolios, p => ({ text: p.title, value: p.title }))
    ]
  }, [portfolios])

  const filteredPortfolioPositions = useMemo(() => {
    var result = portfolioPositions
    if (tagFilter) {
      result = filter(result, r => r.tag.slug === tagFilter)
    }
    if (portfolioFilter) {
      result = filter(result, r => r.portfolio.title === portfolioFilter)
    }
    return result
  }, [tagFilter, portfolioFilter, portfolioPositions])

  const hasElevationPositions = !isEmpty(elevationPositions)

  const scrollContext = useContext(SmoothScrollContext)
  const scrollToPortfoliosCallback = useCallback(() => {
    scrollContext.current.setPageYOffset(portfolioAnchorRef.current.offsetTop - 100, 1000)
  }, [scrollContext.current])
  const scrollToElevationCallback = useCallback(() => {
    if (elevationAnchorRef.current) {
      scrollContext.current.setPageYOffset(elevationAnchorRef.current.offsetTop - 100, 1000)
    }
  }, [scrollContext.current])

  return (
    <section className={classes.section}>
      {copy && <RichText content={copy.text} className={classes.copy} />}
      <div className={classes.anchorLinks}>
        <button className={classes.anchorLink} onClick={scrollToPortfoliosCallback}>
          <DownArrow className={classes.downArrow} />
          <span className={classes.anchorLinkText}>{portfolioPositionsTitle}</span>
        </button>
        {hasElevationPositions && (
          <button className={classes.anchorLink} onClick={scrollToElevationCallback}>
            <DownArrow className={classes.downArrow} />
            <span className={classes.anchorLinkText}>{elevationPositionsTitle}</span>
          </button>
        )}
      </div>
      <h3 className={classes.title} ref={portfolioAnchorRef}>{portfolioPositionsTitle}</h3>
      <div className={classes.filterContainer}>
        <div className={classes.label}>Filter by</div>
        <div className={classes.filterOptionsContainer}>
          <DropdownFilters className={classes.portfolioFilter} options={portfolioOptions} value={portfolioFilter} setValue={setPortfolioFilter} />
          <TagFilters className={classes.tagFilter} tags={portfolioPositionTags} value={tagFilter} setValue={setTagFilter} maxItems={5} />
        </div>
      </div>
      <GroupedList careers={filteredPortfolioPositions} />
      {contactCopy && <RichText content={contactCopy.text} className={classes.contactCopy} />}
      {hasElevationPositions && <h3 className={classes.title} ref={elevationAnchorRef}>{elevationPositionsTitle}</h3>}
      {hasElevationPositions && <List careers={elevationPositions} />}
    </section>
  )
}

const useStyles = createUseStyles({
  sectionMargin: {
    ...theme.margins()
  },
  section: {
    composes: ['$sectionMargin'],
    ...theme.section.footerMargin()
  },
  margins: {
    marginBottom: vw(80),
    [theme.breakpoints.up('md')]: {
      marginBottom: vw(80, 'lg')
    }
  },
  filterContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: vw(16),
    [theme.breakpoints.up('md')]: {
      marginBottom: vw(32, 'lg')
    }
  },
  label: {
    marginRight: vw(8),
    whiteSpace: 'nowrap',
    [theme.breakpoints.up('md')]: {
      marginRight: vw(16, 'lg')
    }
  },
  filterOptionsContainer: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row'
    }
  },
  tagFilter: {
    flexGrow: 1,
    justifyContent: 'flex-end',
    textAlign: 'right'
  },
  portfolioFilter: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'block'
    }
  },
  copy: {
    width: '100%',
    marginBottom: vw(80),
    [theme.breakpoints.up('md')]: {
      marginBottom: vw(40, 'lg'),
      maxWidth: span(10, 'md')
    }
  },
  title: {
    extend: theme.typography.h4,
    marginBottom: vw(30),
    [theme.breakpoints.up('md')]: {
      marginBottom: vw(50, 'lg')
    }
  },
  filters: {
    marginBottom: vw(30),
    [theme.breakpoints.up('md')]: {
      marginBottom: vw(30, 'lg')
    }
  },
  contactCopy: {
    composes: ['$margins'],
    backgroundColor: theme.colors.offWhite,
    padding: vw(18),
    [theme.breakpoints.up('md')]: {
      padding: vw(30, 'lg')
    }
  },
  anchorLinks: {
    composes: ['$margins'],
    alignItems: 'center',
    width: '100%',
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      maxWidth: span(10, 'md')
    }
  },
  anchorLink: {
    width: '50%',
    display: 'flex',
    alignItems: 'center',
    border: 'none',
    padding: [vw(6), 0],
    borderBottom: [1, 'solid', theme.colors.border],
    textAlign: 'left',
    '&:hover': {
      color: theme.colors.primary,
      borderColor: theme.colors.primary
    },
    [theme.breakpoints.up('md')]: {
      fontSize: vw(16, 'lg'),
      padding: [vw(6, 'lg'), 0]
    },
    '&:last-child': {
      [theme.breakpoints.up('md')]: {
        marginLeft: vw(12, 'lg')
      }
    },
    '&:not(:last-child)': {
      [theme.breakpoints.up('md')]: {
        marginRight: vw(12, 'lg')
      }
    }
  },
  anchorLinkText: {
    extend: theme.typography.body
  },
  downArrow: {
    marginRight: 20,
    display: 'block',
    [theme.breakpoints.up('md')]: {
      width: 8,
      height: 21
    }
  }
}, { name: 'CareerListing' })
