import React, { useCallback } from 'react'
import { createUseStyles } from 'react-jss'
import cn from 'classnames'
import map from 'lodash/map'
import range from 'lodash/range'
import useIntersectionObserverCallback from '../../../../hooks/useIntersectionObserverCallback'
import gsap from 'gsap'

const Number = ({ number }) => {
  const classes = useStyles()
  const ref = useIntersectionObserverCallback({ threshold: 0.5, triggerOnce: true }, useCallback((entries, inView) => {
    if (inView) {
      gsap.to(ref.current, { y: `-${number}em`, duration: 1.5, ease: 'quad.out' })
    }
  }, [number]))
  const numberCount = range(10)

  return (
    <span className={classes.number} aria-hidden>
      <span ref={ref} className={classes.numberList}>
        {map(numberCount, (c, i) => <span key={i} className={classes.numberCharacter}>{i}</span>)}
      </span>
    </span>
  )
}

const NumberTicker = ({ className, number, affix }) => {
  const classes = useStyles()
  return (
    <span className={cn(classes.content, className)}>
      {map(Math.round(number) + '', (n, i) => <Number key={i} number={parseInt(n)} />)}
      <span className='srOnly'>{number}</span>
      {affix}
    </span>
  )
}

const useStyles = createUseStyles({
  content: {
    display: 'inline-flex'
  },
  number: {
    overflow: 'hidden',
    height: '1em'
  },
  numberList: {
    height: '1em',
    display: 'block'
  },
  numberCharacter: {
    textAlign: 'center',
    height: '1em',
    display: 'block'
  }
}, { name: 'NumberTicker' })

export default NumberTicker
