import React from 'react'
import { createUseStyles } from 'react-jss'
import cn from 'classnames'

export default ({ className, back }) => {
  const classes = useStyles({ back })
  return (
    <svg className={cn(className, classes.icon)} width='41' height='40' viewBox='0 0 41 40' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M17.4886 3.5L24.25 8.8125L17.4886 14.125' stroke='white' strokeWidth='1.93182' />
      <path d='M23.7352 9.17593C21.2124 8.49995 18.5413 8.63117 16.0969 9.55118C13.6525 10.4712 11.5577 12.1337 10.1066 14.3054C8.6556 16.477 7.92124 19.0486 8.00669 21.659C8.09215 24.2694 8.99313 26.7874 10.5831 28.8595C12.173 30.9316 14.3721 32.4536 16.8714 33.2118C19.3708 33.9699 22.0448 33.9262 24.518 33.0866C26.9912 32.2471 29.1393 30.654 30.6606 28.531C32.1819 26.408 33 23.8618 33 21.25' stroke='white' strokeWidth='1.9375' />
    </svg>
  )
}

const useStyles = createUseStyles({
  icon: {
    transform: ({ back }) => back ? 'scaleX(-1)' : ''
  }
})
