import React, { useEffect } from 'react'
import cn from 'classnames'
import { createUseStyles } from 'react-jss'
import { useDarkSlice } from '../HeaderContextProvider'
import theme, { vw } from '../../style/theme'
import BackgroundVideo from '../BackgroundVideo'

export default ({ slice }) => {
  const classes = useStyles()
  const { videoFile, title, description, cta_link: ctaLink, event_id: eventId } = slice
  const { text: btnTitle, url: btnUrl } = ctaLink[0]
  const ref = useDarkSlice()

  useEffect(() => {
    const script = document.createElement('script')
    script.src = 'https://embed.lu.ma/checkout-button.js'
    script.id = 'luma-checkout'
    document.body.appendChild(script)
    return () => {
      document.body.removeChild(script)
    }
  }, [])

  return (
    <section className={cn(classes.section)} ref={ref}>
      <BackgroundVideo url={videoFile.url} />
      <div className={classes.contentWrapper}>
        <h1 className={classes.heroTitle}>{title}</h1>
        <p className={classes.subtitle}>{description}</p>
        <a
          href={btnUrl}
          data-luma-action='checkout'
          data-luma-event-id={eventId}
        >
          <button className={classes.btn}>{btnTitle}</button>
        </a>
      </div>
    </section>
  )
}

const useStyles = createUseStyles({
  section: {
    position: 'relative',
    width: '100vw',
    height: '100vh',
    overflow: 'hidden',
    [theme.breakpoints.down('fab')]: {
      height: '90vh'
    }
  },
  contentWrapper: {
    position: 'absolute',
    top: '70%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 9,
    [theme.breakpoints.down('md')]: {
      left: '15%',
      transform: 'translate(-10%, -40%)'
    }
  },
  heroTitle: {
    extend: theme.typography.h3,
    color: theme.colors.white,
    textAlign: 'center',
    [theme.breakpoints.up('md')]: {
      extend: theme.typography.h2
    }
  },
  subtitle: {
    display: 'block',
    fontSize: theme.typography.bodyTiny2,
    marginBottom: vw(12),
    marginTop: vw(12),
    color: theme.colors.white,
    textAlign: 'center',
    [theme.breakpoints.up('xs')]: {
      marginBottom: vw(20, 'xs'),
      marginTop: vw(20, 'xs'),
      fontSize: '1.5rem'
    },
    [theme.breakpoints.up('md')]: {
      marginBottom: vw(24, 'md'),
      marginTop: vw(24, 'md'),
      fontSize: '1.5rem'
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: '2rem',
      marginBottom: vw(26, 'lg'),
      marginTop: vw(26, 'lg')
    }
  },
  btn: {
    width: '100%',
    marginTop: '16px',
    fontFamily: 'Mars Condensed',
    textTransform: 'uppercase',
    fontSize: '28px',
    fontWeight: 400,
    lineHeight: '36px',
    letterSpacing: '0.03em',
    backgroundColor: theme.colors.white,
    textAlign: 'center',
    padding: '8px 32px',
    textDecoration: 'none',
    [theme.breakpoints.up('md')]: {
      fontSize: '32px',
      width: 'auto'
    },
    '&:hover': {
      backgroundColor: theme.colors.primary,
      color: theme.colors.white
    }
  }
}, { name: 'FRNewHero' })
