import React, { useEffect, useContext } from 'react'
import cross from '../../images/cross.png'
import { createUseStyles } from 'react-jss'
import theme from '../../style/theme.js'
import { SmoothScrollContext } from '../useSmoothScroll'
import { find } from 'lodash'

const Modal = (props) => {
  const { isModalOpen, closeModal, children, position } = props
  const scrollContext = useContext(SmoothScrollContext)
  let previousPageOffset = 0

  const scrollCallback = () => {
    if (isModalOpen && scrollContext && scrollContext.current) {
      scrollContext.current.setPageYOffset(previousPageOffset, 0)
    }
  }

  useEffect(() => {
    previousPageOffset = scrollContext.current.getPageYOffset()
    if (
      isModalOpen &&
      !find(scrollContext.current.scrollCallbacks, scrollCallback)
    ) {
      scrollContext.current.scrollCallbacks.push(scrollCallback)
    } else {
      if (scrollContext.current.scrollCallbacks.length > 1) {
        scrollContext.current.scrollCallbacks.pop(scrollCallback)
      }
    }
  }, [isModalOpen])

  const classes = useStyles()

  return isModalOpen ? (
    <div
      className={classes.modalWrapper}
      style={{ top: `-${position.top}px`, left: `-${position.left}px` }}
    >
      <div className={classes.modalContainer}>
        <div className={classes.cross} onClick={closeModal}>
          <img src={cross} alt='crossBttn' className={classes.crossBttn} onClick={closeModal} />
        </div>
        {children}
      </div>
    </div>
  ) : null
}

const useStyles = createUseStyles(
  {
    modalWrapper: {
      position: 'absolute',
      top: '0',
      left: '0',
      overflow: 'hidden',
      width: '100vw',
      height: '100vh',
      zIndex: 20,
      background: 'rgba(0, 0, 0, 0.5)'
    },
    modalContainer: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      background: theme.colors.white,
      borderRadius: '32px',
      display: 'flex',
      padding: '42px 78px 58px 56px',
      width: 'fit-content',
      flexDirection: 'column',
      alignItems: 'center',
      zIndex: 1000,
      [theme.breakpoints.down('fab')]: {
        padding: '28px 24px 22px 23px'
      }
    },
    crossBttn: {
      height: '16px',
      width: '16px',
      position: 'absolute',
      top: '32.9px',
      right: '33.5px',
      cursor: 'pointer',
      [theme.breakpoints.down('fab')]: {
        height: '7px',
        width: '7px'
      }
    }
  },
  { name: 'Modal' }
)

export default Modal
