import React from 'react'

export default ({ className }) => {
  return (
    <svg className={className} viewBox='0 0 16 17' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M13.76 14.352H2.24v-2.976H.32v3.968c0 .263.101.515.281.701.18.186.425.291.68.291h13.44a.944.944 0 00.678-.29c.18-.187.281-.439.281-.702v-3.968h-1.92v2.976z' fill='#000' />
      <path d='M7.04.464V8.98L5.12 6.996 3.762 8.4l3.559 3.678a.959.959 0 00.68.29.934.934 0 00.678-.29L12.24 8.4 10.88 6.996 8.96 8.98V.464H7.04z' fill='#000' />
    </svg>
  )
}
