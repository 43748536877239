/* eslint-disable */
import React, { useMemo } from 'react'
import cn from 'classnames'
import { createUseStyles } from "react-jss"
import Link from "../../Link"
import ResponsiveImage from "../../ResponsiveImage"
import { resolveLink } from "../../../helpers/resolveLink"
import theme, { vw, span } from "../../../style/theme"
import { formatDate } from "../../../utils/format"
import FutureCategory from './FutureCategory'

export default (props) => {
  const {
    slug,
    title,
    post_date: postDate,
    preview_image: previewImage,
    preview_snippet: previewSnippet,
    isLandscape = false,
    isFeatured = false,
    className,
    isImageContain,
    featuredPost,
    linked_post
  } = props
  const classes = useStyles({ isFeatured })
  const resolvedLink = useMemo(() => resolveLink(linked_post), [linked_post])

  return (
    // will change a to Link later.
    <a href={resolvedLink.url} target='_blank' nonLinkTag='div' className={classes.postDescription}>
      <div key={slug} className={cn(className, classes.postTile, isLandscape ? 'landscape' : 'portrait')}>
        <div className={classes.previewImage}>
          <ResponsiveImage {...previewImage || featuredPost?.preview_image} aspect={isFeatured ? 1.5 : 1.4} isContain={isImageContain} className={isFeatured ? classes.featuredFutureImage : ''} />
        </div>
        <div className={classes.postInformation}>
          <div className={classes.postMeta}>
            <span className={classes.postDate}>{formatDate(featuredPost?.post_date || postDate, ' . ')}</span>
            {/* <PostCategory page={props} className={classes.metaLinks} /> */}
            <FutureCategory page={props} className={classes.metaLinks} />
          </div>
          <div className={classes.postDescription}>
            <span className={classes.title}>{featuredPost?.title || title}</span>
            <span className={classes.description}>{featuredPost?.preview_snippet || previewSnippet}</span>
          </div>
        </div>
      </div>
    </a>
  )
}

const useStyles = createUseStyles({
  postTile: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    '&.landscape': {
      [theme.breakpoints.up('md')]: {
        flexDirection: 'row',
        '& > *': {
          '&:first-child': {
            flex: 1,
            marginBottom: 0
          },
          '&:last-child': {
            width: span(7, 'md'),
            marginLeft: span(1, 'md')
          }
        }
      }
    }
  },
  postInformation: {
    display: 'flex',
    flexDirection: 'column-reverse',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'column',
      paddingRight: span(1, 'md'),
      maxWidth: span(10, 'md')
    }
  },
  previewImage: {
    marginBottom: 10,
    display: 'block',
    [theme.breakpoints.up('md')]: {
      marginBottom: 35,
    }
  },
  postMeta: {
    display: 'flex',
    flexDirection: 'column-reverse',
    marginBottom: 0,
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      marginBottom: ({ isFeatured }) => isFeatured ? 20 : 10
    }
  },
  postDate: {
    extend: theme.typography.bodyTiny
  },
  metaLinks: {
    marginBottom: 5,
    extend: theme.typography.bodyTiny,
    [theme.breakpoints.up('md')]: {
      marginBottom: 0,
      marginLeft: 20
    }
  },
  postDescription: {
    display: 'flex',
    flexDirection: 'column',
    textDecoration: 'none',
    color: 'inherit',
    marginBottom: 10,
    [theme.breakpoints.up('md')]: {
      marginBottom: 0
    }
  },
  title: {
    extend: theme.typography.bodyPlus,
    fontSize: ({ isFeatured }) => isFeatured ? vw(20) : vw(14),
    fontWeight: theme.typography.fontWeight.bold,
    marginBottom: 10,
    [theme.breakpoints.up('md')]: {
      fontSize: ({ isFeatured }) => isFeatured ? vw(24, 'lg') : vw(20, 'lg'),
      marginBottom: ({ isFeatured }) => isFeatured ? 20 : 10
    }
  },
  description: {
    width: '100%',
    extend: theme.typography.bodyTiny,
    [theme.breakpoints.up('md')]: {
      extend: theme.typography.bodySmall
    }
  },
  featuredFutureImage: {
    // width: '885px',
    height: '600px',
    [theme.breakpoints.down('md')]: {
      height: '246px',
    }
  },
}, { name: 'FutureTile' })
