import React from 'react'
import { createUseStyles } from 'react-jss'
import theme from '../../../../style/theme'
import ArrowNavigation from '../ArrowNavigation/index.js'
import RichText from '../../../RichText'
import ResponsiveImage from '../../../ResponsiveImage'
import { useDarkSlice } from '../../../HeaderContextProvider'
import Color from 'color'

const HeaderWithArrowNavigation = ({ slice }) => {
  const title = slice?.title
  const routeArray = slice?.route
  const subtitle = slice?.subtitle
  const backGroundImageForFieldGuideHero = slice?.photo
  const textColor = slice?.color
  const classes = useStyles({ textColor })

  const isDark = Color(textColor).isDark()

  const ref = useDarkSlice(!isDark)

  return (
    <div
      className={classes.container}
      ref={ref}
      style={{ background: slice?.image ? theme.colors.black : '' }}
    >
      {backGroundImageForFieldGuideHero ? (
        <ResponsiveImage
          {...backGroundImageForFieldGuideHero}
          className={classes.backImage}
        />
      ) : (
        ''
      )}
      <section className={!subtitle ? classes.fieldGuideHero : classes.futureMapsVideoHero}>
        <ArrowNavigation
          arrowData={routeArray}
          fieldGuidePage={slice?.image ? 'true' : 'false'}
        />
        <section className={classes.headings}>
          {title && (
            <RichText content={title.text} className={classes.richText} />
          )}
        </section>
        <p className={classes.subtitle} style={{ display: !subtitle ? 'none' : '' }}>{subtitle}</p>
      </section>
    </div>
  )
}

const useStyles = createUseStyles(
  {
    container: {
      padding: [149, 25, 0, 50],
      display: 'flex',
      flexDirection: 'column',
      rowGap: '29px',
      position: 'relative',
      zIndex: 5,

      [theme.breakpoints.down('md')]: {
        padding: [160, 0, 0, 25],
        paddingTop: '100px',
        rowGap: '60px'
      }
    },
    headings: {
      [theme.breakpoints.up('md')]: {
        width: '53.5vw' // converted value of 770px for above 1440px screen width
      }
    },
    commonStyles: {
      [theme.breakpoints.up('md')]: {
        marginRight: 41
      }
    },
    backImage: {
      position: 'absolute',
      top: '-100px',
      left: 0,
      width: '100%',
      zIndex: -1,
      [theme.breakpoints.down('lg')]: {
        top: 0
      },
      [theme.breakpoints.down('fab')]: {
        height: '90px'
      },
      background: theme.colors.black
    },
    fieldGuideHero: {
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      rowGap: '29px',
      // display: 'none',
      [theme.breakpoints.up('md')]: {
        rowGap: '30px'
      }
    },
    futureMapsVideoHero: {
      display: 'flex',
      flexDirection: 'column'
    },
    richText: {
      color: ({ textColor }) => textColor
    },
    subtitle: {
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '19.6px',
      width: '312px',
      maxWidth: '100%',
      // marginBottom: 0,
      marginTop: '24px',
      marginBottom: '24px',

      [theme.breakpoints.up('xs')]: {
        marginTop: '33px',
        fontSize: '16px',
        lineHeight: '22.4px',
        width: '524px'
      }
    }
  },
  { name: 'HeaderWithArrowNavigation' }
)

export default HeaderWithArrowNavigation
