import React from 'react'

export default () => {
  return (
    <svg viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path fill='#2F80ED' d='M0 0h30v30H0z' />
      <path d='M14.707 21.29h1.523v-5.504h5.332v-1.572H16.23V8.71h-1.523v5.504H9.375v1.572h5.332v5.504z' fill='#fff' />
    </svg>
  )
}
