import React from 'react'
import { createUseStyles } from 'react-jss'
import theme, { vw } from '../../../style/theme'
import RichText from '../../RichText'

export default ({ text, type }) => {
  const classes = useStyles()

  return (
    <div className={classes.container}>
      <div className={classes.content}>
        {text && <RichText content={text} />}
      </div>
    </div>
  )
}

const useStyles = createUseStyles({
  container: {
    width: '100%',
    padding: '0 20px',
    [theme.breakpoints.up('md')]: {
      padding: '0 50px',
      width: vw(1000, 'lg'),
      marginLeft: 'auto',
      marginRight: theme.getMargin('md')
    }
  },

  content: {
    textAlign: 'justify'
  }
}, 'RightSideText')
