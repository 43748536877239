import React from 'react'
import { createUseStyles } from 'react-jss'
import WhatWillYouGetComponent from '../WhatWillYouGetComponent/WhatWillYouGetComponent'
import theme from '../../style/theme'
import { WhatWillYouGetData } from '../WhatWillYouGetSection/data'

const WhatWillYouGetSection = ({ slice }) => {
  const { chapter_number: chapterNumber, tools_number: toolsNumber } = slice
  const valueofChapterInString = chapterNumber?.toString()
  const chapterValue = valueofChapterInString?.length === 1 ? '0' + valueofChapterInString : valueofChapterInString
  const valueofToolsInString = toolsNumber?.toString()
  const toolsValue = valueofToolsInString?.length === 1 ? '0' + valueofToolsInString : valueofToolsInString
  const classes = useStyles()
  return (
    <div className={classes.wrapper}>
      {WhatWillYouGetData?.map((data, index) => (
        <div key={index} className={classes.component}>
          <WhatWillYouGetComponent heading={data.heading} chapterNum={index === 0 ? chapterValue : toolsValue} type={data.type} />
        </div>
      ))}
    </div>
  )
}

const useStyles = createUseStyles({
  wrapper: {
    // padding: [38, 25, 0, 25],
    padding: [0, 25, 60, 0],
    display: 'flex',
    columnGap: '73px',
    [theme.breakpoints.down('mobile')]: {
      flexWrap: 'wrap',
      padding: [0, 25, 60, 25],
      rowGap: '20px'
    },
    [theme.breakpoints.down('md')]: {
      padding: [0, 25, 60, 25]
    },
    [theme.breakpoints.up('md')]: {
      columnGap: 154
    }
  }

}, { name: 'WhatWillYouGetSection' })

export default WhatWillYouGetSection
