import { createAction } from '@reduxjs/toolkit'
import makeFetchSlice from '../makeFetchSlice'

export const relatedFutureMapsContentActionCreator = createAction(
  'future_maps/related_content',
  (payload, loadMore) => ({
    payload,
    meta: { scope: 'future_maps_related', loadMore }
  })
)

export const relatedFutureMapsLoadMoreActionCreator = createAction(
  'future_maps/related_load_more',
  (payload) => ({
    payload,
    meta: { scope: 'future_maps_related' }
  })
)

export const serverErrorActionCreator = createAction('future_maps/serverError')

const relatedFutureMapsSlice = makeFetchSlice(
  'future_maps_related',
  relatedFutureMapsContentActionCreator,
  serverErrorActionCreator
)

export default relatedFutureMapsSlice.reducer
