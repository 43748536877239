import React, { useMemo } from 'react'
import cn from 'classnames'
import { useSelector } from 'react-redux'
import { getPostsQueryStringArguments, getPostListSlice } from '../../redux/slices/content'
import { createUseStyles } from 'react-jss'
import ResponsiveImage from '../ResponsiveImage'
import Link from '../Link'
import { resolveLink } from '../../helpers/resolveLink'
import theme, { span, vw } from '../../style/theme'

const Platform = (props) => {
  const {
    logo,
    link
    // label
  } = props
  const classes = useStyles()
  const resolvedLink = useMemo(() => resolveLink(link[0]), [link])
  return (
    <Link className={classes.platform} link={resolvedLink}>
      <ResponsiveImage className={classes.logo} {...logo} />
      {/* <span className={classes.platformLabel}>{label}</span> */}
    </Link>
  )
}

const PlatformsSlice = ({ slice }) => {
  const {
    headline,
    platforms
  } = slice
  const classes = useStyles()
  const { postInitiative } = useSelector(getPostsQueryStringArguments)
  const postListSlice = useSelector(getPostListSlice)
  if (postListSlice && postInitiative) return null
  return (
    <section className={classes.content}>
      <div className={classes.cols}>
        <div className={cn(classes.col, classes.colLeft)}>
          <div className={classes.container}>
            <h2 className={classes.headline}>{headline}</h2>
          </div>
        </div>
        <div className={cn(classes.col, classes.colRight)}>
          {platforms && platforms.length > 0 && (
            <div className={classes.container}>
              <div className={classes.platforms}>
                {platforms.map(platform => <Platform key={platform.id} {...platform} />)}
              </div>
            </div>
          )}
        </div>
      </div>
    </section>
  )
}

const useStyles = createUseStyles({
  content: {
    padding: [vw(30), theme.getMargin('min'), vw(60)],
    position: 'relative',
    [theme.breakpoints.up('md')]: {
      padding: [50, theme.getMargin('md'), 50]
    }
  },
  headline: {
    extend: theme.typography.h4,
    [theme.breakpoints.up('md')]: {
      extend: theme.typography.h5
    }
  },
  cols: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    margin: [0, 'auto'],
    position: 'relative',
    [theme.breakpoints.up('md')]: {
      maxWidth: span(18, 'md')
    }
  },
  col: {
    flex: 1
  },
  colLeft: {
    // marginBottom: 0,
    // [theme.breakpoints.up('md')]: {
    //   marginBottom: vw(40, 'md')
    // }
  },
  colRight: {
  },
  platforms: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    columnGap: vw(12),
    rowGap: vw(40),
    [theme.breakpoints.up('md')]: {
      columnGap: span(1, 'md'),
      rowGap: vw(40, 'lg'),
      gridTemplateColumns: '1fr 1fr 1fr 1fr'
    }
  },
  platform: {
    display: 'flex',
    alignItems: 'center',
    maxWidth: vw(130),
    textDecoration: 'none',
    color: 'currentColor',
    [theme.breakpoints.up('md')]: {
      maxWidth: vw(200, 'lg')
    }
  },
  platformLabel: {
    extend: theme.typography.body,
    color: theme.typography.black,
    marginLeft: 20
  },
  logo: {
    maxWidth: 40
  }
}, { name: 'PlatformsSlice' })

export default PlatformsSlice
