import React from 'react'
import theme from '../../style/theme'
import { createUseStyles } from 'react-jss'

const WhatWillYouGetComponent = ({ heading, chapterNum, type }) => {
  const classes = useStyles()
  return (
    <>
      {chapterNum ? (
        <div className={classes.container}>
          <p>{heading}</p>
          <section className={classes.chapterSection}>
            <span className={classes.chapterNum}>{chapterNum}</span>
            <span className={classes.typeOf}>{type}</span>
          </section>
        </div>
      ) : null}
    </>
  )
}

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: '18.06px',
    '& p': {
      marginBottom: 0,
      fontFamily: 'Object Sans',
      fontStyle: 'normal',
      fontWeight: 700,
      fontSize: '14px',
      lineHeight: '14px',
      letterSpacing: '0.03em',
      [theme.breakpoints.up('md')]: {
        fontSize: '15px',
        lineHeight: '15px'
      }
    },
    [theme.breakpoints.up('md')]: {
      rowGap: '31.5px'
    }
  },
  chapterSection: {
    display: 'flex',
    columnGap: '8.25px',
    alignItems: 'baseline',
    [theme.breakpoints.up('md')]: {
      columnGap: '10px'
    }
  },
  chapterNum: {
    color: theme.colors.grey32,
    fontFamily: 'Object Sans',
    fontStyle: 'normal',
    fontWeight: 800,
    fontSize: '40px',
    lineHeight: '41px',
    [theme.breakpoints.up('md')]: {
      marginRight: 10
    }
  },
  typeOf: {
    fontFamily: 'Object Sans',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '16px'
  }

}, { name: 'WhatWillYouGetComponent' })

export default WhatWillYouGetComponent
