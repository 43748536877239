import React from 'react'
import { createUseStyles } from 'react-jss'
import theme from '../../../style/theme'
import ResponsiveImage from '../../ResponsiveImage'
import Link from '../../Link'
import { resolveInternalLinkUrl } from '../../../helpers/resolveLink'

const PlaybookAuthor = ({ slice, page }) => {
  const classes = useStyles()
  const { authorRef } = page
  const { authors } = authorRef
  const typeOfPage = page?.type

  return (
    <>
      {typeOfPage === 'playbook' ? (
        <>
          <hr className={classes.line} />
          <div className={classes.container}>
            <div className={classes.authorsCards}>
              {authors && authors.map((cardData, index) => (
                <Link key={index} className={classes.card} to={resolveInternalLinkUrl(cardData.author.page)}>
                  <ResponsiveImage {...cardData?.author.photoSecond || cardData.author.photoTwo || cardData.author.photo || cardData?.author.image} className={classes.authorImage} />
                  <section className={classes.aboutSection}>
                    {cardData.collaborator ? <p className={classes.isAuthor}>{cardData.collaborator}</p> : ''}
                    <p className={classes.name}>{cardData?.author.title || cardData?.name}</p>
                    <p className={classes.chapterAuthors}>{cardData.author.type !== 'teamMember' ? cardData.author.position : cardData.author.position + ' at Elevation Capital'}</p>
                  </section>
                </Link>
              ))}
            </div>
          </div>
        </>
      ) : ('')}
    </>
  )
}

const useStyles = createUseStyles(
  {
    container: {
      display: 'flex',
      flexDirection: 'column',
      rowGap: '25px',
      padding: [0, 36, 45, 28],

      '& p': {
        marginBottom: 0
      },

      [theme.breakpoints.up('md')]: {
        padding: [0, 0, 62, 0],
        rowGap: '25.34px'
      }
    },
    line: {
      border: '1px solid #D2D2D2',
      // margin: '61.7px 100px 48.6px 0',
      // margin: '56.67px 100px 38.43px 0',
      margin: '0px 100px 38.43px 0',
      [theme.breakpoints.down('md')]: {
        display: 'none'
      }
    },
    authorsHeading: {
      fontFamily: 'Object Sans',
      fontStyle: 'normal',
      fontWeight: 700,
      fontSize: '20px',
      lineHeight: '25px',

      [theme.breakpoints.up('md')]: {
        lineHeight: '25px',
        fontSize: '24px'
      }
    },
    authorImage: {
      zIndex: -1,
      width: '139.51px',
      height: '156.62px'
    },
    authorsCards: {
      display: 'flex',
      columnGap: '31px',
      rowGap: '15px',
      flexWrap: 'wrap'
    },

    card: {
      display: 'flex',
      flexDirection: 'column',
      rowGap: '13.46px',
      width: '139.51px',
      color: 'inherit',
      textDecoration: 'none'
    },

    aboutSection: {
      display: 'flex',
      flexDirection: 'column',
      rowGap: '3px'
    },
    name: {
      fontFamily: 'Object Sans',
      fontStyle: 'normal',
      fontWeight: 700,
      fontSize: '15px',
      lineHeight: '15px'
    },
    chapterAuthors: {
      fontFamily: 'Object Sans',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '10.65px',
      lineHeight: '11px',
      color: '#7B7B7B'
    },
    isAuthor: {
      color: theme.colors.primary,
      fontSize: '12px'
    }
  },
  { name: 'PlaybookAuthor' }
)

export default PlaybookAuthor
