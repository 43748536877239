import React from 'react'
import { Helmet } from 'react-helmet-async'
import { useSelector } from 'react-redux'
import { getDialogPageHeadTitle, getDialogPageMetaData, getPageHeadTitle, getPageMetaData } from '../redux/slices/content'
import { isDialogOpen } from '../redux/slices/layout'

function Meta () {
  const pageMeta = useSelector(getPageMetaData)
  const headTitle = useSelector(getPageHeadTitle)

  const dialogMeta = useSelector(getDialogPageMetaData)
  const dialogHeadTitle = useSelector(getDialogPageHeadTitle)

  const dialogOpen = useSelector(isDialogOpen)

  const title = dialogOpen ? dialogHeadTitle : headTitle
  const meta = dialogOpen ? dialogMeta : pageMeta

  return (
    <Helmet>
      <title>{title}</title>
      {meta && (
        <>
          <meta name='title' content={meta.metaTitle} />
          {meta.metaDescription && <meta name='description' content={meta.metaDescription} />}
          {meta.metaKeywords && <meta name='keywords' content={meta.metaKeywords} />}
          {meta.ogUrl && <meta property='og:url' content={meta.ogUrl} />}
          <meta property='og:site_name' content={meta.siteName} />
          <meta property='og:title' content={meta.metaTitle} />
          <meta property='og:type' content={meta.metaType} />
          {meta.metaDescription && <meta property='og:description' content={meta.metaDescription} />}
          {meta.metaImage && [
            <meta property='og:image' content={meta.metaImage.url} key={0} />,
            <meta property='og:image:width' content={meta.metaImage.width} key={1} />,
            <meta property='og:image:height' content={meta.metaImage.height} key={2} />
          ]}
          {meta.metaImage && <meta name='twitter:card' content={meta.twitterCardType} />}
          {meta.siteName && <meta name='twitter:site' content={meta.siteName} />}
          <meta name='twitter:title' content={meta.metaTitle} />
          {meta.metaDescription && <meta name='twitter:description' content={meta.metaDescription} />}
          {meta.metaImage && <meta name='twitter:image' content={meta.metaImage.url} />}
          <link rel='canonical' href={meta.canonicalUrl} />
        </>
      )}
    </Helmet>
  )
}

export default Meta
