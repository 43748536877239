import React from 'react'
import { createUseStyles } from 'react-jss'
import cn from 'classnames'
// import ResponsiveImage from '../../ResponsiveImage'
import { formatDate } from '../../../utils/format'
import theme from '../../../style/theme'
import ResponsiveImage from '../../ResponsiveImage4'

const PlaybookListItem = (props) => {
  const {
    slug,
    title,
    // link,
    post_date: postDate,
    preview_image: previewImage,
    preview_snippet: previewSnippet,
    // type,
    isCompleted,
    className
  } = props

  const classes = useStyles()

  return (
    <a className={cn(className, classes.card)} href={isCompleted !== undefined && isCompleted === true ? `/${slug}` : isCompleted === undefined && `/${slug}`}>
      <ResponsiveImage {...previewImage} className={classes.postImage} />
      <div className={classes.postDate}>
        <p>{formatDate(postDate)}</p>
        {/* <p className={classes.type}>{type}</p> */}
      </div>
      <p className={classes.postTitle}>{title}</p>
      <p className={classes.description}>{previewSnippet}</p>
    </a>
  )
}

const useStyles = createUseStyles(
  {
    card: {
      width: '100%',
      height: 'fit-content',
      // aspectRatio: '419/462',
      // zIndex: '-1',

      textDecoration: 'none',
      cursor: 'pointer',
      color: 'inherit',
      display: 'flex',
      flexDirection: 'column'
    },
    postImage: {
      width: '100%',
      // height: 'fit-content',
      zIndex: -1,
      // aspectRatio: '1 / 1',

      [theme.breakpoints.down('md')]: {
        order: '0',
        width: '100%',
        height: 'auto'
      },
      [theme.breakpoints.down('xs')]: {
        width: '100%'
      }
    },
    postDate: {
      display: 'flex',
      gap: '20px',
      textTransform: 'uppercase',
      '& p': {
        margin: '35px 0 11px 0',
        fontSize: '11px',
        fontWeight: 400,
        [theme.breakpoints.down('md')]: {
          fontSize: '10px',
          gap: '15px',
          margin: '10px 0 10px 0'
        }
      },
      [theme.breakpoints.down('md')]: {
        order: '3'
      }
    },
    type: {
      [theme.breakpoints.down('md')]: {
        color: theme.colors.primary
      }
    },
    postTitle: {
      margin: '0 0 30px 0',
      fontWeight: '700',
      fontSize: '14px',

      [theme.breakpoints.down('md')]: {
        fontSize: '10px',
        margin: '10px 0 10px 0',
        order: '1'
      }
    },
    description: {
      fontFamily: 'Object Sans',
      fontWeight: 400,
      fontSize: '10px',
      lineHeight: '140%',
      order: '2',

      [theme.breakpoints.up('md')]: {
        width: '362px',
        fontStyle: 'normal',
        fontSize: '14px'
      }
    }
  },
  { name: 'PlaybookIntroTitle' }
)

export default PlaybookListItem
