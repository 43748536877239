import React, { useRef } from 'react'
import { createUseStyles } from 'react-jss'
import { useSelector } from 'react-redux'
import cn from 'classnames'
import get from 'lodash/get'
import theme from '../style/theme'
import { getDialogContent, getDialogSlices, getPageContent, isDialogContentBusy } from '../redux/slices/content'
import { isDialogOpen } from '../redux/slices/layout'
import useBodyScrollLock from '../hooks/useBodyScrollLock'
import useSmoothScroll, { SmoothScrollContext } from './useSmoothScroll'
import useNavigateCallback from '../hooks/useNavigateCallback'
import Slices from './Slices'
import { resolveInternalLinkUrl } from '../helpers/resolveLink'
import composeRefs from '../helpers/composeRefs'
import detectIt from 'detect-it'
import StaticHeader from './Header/StaticHeader'
import StickyHeader from './Header/StickyHeader'
import useDialogAnimation from './useDialogAnimation'
import HeaderContextProvider from './HeaderContextProvider'
import { NextPageFromState as NextPage } from './NextPage'
import RelatedPosts from './Slices/Post/RelatedPosts'

const DialogScrollContainer = ({ open, content, slices }) => {
  const dialogBusy = useSelector(isDialogContentBusy)
  const classes = useStyles()
  const scrollLockRef = useBodyScrollLock((open || dialogBusy) && detectIt.primaryInput === 'touch')
  const { ref: scrollAreaRef, scrollbarRef } = useSmoothScroll()
  const ref = composeRefs(scrollLockRef, scrollAreaRef)
  const headerRef = useRef()

  const pageContent = useSelector(getPageContent)
  const closeDialog = useNavigateCallback(resolveInternalLinkUrl(pageContent))

  return (
    <HeaderContextProvider headerRef={headerRef}>
      <SmoothScrollContext.Provider value={scrollbarRef}>
        <StickyHeader ref={headerRef} isDialog onCloseClick={closeDialog} />
        <div className={classes.inner} ref={ref} id='dialog-scroller'>
          <StaticHeader isDialog />
          <main className={classes.main}>
            {content && (
              <Slices slices={slices} page={content} />
            )}
            <RelatedPosts page={content} />
            <NextPage page={content} />
          </main>
        </div>
      </SmoothScrollContext.Provider>
    </HeaderContextProvider>
  )
}

const Dialog = () => {
  const classes = useStyles()
  const open = useSelector(isDialogOpen)
  const content = useSelector(getDialogContent)
  const id = get(content, ['id'])
  const slices = useSelector(getDialogSlices)

  const ref = useDialogAnimation(open)

  return (
    <div className={cn(classes.dialog, { open })} ref={ref}>
      <DialogScrollContainer open={open} content={content} slices={slices} key={id} />
    </div>
  )
}

const useStyles = createUseStyles({
  dialog: {
    position: 'fixed',
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    overflow: 'hidden',
    backgroundColor: theme.colors.background,
    zIndex: theme.zIndex.dialog,
    pointerEvents: 'none',
    visibility: 'hidden',
    '&.open': {
      pointerEvents: 'all'
    },
    [theme.breakpoints.up('md')]: {
      height: '100vh'
    }
  },
  inner: {
    position: 'absolute',
    left: 0,
    top: 0,
    bottom: 0,
    right: 0,
    overflow: 'auto',
    WebkitOverflowScrolling: 'touch',
    overscrollBehaviour: 'contain',
    [theme.breakpoints.up('md')]: {
      height: '100vh'
    }
  },
  header: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    zIndex: 10,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.getMargin(),
    pointerEvents: 'none',
    '& > *': {
      pointerEvents: 'all'
    },
    [theme.breakpoints.up('md')]: {
      padding: [25, 50]
    }
  },
  main: {
    display: 'flex',
    flexDirection: 'column'
  },
  closeButton: {
    height: 44,
    width: 44,
    padding: 0,
    border: 0,
    display: 'flex',
    '& > span': {
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0
    }
  },
  hamburger: {
    fontSize: 44
  },
  logoWrapper: {
    display: 'block',
    width: 128,
    height: 30,
    color: 'inherit',
    [theme.breakpoints.up('md')]: {
      width: 200,
      height: 45,
      marginRight: 48
    }
  }
}, { name: 'Dialog' })

export default Dialog
