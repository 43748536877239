import { createAction } from '@reduxjs/toolkit'
import makeFetchSlice from '../makeFetchSlice'

export const pressContentActionCreator = createAction('press/content', (payload, loadMore) => ({
  payload,
  meta: { scope: 'press', loadMore }
}))

export const pressLoadMoreActionCreator = createAction('press/load_more', (payload) => ({
  payload,
  meta: { scope: 'press' }
}))

export const serverErrorActionCreator = createAction('press/serverError')

const pressSlice = makeFetchSlice('press', pressContentActionCreator, serverErrorActionCreator)

export default pressSlice.reducer
