import React from 'react'

export default ({ className, volume = 0, muted }) => {
  return (
    <svg className={className} width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M11 5L6 9H2V15H6L11 19V5Z' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      {!muted && volume > 0 && volume < 0.5 && (
        <path d='M15.54 8.45996C16.4774 9.3976 17.004 10.6691 17.004 11.995C17.004 13.3208 16.4774 14.5923 15.54 15.53' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      )}
      {!muted && volume >= 0.5 && (
        <path d='M19.07 4.92993C20.9447 6.80521 21.9979 9.34829 21.9979 11.9999C21.9979 14.6516 20.9447 17.1947 19.07 19.0699M15.54 8.45993C16.4774 9.39757 17.004 10.6691 17.004 11.9949C17.004 13.3208 16.4774 14.5923 15.54 15.5299' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      )}
      {muted && (
        <g>
          <path d='M23 9L17 15' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
          <path d='M17 9L23 15' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
        </g>
      )}
    </svg>

  )
}
