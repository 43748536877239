import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getOptions, pathToAction, selectLocationState } from 'redux-first-router'

export default function useNavigateCallback (url) {
  const dispatch = useDispatch()
  const { routesMap } = useSelector(selectLocationState)

  return useCallback(() => {
    const { querySerializer } = getOptions()
    dispatch(pathToAction(url, routesMap, querySerializer))
  }, [url, routesMap])
}
