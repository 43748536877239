import React from 'react'
import { createUseStyles } from 'react-jss'
import cn from 'classnames'
import ResponsiveImage from './ResponsiveImage'
import Link from './Link'
import theme, { span } from '../style/theme'
import { getForegroundColorFromImage } from '../style/colorHelper'
import { useDarkSlice } from './HeaderContextProvider'
import Wedge from './Wedge'

const FullWidthImage = ({ className, image, title, copy, linkText, link, containerComponent = 'div' }) => {
  const classes = useStyles()
  const showTab = title || copy
  const Container = containerComponent
  const isDarkSlice = getForegroundColorFromImage(image) === theme.colors.white
  const ref = useDarkSlice(isDarkSlice)

  return (
    <Container className={cn(classes.imageSlice, className)} ref={ref}>
      <Link link={link} nonLinkTag='div' className={classes.link}>
        <ResponsiveImage className={classes.image} {...image} parallax={theme.parallax} />
        {showTab && (
          <Wedge className={classes.wedge} title={title} copy={copy} ctaText={linkText} backgroundColor={theme.colors.white} wedgeAngle={20} />
        )}
      </Link>
    </Container>
  )
}

const useStyles = createUseStyles({
  imageSlice: {
    position: 'relative',
    paddingBottom: '130vw',
    overflow: 'hidden',
    marginBottom: -1,
    backgroundColor: theme.colors.background,
    [theme.breakpoints.up('md')]: {
      paddingBottom: '48vw'
    }
  },
  image: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 0,
    transformStyle: 'preserve-3d'
  },
  link: {
    display: 'block',
    color: 'inherit'
  },
  wedge: {
    transform: 'translate3d(0,0,0)',
    width: `calc(100% - 80px - ${theme.getMargin()}px)`,
    [theme.breakpoints.up('md')]: {
      padding: [40],
      width: span(6, 'md')
    }
  }
}, { name: 'FullWidthImage' })

export default FullWidthImage
