/*eslint-disable*/
import React from 'react'
import theme from '../../../style/theme'
import { createUseStyles } from 'react-jss'

const Tags = ({ slice, page }) => {
  const { tag: tags } = slice
  const tagsList = page?.futureMapsTags
  const classes = useStyles()

  return (
    <div className={classes.tags}>
      {tagsList.map((item) => (
        <p>{item.title}</p>
      ))}
    </div>
  )
}

const useStyles = createUseStyles(
  {
    tags: {
      width: 'fit-content',
      display: 'flex',
      flexWrap: 'wrap',
      gap: '10px',
      '& p': {
        fontSize: '14px',
        border: '1px solid #000023;',
        padding: '6px 10px',
        borderRadius: '25px',
        marginBottom: 0,
      },
      [theme.breakpoints.down('md')]: {
        margin: '0 28px 70px 28px'
      }
    },
  },
  { name: 'Tags' },
)

export default Tags
