import color from 'color'
import theme from './theme'

export const getForegroundColorFromImage = (image, defaultColor = theme.colors.text) => {
  const { palette: { dominant: { title } } } = image
  if (!title) {
    return defaultColor
  }
  const c = color(title)
  return c.isLight() ? theme.colors.white : theme.colors.text
}

export const getForegroundColorFromBackgroundColor = (bg, defaultColor = theme.colors.text) => {
  if (!bg) return defaultColor
  const c = color(bg)
  return c.isLight() ? theme.colors.text : theme.colors.white
}
