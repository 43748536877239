export default function unwrap (node) {
  const parent = node.parentNode
  if (parent) {
    while (node.childNodes.length) {
      const childNode = node.childNodes[0]
      parent.insertBefore(childNode, node)
    }
    parent.removeChild(node)
  }
}
