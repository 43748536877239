import React from 'react'
import { createUseStyles } from 'react-jss'
import theme from '../../../style/theme'
import PlaybookListItem from './PlaybookListItem'

const PlaybookPostList = ({ posts }) => {
  const classes = useStyles()

  return (
    <div className={classes.postList}>
      <div className={classes.list}>
        {posts && posts.map((post, index) => (
          <React.Fragment key={index}>
            <PlaybookListItem {...post} />
          </React.Fragment>
        ))}
      </div>
    </div>
  )
}

const useStyles = createUseStyles(
  {
    postList: {
      marginTop: '67px',
      [theme.breakpoints.down('md')]: {
        marginTop: '15px'
      }
    },
    list: {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr 1fr',
      gap: '41px',
      width: '100%',

      [theme.breakpoints.down('lg')]: {
        gridTemplateColumns: '1fr 1fr',
        gap: '20px'
      },

      [theme.breakpoints.down('mobile')]: {
        gridTemplateColumns: '1fr'
      }
    }
  },
  { name: 'PlaybookPostList' }
)

export default PlaybookPostList
