import React, { forwardRef } from 'react'
import { createUseStyles } from 'react-jss'
import { useDispatch, useSelector } from 'react-redux'
import {
  getNewsletterBusy,
  getNewsletterError,
  getNewsletterIsSubscribed,
  requestSubscribeActionCreator
} from '../../redux/slices/newsletter'
import NewsletterSignup from '../NewsletterSignup'
import theme, { span } from '../../style/theme'

const FooterNewsletterSignup = forwardRef((props, ref) => {
  const { title } = props
  const classes = useStyles()

  const dispatch = useDispatch()
  const busy = useSelector(getNewsletterBusy)
  const subscribed = useSelector(getNewsletterIsSubscribed)
  const error = useSelector(getNewsletterError)
  const onSubmit = (e, email) => {
    dispatch(requestSubscribeActionCreator({ email }))
    e.preventDefault()
  }

  return (
    <>
      <span className={classes.title}>
        {title}
      </span>
      <NewsletterSignup
        textColor={theme.colors.white}
        borderColor={theme.colors.white}
        className={classes.signupForm}
        busy={busy}
        subscribed={subscribed}
        error={error}
        onSubmit={onSubmit}
      />
    </>
  )
})

const useStyles = createUseStyles({
  title: {
    display: 'block',
    extend: theme.typography.bodySmall,
    opacity: 0.5,
    marginBottom: 10
  }
}, { name: 'FooterNewsletterSignup' })

export default FooterNewsletterSignup
