import React, { useRef, useEffect, useCallback } from 'react'
import { createUseStyles } from 'react-jss'
import theme, { vw } from '../../style/theme'
import VolumeIcon from '../../images/VolumeIcon'
import get from 'lodash/get'

const VolumeControls = ({ volume, muted, onToggleMuted, onVolumeChange }) => {
  const classes = useStyles()
  const volumeLevelRef = useRef()
  const volumeLevelContainerRef = useRef()
  const levelIndicatorRef = useRef()

  useEffect(() => {
    volumeLevelRef.current.style.transform = `translate(-${(1 - volume) * 100}%, 0)`
    levelIndicatorRef.current.style.left = `${volume * 100}%`
  }, [volume])

  const onMouseDown = useCallback((e) => {
    if (!onVolumeChange) return

    const updateVolumeLevel = (clientX) => {
      const { width, left } = volumeLevelContainerRef.current.getBoundingClientRect()
      const level = Math.max(0, Math.min(1, (clientX - left) / width))
      onVolumeChange(level)
    }
    updateVolumeLevel(get(e, ['touches', 0, 'clientX'], e.clientX))

    const cleanupHandlers = () => {
      document.body.removeEventListener('mousemove', onMouseMove)
      document.body.removeEventListener('mouseup', onMouseUp)
      document.body.removeEventListener('touchmove', onMouseMove)
      document.body.removeEventListener('touchend', onMouseUp)
    }
    const onMouseMove = (e) => { updateVolumeLevel(get(e, ['touches', 0, 'clientX'], e.clientX)) }
    const onMouseUp = (e) => {
      cleanupHandlers()
    }
    document.body.addEventListener('mousemove', onMouseMove)
    document.body.addEventListener('mouseup', onMouseUp)
    document.body.addEventListener('touchend', onMouseUp)
    document.body.addEventListener('touchmove', onMouseMove)
    return () => {
      cleanupHandlers()
    }
  }, [onVolumeChange])

  return (
    <div className={classes.container}>
      <button className={classes.icon} onClick={onToggleMuted}><VolumeIcon muted={muted} volume={volume} /></button>
      <div className={classes.levelWrapper} onMouseDown={onMouseDown} onTouchStart={onMouseDown}>
        <div className={classes.levelContainer} ref={volumeLevelContainerRef}>
          <span className={classes.level} ref={volumeLevelRef} />
        </div>
        <span className={classes.levelIndicator} ref={levelIndicatorRef} />
      </div>
    </div>
  )
}

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    alignItems: 'center',
    width: vw(80),
    [theme.breakpoints.up('md')]: {
      width: vw(100, 'lg')
    },
    '& svg': {
      color: theme.colors.white
    }
  },
  icon: {
    flexGrow: 0,
    flexShrink: 0,
    marginRight: vw(16),
    [theme.breakpoints.up('md')]: {
      marginRight: vw(16, 'lg')
    }
  },
  levelWrapper: {
    flexGrow: 1,
    flexShrink: 1,
    position: 'relative',
    cursor: 'pointer',
    padding: [16, 0]
  },
  levelContainer: {
    height: 3,
    position: 'relative',
    backgroundColor: 'rgba(255,255,255,0.3)',
    overflow: 'hidden',
    [theme.breakpoints.up('md')]: {
      height: 5
    }
  },
  level: {
    position: 'absolute',
    display: 'block',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: theme.colors.white,
    pointerEvents: 'none',
    transform: 'translateX(-100%)'
  },
  levelIndicator: {
    backgroundColor: theme.colors.white,
    borderRadius: '50%',
    width: 10,
    height: 10,
    position: 'absolute',
    left: 0,
    top: '50%',
    transform: 'translate(-50%, -50%)',
    pointerEvents: 'none',
    [theme.breakpoints.up('md')]: {
      width: 12,
      height: 12
    }
  }
}, { name: 'VolumeControls' })

export default VolumeControls
