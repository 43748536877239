import React, { useEffect, useRef } from 'react'
import { createUseStyles } from 'react-jss'
import { gridConfig } from '../../../style/grid'
import theme, { vw } from '../../../style/theme'
import gsap from 'gsap'
import forEach from 'lodash/forEach'
import { getForegroundColorFromBackgroundColor } from '../../../style/colorHelper'
import color from 'color'
import { useSelector } from 'react-redux'
import { isCurrentBreakpointMobile } from '../../../redux/slices/layout'
import size from './size'
import useWindowResize from '../../../hooks/useWindowResize'
import useDebouncedCallback from '../../../hooks/useDebouncedCallback'

const Pagination = ({ className, color, slides, scrollContext }) => {
  const slideCount = slides.length
  const classes = useStyles({ backgroundColor: getForegroundColorFromBackgroundColor(color), slideCount })
  const ref = useRef()
  const isMobile = useSelector(isCurrentBreakpointMobile)
  const localsRef = useRef({ windowHeight: 0 })

  useEffect(() => {
    const tick = () => {
      const offsetY = scrollContext.current.getPageYOffset()
      const totalHeight = localsRef.current.windowHeight * slideCount * (isMobile ? size.mobileHeight : size.height)
      const percentComplete = offsetY / totalHeight
      forEach(ref.current.children, (item, index) => {
        const bucketAmount = 1 / slideCount
        const p = Math.min(1, Math.max(0, (percentComplete - (index * bucketAmount)) / bucketAmount))
        item.children[0].style.transform = `translate3d(0,-${100 - (p * 100)}%,0)`
      })
    }
    gsap.ticker.add(tick)
    return () => {
      gsap.ticker.remove(tick)
    }
  }, [slideCount, scrollContext.current, isMobile])

  useWindowResize(useDebouncedCallback(() => { localsRef.current.windowHeight = window.innerHeight }, 200, []))

  return (
    <div className={classes.pager} ref={ref}>
      {slides.map((_, index) => <span key={index} className={classes.page}><span className={classes.progress} /></span>)}
    </div>
  )
}

const useStyles = createUseStyles({
  pager: {
    position: 'absolute',
    right: vw(gridConfig.min.gutter),
    display: 'block',
    transform: 'translateY(-50%)',
    top: '31.5%',
    [theme.breakpoints.up('md')]: {
      top: '50%',
      right: vw(gridConfig.md.gutter, 'lg')
    }
  },
  page: {
    display: 'block',
    width: 1,
    backgroundColor: ({ backgroundColor }) => color(backgroundColor).isLight() ? backgroundColor : color(backgroundColor).alpha(0.3).string(),
    position: 'relative',
    overflow: 'hidden',
    height: vw(20),
    [theme.breakpoints.up('md')]: {
      height: vw(35, 'lg')
    },
    '&:not(:last-child)': {
      marginBottom: vw(10),
      [theme.breakpoints.up('md')]: {
        marginBottom: vw(10, 'lg')
      }
    }
  },
  progress: {
    backgroundColor: theme.colors.primary,
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    transform: 'translate(0, -100%)'
  }
}, { name: 'Pagination' })

export default Pagination
