import React from 'react'
import cn from 'classnames'
import { createUseStyles } from 'react-jss'
import theme from '../style/theme'
import { span } from '../style/span'
import { vw } from '../style/vw'

const Locations = ({ locations, className, showPhone }) => {
  const classes = useStyles()
  return (
    <div className={cn(classes.locations, classes.cols, className)}>
      {locations && locations.map((location, i) => (
        <div key={location.id} className={cn(classes.col, classes.location)}>
          <span className={classes.locationTitle}>
            {location.title}
          </span>
          <p className={classes.locationAddress}>
            <span>{location.address}</span>
            {location.link && (
              <a
                className={classes.locationLink}
                href={location.link}
                target='_blank'
                rel='noreferrer noopener'
                aria-label={`Open a Google map showing ${location.title}`}
              >
                <svg viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
                  <path
                    d='M8.846 6.556v3.333A1.11 1.11 0 017.735 11H1.624A1.111 1.111 0 01.513 9.89V3.778a1.111 1.111 0 011.11-1.111h3.334M7.18 1h3.334v3.333M4.403 7.111L10.513 1'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                  />
                </svg>
              </a>
            )}
          </p>
          {showPhone && location.phone && (
            <a className={classes.phone} href={`tel:${location.phone}`}>
              {location.phone}
            </a>
          )}
        </div>
      ))}
    </div>
  )
}

const useStyles = createUseStyles({
  cols: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      justifyContent: 'space-between'
    }
  },
  col: {
    display: 'block',
    marginBottom: 50,
    '&:last-child': {
      marginBottom: 0
    },
    [theme.breakpoints.up('md')]: {
      marginBottom: 0,
      flex: 1,
      '&:first-child': {
        marginRight: span(1, 'md')
      },
      '&:last-child': {
        marginLeft: span(1, 'md')
      }
    }
  },
  locationTitle: {
    display: 'block',
    extend: theme.typography.bodySmall,
    opacity: 0.5,
    marginBottom: 10
  },
  locationAddress: {
    margin: 0,
    extend: theme.typography.bodySmall,
    maxWidth: vw(200),
    [theme.breakpoints.up('md')]: {
      maxWidth: vw(200, 'lg')
    }
  },
  locationLink: {
    display: 'inline-block',
    marginLeft: 6,
    '& svg': {
      width: 12,
      height: 12
    }
  },
  phone: {
    display: 'block',
    color: 'currentColor',
    extend: theme.typography.bodySmall,
    marginTop: vw(32),
    [theme.breakpoints.up('md')]: {
      marginTop: vw(32, 'lg')
    }
  }
}, { name: 'Locations' })

export default Locations
