import React from 'react'
import { createUseStyles } from 'react-jss'
import RichText from '../../RichText'
import TwoColumnLayout from './TwoColumnLayout'
import theme, { vw } from '../../../style/theme'

export default ({ slice, page }) => {
  const {
    primary_text: primaryText,
    secondary_text: secondaryText
  } = slice
  const classes = useStyles()

  return (
    <div className={classes.container}>
      <TwoColumnLayout
        leftComponents={
          <div className={classes.leftCol}>
            {secondaryText && <RichText content={secondaryText.text} />}
          </div>
        }
        rightComponents={
          <div className={classes.rightCol}>
            {primaryText && <RichText content={primaryText.text} />}
          </div>
        }
      />
    </div>
  )
}

const useStyles = createUseStyles({
  container: {
    width: '100%',
    marginTop: '-40px',
    '& section': {
      marginBottom: '18px'
    },
    [theme.breakpoints.up('md')]: {
      width: vw(1000, 'lg'),
      marginLeft: 'auto',
      marginRight: theme.getMargin('md')
    }
  },

  leftCol: {
    marginBottom: '-40px'
  },

  rightCol: {
    textAlign: 'justify',
    [theme.breakpoints.up('md')]: {
      margin: [vw(64, 'lg'), 0, vw(40, 'lg'), 0]
    }
  }
}, 'PostTextTwoCol')
