import inDOM from 'dom-helpers/canUseDOM'
import { ActionType } from 'redux-promise-middleware'
import { rehydrated, queryStringChangeCreator, transitionComplete } from '../../actions'

import { pageContentActionCreator } from '../../slices/content/page'
import { pressLoadMoreActionCreator } from '../../slices/content/press'

import { fetchPress } from '../../api'

import {
  getPressListSlice,
  getPressQueryStringArguments
} from '../../slices/content/selectors'

export const ARTICLES_PER_PAGE = 8

export default store => next => action => {
  if (!inDOM) {
    return next(action)
  }
  const ret = next(action)
  const pressListSlice = getPressListSlice(store.getState())
  if (pressListSlice) {
    const { pressPage = 0 } = getPressQueryStringArguments(store.getState())
    if (action.type === rehydrated.toString() ||
      action.type === `${pageContentActionCreator}_${ActionType.Fulfilled}` ||
      action.type === transitionComplete.toString()) {
      fetchPress(store.dispatch, store.getState, pressListSlice.id, {
        page: 0,
        limit: ARTICLES_PER_PAGE * (pressPage + 1)
      })
    }
    if (action.type === pressLoadMoreActionCreator.toString()) {
      store.dispatch(queryStringChangeCreator(store.getState(), { pressPage: pressPage + 1 }))
      fetchPress(store.dispatch, store.getState, pressListSlice.id, {
        page: pressPage + 1,
        limit: ARTICLES_PER_PAGE
      }, true)
    }
  }
  return ret
}
