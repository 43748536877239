import { useContext, useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import { isCurrentBreakpointMobile } from '../../../redux/slices/layout'
import { SmoothScrollContext } from '../../useSmoothScroll'
import afterFrame from '../../../helpers/afterFrame'
import offsetTop from '../../../utils/offsetTop'
import { get } from 'lodash'

const HEADER_OFFSET = 50

export default (containerRef, contentRef) => {
  const locals = useRef({})
  const isMobile = useSelector(isCurrentBreakpointMobile)
  const smoothScrollContext = useContext(SmoothScrollContext)

  useEffect(() => {
    if (isMobile) return
    var animationFrame
    const tick = () => {
      const dimension = contentRef.current.getBoundingClientRect()
      const containerDimension = containerRef.current.getBoundingClientRect()
      if (contentRef.current) {
        const scrollbar = get(smoothScrollContext, ['current', 'scrollbar'])
        const scrollY = scrollbar.scrollTop
        const containerHeight =
          containerDimension.y > 0
            ? containerDimension.y + containerDimension.height
            : containerDimension.height
        if (
          scrollY >= offsetTop(contentRef.current) &&
          scrollY <= containerHeight - dimension.height
        ) {
          contentRef.current.style.transform = `translate3d(0,${
            scrollY - offsetTop(contentRef.current)
          }px,0)`
        } else {
          if (scrollY > containerDimension.y + containerDimension.height) {
            contentRef.current.style.transform = `translate3d(0, ${
              containerDimension.y +
              containerDimension.height -
              dimension?.height / 2
            }, 0)`
          } else {
            contentRef.current.style.transform = 'translate3d(0, 0, 0)'
          }
        }
      }

      afterFrame(() => {
        if (contentRef.current) {
          locals.current = {
            start: offsetTop(containerRef.current) - HEADER_OFFSET,
            end:
              offsetTop(containerRef.current) +
              containerRef.current.offsetHeight -
              contentRef.current.offsetHeight -
              HEADER_OFFSET
          }
        }
      })
      animationFrame = window.requestAnimationFrame(tick)
    }
    animationFrame = window.requestAnimationFrame(tick)
    return () => {
      window.cancelAnimationFrame(animationFrame)
    }
  }, [isMobile])
}
