import map from 'lodash/map'
import keyBy from 'lodash/keyBy'
import filter from 'lodash/filter'
import flatten from 'lodash/flatten'
import isEmpty from 'lodash/isEmpty'
import sortBy from 'lodash/sortBy'
import get from 'lodash/get'
import { setNextPages } from '../slices/nextPages'
import { ActionType } from 'redux-promise-middleware'
import { pageContentActionCreator as pageAction } from '../slices/content/page'
import { getPageSlices } from '../slices/content/selectors'
import { portfoliosContentActionCreator as portfoliosAction } from '../slices/content/portfolios'
import { getPortfolios } from '../slices/content'
import { resolveInternalLinkUrl } from '../../helpers/resolveLink'

const setTeamMemberNextPages = (store) => {
  const slices = getPageSlices(store.getState())
  const teamGrids = filter(slices, x => x.type === 'team_grid_slice')
  if (!isEmpty(teamGrids)) {
    const teamMembers = flatten(map(flatten(map(teamGrids, x => x.groups)), x => x.teamMembers))
    const nextPages = keyBy(
      teamMembers.map((tm, i) => {
        const nextPage = teamMembers[(i + 1) % teamMembers.length]
        // console.log('tm')
        return {
          slug: tm.page?.slug,
          url: resolveInternalLinkUrl(tm.page),
          type: 'teamMember',
          nextPage: {
            url: resolveInternalLinkUrl(nextPage.page),
            title: nextPage.title,
            subtitle: nextPage.position,
            image: nextPage.photo
          }
        }
      }), x => x.slug)
    store.dispatch(setNextPages(nextPages))
  }
}
const setAttendeeNextPages = (store) => {
  const slices = getPageSlices(store.getState())
  const teamGrids = filter(slices, x => x.type === 'attendee_grid_slice')
  if (!isEmpty(teamGrids)) {
    const attendees = flatten(map(flatten(map(teamGrids, x => x.groups)), x => x.attendees))
    const nextPages = keyBy(
      attendees.map((tm, i) => {
        const nextPage = attendees[(i + 1) % attendees.length]
        return {
          slug: tm.page.slug,
          url: resolveInternalLinkUrl(tm.page),
          type: 'attendee',
          nextPage: {
            url: resolveInternalLinkUrl(nextPage.page),
            title: nextPage.title,
            subtitle: nextPage.position,
            image: nextPage.photo
          }
        }
      }), x => x.slug)
    store.dispatch(setNextPages(nextPages))
  }
}

const setStoriesNextPages = (store) => {
  const slices = getPageSlices(store.getState())
  const stories = filter(slices, x => x.type === 'stories')
  if (!isEmpty(stories)) {
    const pages = flatten(map(stories, x => x.stories))
    const nextPages = keyBy(
      pages.map((page, i) => {
        const nextPage = pages[(i + 1) % pages.length]
        return {
          slug: page.slug,
          url: resolveInternalLinkUrl(page),
          type: 'story',
          nextPage: {
            url: resolveInternalLinkUrl(nextPage),
            title: nextPage.title,
            subtitle: nextPage.summary,
            image: nextPage.image
          }
        }
      }), x => x.slug)
    store.dispatch(setNextPages(nextPages))
  }
}

const setCareersNextPages = (store) => {
  const slices = getPageSlices(store.getState())
  const careers = filter(slices, x => x.type === 'career_listing')
  if (!isEmpty(careers)) {
    const pages = sortBy(flatten(map(careers, x => x.careers)), x => x.tag.title)
    const nextPages = keyBy(
      pages.map((career, i) => {
        const nextPage = pages[(i + 1) % pages.length]
        return {
          slug: career.page.slug,
          url: resolveInternalLinkUrl(career.page),
          type: 'career',
          nextPage: {
            slug: nextPage.page.slug,
            url: resolveInternalLinkUrl(nextPage.page),
            title: nextPage.title,
            subtitle: get(nextPage, ['portfolio', 'title'])
          }
        }
      }), x => x.slug)
    store.dispatch(setNextPages(nextPages))
  }
}

const setCaptainsNextPages = (store) => {
  const slices = getPageSlices(store.getState())
  const teamGrids = filter(slices, (x) => x.type === 'captain_grid_slice')
  if (!isEmpty(teamGrids)) {
    const teamMembers = flatten(
      map(flatten(map(teamGrids, (x) => x.groups)), (x) => x.saasCaptains)
    )
    const nextPages = keyBy(
      teamMembers.map((tm, i) => {
        const nextPage = teamMembers[(i + 1) % teamMembers.length]
        return {
          slug: tm.page.slug,
          url: resolveInternalLinkUrl(tm.page),
          type: 'saasCaptain',
          nextPage: {
            url: resolveInternalLinkUrl(nextPage.page),
            title: nextPage.title,
            subtitle: nextPage.position,
            image: nextPage.photo
          }
        }
      }),
      (x) => x.slug
    )
    store.dispatch(setNextPages(nextPages))
  }
}

export default store => next => action => {
  const ret = next(action)
  if (action.type === `${pageAction}_${ActionType.Fulfilled}`) {
    setTeamMemberNextPages(store)
    setAttendeeNextPages(store)
    setStoriesNextPages(store)
    setCareersNextPages(store)
    setCaptainsNextPages(store)
  }
  if (action.type === `${portfoliosAction}_${ActionType.Fulfilled}`) {
    const portfolios = getPortfolios(store.getState())
    const nextPages = keyBy(map(portfolios, (item, i) => {
      const nextPage = portfolios[(i + 1) % portfolios.length]
      return {
        slug: item.page.slug,
        url: resolveInternalLinkUrl(item.page),
        type: 'portfolio',
        nextPage: {
          url: resolveInternalLinkUrl(nextPage.page),
          title: nextPage.title,
          subtitle: nextPage.short_description,
          image: nextPage.heroImage
        }
      }
    }), x => x.slug)
    store.dispatch(setNextPages(nextPages))
  }
  return ret
}
