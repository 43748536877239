import React, { useMemo } from 'react'
import FullWidthImage from '../FullWidthImage'
import { resolveInternalLink } from '../../helpers/resolveLink'
import map from 'lodash/map'
import { createUseStyles } from 'react-jss'
import theme from '../../style/theme'

const Item = ({ classes, story }) => {
  const { image, title, summary } = story
  const link = useMemo(() => resolveInternalLink(story), [story])
  return (
    <FullWidthImage
      image={image}
      title={title}
      copy={summary}
      link={link}
      linkText={<span>Read the <span className={classes.highlight}>{title} Story</span></span>}
    />
  )
}

export default ({ slice: { stories } }) => {
  const classes = useStyles()
  return (
    <section className={classes.section}>
      {map(stories, story => (
        <Item key={story.id} story={story} classes={classes} />
      ))}
    </section>
  )
}

const useStyles = createUseStyles({
  section: { },
  highlight: {
    color: theme.colors.primary
  }
})
