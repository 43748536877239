import React, { useMemo, forwardRef } from 'react'
import { createUseStyles } from 'react-jss'
import BlockContent from '@sanity/block-content-to-react'
import map from 'lodash/map'
import merge from 'lodash/merge'
import theme from '../style/theme'

const serializers = (normalTag = React.Fragment, className) => ({
  marks: {
    highlight: ({ mark, children }) => {
      const classes = useStyles()
      return <span className={classes.hightlight}>{children}</span>
    }
  },
  types: {
    block: (props) => {
      const style = props.node.style || 'normal'

      if (/^h\d/.test(style)) {
        return React.createElement(style, { className }, props.children)
      }

      return style === 'blockquote'
        ? React.createElement('blockquote', { className }, props.children)
        : React.createElement(normalTag, null, props.children)
    }
  }
})

const HighlightedText = forwardRef(({ content, serializers: extraSerializers, children }, ref) => {
  const allSerializers = useMemo(() => {
    if (extraSerializers) {
      return merge({}, serializers(), extraSerializers)
    }
    return serializers()
  }, [extraSerializers])
  const blocks = useMemo(() => map(content, block => ({
    _type: block.type, // BlockContent expects `_type` prop, not `type`.
    ...block
  })))
  return (
    <BlockContent blocks={blocks} serializers={allSerializers} ref={ref} />
  )
})

const useStyles = createUseStyles({
  hightlight: {
    color: theme.colors.primary
  }
}, { name: 'HighlightedText' })

export default HighlightedText
