import inDOM from 'dom-helpers/canUseDOM'
import { ActionType } from 'redux-promise-middleware'
import {
  rehydrated,
  queryStringChangeCreator,
  transitionComplete
} from '../../actions'

import { pageContentActionCreator } from '../../slices/content/page'
import {
  futureMapsChangeInitiativeActionCreator,
  futureMapsLoadMoreActionCreator
} from '../../slices/content/futureMaps'

import {
  getFutureMapsListSlice,
  getFutureMapsQueryStringArguments,
  getFeaturedFutureMapsListSlice,
  getFeaturedFutureMapsSlice,
  getRelatedFutureMapsListSlice,
  getAiElevationListSlice
} from '../../slices/content/selectors'
import { fetchFutureMaps, fetchRelatedFutureMaps } from '../../api'
import { relatedFutureMapsLoadMoreActionCreator } from '../../slices/content/relatedFutureMaps'

export const POSTS_PER_PAGE = 6

export default (store) => (next) => (action) => {
  if (!inDOM) {
    return next(action)
  }
  const ret = next(action)
  const postListSlice = getFutureMapsListSlice(store.getState())
  const AiListSlice = getAiElevationListSlice(store.getState())
  const relatedFutureMapsListSlice = getRelatedFutureMapsListSlice(
    store.getState()
  )

  if (postListSlice) {
    const { futureMapsCategory, futureMapsTags } = postListSlice
    const { postPage = 0, futureMapsTag } = getFutureMapsQueryStringArguments(
      store.getState()
    )
    const hasCategory = futureMapsCategory && futureMapsCategory.id
    const matchingTag =
      futureMapsTag &&
      futureMapsTags &&
      futureMapsTags.find((initiative) => initiative.title === futureMapsTag)

    let omittedPostIds = []

    const featuredPostSlice = getFeaturedFutureMapsSlice(store.getState())

    if (featuredPostSlice) {
      omittedPostIds.push(featuredPostSlice.featured_post.id)
    }
    const featuredPostListSlice = getFeaturedFutureMapsListSlice(
      store.getState()
    )
    if (featuredPostListSlice) {
      omittedPostIds.push(featuredPostListSlice.primary_post.id)
      omittedPostIds = omittedPostIds.concat(
        featuredPostListSlice.secondary_posts.map((post) => post.id)
      )
    }

    if (
      action.type === rehydrated.toString() ||
      action.type === futureMapsChangeInitiativeActionCreator.toString() ||
      action.type === `${pageContentActionCreator}_${ActionType.Fulfilled}` ||
      action.type === transitionComplete.toString()
    ) {
      fetchFutureMaps(store.dispatch, store.getState, postListSlice.id, {
        page: 0,
        limit: POSTS_PER_PAGE * (postPage + 1),
        futureMapsCategoryId: hasCategory ? futureMapsCategory.id : null,
        futureMapsTagId: matchingTag ? matchingTag.id : null,
        omit: omittedPostIds
      })
    }
    if (action.type === futureMapsLoadMoreActionCreator.toString()) {
      store.dispatch(
        queryStringChangeCreator(store.getState(), { postPage: postPage + 1 })
      )
      fetchFutureMaps(
        store.dispatch,
        store.getState,
        postListSlice.id,
        {
          page: postPage + 1,
          limit: POSTS_PER_PAGE,
          futureMapsCategoryId: hasCategory ? futureMapsCategory.id : null,
          futureMapsTagId: matchingTag ? matchingTag.id : null,
          omit: omittedPostIds
        },
        true
      )
    }
  } else if (AiListSlice) {
    const { futureMapsCategory, futureMapsTags } = AiListSlice
    const { postPage = 0, futureMapsTag } = getFutureMapsQueryStringArguments(
      store.getState()
    )
    const hasCategory = futureMapsCategory && futureMapsCategory.id
    const matchingTag =
      futureMapsTag &&
      futureMapsTags &&
      futureMapsTags.find((initiative) => initiative.title === futureMapsTag)

    let omittedPostIds = []

    const featuredPostSlice = getFeaturedFutureMapsSlice(store.getState())

    if (featuredPostSlice) {
      omittedPostIds.push(featuredPostSlice.featured_post.id)
    }
    const featuredPostListSlice = getFeaturedFutureMapsListSlice(
      store.getState()
    )
    if (featuredPostListSlice) {
      omittedPostIds.push(featuredPostListSlice.primary_post.id)
      omittedPostIds = omittedPostIds.concat(
        featuredPostListSlice.secondary_posts.map((post) => post.id)
      )
    }

    if (
      action.type === rehydrated.toString() ||
      action.type === futureMapsChangeInitiativeActionCreator.toString() ||
      action.type === `${pageContentActionCreator}_${ActionType.Fulfilled}` ||
      action.type === transitionComplete.toString()
    ) {
      fetchFutureMaps(store.dispatch, store.getState, AiListSlice.id, {
        page: 0,
        limit: POSTS_PER_PAGE * (postPage + 1),
        futureMapsCategoryId: hasCategory ? futureMapsCategory.id : null,
        futureMapsTagId: matchingTag ? matchingTag.id : null,
        omit: omittedPostIds
      })
    }
    if (action.type === futureMapsLoadMoreActionCreator.toString()) {
      store.dispatch(
        queryStringChangeCreator(store.getState(), { postPage: postPage + 1 })
      )
      fetchFutureMaps(
        store.dispatch,
        store.getState,
        AiListSlice.id,
        {
          page: postPage + 1,
          limit: POSTS_PER_PAGE,
          futureMapsCategoryId: hasCategory ? futureMapsCategory.id : null,
          futureMapsTagId: matchingTag ? matchingTag.id : null,
          omit: omittedPostIds
        },
        true
      )
    }
  } else if (relatedFutureMapsListSlice) {
    const { postPage = 0 } = getFutureMapsQueryStringArguments(store.getState())

    const omittedPostIds = []

    if (
      action.type === rehydrated.toString() ||
      action.type === `${pageContentActionCreator}_${ActionType.Fulfilled}` ||
      action.type === transitionComplete.toString()
    ) {
      fetchRelatedFutureMaps(store.dispatch, store.getState, null, {
        page: 0,
        limit: POSTS_PER_PAGE * (postPage + 1),
        omit: omittedPostIds
      })
    }
    if (action.type === relatedFutureMapsLoadMoreActionCreator.toString()) {
      store.dispatch(
        queryStringChangeCreator(store.getState(), { postPage: postPage + 1 })
      )
      fetchRelatedFutureMaps(
        store.dispatch,
        store.getState,
        null,
        {
          page: postPage + 1,
          limit: POSTS_PER_PAGE,
          omit: omittedPostIds
        },
        true
      )
    }
  }
  return ret
}
