import React from 'react'
import { createUseStyles } from 'react-jss'
import theme from '../style/theme'
import loader from '../images/loading.gif'

const Loading = ({ invert }) => {
  const classes = useStyles()
  return (
    <div className={classes.loading}>
      <img className={classes.loadingGif} src={loader} alt='loading...' />
    </div>
  )
}

const useStyles = createUseStyles({
  loading: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: [100, theme.getMargin('min')],
    position: 'relative',
    [theme.breakpoints.up('md')]: {
      padding: [100, theme.getMargin('md')]
    }
  },
  loadingGif: {
    height: 87,
    width: 72.5
  }
}, { name: 'Loading' })

export default Loading
