import React, { useRef } from 'react'
import Slices from '../../Slices'
import { createUseStyles } from 'react-jss'
import theme from '../../../style/theme'
import cn from 'classnames'
import PlaybookHeroImage from './PlaybookHeroImage'
import useStickyElement from './PlaybooStickyElement'
import { useSelector } from 'react-redux'
import { isCurrentBreakpointMobile } from '../../../redux/slices/layout'
import ArrowNavigation from '../FieldGuide/ArrowNavigation'
import PlaybookRelatedPost from './PlaybookRelatedPost'
import NewsLetter from '../NewsLetter'
import PlaybookAuthor from './PlaybookAuthor'

const CompletePlaybookSlice = ({ slice, page }) => {
  const isPlaybook = page.type === 'playbook'
  const classes = useStyles({ isPlaybook })
  const { playbookTags } = page

  const sectionRef = useRef()
  const contentRef = useRef()

  useStickyElement(sectionRef, contentRef)
  const isMobile = useSelector(isCurrentBreakpointMobile)

  const slices = slice.playbook_content_slices.filter(
    (slice) => slice.type !== 'bread_crumbs'
  )

  const breadCrumbs = slice.playbook_content_slices.filter(
    (slice) => slice.type === 'bread_crumbs'
  )

  const modalTitle = 'Subscribe To SaaS Compass'
  const modalDescription =
    'Receive new articles and resources directly in your inbox.'

  let related
  if (!isPlaybook) {
    const parentPlaybook = page?.allPlaybooks?.find((book) => {
      const isCurrentChapter = book?.chapter?.find(
        (chapter) => chapter.id === page.id
      )
      if (isCurrentChapter) {
        return true
      }
    })
    related = parentPlaybook?.slices?.find(
      (slice) => slice.type === 'related_playbook_slice'
    )
  }

  return (
    <>
      {isMobile ? (
        <div>
          {isPlaybook && (
            <PlaybookHeroImage slice={{ image: slice.playbook_hero_image }} />
          )}
          {isPlaybook && (
            <h2
              className={
                isPlaybook ? classes.heading : classes.forChapterOnlyInMobile
              }
            >
              {page.title}
            </h2>
          )}
          <div className={classes.breadCrumbsInPlaybook}>
            {breadCrumbs.length > 0 && (
              <ArrowNavigation slice={breadCrumbs[0]} />
            )}
          </div>
          <div className={classes.tagWrapper}>
            {/* <a className={classes.tags} href='/'>SAAS COMPASS</a> */}
            {
              playbookTags.map((tag, i) => (
                <a className={classes.tags} href={tag.slug} key={i}>{tag.title}</a>
              ))
            }
          </div>
          <div>
            <NewsLetter
              title={modalTitle}
              description={modalDescription}
              className={classes.subscribeBtn}
            />
            <PlaybookAuthor page={page} />
          </div>
          <Slices slices={slices} page={page} />
        </div>
      ) : (
        <div>
          <div
            className={cn(
              classes.container,
              slice.mobileOrder === 'reverse'
                ? classes.columnReverse
                : classes.column
            )}
            ref={sectionRef}
          >
            <div className={classes.leftContainer}>
              <div ref={contentRef} className={classes.leftContent}>
                {breadCrumbs.length > 0 && (
                  <div className={classes.breadCrumbsContainer}>
                    <ArrowNavigation slice={breadCrumbs[0]} />
                  </div>
                )}
                <div className={classes.tagWrapper}>
                  {/* <a className={classes.tags} href='/'>SAAS Compass</a> */}
                  {
                    playbookTags && playbookTags.map((tag, i) => (
                      <a className={classes.tags} href={tag.slug} key={i}>{tag.title}</a>
                    ))
                  }
                </div>
                {isPlaybook && (
                  <>
                    <h3 className={classes.heading}>{page.title}</h3>
                  </>
                )}
                <div>
                  <NewsLetter
                    title={modalTitle}
                    description={modalDescription}
                    className={classes.subscribeBtn}
                  />
                  <PlaybookAuthor page={page} />
                </div>
              </div>
            </div>
            <div className={classes.rightContainer}>
              {isPlaybook ? (
                <>
                  <PlaybookHeroImage
                    slice={{ image: slice.playbook_hero_image }}
                    atPlayBookPage='true'
                  />
                </>
              ) : (
                ''
              )}
              <Slices slices={slices} page={page} />
            </div>
          </div>
        </div>
      )}
      {related && <PlaybookRelatedPost slice={related} page={page} />}
    </>
  )
}

const useStyles = createUseStyles(
  {
    breadCrumbsContainer: {
      margin: '0 0 33.5px 0',

      [theme.breakpoints.down('md')]: {
        margin: '0 0 0 25px',
        color: theme.colors.primary
      }
    },
    container: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-between'
    },
    heading: {
      margin: '0 0 33.5px 0',
      [theme.breakpoints.down('md')]: {
        padding: '95px 0 0 25px',
        margin: '0 0 16.5px 0',
        color: theme.colors.primary
      }
    },
    subtitle: {
      margin: '0 0 52px 0',
      [theme.breakpoints.down('md')]: {
        margin: '24px 0 0 25px'
      }
    },
    columnReverse: {
      [theme.breakpoints.down('md')]: {
        flexDirection: 'column-reverse'
      }
    },
    column: {
      [theme.breakpoints.down('md')]: {
        flexDirection: 'column'
      }
    },
    leftContainer: {
      width: '40%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      padding: '130px 100px 0 55.6px',
      [theme.breakpoints.down('lg')]: {
        padding: '130px 60px 0 55.6px'
      },
      [theme.breakpoints.down('md')]: {
        width: '100%',
        padding: '40px 44px 0 25px'
      }
    },
    leftContent: {
      paddingTop: '30px'
    },
    rightContainer: {
      width: '60%',
      paddingTop: '230px',
      [theme.breakpoints.down('md')]: {
        width: '100%',
        paddingTop: '1vw'
      }
    },
    breadCrumbsInPlaybook: {
      [theme.breakpoints.down('md')]: {
        marginTop: ({ isPlaybook }) => (isPlaybook ? '22px' : '103px'),
        marginLeft: '25px'
      }
    },
    subscribeBtn: {
      [theme.breakpoints.down('md')]: {
        display: 'none',
        marginLeft: 0
      }
    },
    forChapterOnlyInMobile: {
      margin: '0 22.2px 33.5px 55.6px',
      [theme.breakpoints.down('md')]: {
        margin: '40px 44px 0 25px',
        color: theme.colors.primary,
        paddingTop: '35px'
      }
    },
    tagWrapper: {
      display: 'flex',
      marginTop: '12px',
      marginBottom: '12px',
      flexWrap: 'wrap',
      [theme.breakpoints.down('md')]: {
        display: 'block',
        marginLeft: '12px'
      }
    },
    tags: {
      padding: [4, 8],
      textDecoration: 'none',
      marginRight: '8px',
      marginBottom: '4px',
      background: theme.colors.primary,
      color: theme.colors.white,
      [theme.breakpoints.down('md')]: {
        padding: [4, 12],
        display: 'inline-block'
      }
    }
  },
  { name: 'CompletePlaybookSlice' }
)

export default CompletePlaybookSlice
