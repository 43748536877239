import { createAction, createSlice } from '@reduxjs/toolkit'
import { ActionType } from 'redux-promise-middleware'

export const requestCustomSubscribeActionCreator = createAction('customNewsletter/request')
export const customSubscribeActionCreator = createAction('customNewsletter/subscribe')

const customNewsletter = createSlice({
  name: 'customNewsletter',
  initialState: {
    busy: false,
    error: null,
    data: null,
    isSubscribed: false
  },
  extraReducers: {
    [requestCustomSubscribeActionCreator.toString()]: (state, action) => {
      state.data = action.payload
    },
    [`${customSubscribeActionCreator}_${ActionType.Pending}`]: (state, action) => {
      state.error = null
      state.busy = true
    },
    [`${customSubscribeActionCreator}_${ActionType.Fulfilled}`]: (state, action) => {
      state.error = null
      state.busy = false
      state.isSubscribed = true
    },
    [`${customSubscribeActionCreator}_${ActionType.Rejected}`]: (state, action) => {
      state.busy = false
      state.error = action.payload
    }
  }
})

export const getCustomNewsletterBusy = state => state.customNewsletter.busy
export const getCustomNewsletterError = state => state.customNewsletter.error
export const getCustomNewsletterIsSubscribed = state => state.customNewsletter.isSubscribed
export const getCustomNewsletterData = state => state.customNewsletter.data

export default customNewsletter.reducer
