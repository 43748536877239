import React, { forwardRef } from 'react'
import { createUseStyles } from 'react-jss'
import cn from 'classnames'
import get from 'lodash/get'
import RichText from '../RichText'
import Button from '../Button'
import theme from '../../style/theme'
import { span } from '../../style/span'

const JobContentSlice = forwardRef(({ className, slice }, ref) => {
  const {
    job_description: jobDescription,
    apply_link: applyLink
  } = slice
  const applyLinkLabel = get(applyLink, '[0].text')
  const classes = useStyles()

  return (
    <section className={cn(classes.content, className)}>
      <div className={classes.cols}>
        <div className={classes.col} />
        <div className={classes.col}>
          <RichText
            ref={ref}
            className={classes.richContent}
            content={jobDescription.text}
          />
          <Button className={classes.applyButton} link={applyLink[0]}>
            <div className={classes.buttonContent}>
              <span>{applyLinkLabel}</span>
              <svg viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <path className='outer' d='M15.771 4.772a.75.75 0 00-.75-.75h-6.75a.75.75 0 000 1.5h6v6a.75.75 0 001.5 0v-6.75zm-7.22 7.53l7-7-1.06-1.06-7 7 1.06 1.06z' />
                <path className='inner' d='M7 6.583H1v12.645h11.471V13' strokeWidth='1.5' />
              </svg>
            </div>
          </Button>
        </div>
      </div>
    </section>
  )
})

const useStyles = createUseStyles({
  content: {
    padding: [50, theme.getMargin('min')],
    position: 'relative',
    [theme.breakpoints.up('md')]: {
      padding: [100, theme.getMargin('md'), 200]
    }
  },
  cols: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row'
    }
  },
  col: {
    display: 'block',
    marginBottom: 50,
    '&:last-child': {
      marginBottom: 0
    },
    [theme.breakpoints.up('md')]: {
      marginBottom: 0,
      flex: 1,
      '&:first-child': {
        maxWidth: span(6, 'md'),
        marginRight: span(2, 'md')
      },
      '&:last-child': {
        maxWidth: span(10, 'md'),
        marginLeft: span(2, 'md')
      }
    }
  },
  applyButton: {
    marginTop: 30,
    [theme.breakpoints.up('md')]: {
      marginTop: 60
    },
    '& svg': {
      width: 15,
      height: 15,
      paddingBottom: 2,
      marginLeft: 15,
      display: 'block',
      [theme.breakpoints.up('md')]: {
        width: 20,
        height: 20,
        marginLeft: 15
      },
      '& .outer': {
        fill: 'currentColor'
      },
      '& .inner': {
        stroke: 'currentColor'
      }
    }
  },
  buttonContent: {
    display: 'inline-flex',
    alignItems: 'center'
  }
}, { name: 'JobContentSlice' })

export default JobContentSlice
