import { up } from './breakpoints'

export const gridConfig = {
  min: {
    cols: 2,
    gutter: 20,
    margin: 25
  },
  md: {
    cols: 24,
    gutter: 25,
    margin: 50
  }
}

export const generateAllGridStyles = () => {
  const breakpoints = Object.keys(gridConfig)
  const gridStyles = []
  for (const bp of breakpoints) {
    gridStyles[up(bp)] = generateGridStyles(bp)
  }
  return gridStyles
}

const generateGridStyles = (bp = 'min') => {
  const grid = gridConfig[bp]
  const lineThickness = '1px'
  const color = 'rgba(255,0,0,0.2)'

  const repeatingWidth = `calc(100% / ${grid.cols})`
  const columnWidth = `calc((100% / ${grid.cols}) - ${grid.gutter}px)`
  const backgroundWidth = `calc(100% + ${grid.gutter}px)`
  const backgroundColumns = `repeating-linear-gradient(
    to right,
    transparent,
    transparent ${lineThickness},
    ${color} ${lineThickness},
    ${color} calc(${columnWidth} - ${lineThickness}),
    transparent calc(${columnWidth} - ${lineThickness}),
    transparent ${columnWidth},
    transparent ${columnWidth},
    transparent ${repeatingWidth}
  )`

  return {
    '&::before': {
      content: '""',
      position: 'fixed',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      margin: {
        right: 'auto',
        left: 'auto'
      },
      width: `calc(100% - ${2 * grid.margin}px)`,
      'min-height': '100vh',
      'background-image': backgroundColumns,
      'background-size': `${backgroundWidth} 100%`,
      'z-index': 10000,
      'pointer-events': 'none'
    }
  }
}
