import React, { useMemo } from 'react'
import cn from 'classnames'
import { createUseStyles } from 'react-jss'
import theme, { span } from '../../../style/theme'
import { vw } from '../../../style/vw'
import ResponsiveImage from '../../ResponsiveImage'
import get from 'lodash/get'
import Link from '../../Link'
import { resolveInternalLinkUrl } from '../../../helpers/resolveLink'
import { quart } from '../../../style/eases'
import Color from 'color'
import isEmpty from 'lodash/isEmpty'
import ArrowIcon from '../../../images/ArrowIcon'
import detectIt from 'detect-it'

export default ({ title, small_logo: smallLogo, heroImage, stage, inception, page, short_description: description, founders, className }) => {
  const classes = useStyles({ logoAspect: get(smallLogo, ['aspect'], 0) })

  const resolvedLink = useMemo(() => page && resolveInternalLinkUrl(page), [page])

  return (
    <li key={title} className={cn(className, classes.listItem)}>
      <Link to={resolvedLink} className={classes.link} nonLinkTag='div'>
        <div className={classes.hoverEffect}>
          {!isEmpty(heroImage) && <ResponsiveImage {...heroImage} className={classes.hoverImage} />}
        </div>
        <div className={classes.titleAndLogo}>
          <div className={classes.logoContainer}><ResponsiveImage {...smallLogo} className={classes.logo} /></div>
          <span className={classes.title}>
            <h4 className={classes.title}>{title}</h4>
            {stage && <span className={classes.subtitle}>{stage}</span>}
          </span>
        </div>

        <span className={classes.description}>{description}</span>
        <span className={classes.founders}>
          <label className={classes.label}>Founders</label>
          {founders}
        </span>
        <span className={classes.stageColumn}>
          <label className={classes.label}>Stage</label>
          {stage}
        </span>
        <span className={classes.arrow}>
          <ArrowIcon />
        </span>
      </Link>
    </li>
  )
}

const useStyles = createUseStyles({
  listItem: {
    fontSize: vw(14),
    borderTop: [1, 'solid', theme.colors.border],
    position: 'relative',
    overflow: 'hidden',
    [theme.breakpoints.up('md')]: {
      fontSize: vw(14, 'lg')
    },
    '&:last-child': {
      borderBottom: [1, 'solid', theme.colors.border]
    }
  },
  link: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    padding: [vw(30), theme.getMargin()],
    textDecoration: 'none',
    color: theme.colors.text,
    margin: [0, vw(-6)],
    overflow: 'hidden',
    [theme.breakpoints.up('md')]: {
      minHeight: vw(180, 'lg'),
      margin: [0, vw(-30, 'vw')],
      padding: vw(30, 'lg')
    },
    '& > *': {
      position: 'relative',
      margin: [0, vw(6)],
      [theme.breakpoints.up('md')]: {
        marginRight: [0, vw(30, 'vw')]
      }
    },
    transition: `color 0.25s ${quart.inOut}, background-color 0.25s ${quart.inOut}`,
    ...(detectIt.primaryInput === 'touch'
      ? {
        '&:hover': {
          color: theme.colors.white,
          backgroundColor: theme.colors.primary
        }
      }
      : {
        '&:hover': {
          color: theme.colors.white
        }
      })
  },
  titleAndLogo: {
    display: 'flex',
    alignItems: 'center',
    width: '50%',
    [theme.breakpoints.up('md')]: {
      flexGrow: 1,
      flexShrink: 1,
      width: span(8, 'md')
    }
  },
  label: {
    display: 'block',
    opacity: 0.5
  },
  logoContainer: {
    display: 'none',
    width: vw(40),
    height: vw(40),
    borderRadius: '50%',
    overflow: 'hidden',
    alignItems: 'center',
    justifyContent: 'center',
    border: [1, 'solid', theme.colors.border],
    flexShrink: 0,
    flexGrow: 0,
    marginRight: vw(12),
    backgroundColor: Color(theme.colors.white).alpha(0.2).string(),
    [theme.breakpoints.up('md')]: {
      marginRight: vw(60, 'vw'),
      width: vw(80, 'lg'),
      height: vw(80, 'lg')
    }
  },
  logo: {
    width: ({ logoAspect }) => logoAspect > 1 ? '100%' : `${logoAspect * 100}%`,
    height: ({ logoAspect }) => logoAspect > 1 ? `${logoAspect * 100}%` : '100%'
  },
  title: {
    fontSize: vw(20),
    [theme.breakpoints.up('md')]: {
      fontSize: vw(50, 'lg')
    }
  },
  subtitle: {
    fontSize: vw(12),
    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  },
  description: {
    width: '50%',
    [theme.breakpoints.up('md')]: {
      width: span(6, 'md')
    }
  },
  desktopOnly: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'block'
    }
  },
  founders: {
    composes: ['$desktopOnly'],
    whiteSpace: 'pre-wrap',
    [theme.breakpoints.up('md')]: {
      width: span(5, 'md')
    }
  },
  stageColumn: {
    composes: ['$desktopOnly'],
    [theme.breakpoints.up('md')]: {
      width: span(4, 'md')
    }
  },
  arrow: {
    composes: ['$desktopOnly'],
    textAlign: 'right',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      width: span(1, 'md')
    }
  },
  hoverEffect: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: -2,
    backgroundColor: theme.colors.primary,
    transform: 'translateY(100%)',
    transition: `transform 0.25s ${quart.inOut}`,
    ...(detectIt.primaryInput === 'touch' ? { } : {
      '$link:hover &': {
        transform: 'translateY(0%)'
      }
    })
  },
  hoverImage: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    '&:after': {
      content: '""',
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      backgroundColor: 'rgba(34, 34, 34, 0.7)'
    }
  }
}, { name: 'PortfolioList' })
