import React, { useCallback, useContext, useRef, useState } from 'react'
import { createUseStyles } from 'react-jss'
import RightSideText from './RightSideText'
import PostImgCom from './PostImgCom'
import useStickyElement from '../../../hooks/useStickyElement'
import theme from '../../../style/theme'
import { SmoothScrollContext } from '../../useSmoothScroll'
import useIntersectionObserverTopRef from '../../../hooks/useIntersectionObserverTopRef'

export default ({ slice }) => {
  const [activeNavItem, setActiveNavItem] = useState(false)
  const {
    post_text_right_one: postTextRightOne,
    post_text_right: postTextRightFirst,
    post_text_right_second: postTextRightSecond,
    post_text_right_third: postTextRightThird,
    post_images: postImages,
    post_images_second: postImagesSecond,
    post_images_third: postImagesThird,
    tableTitle,
    post_text_right_four: postTextRightFour,
    post_text_right_five: postTextRightFive,
    post_text_right_six: postTextRightSix,
    post_text_right_seven: postTextRightSeven,
    post_text_right_eight: postTextRightEight,
    post_text_right_nine: postTextRightNine,
    post_text_right_ten: postTextRightTen,
    post_images_four: postImagesFour,
    post_images_five: postImagesFive,
    post_images_six: postImagesSix,
    post_images_seven: postImagesSeven,
    post_images_eight: postImagesEight,
    post_images_nine: postImagesNine,
    post_images_ten: postImagesTen
  } = slice
  const { primary_text: primaryTextOne, tableHeadingOne } = postTextRightOne || ''
  const { primary_text: primaryTextFirst, tableHeadingFirst } = postTextRightFirst || ''
  const { primary_text: primaryTextSecond, tableHeadingSecond } = postTextRightSecond || ''
  const { primary_text: primaryTextThird, tableHeadingThird } = postTextRightThird || ''
  const { primary_text: primaryTextFour, tableHeadingFour } = postTextRightFour || ''
  const { primary_text: primaryTextFive, tableHeadingFive } = postTextRightFive || ''
  const { primary_text: primaryTextSix, tableHeadingSix } = postTextRightSix || ''
  const { primary_text: primaryTextSeven, tableHeadingSeven } = postTextRightSeven || ''
  const { primary_text: primaryTextEight, tableHeadingEight } = postTextRightEight || ''
  const { primary_text: primaryTextNine, tableHeadingNine } = postTextRightNine || ''
  const { primary_text: primaryTextTen, tableHeadingTen } = postTextRightTen || ''
  const postImage = postImages || ''
  const { tableHeadingImage } = postImage
  const postImageSecond = postImagesSecond || ''
  const { tableHeadingImageSecond } = postImageSecond
  const postImageThird = postImagesThird || ''
  const { tableHeadingImageThird } = postImageThird
  const postImageFour = postImagesFour || ''
  const { tableHeadingImageFour } = postImageFour
  const postImageFive = postImagesFive || ''
  const { tableHeadingImageFive } = postImageFive
  const postImageSix = postImagesSix || ''
  const { tableHeadingImageSix } = postImageSix
  const postImageSeven = postImagesSeven || ''
  const { tableHeadingImageSeven } = postImageSeven
  const postImageEight = postImagesEight || ''
  const { tableHeadingImageEight } = postImageEight
  const postImageNine = postImagesNine || ''
  const { tableHeadingImageNine } = postImageNine
  const postImageTen = postImagesTen || ''
  const { tableHeadingImageTen } = postImageTen
  const first = primaryTextFirst || ''
  const second = primaryTextSecond || ''
  const third = primaryTextThird || ''
  const one = primaryTextOne || ''
  const four = primaryTextFour || ''
  const five = primaryTextFive || ''
  const six = primaryTextSix || ''
  const seven = primaryTextSeven || ''
  const eight = primaryTextEight || ''
  const nine = primaryTextNine || ''
  const ten = primaryTextTen || ''
  const classes = useStyles()
  const sectionRef = useRef()
  const contentRef = useRef()
  const tableHeadingOneRef = useRef()
  const tableHeadingFirstRef = useRef()
  const tableHeadingSecondRef = useRef()
  const tableHeadingThirdRef = useRef()
  const tableHeadingFourRef = useRef()
  const tableHeadingFiveRef = useRef()
  const tableHeadingSixRef = useRef()
  const tableHeadingSevenRef = useRef()
  const tableHeadingEightRef = useRef()
  const tableHeadingNineRef = useRef()
  const tableHeadingTenRef = useRef()
  const tableImageFirstRef = useRef()
  const tableImageSecondRef = useRef()
  const tableImageThirdRef = useRef()
  const tableImageFourRef = useRef()
  const tableImageFiveRef = useRef()
  const tableImageSixRef = useRef()
  const tableImageEightRef = useRef()
  const tableImageSevenRef = useRef()
  const tableImageNineRef = useRef()
  const tableImageTenRef = useRef()
  useStickyElement(sectionRef, contentRef)
  const scrollContext = useContext(SmoothScrollContext)

  const scrollToOneHeadingCallback = useCallback(() => {
    if (tableHeadingOneRef.current) {
      scrollContext.current.setPageYOffset(tableHeadingOneRef.current.offsetTop - 100, 1000)
    }
  }, [scrollContext.current])

  const scrollToFirstHeadingCallback = useCallback(() => {
    if (tableHeadingFirstRef.current) {
      scrollContext.current.setPageYOffset(tableHeadingFirstRef.current.offsetTop - 100, 1000)
    }
  }, [scrollContext.current])

  const scrollToTwoHeadingCallback = useCallback(() => {
    if (tableHeadingSecondRef.current) {
      scrollContext.current.setPageYOffset(tableHeadingSecondRef.current.offsetTop - 100, 1000)
    }
  }, [scrollContext.current])

  const scrollToThirdHeadingCallback = useCallback(() => {
    if (tableHeadingThirdRef.current) {
      scrollContext.current.setPageYOffset(tableHeadingThirdRef.current.offsetTop - 100, 1000)
    }
  }, [scrollContext.current])

  const scrollToFourHeadingCallback = useCallback(() => {
    if (tableHeadingFourRef.current) {
      scrollContext.current.setPageYOffset(tableHeadingFourRef.current.offsetTop - 100, 1000)
    }
  }, [scrollContext.current])

  const scrollToFiveHeadingCallback = useCallback(() => {
    if (tableHeadingFiveRef.current) {
      scrollContext.current.setPageYOffset(tableHeadingFiveRef.current.offsetTop - 100, 1000)
    }
  }, [scrollContext.current])

  const scrollToSixHeadingCallback = useCallback(() => {
    if (tableHeadingSixRef.current) {
      scrollContext.current.setPageYOffset(tableHeadingSixRef.current.offsetTop - 100, 1000)
    }
  }, [scrollContext.current])

  const scrollToSevenHeadingCallback = useCallback(() => {
    if (tableHeadingSevenRef.current) {
      scrollContext.current.setPageYOffset(tableHeadingSevenRef.current.offsetTop - 100, 1000)
    }
  }, [scrollContext.current])

  const scrollToEightHeadingCallback = useCallback(() => {
    if (tableHeadingEightRef.current) {
      scrollContext.current.setPageYOffset(tableHeadingEightRef.current.offsetTop - 100, 1000)
    }
  }, [scrollContext.current])

  const scrollToNineHeadingCallback = useCallback(() => {
    if (tableHeadingNineRef.current) {
      scrollContext.current.setPageYOffset(tableHeadingNineRef.current.offsetTop - 100, 1000)
    }
  }, [scrollContext.current])

  const scrollToTenHeadingCallback = useCallback(() => {
    if (tableHeadingTenRef.current) {
      scrollContext.current.setPageYOffset(tableHeadingTenRef.current.offsetTop - 100, 1000)
    }
  }, [scrollContext.current])

  const scrollToFirstImageCallback = useCallback(() => {
    if (tableImageFirstRef.current) {
      scrollContext.current.setPageYOffset(tableImageFirstRef.current.offsetTop - 100, 1000)
    }
  }, [scrollContext.current])

  const scrollToSecondImageCallback = useCallback(() => {
    if (tableImageSecondRef.current) {
      scrollContext.current.setPageYOffset(tableImageSecondRef.current.offsetTop - 100, 1000)
    }
  }, [scrollContext.current])

  const scrollToThirdImageCallback = useCallback(() => {
    if (tableImageThirdRef.current) {
      scrollContext.current.setPageYOffset(tableImageThirdRef.current.offsetTop - 100, 1000)
    }
  }, [scrollContext.current])

  const scrollToFourImageCallback = useCallback(() => {
    if (tableImageFourRef.current) {
      scrollContext.current.setPageYOffset(tableImageFourRef.current.offsetTop - 100, 1000)
    }
  }, [scrollContext.current])

  const scrollToFiveImageCallback = useCallback(() => {
    if (tableImageFiveRef.current) {
      scrollContext.current.setPageYOffset(tableImageFiveRef.current.offsetTop - 100, 1000)
    }
  }, [scrollContext.current])

  const scrollToSixImageCallback = useCallback(() => {
    if (tableImageSixRef.current) {
      scrollContext.current.setPageYOffset(tableImageSixRef.current.offsetTop - 100, 1000)
    }
  }, [scrollContext.current])

  const scrollToSevenImageCallback = useCallback(() => {
    if (tableImageSevenRef.current) {
      scrollContext.current.setPageYOffset(tableImageSevenRef.current.offsetTop - 100, 1000)
    }
  }, [scrollContext.current])

  const scrollToEightImageCallback = useCallback(() => {
    if (tableImageEightRef.current) {
      scrollContext.current.setPageYOffset(tableImageEightRef.current.offsetTop - 100, 1000)
    }
  }, [scrollContext.current])

  const scrollToNineImageCallback = useCallback(() => {
    if (tableImageNineRef.current) {
      scrollContext.current.setPageYOffset(tableImageNineRef.current.offsetTop - 100, 1000)
    }
  }, [scrollContext.current])

  const scrollToTenImageCallback = useCallback(() => {
    if (tableImageTenRef.current) {
      scrollContext.current.setPageYOffset(tableImageTenRef.current.offsetTop - 100, 1000)
    }
  }, [scrollContext.current])

  const handleIntersection = (entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        if (entry.target === tableHeadingOneRef.current) {
          setActiveNavItem('tableHeadingOne')
        }
        if (entry.target === tableImageFirstRef.current) {
          setActiveNavItem('tableHeadingFirstImg')
        }
        if (entry.target === tableHeadingFirstRef.current) {
          setActiveNavItem('tableHeadingFirst')
        }
        if (entry.target === tableImageSecondRef.current) {
          setActiveNavItem('tableSecondImg')
        }
        if (entry.target === tableHeadingSecondRef.current) {
          setActiveNavItem('tableSecondHeading')
        }
        if (entry.target === tableImageThirdRef.current) {
          setActiveNavItem('tableThirdImg')
        }
        if (entry.target === tableHeadingThirdRef.current) {
          setActiveNavItem('tableHeadingThird')
        }
        if (entry.target === tableImageFourRef.current) {
          setActiveNavItem('tableHeadingFourImg')
        }
        if (entry.target === tableHeadingFourRef.current) {
          setActiveNavItem('tableHeadingFour')
        }
        if (entry.target === tableImageFiveRef.current) {
          setActiveNavItem('tableFiveImg')
        }
        if (entry.target === tableHeadingFiveRef.current) {
          setActiveNavItem('tableFiveHeading')
        }
        if (entry.target === tableImageSixRef.current) {
          setActiveNavItem('tableSixImg')
        }
        if (entry.target === tableHeadingSixRef.current) {
          setActiveNavItem('tableHeadingSix')
        }
        if (entry.target === tableImageSevenRef.current) {
          setActiveNavItem('tableHeadingSevenImg')
        }
        if (entry.target === tableHeadingSevenRef.current) {
          setActiveNavItem('tableHeadingSeven')
        }
        if (entry.target === tableImageEightRef.current) {
          setActiveNavItem('tableEightImg')
        }
        if (entry.target === tableHeadingEightRef.current) {
          setActiveNavItem('tableEightHeading')
        }
        if (entry.target === tableImageNineRef.current) {
          setActiveNavItem('tableNineImg')
        }
        if (entry.target === tableHeadingNineRef.current) {
          setActiveNavItem('tableHeadingNine')
        }
        if (entry.target === tableImageTenRef.current) {
          setActiveNavItem('tableTenImg')
        }
        if (entry.target === tableHeadingTenRef.current) {
          setActiveNavItem('tableHeadingTen')
        }
      }
    })
  }

  const refsToObserve = [tableHeadingOneRef, tableImageFirstRef, tableHeadingFirstRef, tableImageSecondRef, tableImageSecondRef, tableImageThirdRef, tableHeadingThirdRef, tableHeadingFourRef, tableHeadingFiveRef, tableImageSixRef, tableHeadingSevenRef, tableHeadingEightRef, tableHeadingNineRef, tableHeadingTenRef, tableImageFourRef, tableImageFiveRef, tableImageSixRef, tableImageSevenRef, tableImageEightRef, tableImageNineRef, tableImageTenRef]
  const threshold = {
    root: null,
    marginTop: '0px',
    threshold: 0.8
  }

  useIntersectionObserverTopRef(refsToObserve, threshold, handleIntersection)

  return (
    <div className={classes.container} ref={sectionRef}>
      <div className={classes.leftContainer} ref={contentRef}>
        <h4 className={classes.heading}>{tableTitle}</h4>
        <div className={classes.navigateContainer}>
          {tableHeadingOne && <p className={activeNavItem === 'tableHeadingOne' ? classes.active : ''} onClick={scrollToOneHeadingCallback}>{tableHeadingOne}</p>}
          {tableHeadingImage && <p className={activeNavItem === 'tableHeadingFirstImg' ? classes.active : ''} onClick={scrollToFirstImageCallback}>{tableHeadingImage}</p>}
          {tableHeadingFirst && <p className={activeNavItem === 'tableHeadingFirst' ? classes.active : ''} onClick={scrollToFirstHeadingCallback}>{tableHeadingFirst}</p>}
          {tableHeadingImageSecond && <p className={activeNavItem === 'tableSecondImg' ? classes.active : ''} onClick={scrollToSecondImageCallback}>{tableHeadingImageSecond}</p>}
          {tableHeadingSecond && <p className={activeNavItem === 'tableSecondHeading' ? classes.active : ''} onClick={scrollToTwoHeadingCallback}>{tableHeadingSecond}</p>}
          {tableHeadingImageThird && <p className={activeNavItem === 'tableThirdImg' ? classes.active : ''} onClick={scrollToThirdImageCallback}>{tableHeadingImageThird}</p>}
          {tableHeadingThird && <p className={activeNavItem === 'tableHeadingThird' ? classes.active : ''} onClick={scrollToThirdHeadingCallback}>{tableHeadingThird}</p>}
          {tableHeadingImageFour && <p className={activeNavItem === 'tableHeadingFourImg' ? classes.active : ''} onClick={scrollToFourImageCallback}>{tableHeadingImageFour}</p>}
          {tableHeadingFour && <p className={activeNavItem === 'tableHeadingFour' ? classes.active : ''} onClick={scrollToFourHeadingCallback}>{tableHeadingFour}</p>}
          {tableHeadingImageFive && <p className={activeNavItem === 'tableFiveImg' ? classes.active : ''} onClick={scrollToFiveImageCallback}>{tableHeadingImageFive}</p>}
          {tableHeadingFive && <p className={activeNavItem === 'tableFiveHeading' ? classes.active : ''} onClick={scrollToFiveHeadingCallback}>{tableHeadingFive}</p>}
          {tableHeadingImageSix && <p className={activeNavItem === 'tableSixImg' ? classes.active : ''} onClick={scrollToSixImageCallback}>{tableHeadingImageSix}</p>}
          {tableHeadingSix && <p className={activeNavItem === 'tableHeadingSix' ? classes.active : ''} onClick={scrollToSixHeadingCallback}>{tableHeadingSix}</p>}
          {tableHeadingImageSeven && <p className={activeNavItem === 'tableHeadingSevenImg' ? classes.active : ''} onClick={scrollToSevenImageCallback}>{tableHeadingImageSeven}</p>}
          {tableHeadingSeven && <p className={activeNavItem === 'tableHeadingSeven' ? classes.active : ''} onClick={scrollToSevenHeadingCallback}>{tableHeadingSeven}</p>}
          {tableHeadingImageEight && <p className={activeNavItem === 'tableEightImg' ? classes.active : ''} onClick={scrollToEightImageCallback}>{tableHeadingImageEight}</p>}
          {tableHeadingEight && <p className={activeNavItem === 'tableEightHeading' ? classes.active : ''} onClick={scrollToEightHeadingCallback}>{tableHeadingEight}</p>}
          {tableHeadingImageNine && <p className={activeNavItem === 'tableNineImg' ? classes.active : ''} onClick={scrollToNineImageCallback}>{tableHeadingImageNine}</p>}
          {tableHeadingNine && <p className={activeNavItem === 'tableHeadingNine' ? classes.active : ''} onClick={scrollToNineHeadingCallback}>{tableHeadingNine}</p>}
          {tableHeadingImageTen && <p className={activeNavItem === 'tableTenImg' ? classes.active : ''} onClick={scrollToTenImageCallback}>{tableHeadingImageTen}</p>}
          {tableHeadingTen && <p className={activeNavItem === 'tableHeadingTen' ? classes.active : ''} onClick={scrollToTenHeadingCallback}>{tableHeadingTen}</p>}
        </div>
      </div>
      <div>
        {one &&
          <div ref={tableHeadingOneRef}>
            <RightSideText text={one.text} />
          </div>}
        {postImage &&
          <div ref={tableImageFirstRef}>
            <PostImgCom slice={postImage} />
          </div>}
        {first &&
          <div ref={tableHeadingFirstRef}>
            <RightSideText text={first.text} />
          </div>}
        {postImageSecond &&
          <div ref={tableImageSecondRef}>
            <PostImgCom slice={postImageSecond} />
          </div>}
        {second &&
          <div ref={tableHeadingSecondRef}>
            <RightSideText text={second.text} />
          </div>}
        {postImageThird &&
          <div ref={tableImageThirdRef}>
            <PostImgCom slice={postImageThird} />
          </div>}
        {third &&
          <div ref={tableHeadingThirdRef}>
            <RightSideText text={third.text} />
          </div>}
        {postImageFour &&
          <div ref={tableImageFourRef}>
            <PostImgCom slice={postImageFour} />
          </div>}
        {four &&
          <div ref={tableHeadingFourRef}>
            <RightSideText text={four.text} />
          </div>}
        {postImageFive &&
          <div ref={tableImageFiveRef}>
            <PostImgCom slice={postImageFive} />
          </div>}
        {five &&
          <div ref={tableHeadingFiveRef}>
            <RightSideText text={five.text} />
          </div>}
        {postImageSix &&
          <div ref={tableImageSixRef}>
            <PostImgCom slice={postImageSix} />
          </div>}
        {six &&
          <div ref={tableHeadingSixRef}>
            <RightSideText text={six.text} />
          </div>}
        {postImageSeven &&
          <div ref={tableImageSevenRef}>
            <PostImgCom slice={postImageSeven} />
          </div>}
        {seven &&
          <div ref={tableHeadingSevenRef}>
            <RightSideText text={seven.text} />
          </div>}
        {postImageEight &&
          <div ref={tableImageEightRef}>
            <PostImgCom slice={postImageEight} />
          </div>}
        {eight &&
          <div ref={tableHeadingEightRef}>
            <RightSideText text={eight.text} />
          </div>}
        {postImageNine &&
          <div ref={tableImageNineRef}>
            <PostImgCom slice={postImageNine} />
          </div>}
        {nine &&
          <div ref={tableHeadingNineRef}>
            <RightSideText text={nine.text} />
          </div>}
        {postImageTen &&
          <div ref={tableImageTenRef}>
            <PostImgCom slice={postImageTen} />
          </div>}
        {ten &&
          <div ref={tableHeadingTenRef}>
            <RightSideText text={ten.text} />
          </div>}
      </div>
    </div>
  )
}

const useStyles = createUseStyles({
  container: {
    marginTop: '10px',
    display: 'flex'
  },
  leftContainer: {
    width: '25%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    background: theme.colors.white,
    boxShadow: '5px 4px 32px rgba(201, 201, 201, 0.17)',
    padding: '48px 28px',
    gap: '32px',
    marginLeft: '50px',
    '& p': {
      marginBottom: 0,
      cursor: 'pointer',
      fontStyle: 'italic'
    },
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  },
  heading: {
    fontFamily: 'Object Sans',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '24px',
    lineHeight: '25px',
    letterSpacing: '0.03em',
    textTransform: 'capitalize'
  },
  navigateContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '14px'
  },
  active: {
    color: theme.colors.primary
  }
}, 'PostTextNew')
