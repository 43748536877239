import React, { useMemo } from 'react'
import { createUseStyles } from 'react-jss'
import { resolveLink } from '../../helpers/resolveLink'
import theme, { vw } from '../../style/theme'
import RichText from '../RichText'

export default ({ slice }) => {
  const classes = useStyles()
  const { pdf, primary_text: primaryText } = slice
  const resolvedFile = useMemo(() => pdf && pdf.file ? resolveLink(pdf.file) : null, [pdf])
  const text = primaryText || ''
  console.log(text)

  return (
    <section className={classes.section}>
      <RichText className={classes.para} content={text.text} />
      <embed className={classes.pdfWrapper} src={resolvedFile.url} type='application/pdf' width='100%' />
    </section>
  )
}

const useStyles = createUseStyles({
  section: {
    width: '100%',
    padding: '0 20px',
    margin: '20px 0',
    [theme.breakpoints.up('md')]: {
      padding: '0 50px',
      width: vw(1000, 'lg'),
      marginLeft: 'auto',
      marginRight: theme.getMargin('md')
    }
  },
  para: {
    marginBottom: '10px'
  },
  pdfWrapper: {
    height: '300x',
    [theme.breakpoints.up('md')]: {
      height: '550px'
    }
  }
}, { name: 'Document' })
