import React, { useCallback } from 'react'
import DropDown from '../../DropDown'

const DropdownFilters = ({ options, value, setValue, className }) => {
  const onChange = useCallback(({ target }) => {
    setValue(target.value)
  }, [setValue])

  return (
    <DropDown className={className} options={options} onChange={onChange} value={value} placeholderText='More...' />
  )
}

export default DropdownFilters
