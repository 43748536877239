import { oneLineTrim } from 'common-tags'

export const unquotedBodyFace = 'Object Sans'
export const bodyFace = `"${unquotedBodyFace}"`
export const body = `${bodyFace}, "Lucida Sans", Tahoma, sans-serif`
export const bodyFontWeight = 400
export const bodyBoldFontWeight = 700

export const unquotedHeadingsFace = 'Mars Condensed'
export const headingsFace = `"${unquotedHeadingsFace}"`
export const headings = `${headingsFace}, Arial`
export const headingsFontWeight = 400

export const preload = [
  require('./ObjectSans-Bold.woff2'),
  require('./ObjectSans-Regular.woff2'),
  require('./marscondensedweb-regular.woff')
].filter(item => !item.startsWith('data:'))

export const faces = [
  {
    fontFamily: bodyFace,
    src: oneLineTrim`
      url('${require('./ObjectSans-Regular.woff2')}') format('woff2'),
      url('${require('./ObjectSans-Regular.woff')}') format('woff')`,
    fontWeight: bodyFontWeight,
    fontStyle: 'normal'
  }, {
    fontFamily: bodyFace,
    src: oneLineTrim`
      url('${require('./ObjectSans-Bold.woff2')}') format('woff2'),
      url('${require('./ObjectSans-Bold.woff')}') format('woff')`,
    fontWeight: bodyBoldFontWeight,
    fontStyle: 'normal'
  }, {
    fontFamily: headingsFace,
    src: oneLineTrim`
      url('${require('./marscondensedweb-regular.woff2')}') format('woff2'),
      url('${require('./marscondensedweb-regular.woff')}') format('woff')`,
    fontWeight: headingsFontWeight,
    fontStyle: 'normal'
  }
]
