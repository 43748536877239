import React, { useCallback, useState } from 'react'
import { createUseStyles } from 'react-jss'
import theme, { span, vw } from '../../../../style/theme'
import DropDown from '../../../DropDown'
import { convertCase } from '../../../../helpers/string'
import PlaybookList from './PlaybookItem'

const PlaybookGrid = ({ slice, page }) => {
  const playbooksArray = page?.allPlaybooks
  const classes = useStyles()
  const [playbookFilter, setPlaybookFilter] = useState('')
  const playbookTagsList = []
  const playbookFilterOptions = []
  const playBookList = []
  const filterByTitle = slice?.fieldGuideCategory?.title

  const onChange = useCallback(({ target }) => {
    setPlaybookFilter(target.value)
  }, [setPlaybookFilter])

  if (playbooksArray && Array.isArray(playbooksArray) && playbooksArray.length) {
    for (let i = 0; i < playbooksArray.length; i++) {
      const playbooks = playbooksArray[i]
      const { playbookTags } = playbooks
      if (playbookTags && Array.isArray(playbookTags) && playbookTags.length) {
        for (let j = 0; j < playbookTags.length; j++) {
          const playbookTag = playbookTags[j]
          const tag = playbookTag.title
          if (tag && !playbookTagsList.includes(tag)) {
            playbookTagsList.push(tag)
          }
        }
      }
    }

    for (let i = 0; i < playbooksArray.length; i++) {
      const playbook = playbooksArray[i]
      const { playbookTags } = playbook

      let matchingTagFound = false

      if (filterByTitle) {
        if (playbookTags?.some(tag => tag.title === filterByTitle)) {
          matchingTagFound = true
        }
      } else if (playbookTags?.length > 0) {
        for (let j = 0; j < playbookTags.length; j++) {
          const tagOfPlaybook = playbookTags[j].title

          if (playbookFilter === '' || tagOfPlaybook === playbookFilter) {
            matchingTagFound = true
            break
          }
        }
      } else if (playbookFilter === '') {
        matchingTagFound = true
      }

      if (matchingTagFound) {
        playBookList.push(playbook)
      }
    }
  }

  playbookTagsList.sort()
  playbookFilterOptions.push({ text: 'FILTER', value: '' })
  if (playbookTagsList && playbookTagsList.length) {
    for (let i = 0; i < playbookTagsList.length; i++) {
      playbookFilterOptions.push({ text: convertCase(playbookTagsList[i]), value: playbookTagsList[i] })
    }
  }

  return (
    <section className={classes.parent}>
      <section className={classes.dropDownWrapper}>
        <div className={classes.headingBox}>
          <h3 className={classes.fieldGuidesTitle}>Field Guides</h3>
          <div className={classes.filterContainer}>
            {filterByTitle ? (
              <span className={classes.filterByTitle}>
                {filterByTitle}
              </span>
            ) : (
              <DropDown
                className={classes.locationFilter}
                placeholderText='Filter'
                options={playbookFilterOptions}
                onChange={onChange}
                value={playbookFilter}
              />
            )}
          </div>
        </div>
        {/* <div className={classes.seperator} /> */}
      </section>
      <section className={classes.cardsContainer}>
        <ul className={classes.list}>
          <li className={classes.listItem}>
            <div className={classes.link}>
              <span className={classes.titleWrapper}>
                <span className={classes.title}>Topics</span>
              </span>
              <span className={classes.authors}>
                <span className={classes.authors}>Authors</span>
              </span>
              {/* <span className={classes.description}> */}
              <span className={classes.description}>For founders who want to understand</span>
              {/* </span> */}
            </div>
          </li>
        </ul>
        <ul className={classes.list}>
          {playBookList?.map((item, index) => (
            <React.Fragment key={index}>
              <PlaybookList title={item.title} description={item.preview_snippet} authors={item.authors ? item.authors : item.authorRef.authors} heroImage={item.heroImage} playbookTags={item.playbookTags} link={item?.isCompleted === true && `/${item?.slug}`} />
            </React.Fragment>
          ))}
        </ul>
      </section>
    </section>
  )
}

const useStyles = createUseStyles(
  {
    cardsContainer: {
      margin: [0, 0, vw(60)],
      [theme.breakpoints.up('md')]: {
        margin: [0, theme.getMargin('md'), vw(100, 'lg')]
      }
    },
    list: {
      margin: 0,
      padding: 0,
      listStyle: 'none'
    },
    responsiveImage: {
      cursor: 'pointer',
      width: '100%',
      height: 'auto'
    },
    fieldGuidesTitle: {
      color: theme.colors.black,
      fontFamily: 'Object Sans',
      fontWeight: 700,
      textTransform: 'none'
    },
    titleTag: {
      color: 'white',
      textDecoration: 'none',
      marginTop: '12px'
    },
    linkPage: {
      textDecoration: 'none'
    },
    dropDownWrapper: {
      padding: [0, 25],
      // position: 'relative',
      // zIndex: 99,
      color: theme.colors.white,
      [theme.breakpoints.up('md')]: {
        padding: [0, 50]
      }
    },
    headingBox: {
      padding: [97, 0, 24],
      [theme.breakpoints.down('md')]: {
        padding: [70, 0, 24]
      },
      [theme.breakpoints.down('fab')]: {
        padding: [40, 0, 16]
      }
    },
    filterContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      paddingTop: '30px',
      [theme.breakpoints.down('md')]: {
        width: '100%'
      }
    },
    label: {
      extend: theme.typography.bodySmall,
      marginRight: 50,
      whiteSpace: 'nowrap'
    },
    locationFilter: {
      maxWidth: span(1),
      color: theme.colors.black,
      [theme.breakpoints.down('md')]: {
      }
    },
    seperator: {
      height: 1,
      width: '100%',
      backgroundColor: theme.colors.grey,
      marginTop: 40,
      marginBottom: 60,
      [theme.breakpoints.down('md')]: {
        marginTop: 20,
        marginBottom: 30
      }
    },
    filterByTitle: {
      border: '1px solid #fff',
      padding: '4px 8px'
    },
    listItem: {
      fontSize: vw(14),
      borderTop: [3, 'solid', '#D9E5FD'],
      borderBottom: [3, 'solid', '#D9E5FD'],
      position: 'relative',
      overflow: 'hidden',
      [theme.breakpoints.up('md')]: {
        fontSize: vw(14, 'lg')
      }
      // '&:last-child': {
      //   borderBottom: [1, 'solid', '#']
      // }
    },
    link: {
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
      padding: [vw(30), theme.getMargin()],
      textDecoration: 'none',
      color: theme.colors.white,
      backgroundColor: theme.colors.black,
      margin: [0, vw(-6)],
      overflow: 'hidden',
      [theme.breakpoints.up('md')]: {
        // minHeight: vw(180, 'lg'),
        margin: [0, vw(-30, 'vw')],
        padding: vw(30, 'lg')
      },
      '& > *': {
        position: 'relative',
        margin: [0, vw(6)],
        [theme.breakpoints.up('md')]: {
          marginRight: [0, vw(30, 'vw')]
        }
      }
    },
    titleWrapper: {
      width: '40%'
    },
    title: {
      fontFamily: 'Object Sans',
      fontWeight: 700,
      fontSize: vw(12),
      [theme.breakpoints.up('md')]: {
        fontSize: vw(5)
      },
      [theme.breakpoints.up('lg')]: {
        fontSize: vw(6)
      }
    },
    description: {
      fontFamily: 'Object Sans',
      fontWeight: 700,
      width: '50%',
      fontSize: vw(12),
      [theme.breakpoints.up('md')]: {
        fontSize: vw(5)
      },
      [theme.breakpoints.up('lg')]: {
        fontSize: vw(6)
      }
      // [theme.breakpoints.up('fab')]: {
      //   fontSize: '16px'
      // }
    },
    descriptionHeading: {

    },
    desktopOnly: {
      display: 'none',
      [theme.breakpoints.up('md')]: {
        display: 'block'
      }
    },
    authors: {
      fontFamily: 'Object Sans',
      fontWeight: 700,
      fontSize: vw(5),
      [theme.breakpoints.up('md')]: {
        fontSize: vw(5)
      },
      [theme.breakpoints.up('lg')]: {
        fontSize: vw(6)
      },
      composes: ['$desktopOnly'],
      whiteSpace: 'pre-wrap',
      [theme.breakpoints.up('md')]: {
        width: span(5, 'md')
      }
    }
  },
  { name: 'PlaybookGrid' }
)

export default PlaybookGrid
