export const convertCase = (str) => {
  if (!str) return ''
  let arr = str.split(' ')
  arr = arr.map(string => {
    if (typeof string === 'string' && string) {
      return string[0].toUpperCase() + (string.slice(1)).toLowerCase()
    }
    return ''
  })
  return arr.length > 1 ? arr.join(' ') : arr[0]
}
