import React from 'react'
import { createUseStyles } from 'react-jss'
import { resolveLink } from '../../helpers/resolveLink'
import theme, { vw } from '../../style/theme'
import Link from '../Link'
import ResponsiveImage from '../ResponsiveImage'
import get from 'lodash/get'

export default ({ slice }) => {
  const { title, tiles } = slice
  const classes = useStyles()
  return (
    <section className={classes.section}>
      {title && <h2 className={classes.title}>{title}</h2>}
      <div className={classes.grid}>
        {tiles.map(({ title, subtitle, link, image }, key) => (
          <div key={title + key}>
            <Link link={resolveLink(get(link, [0]))}>
              <ResponsiveImage {...image} aspect={325 / 420} />
            </Link>
            <div className={classes.contentContainer}>
              {subtitle && <div className={classes.subtitle}>{subtitle}</div>}
              <div className={classes.tileTitle}>{title}</div>
              {link && <Link className={classes.link} link={resolveLink(link[0])} />}
            </div>
          </div>
        ))}
      </div>
    </section>
  )
}

const useStyles = createUseStyles({
  sectionMargins: {
    ...theme.margins('margin', x => [0, x])
  },
  section: {
    composes: ['$sectionMargins'],
    marginBottom: vw(50),
    [theme.breakpoints.up('md')]: {
      marginBottom: vw(80, 'lg')
    }
  },
  contentContainer: {
    paddingTop: vw(16),
    [theme.breakpoints.up('md')]: {
      paddingTop: vw(16, 'lg')
    }
  },
  title: {
    extend: theme.typography.h4,
    marginBottom: vw(25),
    [theme.breakpoints.up('md')]: {
      marginBottom: vw(25, 'lg')
    }
  },
  grid: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    columnGap: vw(20),
    rowGap: vw(20),
    [theme.breakpoints.up('md')]: {
      gridTemplateColumns: '1fr 1fr 1fr 1fr',
      columnGap: vw(25, 'lg'),
      rowGap: vw(25, 'lg')
    }
  },
  subtitle: {
    display: 'block',
    extend: theme.typography.bodySmall,
    marginBottom: vw(8),
    [theme.breakpoints.up('md')]: {
      marginBottom: vw(8, 'lg')
    }
  },
  tileTitle: {
    extend: theme.typography.bodyPlus,
    textTransform: 'capitalize',
    marginBottom: vw(8),
    [theme.breakpoints.up('md')]: {
      marginBottom: vw(8, 'lg')
    }
  },
  link: {
    ...theme.link,
    extend: theme.typography.bodyTiny,
    color: theme.colors.primary,
    display: 'inline-block',
    textDecoration: 'none',
    '&:before': {
      backgroundColor: theme.colors.white
    },
    '&:after': {
      backgroundColor: theme.colors.primary
    }
  }
}, { name: 'Tiles' })
