import padStart from 'lodash/padStart'

export const formatDuration = (duration) => {
  if (duration === null || duration === undefined) return '--:--'
  let remainDuration = duration
  const hours = Math.floor(remainDuration / 60 / 60)
  remainDuration = remainDuration - ((hours * 60) * 60)
  const minutes = Math.floor(remainDuration / 60)
  remainDuration = remainDuration - (minutes * 60)
  const seconds = Math.floor(remainDuration)
  const hourValue = hours
  const minuteValue = hours > 0 ? padStart(minutes + '', 2, '0') : minutes
  const secondValue = padStart(seconds + '', 2, '0')
  return (hourValue ? `${hourValue}:` : '') + `${minuteValue}:${secondValue}`
}

export const formatDate = (dateString, delimiter = ' . ') => {
  var d = new Date(dateString)
  return padStart(d.getDate() + '', 2, '0') + delimiter + padStart((d.getMonth() + 1) + '', 2, '0') + delimiter + d.getFullYear()
}

function getNumberWithOrdinal (n) {
  var s = ['th', 'st', 'nd', 'rd']
  var v = n % 100
  return n + (s[(v - 20) % 10] || s[v] || s[0])
}

export const formatLongDate = (dateString) => {
  const d = new Date(dateString)
  const ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d)
  const mo = new Intl.DateTimeFormat('en', { month: 'long' }).format(d)
  const da = new Intl.DateTimeFormat('en', { day: 'numeric' }).format(d)
  return `${getNumberWithOrdinal(da)} ${mo} ${ye}`
}
