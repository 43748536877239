import React, { useRef, useState, useCallback, useEffect } from 'react'
import { createUseStyles } from 'react-jss'
import cn from 'classnames'
import get from 'lodash/get'
import { isCurrentBreakpointMobile } from '../../../redux/slices/layout'
import { useSelector } from 'react-redux'
import useHlsPlayer from '../../../hooks/useHlsPlayer'
import { formatDuration } from '../../../utils/format'
import SourceToggleButtons from '../../VideoAudioPlayer/SourceToggleButtons'
import ResponsiveImage from '../../ResponsiveImage'
import PlayPauseButtons from '../../VideoAudioPlayer/PlayPauseButtons'
import PlaybackRate from '../../VideoAudioPlayer/PlaybackRate'
import VolumeControls from '../../VideoAudioPlayer/VolumeControls'
import theme, { vw } from '../../../style/theme'

const getVideoAspect = (video) => {
  const aspect = get(video, ['data', 'aspect_ratio'], '16:9').split(':')
  return aspect[1] / aspect[0]
}

const VideoPlayerForFutureMaps = ({ slice }) => {
  const video = slice?.video
  const audio = slice?.audio
  const image = slice?.image
  const posterImage = image
  const [playing, setPlaying] = useState(false)
  const [volume, setVolume] = useState()
  const [muted, setMuted] = useState(false)
  const [rate, setRate] = useState(1)
  const [duration, setDuration] = useState()
  const [showControls, setShowControls] = useState(false)
  const showControlsCallbackRef = useRef()
  const isMobile = useSelector(isCurrentBreakpointMobile)

  const videoUrl = get(video, ['url'])
  const audioUrl = get(audio, ['url'])

  const aspect = getVideoAspect(video)
  const classes = useStyles({ aspect })

  const [source, setSource] = useState(videoUrl ? 'video' : 'audio')

  const playerRef = useRef()
  const timerRef = useRef()
  const timerLabelRef = useRef()
  const timerBufferProgressRef = useRef()
  const timerProgressRef = useRef()
  const timerCurrentTimeRef = useRef()
  const durationLabelRef = useRef()

  const hasBothVideoAndAudioSources = videoUrl && audioUrl

  useHlsPlayer(playerRef, source === 'video' ? videoUrl : audioUrl)

  const onPlayClick = useCallback(() => {
    if (!playing) {
      playerRef.current.play()
    } else {
      playerRef.current.pause()
    }
  }, [playing])

  const onSourceChanged = useCallback((source) => {
    playerRef.current.pause()
    setSource(source)
  }, [])

  const onSkipForward = useCallback(() => {
    playerRef.current.currentTime += 10
  }, [])

  const onSkipBack = useCallback(() => {
    playerRef.current.currentTime -= 10
  }, [])

  const updateTimerProgress = (percent) => {
    timerProgressRef.current.style.transform = `translate(-${100 - percent}%, 0)`
    timerCurrentTimeRef.current.style.left = `${percent}%`
  }

  useEffect(() => {
    if (playerRef.current) {
      setVolume(playerRef.current.volume)
      const onEnded = () => {
        playerRef.current.currentTime = 0
        setPlaying(false)
      }
      const onDurationChanged = () => {
        setDuration(playerRef.current.duration)
      }
      const onPlay = () => { setPlaying(true) }
      const onPause = () => { setPlaying(false) }
      const onLoadStart = () => {
        updateTimerProgress(0)
        timerBufferProgressRef.current.style.transform = 'translate(-100%, 0)'
      }
      const onTimeUpdate = (e) => {
        if (playerRef.current) {
          const percent = (playerRef.current.currentTime / playerRef.current.duration) * 100
          updateTimerProgress(percent)
          timerLabelRef.current.innerHTML = formatDuration(playerRef.current.currentTime)
        }
      }
      const onProgress = (e) => {
        if (playerRef.current) {
          const percentBuffered = (playerRef.current.buffered.end(0) / playerRef.current.duration) * 100
          timerBufferProgressRef.current.style.transform = `translate(-${100 - percentBuffered}%, 0)`
        }
      }

      const onVolumeChanged = () => {
        setVolume(playerRef.current.volume)
      }

      playerRef.current.addEventListener('loadstart', onLoadStart)
      playerRef.current.addEventListener('ended', onEnded)
      playerRef.current.addEventListener('play', onPlay)
      playerRef.current.addEventListener('pause', onPause)
      playerRef.current.addEventListener('timeupdate', onTimeUpdate)
      playerRef.current.addEventListener('progress', onProgress)
      playerRef.current.addEventListener('volumechange', onVolumeChanged)
      playerRef.current.addEventListener('durationchange', onDurationChanged)
      return () => {
        playerRef.current.removeEventListener('loadstart', onLoadStart)
        playerRef.current.removeEventListener('ended', onEnded)
        playerRef.current.removeEventListener('play', onPlay)
        playerRef.current.removeEventListener('pause', onPause)
        playerRef.current.removeEventListener('pause', onTimeUpdate)
        playerRef.current.removeEventListener('progress', onProgress)
        playerRef.current.removeEventListener('volumechange', onVolumeChanged)
        playerRef.current.removeEventListener('durationchange', onDurationChanged)
      }
    }
  }, [])

  const setCurrentTimeFromMousePosition = (clientX) => {
    if (playerRef.current) {
      const { width, left } = timerRef.current.getBoundingClientRect()
      const percent = Math.max(0, Math.min(1, (clientX - left) / width))
      playerRef.current.currentTime = percent * playerRef.current.duration
      updateTimerProgress(percent * 100)
    }
  }

  const onTimerMouseDown = useCallback((e) => {
    setCurrentTimeFromMousePosition(get(e, ['touches', 0, 'clientX'], e.clientX))
    if (playing && playerRef.current) playerRef.current.pause()
    const onMouseMove = (e) => { setCurrentTimeFromMousePosition(get(e, ['touches', 0, 'clientX'], e.clientX)) }
    const onMouseUp = (e) => {
      document.body.removeEventListener('mousemove', onMouseMove)
      document.body.removeEventListener('mouseup', onMouseUp)
      document.body.removeEventListener('touchmove', onMouseMove)
      document.body.removeEventListener('touchend', onMouseUp)
      if (playing && playerRef.current) playerRef.current.play()
    }
    document.body.addEventListener('mousemove', onMouseMove)
    document.body.addEventListener('mouseup', onMouseUp)
    document.body.addEventListener('touchend', onMouseUp)
    document.body.addEventListener('touchmove', onMouseMove)
  }, [playing])

  const onToggleMuted = useCallback(() => {
    setMuted(value => {
      playerRef.current.muted = !value
      return !value
    })
  }, [])

  const onVolumeChange = useCallback((volume) => {
    playerRef.current.volume = volume
  }, [])

  const onRateChanged = useCallback((e) => {
    playerRef.current.playbackRate = e.target.value
    setRate(e.target.value)
  }, [])

  const onMouseMove = useCallback(() => {
    if (showControlsCallbackRef.current) {
      clearTimeout(showControlsCallbackRef.current)
      showControlsCallbackRef.current = null
    }
    setShowControls(true)
    showControlsCallbackRef.current = setTimeout(() => { setShowControls(false) }, 2000)
  }, [])

  return (
    <>
      {hasBothVideoAndAudioSources && <SourceToggleButtons source={source} onChange={onSourceChanged} className={classes.sourceControls} invert />}
      <div className={classes.videoContainer} draggable='false' onMouseMove={onMouseMove}>
        <ResponsiveImage {...posterImage} className={classes.videoPoster} />
        <video
          ref={playerRef}
          src={videoUrl}
          className={cn(classes.video, { hide: source === 'audio' })}
          controls={isMobile}
          playsInline
        />
        <div className={cn(classes.controlsOverlay, { show: source === 'audio' || !playing || showControls })} />
        <div className={cn(classes.controls, { show: source === 'audio' || !playing || showControls })} draggable='false' />
        <div className={cn(classes.controls, { show: source === 'audio' || !playing || showControls })} draggable='false'>
          <div className={cn(classes.controlsRow, classes.buttons)}>
            <div>
              {hasBothVideoAndAudioSources && <SourceToggleButtons source={source} onChange={onSourceChanged} className={classes.inlineSourceControls} />}
            </div>
            <PlayPauseButtons playing={playing} onPlayClick={onPlayClick} onSkipBack={onSkipBack} onSkipForward={onSkipForward} />
            <div className={classes.volumeContainer}>
              <PlaybackRate rate={rate} onRateChanged={onRateChanged} />
              <VolumeControls muted={muted} volume={volume} onToggleMuted={onToggleMuted} onVolumeChange={onVolumeChange} />
            </div>
          </div>
          <div className={classes.timer} ref={timerRef} onMouseDown={onTimerMouseDown} onTouchStart={onTimerMouseDown} draggable='false'>
            <div className={classes.timerWrapper} draggable='false'>
              <span className={classes.bufferProgress} ref={timerBufferProgressRef} draggable='false' />
              <span className={classes.timerProgress} ref={timerProgressRef} draggable='false' />
            </div>
            <button className={classes.currentTime} ref={timerCurrentTimeRef} draggable='false' />
          </div>
          <div className={classes.controlsRow}>
            <div className={classes.label} ref={timerLabelRef}>--:--</div>
            <div className={classes.label} ref={durationLabelRef}>{formatDuration(duration)}</div>
          </div>
        </div>
      </div>
    </>
  )
}

const useStyles = createUseStyles({
  videoContainer: {
    position: 'relative',
    width: '100%',
    paddingTop: ({ aspect }) => `${(aspect * 100)}%`,
    userSelect: 'none'
  },
  video: {
    outline: 'none',
    width: '100%',
    display: 'block',
    opacity: 1,
    transition: 'opacity 0.15s ease-in-out',
    // position: 'relative',
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    '&:hide': {
      display: 'none'
    }
  },
  videoPoster: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0
  },
  controlsOverlay: {
    position: 'absolute',
    bottom: 0,
    top: '55%',
    left: 0,
    right: 0,
    pointerEvents: 'none',
    background: 'linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.7) 100%)',
    opacity: 0,
    transition: 'opacity 0.15s ease-in-out',
    '&.show': {
      opacity: 1
    },
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'block'
    }
  },
  controls: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    color: theme.colors.white,
    opacity: 0,
    transition: 'opacity 0.25s ease-in-out',
    margin: [8, theme.getMargin()],
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'block',
      margin: theme.getMargin('md')
    },
    '&.show': {
      opacity: 1
    }
  },
  controlsRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  buttons: {
    '& > *': {
      width: '33%'
    }
  },
  label: {
    fontSize: vw(8),
    [theme.breakpoints.up('md')]: {
      fontSize: vw(16, 'lg')
    }
  },
  timer: {
    width: '100%',
    position: 'relative',
    display: 'block',
    margin: [vw(8), 0],
    height: 3,
    [theme.breakpoints.up('md')]: {
      height: 5,
      margin: [vw(32, 'lg'), 0, vw(16, 'lg')]
    }
  },
  timerWrapper: {
    cursor: 'pointer',
    width: '100%',
    display: 'block',
    backgroundColor: 'rgba(255,255,255,0.2)',
    height: 3,
    position: 'relative',
    overflow: 'hidden',
    [theme.breakpoints.up('md')]: {
      height: 5
    }
  },
  bufferProgress: {
    position: 'absolute',
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(255,255,255,0.5)',
    transform: 'translateX(-100%)'
  },
  timerProgress: {
    position: 'absolute',
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    backgroundColor: theme.colors.white,
    transform: 'translateX(-100%)'
  },
  currentTime: {
    margin: 0,
    padding: 0,
    border: 'none',
    position: 'absolute',
    pointerEvents: 'none',
    top: '50%',
    left: 0,
    width: 3,
    height: 16,
    transform: 'translate(-50%, -50%)',
    backgroundColor: theme.colors.white,
    [theme.breakpoints.up('md')]: {
      height: 20
    }
  },
  volumeContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    '& > *:not(:last-child)': {
      marginRight: vw(24),
      [theme.breakpoints.up('md')]: {
        marginRight: vw(24, 'lg')
      }
    }
  },
  sourceControls: {
    display: 'flex',
    margin: [0, theme.getMargin(), theme.getMargin()],
    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  },
  inlineSourceControls: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex'
    }
  }
}, { name: 'VideoPlayerForFutureMaps' })

export default VideoPlayerForFutureMaps
