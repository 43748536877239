import React from 'react'
import cn from 'classnames'
import { createUseStyles } from 'react-jss'
import theme, { span, vw } from '../../style/theme'
import Link from '../Link'
import ResponsiveImage from '../ResponsiveImage'
import RichText from '../RichText'
import isEmpty from 'lodash/isEmpty'
import SocialLink, { FACEBOOK_TYPE, INSTAGRAM_TYPE, LINKEDIN_TYPE, TWITTER_TYPE } from '../SocialLink'
import ArrowNavigation from './FieldGuide/ArrowNavigation'
import CaptainProfileContent from './CaptainProfileContent'

function getSocialLinkType (url) {
  if (url.indexOf('facebook') >= 0) {
    return FACEBOOK_TYPE
  }
  if (url.indexOf('twitter') >= 0) {
    return TWITTER_TYPE
  }
  if (url.indexOf('linkedin') >= 0) {
    return LINKEDIN_TYPE
  }
  if (url.indexOf('instagram') >= 0) {
    return INSTAGRAM_TYPE
  }
}

export default ({ slice, page }) => {
  const {
    saasCaptain,
    bread_crumbs: breadCrumbs
  } = slice
  const { photoSecond, photo, title, bio, position, socialLinks, id } = saasCaptain
  // const breadCrumbs = slice?.bread_crumbs
  const hasConnection = !isEmpty(socialLinks)
  const routeArray = breadCrumbs?.route
  const classes = useStyles()
  const profileImage = Object.keys(photoSecond).length === 0 ? photo : photoSecond

  return (
    <section className={classes.section}>
      <div className={classes.inner}>
        <div className={cn(classes.col, classes.colLeft)}>
          <ResponsiveImage className={classes.profilePhoto} {...profileImage} aspect={545 / 659} />
          {hasConnection && (
            <div className={classes.connectionsContainer}>
              <span className={classes.connectTitle}>Connect</span>
              <div className={classes.socialLinksContainer}>
                {socialLinks.map(link => {
                  const type = getSocialLinkType(link.url)
                  if (type) {
                    return (
                      <SocialLink className={classes.socialLink} key={link.url} url={link.url} type={type} />
                    )
                  }
                  return <Link className={classes.socialLink} key={link.url} to={link.url}>{link.title}</Link>
                })}
              </div>
            </div>
          )}
        </div>
        <div className={cn(classes.col, classes.colRight)}>
          <ArrowNavigation className={classes.breadCrumbsDesktop} arrowData={routeArray} />
          <h1 className={classes.title}>{title}</h1>
          <span className={classes.position}>{position}</span>
          <ArrowNavigation className={classes.breadCrumbsMobile} arrowData={routeArray} />
          {bio && <RichText content={bio.text} className={classes.bioText} />}
          <CaptainProfileContent page={page} id={id} />
        </div>
      </div>
    </section>
  )
}

const useStyles = createUseStyles({
  sectionPadding: {
    ...theme.margins('padding')
  },
  section: {
    composes: ['$sectionPadding'],
    marginBottom: 127,
    paddingTop: vw(80),
    [theme.breakpoints.up('md')]: {
      paddingTop: vw(180, 'lg')
    }
  },
  inner: {
    [theme.breakpoints.up('md')]: {
      display: 'flex'
    }
  },
  colLeft: {
    marginBottom: vw(24),
    [theme.breakpoints.up('md')]: {
      width: span(10, 'md'),
      padding: [0, span(1, 'md'), 0, span(2, 'md')],
      marginBottom: 0
    }
  },
  colRight: {
    [theme.breakpoints.up('md')]: {
      width: span(14, 'md'),
      padding: [0, span(2, 'md'), 0, span(1, 'md')]
    }
  },
  profilePhoto: {},
  title: {
    extend: theme.typography.h2,
    // marginBottom: vw(8),
    marginBottom: '14.9661px',
    fontFamily: 'Mars Condensed',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '60px',
    lineHeight: '93%',
    letterSpacing: '-0.01em',
    textTransform: 'uppercase',
    color: theme.colors.text,

    [theme.breakpoints.up('md')]: {
      fontSize: vw(80, 'lg'),
      lineHeight: '85%'
    }
  },
  position: {
    display: 'block',
    extend: theme.typography.bodyPlus,
    marginBottom: 0,

    fontFamily: 'Mars Condensed',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '20px',
    lineHeight: '88%',
    letterSpacing: '0.04em',
    textTransform: 'uppercase',
    color: '#838383',

    [theme.breakpoints.up('md')]: {
      fontFamily: 'Object Sans',
      fontSize: '14px',
      lineHeight: '140%',
      color: theme.colors.grey32,
      // marginBottom: vw(12, 'lg'),
      marginBottom: '38.314px',
      textTransform: 'capitalize'
    }
  },
  connectionsContainer: {
    extend: theme.typography.bodySmall,
    // marginTop: vw(30),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: [1, 'solid', theme.colors.border],
    // padding: [vw(16), 0],
    padding: [19, 0, 16, 0],
    '& > span': {
      display: 'inline-block',
      [theme.breakpoints.up('md')]: {
        marginRight: vw(48, 'lg')
      }
    },
    [theme.breakpoints.up('md')]: {
      marginTop: vw(30, 'lg'),
      padding: [vw(16, 'lg'), 0]
    }
  },
  socialLinksContainer: {
    display: 'flex'
  },
  socialLink: {
    color: theme.colors.primary,
    '&:not(:last-child)': {
      marginRight: vw(24),
      [theme.breakpoints.up('md')]: {
        marginRight: vw(24, 'lg')
      }
    }
  },
  locationBox: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: vw(50),
    [theme.breakpoints.up('md')]: {
      marginBottom: vw(50, 'lg')
    }
  },
  locationIcon: {
    width: 12,
    height: 22,
    marginTop: 5
  },
  locationName: {
    color: theme.colors.textLight,
    textTransform: 'capitalize',
    marginLeft: 10,
    lineHeight: 1
  },
  breadCrumbsDesktop: {
    marginBottom: '24px',
    display: 'none',

    [theme.breakpoints.up('md')]: {
      marginBottom: '37px',
      display: 'block'
    }
  },
  breadCrumbsMobile: {
    marginTop: '35px',
    marginBottom: '40px',
    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  },
  bioText: {
    fontFamily: 'Object Sans',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '140%',
    marginBottom: '54px',
    color: theme.colors.text,

    [theme.breakpoints.up('md')]: {
      fontSize: '20px'
    }
  },
  connectTitle: {
    fontFamily: 'Object Sans',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '140%',
    color: theme.colors.text
  }
}, { name: 'CaptainMemberHero' })
