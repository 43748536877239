import { createSlice } from '@reduxjs/toolkit'
import { routeContent } from '../actions'
import theme from '../../style/theme'

const timelineSlice = createSlice({
  name: 'timeline',
  initialState: {
    blockIndex: 0,
    backgroundColor: theme.colors.white
  },
  reducers: {
    blockReached: (state, action) => {
      state.backgroundColor = action.payload.backgroundColor
      state.blockIndex = action.payload.blockIndex
    }
  },
  extraReducers: {
    [routeContent]: (state, action) => {
      state.blockIndex = 0
    }
  }
})

export const {
  blockReached
} = timelineSlice.actions

export const getTimelineCurrentBlockIndex = state => state.timeline.blockIndex
export const getTimelineBackgroundColor = (state) => state.timeline.backgroundColor

export default timelineSlice.reducer
