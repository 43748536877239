import React from 'react'
import { createUseStyles } from 'react-jss'
import theme from '../../../style/theme.js'

const PlaybookIntroTitle = ({ slice, page }) => {
  const typeOfPage = page?.type
  const classes = useStyles({ typeOfPage })

  return (
    <div>
      {/* {slice && <RichText content={slice.text} className={classes.introTitle} />} */}
      <h1 className={classes.introTitle}>{slice.text}</h1>
    </div>
  )
}

const useStyles = createUseStyles(
  {
    introTitle: {
      // margin: '-150px 339px 70px 0',
      margin: ({ typeOfPage }) => typeOfPage === 'playbook' ? '44px 120px 70px 0' : '26px 120px 70px 0',
      fontSize: '50px',
      lineHeight: '90%',
      [theme.breakpoints.down('md')]: {
        margin: ({ typeOfPage }) => typeOfPage === 'playbook' ? '25px 25px' : '0px 25px'
      }
    }
  },
  { name: 'PlaybookIntroTitle' }
)

export default PlaybookIntroTitle
