import React, { useMemo, useCallback } from 'react'
import range from 'lodash/range'
import { useSelector, useDispatch } from 'react-redux'
import { createUseStyles } from 'react-jss'
import {
  getPosts,
  isPostsBusy,
  getPostsTotal,
  isPostsLoaded,
  getPostsQueryStringArguments
} from '../../redux/slices/content'
import { postsLoadMoreActionCreator } from '../../redux/slices/content/posts'
import { POSTS_PER_PAGE } from '../../redux/middlewares/content/posts'
import PostTile from '../Summit/PostTile'
import Loading from '../Loading'
import theme, { vw } from '../../style/theme'
import LoadMorePlus from '../../images/LoadMorePlus'
import { resolveInternalLink } from '../../helpers/resolveLink'

const PostListSlice = ({ slice }) => {
  const classes = useStyles()
  const { postInitiatives } = slice
  const posts = useSelector(getPosts) || []
  const total = useSelector(getPostsTotal)
  const loaded = useSelector(isPostsLoaded)
  const { postsPage: index, postInitiative } = useSelector(getPostsQueryStringArguments)
  const matchingInitiative = postInitiative && postInitiatives && postInitiatives.find(initiative => initiative.slug === postInitiative)
  const busy = useSelector(isPostsBusy)
  const dispatch = useDispatch()

  const loadMore = useCallback(() => {
    dispatch(postsLoadMoreActionCreator())
  }, [])

  const postTiles = useMemo(() => {
    const expectedTiles = POSTS_PER_PAGE * (index + 1)
    var tiles = []
    if (posts) {
      tiles = posts.map((post, i) => {
        return {
          ...post,
          link: resolveInternalLink(post)
        }
      })
    }
    // if the pages have not loaded or it is busy getting the pages, then show the placeholders
    if (busy || !loaded) {
      const currentPageLength = (posts ? posts.length : 0)
      tiles = [
        ...tiles,
        ...range(expectedTiles - currentPageLength).map((x, i) => ({ placeholder: true }))
      ]
    }
    return tiles
  }, [index, posts])

  const hasMore = !posts || total > posts.length

  return (
    <>
      {matchingInitiative && (
        <div className={classes.featuredPostWrapper}>
          <PostTile
            {...matchingInitiative.featuredPost}
            link={resolveInternalLink(matchingInitiative.featuredPost)}
            isLandscape
            isFeatured
          />
        </div>
      )}
      <div className={classes.content}>
        <div className={classes.list}>
          {postTiles && postTiles.map((post, i) => (
            <PostTile key={post.id || i} className={classes.postListItem} {...post} />
          ))}
        </div>
        {busy && <Loading />}
        {hasMore && (
          <div className={classes.listActions}>
            <button disabled={busy} className={classes.loadMoreButton} onClick={loadMore}>
              <LoadMorePlus />
              <span>Load More</span>
            </button>
          </div>
        )}
      </div>
    </>
  )
}

const useStyles = createUseStyles({
  featuredPostWrapper: {
    padding: [30, theme.getMargin('min')],
    position: 'relative',
    [theme.breakpoints.up('md')]: {
      padding: [50, theme.getMargin('md')]
    }
  },
  content: {
    padding: [30, theme.getMargin('min'), 60],
    position: 'relative',
    [theme.breakpoints.up('md')]: {
      padding: [50, theme.getMargin('md'), 100]
    }
  },
  list: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    columnGap: vw(20),
    rowGap: vw(60),
    [theme.breakpoints.up('md')]: {
      gridTemplateColumns: '1fr 1fr 1fr',
      columnGap: vw(25, 'lg'),
      rowGap: vw(80, 'lg')
    }
  },
  listActions: {
    display: 'flex',
    justifyContent: 'center',
    padding: [30, theme.getMargin('min')],
    [theme.breakpoints.up('md')]: {
      padding: [50, theme.getMargin('md')]
    }
  },
  loadMoreButton: {
    display: 'flex',
    alignItems: 'center',
    '& svg': {
      width: 30,
      height: 30,
      marginRight: 10
    },
    '& span': {
      paddingTop: 2,
      extend: theme.typography.bodySmall
    }
  }
}, { name: 'PostListSlice' })

export default PostListSlice
