import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit'
import inDOM from 'dom-helpers/canUseDOM'
import { connectRoutes } from 'redux-first-router'
import reducers from './slices'
import routesMap from './routesMap'
import querystring from 'query-string'
import middlewares from './middlewares'
import { getStateStorage } from './middlewares/PauseableSessionStorage'

export default function ({ preloadedState, initialEntries } = {}) {
  let restoreScroll
  if (inDOM) {
    restoreScroll = require('redux-first-router-restore-scroll').default({
      // We’ll restore the scroll position as part of the transitions in the Page component.
      manual: true,
      stateStorage: getStateStorage()
    })
  }
  const {
    reducer: routerReducer,
    middleware: routerMiddleware,
    enhancer: routerEnhancer,
    thunk: routerThunk
  } = connectRoutes(
    routesMap, {
      initialEntries,
      restoreScroll,
      querySerializer: querystring
    }
  )

  const store = configureStore({
    reducer: {
      ...reducers,
      location: routerReducer
    },
    preloadedState,
    middleware: [
      ...middlewares,
      routerMiddleware,
      ...getDefaultMiddleware({
        serializableCheck: false
        // serializableCheck: {
        //   ignoredPaths: [
        //     'location.routesMap.ROUTE_CONTENT.toPath',
        //     'location.routesMap.ROUTE_CONTENT.thunk',
        //     'location.routesMap.ROUTE_EMPTY.toPath',
        //     'content.page.error',
        //     'meta.location.prev.routesMap.ROUTE_CONTENT.toPath'
        //   ]
        // }
      })
    ],
    enhancers: (defaultEnhancers) => [
      routerEnhancer,
      ...defaultEnhancers
    ]
  })

  if (module.hot && process.env.NODE_ENV === 'development') {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept('./slices', () => {
      const rootReducer = { ...reducers, location: routerReducer }
      store.replaceReducer(rootReducer)
    })
  }

  return { store, routerThunk }
}
