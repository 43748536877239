import layout from './layout'
import content from './content'
import config from './config'
import timeline from './timeline'
import homeHero from './homeHero'
import newsletter from './newsletter'
import customNewsletter from './customNewsletter'
import nextPages from './nextPages'
import { loadingBarReducer as loadingBar } from 'react-redux-loading-bar'

export default {
  config,
  layout,
  content,
  timeline,
  homeHero,
  newsletter,
  customNewsletter,
  nextPages,
  loadingBar
}
