import React from 'react'
import { useSelector } from 'react-redux'
import { createUseStyles } from 'react-jss'
import find from 'lodash/find'
import detectIt from 'detect-it'
import Link from './Link'
import theme, { span, vw } from '../style/theme'
import { quad } from '../style/eases'
import { getNextPages } from '../redux/slices/nextPages'
import NextPost from './NextPost'

export const NextPageFromState = ({ page = {} }) => {
  const { slices, slug } = page
  const nextPages = useSelector(getNextPages)
  if (page.notShowRelatedPosts) return null
  if (page.type === 'post' || page.type === 'playbook' || page.type === 'playbook_chapter' || page.type === 'future_maps') {
    return <NextPost page={page} />
  }

  const nextPage = nextPages[slug]
  const hasNextPageSlice = !!find(slices, slice => slice.type === 'next_page_slice')
  if (!nextPage || hasNextPageSlice) return null

  const { nextPage: { title, url, subtitle, color } } = nextPage
  return <NextPage title={title} url={url} copy={subtitle} color={color} />
}

const NextPage = ({ title, url, copy, color }) => {
  const classes = useStyles()
  if (!url) return null
  return (
    <Link className={classes.section} to={url} nonLinkTag='section' style={{ backgroundColor: color }}>
      <div className={classes.content}>
        <span className={classes.headline}>Up Next</span>
        <h3 className={classes.title}>{title}</h3>
        <p className={classes.copy}>{copy}</p>
      </div>
    </Link>
  )
}

const useStyles = createUseStyles({
  section: {
    display: 'block',
    backgroundColor: theme.colors.offWhite,
    position: 'relative',
    color: theme.colors.text,
    overflow: 'hidden',
    textDecoration: 'none',
    transition: `color 0.25s ${quad.inOut}, background-color 0.25s ${quad.inOut}`,
    '&:before': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: theme.colors.primary,
      transition: `transform 0.25s ${quad.inOut}`,
      transform: 'translateY(100%)'
    },
    ...(detectIt.primaryInput === 'touch'
      ? {
        '&:hover': {
          color: theme.colors.white,
          backgroundColor: theme.colors.primary
        }
      }
      : {
        '&:hover': {
          color: theme.colors.white,
          '&:before': {
            transform: 'translateY(0%)'
          },
          '& svg': {
            fill: theme.colors.white
          },
          '& $headline': {
            color: theme.colors.white
          }
        }
      }
    )
  },
  headline: {
    display: 'block',
    color: theme.colors.primary,
    extend: theme.typography.bodySmall,
    textTransform: 'uppercase',
    marginBottom: vw(12),
    transition: `color 0.25s ${quad.inOut}`,
    [theme.breakpoints.up('md')]: {
      marginBottom: vw(12, 'lg')
    }
  },
  content: {
    overflow: 'hidden',
    position: 'relative',
    margin: [vw(40), theme.getMargin('min'), vw(50)],
    [theme.breakpoints.up('md')]: {
      margin: [vw(50, 'md'), theme.getMargin('md')]
    }
  },
  title: {
    extend: theme.typography.h4,
    display: 'flex',
    alignItems: 'center',
    marginBottom: vw(12),
    [theme.breakpoints.up('md')]: {
      maxWidth: vw(450, 'lg'),
      marginRight: span(2, 'md'),
      marginBottom: vw(12, 'lg')
    }
  },
  copy: {
    extend: theme.typography.bodySmall,
    marginBottom: 0,
    [theme.breakpoints.up('md')]: {
      maxWidth: vw(300, 'lg')
    }
  }
}, { name: 'NextPage' })

export default NextPage
