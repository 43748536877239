import { useEffect, useRef, useContext } from 'react'
import get from 'lodash/get'
import { useSelector } from 'react-redux'
import { SmoothScrollContext } from '../components/useSmoothScroll'
import afterFrame from '../helpers/afterFrame'
import offsetTop from '../utils/offsetTop'
import { isCurrentBreakpointMobile } from '../redux/slices/layout'

const HEADER_OFFSET = 50

export default (containerRef, contentRef) => {
  const locals = useRef({})
  const isMobile = useSelector(isCurrentBreakpointMobile)
  const smoothScrollContext = useContext(SmoothScrollContext)

  useEffect(() => {
    if (isMobile) return
    var animationFrame
    const tick = () => {
      const { start, end } = locals.current
      if (contentRef.current) {
        const scrollbar = get(smoothScrollContext, ['current', 'scrollbar'])
        const scrollY = scrollbar.scrollTop
        if (scrollY >= start && scrollY <= end) {
          contentRef.current.style.transform = `translate3d(0,${scrollY - start}px,0)`
        } else {
          if (scrollY > end) {
            contentRef.current.style.transform = `translate3d(0, ${end}, 0)`
          } else {
            contentRef.current.style.transform = 'translate3d(0, 0, 0)'
          }
        }
      }

      afterFrame(() => {
        if (contentRef.current) {
          locals.current = {
            start: offsetTop(containerRef.current) - HEADER_OFFSET,
            end: offsetTop(containerRef.current) + containerRef.current.offsetHeight - contentRef.current.offsetHeight - HEADER_OFFSET
          }
        }
      })
      animationFrame = window.requestAnimationFrame(tick)
    }
    animationFrame = window.requestAnimationFrame(tick)
    return () => {
      window.cancelAnimationFrame(animationFrame)
    }
  }, [isMobile])
}
