import { createAction } from '@reduxjs/toolkit'
import makeFetchSlice from '../makeFetchSlice'

export const dialogContentActionCreator = createAction('dialog/content', (payload) => ({
  payload,
  meta: { scope: 'dialog' }
}))
export const serverErrorActionCreator = createAction('dialog/serverError')

const dialogSlice = makeFetchSlice('dialog', dialogContentActionCreator, serverErrorActionCreator)

export default dialogSlice.reducer
