import React, { useEffect, useRef, forwardRef } from 'react'
import cn from 'classnames'
import { createUseStyles } from 'react-jss'
import gsap from 'gsap'
import composeRefs from '../helpers/composeRefs'

export default forwardRef(({ className, open }, forwardRef) => {
  const classes = useStyles()
  const timelineRef = useRef()
  const ref = useRef()

  useEffect(() => {
    if (ref.current && !timelineRef.current) {
      const [top, middle, bottom] = ref.current.children
      const tl = gsap.timeline()

      tl.to([middle, bottom], { y: '0.34em', duration: 0.15, ease: 'sine.out' }, 0)
      tl.to(top, { y: '0.5em', rotate: 45, duration: 0.15, ease: 'sine.out' }, 0.3)
      tl.to([middle, bottom], { y: '0.5em', rotate: -45, duration: 0.15, ease: 'sine.out' }, 0.3)

      timelineRef.current = tl
      return () => {
        timelineRef.current.kill()
        timelineRef.current = null
      }
    }
  }, [])

  useEffect(() => {
    if (ref.current && timelineRef.current) {
      timelineRef.current.reversed(!open)
    }
  }, [open])

  return (
    <div className={cn(classes.hamburger, className)} ref={composeRefs(forwardRef, ref)}>
      <span />
      <span />
      <span />
    </div>
  )
})

const useStyles = createUseStyles({
  hamburger: {
    fontSize: 44,
    position: 'absolute',
    left: 0,
    top: 0,
    width: 44,
    height: 44,
    color: 'currentColor',
    '& > *': {
      position: 'absolute',
      display: 'block',
      left: 10,
      right: 10,
      height: 2,
      backgroundColor: 'currentColor',
      transition: 'background-color 0.3s ease, transform 0.3s'
    },
    '& > *:nth-child(1)': {
      transform: 'translateY(0.34em)'
    },
    '& > *:nth-child(2)': {
      transform: 'translateY(0.50em)'
    },
    '& > *:nth-child(3)': {
      transform: 'translateY(0.67em)'
    }
  }
}, { name: 'Header' })
