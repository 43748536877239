import React, { useCallback, useEffect, useRef, useState } from 'react'
import gsap from 'gsap'
import { createUseStyles } from 'react-jss'
import SplitText from 'gsap/SplitText'
import composeRefs from '../helpers/composeRefs'
import wrapAll from '../helpers/wrapAll'
import unwrap from '../helpers/unwrap'
import useWebFontLoaded from '../hooks/useWebFontLoaded'
import get from 'lodash/get'
import useIntersectionObserverCallback from '../hooks/useIntersectionObserverCallback'

gsap.registerPlugin(SplitText)

function useSplitWordsAnimation ({ linesOnly = false, wrapped = false, overflow = 'hidden' }) {
  const inView = useRef()
  const [fontsReady, setFontsReady] = useState(false)
  const fontsReadyRef = useWebFontLoaded(useCallback(() => {
    setFontsReady(true)
  }, []))

  const locals = useRef({}).current

  const mountRef = useCallback((el) => {
    if (el) {
      if (!fontsReady) {
        return
      }
      locals.split = new SplitText(wrapped ? get(el, ['children', 0]) : el, { type: 'lines' })
      gsap.set(locals.split.lines, { overflow })
      locals.targets = locals.split.lines.map(
        line => wrapAll(line.childNodes, document.createElement('div'))
      )
      gsap.set(locals.targets, { yPercent: 120, skewY: -10, opacity: 0, visibility: 'hidden' })
      gsap.set(el, { visibility: 'visible' })
      locals.el = el
    } else if (locals.split) {
      locals.targets.forEach(innerLine => {
        unwrap(innerLine)
      })
      locals.split.revert()
      delete locals.targets
      delete locals.split
      delete locals.el
    }
  }, [locals, linesOnly, fontsReady])

  const triggerAnimation = () => {
    gsap.to(
      locals.targets,
      { ease: 'expo.out', yPercent: 0, skewY: 0, opacity: 1, stagger: 0.06, duration: 1.6, visibility: 'visible' }
    )
  }

  const inViewRef = useIntersectionObserverCallback(
    { threshold: 1, triggerOnce: true },
    useCallback((entries) => {
      if (fontsReady) {
        inView.current = get(entries, [0, 'isIntersecting'])
        if (inView.current) {
          triggerAnimation()
        }
      }
    }, [fontsReady])
  )

  useEffect(() => {
    if (!locals.el) {
      return
    }
    if (fontsReady && inView.current) {
      triggerAnimation()
    }
  }, [fontsReady])

  return composeRefs(inViewRef, fontsReadyRef, mountRef)
}

const SplitWordsAnimation = ({ children, srOnlyContent, linesOnly = false, screenReaderClone = true, wrapped = false, overflow }) => {
  const classes = useStyles()
  const child = React.Children.only(children)
  const content = child.props.children
  const ref = useSplitWordsAnimation({ linesOnly, wrapped, overflow })
  return React.cloneElement(child, {}, (
    <>
      {screenReaderClone && <div className='srOnly'>{srOnlyContent || content}</div>}
      <div className={classes.splitWords} ref={ref} aria-hidden='true'>
        {content}
      </div>
    </>
  ))
}

const useStyles = createUseStyles({
  splitWords: {
    visibility: 'hidden',
    '& > div': {
      marginBottom: '-0.1em',
      '& > div': {
        paddingBottom: '0.1em'
      }
    }
  }
})

export default SplitWordsAnimation
