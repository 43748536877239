import React from 'react'
import { createUseStyles } from 'react-jss'
import RightSideText from './RightSideText'

export default ({ slice }) => {
  const {
    primary_text: primaryText
  } = slice
  const classes = useStyles()
  return (
    <section className={classes.container}>
      <RightSideText text={primaryText.text} />
    </section>
  )
}

const useStyles = createUseStyles({
  container: {
    marginBottom: '20px'
  }
}, 'PostTextNew')
