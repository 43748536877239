import React, { useRef } from 'react'
import cn from 'classnames'
import { createUseStyles } from 'react-jss'
// import { resolveInternalLink } from '../../helpers/resolveLink'
import theme, { vw, span } from '../../../style/theme'
import useStickyElement from '../../../hooks/useStickyElement'

const FeaturedFutureMapsListSlice = ({ slice }) => {
  const {
    primary_post: primaryPost,
    secondary_posts: secondaryPosts
  } = slice
  console.log(primaryPost, secondaryPosts)
  const classes = useStyles()

  const sectionRef = useRef()
  const contentRef = useRef()

  useStickyElement(sectionRef, contentRef)

  // return null

  return (
    <div className={classes.content}>
      <h2 className={classes.featuredTitle}>Featured</h2>
      <div className={classes.cols} ref={sectionRef}>
        <div className={cn(classes.col, classes.colLeft)}>
          <div className={classes.featuredWrapper} ref={contentRef}>
            {null}
          </div>
        </div>
        <div className={cn(classes.col, classes.colRight)}>
          {null}
        </div>
      </div>
    </div>
  )
}

const useStyles = createUseStyles(
  {
    content: {
      padding: [vw(30), theme.getMargin('min')],
      position: 'relative',
      [theme.breakpoints.up('md')]: {
        padding: [vw(40, 'md'), theme.getMargin('md')]
      }
    },
    cols: {
      display: 'flex',
      flexDirection: 'column',
      [theme.breakpoints.up('md')]: {
        flexDirection: 'row'
      }
    },
    col: {
      display: 'block',
      marginBottom: vw(50),
      '&:last-child': {
        marginBottom: 0
      },
      [theme.breakpoints.up('md')]: {
        marginBottom: 0,
        flex: 1,
        '&:first-child': {
          maxWidth: span(12, 'md'),
          marginRight: span(1, 'md')
        },
        '&:last-child': {
          maxWidth: span(10, 'md'),
          marginLeft: span(1, 'md')
        }
      }
    },
    featuredTitle: {
      extend: theme.typography.h5,
      marginBottom: vw(30),
      [theme.breakpoints.up('md')]: {
        marginBottom: vw(30, 'md')
      }
    }
  },
  { name: 'FeaturedFutureMapsListSlice' }
)

export default FeaturedFutureMapsListSlice
