import React, { forwardRef, useState } from 'react'
import { createUseStyles } from 'react-jss'
import cn from 'classnames'
import Link from '../Link'
import ResponsiveImage from '../ResponsiveImage'
import theme, { vw } from '../../style/theme'
import { quart } from '../../style/eases'
import { resolveInternalLinkUrl } from '../../helpers/resolveLink'
import DropdownFilters from './ListFilters/DropdownFilters'
import { convertCase } from '../../helpers/string'

const TeamMemberTile = ({ photo, position, title, page, location }) => {
  const classes = useStyles()
  const url = resolveInternalLinkUrl(page)
  const alt = photo.alt || `A photo of ${title}`
  return (
    <Link className={classes.teamMemberTile} to={url} nonLinkTag='div'>
      <div className={classes.hoverEffect} />
      <ResponsiveImage className={classes.profilePhoto} {...photo} alt={alt} aspect={325 / 420} />
      <span className={classes.teamMemberPosition}>{position}</span>
      <h4 className={classes.teamMemberName}>{title}</h4>
      {location && (
        <div className={classes.locationBox}>
          <svg className={classes.locationIcon} xmlns='http://www.w3.org/2000/svg' width='11' height='22' viewBox='0 0 11 22' fill='none'>
            <path d='M1.61572 7.54356L5.50016 14L9.3846 7.54356C11.0954 4.69932 9.04525 1.08452 5.62748 1.00156C5.58486 1.00052 5.54224 1 5.49962 1C5.457 1 5.41438 1.00052 5.37176 1.00156C1.95453 1.08452 -0.0955924 4.69932 1.61572 7.54356Z' stroke='#2F80ED' strokeMiterlimit='10' strokeLinejoin='round' />
            <path d='M5.49964 7.27443C4.34653 7.27443 3.41175 6.37596 3.41175 5.26765C3.41175 4.15933 4.34653 3.26086 5.49964 3.26086C6.65275 3.26086 7.58752 4.15933 7.58752 5.26765C7.58752 6.37596 6.65275 7.27443 5.49964 7.27443Z' stroke='#2F80ED' strokeMiterlimit='10' />
          </svg>
          <div className={classes.locationName}>{location}</div>
        </div>
      )}
    </Link>
  )
}

const TeamGrid = forwardRef(({ className, slice }, ref) => {
  const { groups } = slice
  const classes = useStyles()
  const [locationFilter, setLocationFilter] = useState('')
  const locationsList = []
  var teams = []
  const locationFilteOptions = []

  if (groups && Array.isArray(groups) && groups.length) {
    for (let i = 0; i < groups.length; i++) {
      const group = groups[i]
      const { teamMembers } = group
      if (teamMembers && Array.isArray(teamMembers) && teamMembers.length) {
        for (let j = 0; j < teamMembers.length; j++) {
          const teamMember = teamMembers[j]
          const location = teamMember.location
          if (location && !locationsList.includes(location)) {
            locationsList.push(location)
          }
        }
      }
    }

    for (let i = 0; i < groups.length; i++) {
      const group = groups[i]
      const { id, title, teamMembers } = group
      var filterLocations = []
      if (teamMembers) {
        for (let j = 0; j < teamMembers.length; j++) {
          const teamMember = teamMembers[j]
          const locationOfTeamMember = teamMember.location
          if (locationFilter === '' || locationOfTeamMember === locationFilter) {
            filterLocations.push(
              <TeamMemberTile key={teamMember && teamMember.id} {...teamMember} />
            )
          }
        }
      }

      if (filterLocations.length) {
        teams.push(
          <div key={id} className={classes.gridRow}>
            <h2 className={classes.rowTitle}>{title}</h2>
            <div className={classes.teamMembers}>{filterLocations}</div>
          </div>
        )
      }
    }
  }

  locationsList.sort()
  locationFilteOptions.push({ text: 'Location', value: '' })
  if (locationsList && locationsList.length) {
    for (let i = 0; i < locationsList.length; i++) {
      locationFilteOptions.push({ text: convertCase(locationsList[i]), value: locationsList[i] })
    }
  }

  return (
    <section className={cn(classes.content, className)}>
      <div className={classes.headingBox}>
        <div className={classes.filterContainer}>
          <div className={classes.label}>Filter by</div>
          <DropdownFilters className={classes.locationFilter} options={locationFilteOptions} value={locationFilter} setValue={setLocationFilter} />
        </div>
      </div>
      <div className={classes.seperator} />
      {teams}
    </section>
  )
})

const useStyles = createUseStyles({
  content: {
    padding: [0, theme.getMargin('min')],
    position: 'relative',
    [theme.breakpoints.up('md')]: {
      padding: [25, theme.getMargin('md'), 200]
    }
  },
  gridRow: {
    marginBottom: 50,
    [theme.breakpoints.up('md')]: {
      marginBottom: 130
    }
  },
  rowTitle: {
    extend: theme.typography.h4,
    marginBottom: vw(25),
    [theme.breakpoints.up('md')]: {
      marginBottom: vw(25, 'lg')
    }
  },
  mainTitle: {
    extend: theme.typography.h3
  },
  teamMembers: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    columnGap: `${theme.getGutter()}px`,
    rowGap: vw(25),
    [theme.breakpoints.up('md')]: {
      gridTemplateColumns: '1fr 1fr 1fr 1fr',
      columnGap: `${theme.getGutter('md')}px`,
      rowGap: vw(50, 'lg')
    }
  },
  teamMemberTile: {
    display: 'block',
    textDecoration: 'none',
    color: theme.colors.text,
    transition: 'color 0.3s ease',
    position: 'relative',
    '&:hover': {
      color: theme.colors.primary
    },
    [theme.breakpoints.up('md')]: {
      '&:hover': {
        color: theme.colors.white
      },
      '&:before': {
        top: `calc(-${theme.getGutter('md')}px / 2)`,
        left: `calc(-${theme.getGutter('md')}px / 2)`,
        height: `calc(100% + ${theme.getGutter('md')}px)`,
        width: `calc(100% + ${theme.getGutter('md')}px)`
      }
    }
  },
  hoverEffect: {
    position: 'absolute',
    top: `calc(-${theme.getGutter()}px / 2)`,
    left: `calc(-${theme.getGutter()}px / 2)`,
    height: `calc(100% + ${theme.getGutter()}px)`,
    width: `calc(100% + ${theme.getGutter()}px)`,
    zIndex: -1,
    overflow: 'hidden',
    // The hover effect is hidden on mobile as it take the focus and the scrolling gets locked to the parent
    // which causes issues in dialogs
    display: 'none',
    '&:before': {
      content: "''",
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: -2,
      backgroundColor: theme.colors.secondary,
      transform: 'translateY(100%)',
      transition: `transform 0.5s ${quart.inOut}`,
      '$teamMemberTile:hover &': {
        transform: 'translateY(0%)'
      }
    },
    [theme.breakpoints.up('md')]: {
      display: 'block',
      top: `calc(-${theme.getGutter('md')}px / 2)`,
      left: `calc(-${theme.getGutter('md')}px / 2)`,
      height: `calc(100% + ${theme.getGutter('md')}px)`,
      width: `calc(100% + ${theme.getGutter('md')}px)`
    }
  },
  profilePhoto: {
    marginBottom: vw(5),
    [theme.breakpoints.up('md')]: {
      marginBottom: vw(20, 'lg')
    }
  },
  teamMemberPosition: {
    display: 'block',
    extend: theme.typography.bodySmall,
    marginBottom: vw(8),
    [theme.breakpoints.up('md')]: {
      marginBottom: vw(8, 'lg')
    }
  },
  teamMemberName: {
    extend: theme.typography.bodyPlus,
    textTransform: 'capitalize',
    marginBottom: vw(8),
    [theme.breakpoints.up('md')]: {
      marginBottom: vw(8, 'lg')
    }
  },
  headingBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      alignItems: 'flex-start'
    }
  },
  filterContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      marginTop: 30
    }
  },
  label: {
    extend: theme.typography.bodySmall,
    marginRight: 50,
    whiteSpace: 'nowrap'
  },
  locationFilter: {
    [theme.breakpoints.up('md')]: {
      display: 'block'
    }
  },
  seperator: {
    height: 1,
    width: '100%',
    backgroundColor: theme.colors.grey,
    marginTop: 40,
    marginBottom: 60,
    [theme.breakpoints.down('md')]: {
      marginTop: 20,
      marginBottom: 30
    }
  },
  locationBox: {
    display: 'flex',
    alignItems: 'center'
  },
  locationIcon: {
    width: 'auto',
    height: 25,
    marginTop: 5,
    marginRight: 8
  },
  locationName: {
    color: theme.colors.textLight,
    textTransform: 'capitalize',
    marginLeft: 5,
    lineHeight: 1
  }
}, { name: 'TeamGrid' })

export default TeamGrid
