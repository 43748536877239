import React, { useCallback, useRef, useState } from 'react'
import gsap from 'gsap'
import Color from 'color'
import { useSelector } from 'react-redux'
import ScrollTrigger from 'gsap/ScrollTrigger'
import { createUseStyles } from 'react-jss'
import { useScrollReadyListener, useScrollVelocityListener } from '../../useSmoothScroll'
import theme, { vw } from '../../../style/theme'
import last from 'lodash/last'
import { getTimelineBackgroundColor } from '../../../redux/slices/timeline'
import { getForegroundColorFromBackgroundColor } from '../../../style/colorHelper'
import { isCurrentBreakpointMobile } from '../../../redux/slices/layout'
import Blocks from './Blocks'
// import EndBlock from './EndBlock'
import RichText from '../../RichText'

export const TEXT_POSITION_LEFT = 'left'
export const TEXT_POSITION_RIGHT = 'right'

const TimelineSlice = ({ slice }) => {
  const {
    end_label: endLabel,
    // end_content: endContent,
    blocks,
    title,
    sideTitle,
    subheading
  } = slice
  const [showMarker, setShowMarker] = useState(false)
  const [complete, setComplete] = useState(false)
  const backgroundColor = useSelector(getTimelineBackgroundColor)
  const foregroundColor = getForegroundColorFromBackgroundColor(backgroundColor)
  const isMobile = useSelector(isCurrentBreakpointMobile)
  const classes = useStyles({ showMarker, backgroundColor, foregroundColor, complete })

  const lineRef = useRef()
  const progressLineRef = useRef()
  const progressMarkerRef = useRef()
  const timelineContainerRef = useRef()

  const calculateProgressPx = useCallback((scrollTrigger) => {
    if (lineRef && lineRef.current) {
      const progressPx = parseInt(scrollTrigger.progress * lineRef.current.clientHeight)
      const markerOffset = 15
      const revealMarker = progressPx > markerOffset && progressPx < (lineRef.current.clientHeight - markerOffset)
      setShowMarker(revealMarker)
    }
  }, [])

  useScrollVelocityListener(useCallback((velocity) => {
    if (progressMarkerRef.current) {
      var skewY = gsap.utils.interpolate(0, 50, Math.min(50, Math.abs(velocity)) / 50) * (velocity < 0 ? 1 : -1)
      gsap.set(progressMarkerRef.current.children[0], { skewY })
    }
  }, []))

  const setupScrollTriggers = useCallback(({ scrollElement }) => {
    if (lineRef.current) {
      if (!endLabel) {
        // Moves the line up to the top of the last block
        const lastBlock = last(lineRef.current.parentNode.children)
        if (lastBlock) {
          var styles = window.getComputedStyle(lastBlock)
          var containerStyles = window.getComputedStyle(timelineContainerRef.current)
          var margin = parseFloat(styles.marginTop) + parseFloat(styles.marginBottom)
          var padding = parseFloat(containerStyles.paddingBottom)
          gsap.set([lineRef.current, progressLineRef.current, progressMarkerRef.current], { bottom: lastBlock.offsetHeight + margin + padding })
        }
      }

      const tl = gsap.timeline()
      const scrollTrigger = ScrollTrigger.create({
        scroller: scrollElement,
        trigger: lineRef.current,
        pin: false,
        start: 'top 75%',
        end: 'bottom 75%',
        scrub: isMobile ? 0.2 : 0,
        animation: tl,
        onUpdate: scrollTrigger => calculateProgressPx(scrollTrigger),
        onEnterBack: () => setComplete(false),
        onLeave: () => setComplete(true)
      })
      tl.fromTo(progressLineRef.current, { scaleY: 0 }, { scaleY: '1', ease: 'none' }, 0)
      tl.fromTo(progressMarkerRef.current, { y: 0 }, { y: '100%', ease: 'none' }, 0)

      return () => {
        tl.kill()
        scrollTrigger.kill()
      }
    }
  }, [setShowMarker, isMobile])

  useScrollReadyListener(setupScrollTriggers)

  return (
    <div>
      <section className={classes.content}>
        {sideTitle && <RichText className={classes.title} content={sideTitle.text} />}
        {/* {subheading && <p className={classes.subheading}>{subheading}</p>} */}
        {subheading && (
          <div className={classes.subWrap}>
            {/* <span className={classes.subWrapSpan}>NEW</span> */}
            <div className={classes.subheading}>Stay tuned for the final</div>
            <div className={classes.subheading}><strong>FR22 Agenda</strong> </div>
          </div>
        )}
        {title && <h3 className={classes.title}>{title}</h3>}
        {/* {slice.sideTitle && <h3 className={classes.title}>{slice.sideTitle}</h3>} */}
        <div className={classes.timelineContainer} ref={timelineContainerRef}>
          <div className={classes.staticLine} ref={lineRef} />
          <div className={classes.progressLine} ref={progressLineRef} />
          <div className={classes.progressMarker} ref={progressMarkerRef}>
            <div className={classes.progressMarkerShape} />
          </div>
          <Blocks blocks={blocks} />
          {endLabel && <span className={classes.endLabel}>{endLabel}</span>}
          {endLabel && <span className={classes.endMarker} />}
        </div>
        {/* {sideTitle && <EndBlock show={complete} endContent={sideTitle} />} */}
      </section>
    </div>
  )
}

const useStyles = createUseStyles({
  content: {
    backgroundColor: ({ backgroundColor }) => backgroundColor || theme.colors.white,
    color: ({ foregroundColor }) => foregroundColor || theme.colors.secondary,
    transition: 'background-color 0.3s ease-in-out, color 0.3s ease-in-out',
    padding: [60, theme.getMargin('min')],
    position: 'relative',
    [theme.breakpoints.up('md')]: {
      padding: [100, theme.getMargin('md')],
      paddingBottom: vw(0)
    }
  },
  title: {
    marginBottom: vw(64),
    [theme.breakpoints.up('md')]: {
      position: 'absolute',
      top: 100,
      marginBottom: 0
    }
  },
  // subheadingWrap: {
  //   position: 'relative',
  //   paddingBottom: vw(1),
  //   [theme.breakpoints.up('md')]: {
  //     paddingBottom: vw(1, 'lg')
  //   }
  // },
  // subWrap: {
  //   borderRadius: '20px',
  //   background: 'darkgray',
  //   width: '40%',
  //   display: 'flex',
  //   alignItems: 'center',
  //   justifyContent: 'start',
  //   marginTop: vw(20),
  // },
  subheading: {
    width: '1/3',
    color: '#000',
    fontSize: '16px',
    [theme.breakpoints.up('mobile')]: {
      fontSize: vw(20)
    },
    [theme.breakpoints.up('fab')]: {
      fontSize: vw(20)
    },
    [theme.breakpoints.up('xs')]: {
      fontSize: vw(25)
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: vw(20)
    },
    [theme.breakpoints.up('md')]: {
      fontSize: vw(6)
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: vw(6)
    },
    [theme.breakpoints.up('xl')]: {
      fontSize: vw(6)
    },
    [theme.breakpoints.up('xxl')]: {
      fontSize: vw(6)
    },
    [theme.breakpoints.up('xxxl')]: {
      fontSize: vw(6)
    }
  },
  subWrap: {
    borderRadius: '20px',
    background: 'darkgray',
    width: '80%',
    marginTop: vw(-40),
    backgroundColor: 'yellow',
    padding: vw(10),
    color: '#000',
    // paddingLeft: 0,
    marginBottom: vw(64),
    [theme.breakpoints.up('mobile')]: {
      marginTop: vw(-40),
      width: '80%',
      backgroundColor: 'yellow',
      // padding: [vw(6, 'lg'), vw(24, 'lg')],
      padding: vw(12),
      // paddingLeft: 10,
      color: '#000',
      // textAlign: 'center',
      marginBottom: vw(64)
    },
    [theme.breakpoints.up('fab')]: {
      marginTop: vw(-40),
      width: '70%',
      backgroundColor: 'yellow',
      padding: [vw(6, 'lg'), vw(24, 'lg')],
      // paddingLeft: 10,
      color: '#000',
      // textAlign: 'center',
      marginBottom: vw(64)
    },
    [theme.breakpoints.up('xs')]: {
      marginTop: vw(-40),
      width: '90%',
      backgroundColor: 'yellow',
      padding: [vw(60, 'lg'), vw(24, 'lg')],
      // paddingLeft: 10,
      color: '#000',
      // textAlign: 'center',
      marginBottom: vw(64)
    },
    [theme.breakpoints.up('sm')]: {
      marginTop: vw(0),
      width: '68%',
      backgroundColor: 'yellow',
      padding: vw(5),
      // paddingLeft: 0,
      color: '#000'
      // marginBottom: vw(64)
    },
    [theme.breakpoints.up('md')]: {
      marginTop: vw(60),
      width: vw(75),
      padding: vw(3),
      // borderRadius: 20,
      color: '#000',
      position: 'absolute',
      top: 10
      // marginBottom: 0
    },
    [theme.breakpoints.up('lg')]: {
      marginTop: vw(50),
      width: vw(75),
      padding: vw(3),
      // borderRadius: 20,
      color: '#000',
      position: 'absolute',
      top: 10
      // marginBottom: 0
    },
    [theme.breakpoints.up('xl')]: {
      marginTop: vw(40),
      width: vw(75),
      padding: vw(3),
      // borderRadius: 20,
      color: '#000',
      position: 'absolute',
      top: 10
      // marginBottom: 0
    }
  },
  timelineContainer: {
    position: 'relative',
    paddingBottom: vw(1),
    [theme.breakpoints.up('md')]: {
      paddingBottom: vw(1, 'lg')
    }
  },
  endMarker: {
    display: 'block',
    content: "''",
    position: 'absolute',
    bottom: 0,
    left: 0,
    transform: 'translateX(-50%)',
    height: 11,
    width: 11,
    border: [1, 'solid'],
    borderColor: ({ complete }) => complete ? theme.colors.primary : theme.colors.secondary,
    backgroundColor: ({ complete }) => complete ? theme.colors.primary : theme.colors.white,
    transition: 'border-color 0.3s ease-out, background-color 0.3s ease-out',
    zIndex: 2,
    [theme.breakpoints.up('md')]: {
      left: '50%'
    }
  },
  progressMarker: {
    display: 'block',
    position: 'absolute',
    top: -9,
    left: 0,
    zIndex: 3,
    bottom: -9,
    /// transform: 'translateX(-50%)',
    opacity: ({ showMarker }) => showMarker ? 1 : 0,
    transition: 'opacity 0.3s ease-out',
    [theme.breakpoints.up('md')]: {
      left: '50%'
    }
  },
  progressMarkerShape: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: -6,
    right: 0,
    height: 12,
    width: 12,
    backgroundColor: theme.colors.primary
  },
  staticLine: {
    width: 2,
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    backgroundColor: ({ backgroundColor }) => backgroundColor
      ? Color(backgroundColor).isLight() ? theme.colors.secondary : theme.colors.offWhite
      : theme.colors.secondary,
    transition: 'background-color 0.3s ease-in-out',
    zIndex: 1,
    transform: 'translateX(-50%)',
    [theme.breakpoints.up('md')]: {
      left: '50%'
    }
  },
  progressLine: {
    width: 2,
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    background: theme.colors.primary,
    zIndex: 2,
    transformOrigin: 'top',
    transform: 'translateX(-50%)',
    [theme.breakpoints.up('md')]: {
      left: '50%'
    }
  },
  endLabel: {
    display: 'block',
    extend: theme.typography.h6,
    position: 'absolute',
    left: 20,
    bottom: 0,
    transform: 'translateY(2px)',
    [theme.breakpoints.up('md')]: {
      position: 'absolute',
      left: '50%',
      transform: 'translateX(calc(-100% - 30px)) translateY(2px)'
    }
  }
}, { name: 'TimelineSlice' })

export default TimelineSlice
