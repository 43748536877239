import React, { useRef, useState, useEffect } from 'react'
import gsap from 'gsap'
import { createUseStyles } from 'react-jss'
import RichText from '../../../RichText'
import ResponsiveImage from '../../../ResponsiveImage'
import theme, { vw } from '../../../../style/theme'
import PlusIcon from '../../../../images/PlusIcon'
import Minus from '../../../../images/Minus'
import { span } from '../../../../style/span'
import { TEXT_POSITION_RIGHT } from '..'

export const BLOCK_SIZE_SMALL = 'small'
export const BLOCK_SIZE_LARGE = 'large'
export const IMAGE_INLINE = 'inline'
export const IMAGE_OPPOSITE = 'opposite'

const animateInValues = { ease: 'expo.out', yPercent: 0, opacity: 1, stagger: 0.16, duration: 1.6, visibility: 'visible' }
const animateOutValues = { ease: 'expo.out', yPercent: 20, opacity: 0, duration: 0 }

const TimelineTwoColumnBlock = ({ block, isActive }) => {
  const [isOptionOpen, setIsOptionOpen] = useState(false)
  const toggleButton = () => setIsOptionOpen(!isOptionOpen)
  const {
    headings,
    text,
    text_column_position: textPosition,
    size,
    image,
    image_position: imagePosition,
    option_blocks: options
  } = block
  const classes = useStyles({
    size: size ? size.toLowerCase() : null,
    textPosition: textPosition ? textPosition.toLowerCase() : null,
    imagePosition: imagePosition ? imagePosition.toLowerCase() : null
  })

  const leftColRef = useRef()
  const rightColRef = useRef()

  useEffect(() => {
    if (leftColRef.current && rightColRef.current) {
      gsap.set(leftColRef.current.children, animateOutValues)
      gsap.set(rightColRef.current.children, animateOutValues)
    }
  }, [])

  useEffect(() => {
    if (leftColRef.current && rightColRef.current) {
      if (isActive) {
        gsap.to(leftColRef.current.children, animateInValues)
        gsap.to(rightColRef.current.children, animateInValues)
      }
    }
  }, [isActive])

  return (
    <section className={classes.content}>
      <div className={classes.cols}>
        <div className={classes.col} ref={leftColRef}>
          <div />
          {image && imagePosition && imagePosition.toLowerCase() === IMAGE_OPPOSITE && (
            <div className={classes.container}>
              <ResponsiveImage className={classes.image} {...image} />
            </div>
          )}
        </div>
        <div className={classes.col}>
          {options &&
            <button className={classes.downloadIcon} onClick={toggleButton}>
              {isOptionOpen ? <Minus /> : <PlusIcon />}
            </button>}
          <div className={classes.container} ref={rightColRef}>
            {image && imagePosition && imagePosition.toLowerCase() === IMAGE_INLINE && (
              <div className={classes.inlineImage}>
                <ResponsiveImage className={classes.image} {...image} />
              </div>
            )}
            {headings && <RichText className={classes.headings} content={headings.text} />}
            {text && <RichText className={classes.bodyText} content={text.text} />}
            {options && isOptionOpen && options.map(({ time, subject, speaker, id }) => (
              <div className={`${classes.cols2} ${isOptionOpen}`} key={id}>
                <div className={classes.col2}>
                  <h6 className={classes.time}>{time}</h6>
                  <p className={classes.subject}>{subject}</p>
                </div>
                <div className={classes.col2}>
                  <p className={classes.speaker}>{speaker}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  )
}

const useStyles = createUseStyles({
  content: {
    position: 'relative'
  },
  cols: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    width: '100',
    paddingTop: vw(40),
    [theme.breakpoints.up('md')]: {
      flexDirection: ({ textPosition }) => textPosition === TEXT_POSITION_RIGHT ? 'row' : 'row-reverse',
      paddingTop: ({ imagePosition }) => imagePosition === IMAGE_OPPOSITE ? vw(50, 'lg') : 0
    }
  },
  cols2: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    marginTop: vw(15),
    // paddingTop: vw(40),
    [theme.breakpoints.up('md')]: {
      marginTop: vw(10),
      flexDirection: ({ textPosition }) => textPosition === TEXT_POSITION_RIGHT ? 'row' : 'row-reverse',
      paddingTop: ({ imagePosition }) => imagePosition === IMAGE_OPPOSITE ? vw(50, 'lg') : 0
    }
  },
  col2: {
    marginRight: ({ textPosition }) => textPosition === TEXT_POSITION_RIGHT ? span(0.5, 'md') : 0
  },
  time: {
    marginBottom: 10,
    [theme.breakpoints.up('md')]: {
      marginBottom: vw(2)
    }
  },
  col: {
    '&:first-child': {
      marginBottom: ({ imagePosition }) => imagePosition === IMAGE_OPPOSITE ? vw(25) : 0
    },
    [theme.breakpoints.up('md')]: {
      flex: 1,
      display: 'flex',
      '&:first-child': {
        marginBottom: 0,
        marginLeft: ({ textPosition }) => textPosition === TEXT_POSITION_RIGHT ? 0 : span(1.5, 'md'),
        marginRight: ({ textPosition }) => textPosition === TEXT_POSITION_RIGHT ? span(1.5, 'md') : 0,
        justifyContent: ({ textPosition }) => textPosition === TEXT_POSITION_RIGHT ? 'flex-end' : 'flex-start'
      },
      '&:last-child': {
        marginRight: ({ textPosition }) => textPosition === TEXT_POSITION_RIGHT ? 0 : span(1.5, 'md'),
        marginLeft: ({ textPosition }) => textPosition === TEXT_POSITION_RIGHT ? span(1.5, 'md') : 0,
        justifyContent: ({ textPosition }) => textPosition === TEXT_POSITION_RIGHT ? 'flex-start' : 'flex-end'
      }
    }
  },
  container: {
    [theme.breakpoints.up('md')]: {
      width: ({ size }) => size === BLOCK_SIZE_LARGE ? span(8, 'md') : span(8, 'md'),
      marginTop: '-10px'
    }
  },
  inlineImage: {
    marginBottom: vw(25),
    [theme.breakpoints.up('md')]: {
      marginBottom: vw(25, 'lg')
    }
  },
  active: {
    display: 'block'
  },
  downloadIcon: {
    width: 30,
    cursor: 'pointer',
    height: 30,
    marginRight: 5,
    [theme.breakpoints.up('md')]: {
      marginRight: 15,
      marginTop: '-10px',
      width: 36,
      height: 36
    }
  },
  plusIcon: {
    fontSize: '50px',
    border: '1px solid black',
    padding: '5px 15px',
    margin: 0
  },
  headings: {
    marginBottom: vw(30),
    [theme.breakpoints.up('md')]: {
      marginBottom: ({ size }) => size === BLOCK_SIZE_LARGE ? vw(50, 'lg') : vw(25, 'lg')
    },
    '& h1, h2, h3, h4, h5': {
      marginBottom: vw(28),
      [theme.breakpoints.up('md')]: {
        marginBottom: vw(28, 'lg')
      }
    },
    '& h3': {
      marginBottom: vw(35),
      [theme.breakpoints.up('md')]: {
        marginBottom: vw(35, 'lg')
      }
    },
    '& h6': {
      marginBottom: vw(10),
      [theme.breakpoints.up('md')]: {
        marginBottom: vw(10, 'lg')
      }
    }
  }
}, { name: 'TimelineTwoColumnBlock' })

export default TimelineTwoColumnBlock
