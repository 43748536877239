import React from 'react'
import { createUseStyles } from 'react-jss'
import cn from 'classnames'
import { useSelector } from 'react-redux'
import { getFooter } from '../../../redux/slices/content'
import Form from './Form'
import Faq from './FAQ'
import Locations from '../../Locations'
import theme, { span, vw } from '../../../style/theme'

export default ({ slice }) => {
  const classes = useStyles()
  const {
    email,
    locations
    // phone
  } = useSelector(getFooter)
  return (
    <section className={classes.section}>
      <div className={cn(classes.col, classes.colLeft)}>
        <Form slice={slice} />
      </div>
      <div className={cn(classes.col, classes.colRight)}>
        <div className={classes.colRightInner}>
          <Faq slice={slice} />
          <div className={classes.colRightBottom}>
            <div className={classes.emailContact}>
              <span className={classes.emailTitle}>
                General Enquiries
              </span>
              <a className={classes.emailLink} href={`mailto:${email}`}>
                {email}
              </a>
            </div>
            <Locations className={classes.locations} locations={locations} showPhone />
          </div>
        </div>
      </div>
    </section>
  )
}

const useStyles = createUseStyles({
  section: {
    minHeight: '100vh',
    [theme.breakpoints.up('md')]: {
      display: 'flex'
    }
  },
  col: {
    [theme.breakpoints.up('md')]: {
      width: '50%'
    }
  },
  colLeft: {

  },
  colRight: {
    color: theme.colors.white,
    backgroundColor: theme.colors.secondary,
    padding: [vw(32), theme.getMargin()],
    [theme.breakpoints.up('md')]: {
      padding: [vw(120, 'lg'), theme.getMargin('md')]
    }
  },
  colRightInner: {
    [theme.breakpoints.up('md')]: {
      maxWidth: span(9, 'md')
    }
  },
  locations: {
    justifyContent: 'flex-start',
    stroke: theme.colors.text
  },
  emailContact: {
    marginBottom: vw(32),
    [theme.breakpoints.up('md')]: {
      marginBottom: vw(32, 'lg')
    }
  },
  emailTitle: {
    display: 'block',
    extend: theme.typography.bodySmall,
    opacity: 0.5,
    marginBottom: 8
  },
  emailLink: {
    margin: 0,
    extend: theme.typography.bodySmall,
    color: 'currentColor'
  }
})
