import React, { useMemo } from 'react'
import cn from 'classnames'
import { createUseStyles } from 'react-jss'
import map from 'lodash/map'
import theme, { vw } from '../../style/theme'
import ResponsiveImage from '../ResponsiveImage2'
import Slider, { flickityOptions } from '../Slider2'
import { useDarkSlice } from '../HeaderContextProvider'

export default ({ className, slice }) => {
  const classes = useStyles()
  const { title, images, auto_scroll: autoScroll } = slice
  const options = useMemo(() => ({
    ...flickityOptions,
    autoPlay: autoScroll
  }), [autoScroll])
  const ref = useDarkSlice()
  return (
    <section className={cn(classes.imageSlider, className)} ref={ref}>
      {/* <div className={classes.floatingBtn}>
        <div className={classes.links}>
          <a href={btnUrl}>
            <button className={classes.btn}>
              {btnTitle}
            </button>
          </a>
        </div>
      </div> */}
      <div className={classes.container}>
        <h3 className={classes.title}>{title}</h3>
        <Slider className={classes.slider} options={options}>
          {map(images, (image, i) => (
            <ResponsiveImage key={i} {...image} aspect={2} className={classes.image} />
          ))}
        </Slider>
      </div>
    </section>
  )
}

const useStyles = createUseStyles({
  imageSlider: {
    position: 'relative',
    overflow: 'hidden'
  },
  container: {
    // margin: [0, theme.getMargin(), vw(60)],
    // [theme.breakpoints.up('md')]: {
    //   margin: [0, theme.getMargin('md'), vw(100, 'lg')]
    // }
    marginBottom: vw(0)
  },
  title: {
    extend: theme.typography.h4,
    marginBottom: vw(0),
    [theme.breakpoints.up('md')]: {
      marginBottom: vw(0, 'lg')
    }
  },
  floatingBtn: {
    position: 'absolute',
    top: 100,
    right: 28,
    zIndex: 9999,
    [theme.breakpoints.up('md')]: {
      right: 4
    }
  },
  slider: {
    margin: [0, -theme.getMargin()],
    [theme.breakpoints.up('md')]: {
      margin: 0
    }
  },
  btn: {
    // width: '350px',
    fontFamily: 'Mars Condensed',
    textTransform: 'uppercase',
    fontSize: '20px',
    fontWeight: 400,
    // lineHeight: '36px',
    letterSpacing: '0.03em',
    backgroundColor: '#3283FF',
    cursor: 'pointer',
    color: '#FFFFFF',
    textAlign: 'center',
    padding: '5px 10px',
    [theme.breakpoints.up('md')]: {
      marginRight: theme.getMargin('md'),
      fontSize: '30px',
      padding: '10px 40px'
    }
  },
  image: {
    [theme.breakpoints.up('md')]: {
      marginRight: theme.getMargin('md')
    }
  }
})
