import React from 'react'
import { createUseStyles } from 'react-jss'
// import map from 'lodash/map'
import theme, { vw } from '../../../style/theme'
import RichText from '../../RichText'
import RightSideText from './RightSideText'

export default ({ slice, page }) => {
  const { custom_meta_fields: customFields, copy } = slice
  const classes = useStyles()
  const readingTime = customFields[0]

  return (
    <div className={classes.container}>
      <div className={classes.readContainer}>
        <div className={classes.readTime}>
          <p className={classes.fieldLabel}>{readingTime.label}:</p>
          <RichText content={readingTime.value.text} className={classes.fieldValue} />
        </div>
      </div>
      <div className={classes.content}>
        <RightSideText text={copy.text} />
      </div>
    </div>
  )
}

const useStyles = createUseStyles({
  container: {
    marginBottom: '10px',
    [theme.breakpoints.up('md')]: {
      marginBottom: '30px'
    }
  },

  readContainer: {
    padding: '0 20px',
    [theme.breakpoints.up('md')]: {
      width: vw(1000, 'lg'),
      marginLeft: 'auto',
      marginRight: theme.getMargin('md'),
      padding: '0 50px'
    }
  },

  readTime: {
    width: '100%',
    display: 'flex',
    paddingBottom: '14px',
    marginBottom: '20px',
    borderBottom: '1px solid #9999A7',
    [theme.breakpoints.up('md')]: {
      marginLeft: 'auto',
      marginRight: theme.getMargin('md')
    }
  },

  fieldLabel: {
    color: '#9999A7',
    margin: 0
  },

  fieldValue: {
    color: theme.colors.primary,
    marginLeft: '8px'
  },

  content: {
    textAlign: 'justify'
  }
}, 'PostIntroNew')
