import React, { useMemo } from 'react'
import { createUseStyles } from 'react-jss'
import theme, { vw } from '../../style/theme'
import ResponsiveImage from '../ResponsiveImage'
import Color from 'color'
import Link from '../Link'
import { resolveLink } from '../../helpers/resolveLink'

const Founder = ({ title, position, link }) => {
  const classes = useStyles()
  const resolvedLink = useMemo(() => link && resolveLink(link[0]), [link])
  return (
    <li className={classes.item}>
      <Link link={resolvedLink} nonLinkTag='span' className={classes.link}>
        {title && <span className={classes.founderTitle}>{title}</span>}
        {resolvedLink && <span className={classes.linkText}>{resolvedLink.text}</span>}
      </Link>
    </li>
  )
}

export default ({ slice }) => {
  const {
    image,
    title,
    founders
  } = slice
  const classes = useStyles()

  return (
    <section className={classes.section}>
      <ResponsiveImage {...image} className={classes.image} aspect={957 / 575} />
      <div className={classes.content}>
        {title && <h4 className={classes.title}>{title}</h4>}
        <div className={classes.listContainer}>
          <ul className={classes.list}>
            {founders.map((founder, i) => (
              <Founder key={i} {...founder} />
            ))}
          </ul>
        </div>
      </div>
    </section>
  )
}

const useStyles = createUseStyles({
  section: {
    display: 'block',
    margin: [0, 0, vw(50)],
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      flexDirection: 'row-reverse',
      margin: [0, theme.getMargin('md'), vw(80, 'lg')]
    }
  },
  content: {
    flexGrow: 1,
    flexShrink: 1,
    display: 'block',
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      marginRight: vw(32, 'lg')
    }
  },
  image: {
    width: '100%',
    flexGrow: 0,
    flexShrink: 0,
    [theme.breakpoints.up('md')]: {
      width: '72%'
    }
  },
  title: {
    margin: [vw(16), 0, vw(16), theme.getMargin()],
    [theme.breakpoints.up('md')]: {
      margin: [0, 0, vw(45, 'lg'), 0]
    }
  },
  listContainer: {
    flexGrow: 1,
    position: 'relative',
    overflow: 'hidden',
    [theme.breakpoints.up('md')]: {
      height: '100%'
    }
  },
  list: {
    margin: [0, 0, -5],
    padding: 0,
    top: 0,
    left: 0,
    width: '100%',
    overflow: 'auto',
    listStyle: 'none',
    display: 'flex',
    flexDirection: 'row',
    [theme.breakpoints.up('md')]: {
      direction: 'rtl',
      height: '100%',
      position: 'absolute',
      flexDirection: 'column'
    },
    '&::-webkit-scrollbar': {
      width: 6,
      height: 2
    },
    '&::-webkit-scrollbar-track': {
      background: 'rgba(0, 0, 0, 0.2)'
    },
    '&::-webkit-scrollbar-thumb': {
      background: Color(theme.colors.primary).alpha(0.5).toString(),
      borderRadius: 6
    },
    '&::-webkit-scrollbar-thumb:hover': {
      background: theme.colors.primary
    }
  },
  item: {
    textAlign: 'left',
    display: 'block',
    margin: [0, 5, vw(30), 25],
    whiteSpace: 'nowrap',
    [theme.breakpoints.up('md')]: {
      whiteSpace: 'normal',
      margin: [0, 0, vw(30, 'lg'), vw(16, 'lg')]
    }
  },
  link: {
    textDecoration: 'none',
    color: 'inherit',
    display: 'block',
    minWidth: '30vw',
    [theme.breakpoints.up('md')]: {
      minWidth: '100%'
    }
  },
  linkText: {
    fontSize: vw(12),
    color: theme.colors.primary,
    textDecoration: 'underline',
    [theme.breakpoints.up('md')]: {
      fontSize: vw(12, 'lg')
    }
  },
  position: {
    fontSize: vw(12),
    [theme.breakpoints.up('md')]: {
      fontSize: vw(12, 'lg')
    }
  },
  founderTitle: {
    display: 'block'
  }
}, { name: 'FoundersGroup' })
