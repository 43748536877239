import React, { useRef, useState, useEffect, useCallback } from 'react'
import gsap from 'gsap'
import { useInView } from 'react-intersection-observer'
import cn from 'classnames'
import { createUseStyles } from 'react-jss'
import theme, { vw } from '../style/theme'
import composeRefs from '../helpers/composeRefs'

const animateInValues = { ease: 'expo.out', y: 0, opacity: 1, duration: 0.4 }

const CountdownTimer = ({ eventDate }) => {
  const [time, setTime] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0
  })

  const classes = useStyles()

  const [inViewRef, inView] = useInView({ threshold: 0, triggerOnce: true })
  const ref = useRef()

  const setNewTime = useCallback(() => {
    if (eventDate) {
      const currentTime = new Date().getTime()
      const countdownDate = new Date(eventDate)
      const distanceToDate = countdownDate - currentTime
      let days = Math.floor(distanceToDate / (1000 * 60 * 60 * 24))
      let hours = Math.floor(
        (distanceToDate % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      )
      let minutes = Math.floor(
        (distanceToDate % (1000 * 60 * 60)) / (1000 * 60)
      )
      let seconds = Math.floor((distanceToDate % (1000 * 60)) / 1000)

      const numbersToAddZeroTo = [1, 2, 3, 4, 5, 6, 7, 8, 9]

      days = `${days}`
      if (numbersToAddZeroTo.includes(hours)) {
        hours = `0${hours}`
      } else if (numbersToAddZeroTo.includes(minutes)) {
        minutes = `0${minutes}`
      } else if (numbersToAddZeroTo.includes(seconds)) {
        seconds = `0${seconds}`
      }

      setTime({ days: days, hours: hours, minutes, seconds })
    }
  }, [eventDate])

  useEffect(() => {
    setNewTime()
    const tick = setInterval(() => setNewTime(), 1000)
    return () => clearInterval(tick)
  }, [setNewTime])

  useEffect(() => {
    if (ref.current) {
      if (inView) {
        gsap.to(ref.current, animateInValues)
      }
    }
  }, [inView])

  return (
    <div className={classes.timer} ref={composeRefs(ref, inViewRef)}>
      <div className={cn(classes.timeSection, time.days && time.days > 0 ? 'highlighted' : '')}>
        <div className={classes.timeUnit}>{time.days && time.days > 0 ? time.days : '0'}</div>
        <small className={classes.timeLabel}>Days</small>
      </div>
      <span className={classes.separator}>:</span>
      <div className={cn(classes.timeSection, !time.days && time.hours ? 'highlighted' : '')}>
        <div className={classes.timeUnit}>{time.hours && time.hours > 0 ? time.hours : '00'}</div>
        <small className={classes.timeLabel}>Hrs</small>
      </div>
      <span className={classes.separator}>:</span>
      <div className={cn(classes.timeSection, !time.hours && time.minutes ? 'highlighted' : '')}>
        <div className={classes.timeUnit}>{time.minutes && time.minutes > 0 ? time.minutes : '00'}</div>
        <small className={classes.timeLabel}>Mins</small>
      </div>
      <span className={classes.separator}>:</span>
      <div className={cn(classes.timeSection, !time.minutes && time.seconds > 0 ? 'highlighted' : '')}>
        <div className={classes.timeUnit}>{time.seconds && time.seconds > 0 ? time.seconds : '00'}</div>
        <small className={classes.timeLabel}>Secs</small>
      </div>
    </div>
  )
}

const useStyles = createUseStyles({
  timer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingBottom: vw(25),
    marginBottom: vw(20),
    transform: 'translate(0, 20px)',
    opacity: 0,
    [theme.breakpoints.up('md')]: {
      justifyContent: 'flex-start',
      paddingBottom: vw(20, 'md'),
      marginBottom: vw(35, 'md')
    }
  },
  timeSection: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    '&.highlighted': {
      color: theme.colors.primary
    }
  },
  timeUnit: {
    extend: theme.typography.h5,
    textAlign: 'center',
    width: vw(40),
    [theme.breakpoints.up('md')]: {
      extend: theme.typography.h3,
      width: vw(50, 'md')
    }
  },
  timeLabel: {
    display: 'block',
    position: 'absolute',
    bottom: 0,
    transform: 'translateY(calc(100% + 10px))',
    extend: theme.typography.bodyTiny,
    textTransform: 'uppercase',
    lineHeight: 1.5
  },
  separator: {
    extend: theme.typography.h4,
    margin: [0, 20],
    [theme.breakpoints.up('md')]: {
      margin: [0, 35]
    }
  }
}, { name: 'CountdownTimer' })

export default CountdownTimer
