import React from 'react'
import { createUseStyles } from 'react-jss'
import theme from '../../../style/theme'
import VideoAudioPlayer from '../../VideoAudioPlayer'

export default ({ slice }) => {
  const { video, audio, image } = slice
  const classes = useStyles()
  return (
    <section className={classes.section}>
      <VideoAudioPlayer video={video} audio={audio} posterImage={image} />
    </section>
  )
}

const useStyles = createUseStyles({
  section: {
    marginBottom: 60,
    [theme.breakpoints.up('md')]: {
      marginBottom: 100
    }
  }
})
