import React, { useEffect, forwardRef, useRef } from 'react'
import get from 'lodash/get'
import cn from 'classnames'
import { createUseStyles } from 'react-jss'
import round from '../helpers/round'
import defaultImage from '../images/spacer'
import { quart } from '../style/eases'
import ParallaxElement from './ParallaxElement'

const Picture = forwardRef(({ classes, alt, sizes, disableLazy, isContain }, ref) => {
  const hasWebp = !!get(sizes, [0, 'webpUrl'])
  const srcset = key => sizes.map(item => (`${item[key]} ${item.width}w`)).join()
  const srcName = disableLazy ? 'srcSet' : 'data-srcset'
  return (
    <picture>
      {hasWebp && <source {...{ [srcName]: srcset('webpUrl') }} type='image/webp' />}
      {sizes && <source {...{ [srcName]: srcset('url') }} />}
      <img
        ref={ref}
        data-sizes='auto'
        alt={alt}
        className={cn((sizes && !disableLazy && 'lazyload') || (disableLazy && 'lazyloaded'), classes.image, isContain && classes.containImage)}
        src={defaultImage}
      />
    </picture>
  )
})

const NoScript = ({ classes, alt, sizes }) => {
  if (sizes) {
    return (
      <noscript>
        <img
          src={sizes[sizes.length - 1].url}
          srcSet={sizes.map(item => (`${item.url} ${item.width}w`)).join()}
          className={`${classes.image} lazyloaded `}
          alt={alt}
        />
      </noscript>
    )
  }
  return null
}

const ResponsiveImage = React.forwardRef(function ResponsiveImage (props, ref) {
  const { disableLazy, aspect, children, className, alt, sizes, palette, style, title, onImageLoaded, parallax, captionAlignment, isHeightFull, isContain } = props
  const classes = useStyles({ aspect, palette, captionAlignment })
  const pictureRef = useRef()

  useEffect(() => {
    if (onImageLoaded) {
      const onLoaded = (e) => {
        if (pictureRef.current === e.target) {
          onImageLoaded(e)
        }
      }
      document.addEventListener('lazyloaded', onLoaded)
      return () => {
        document.removeEventListener('lazyloaded', onLoaded)
      }
    }
  }, [onImageLoaded])

  return (
    <div className={cn(classes.container, className, { withCaption: title }, isHeightFull ? 'fullHeight' : '')} ref={ref} style={style}>
      {parallax ? (
        <ParallaxElement {...parallax} className={classes.parallax}>
          <Picture classes={classes} alt={alt} sizes={sizes} disableLazy={disableLazy} isContain={isContain} />
        </ParallaxElement>
      ) : (
        <Picture classes={classes} alt={alt} sizes={sizes} disableLazy={disableLazy} ref={pictureRef} isContain={isContain} />
      )}
      <NoScript classes={classes} alt={alt} sizes={sizes} />
      {children}
      {title && <span className={cn(classes.caption, captionAlignment)}>{title}</span>}
    </div>
  )
})

const useStyles = createUseStyles({
  container: {
    position: 'relative',
    width: '100%',
    display: 'block',
    overflow: 'hidden',
    '& picture::before': {
      display: 'block',
      content: ({ aspect }) => aspect ? '""' : undefined,
      paddingTop: ({ aspect }) => aspect ? `${round(100 / aspect)}%` : undefined
    },
    '&.withCaption': {
      overflow: 'visible',
      marginBottom: 32
    },
    '&.fullHeight': {
      height: '100%'
    }
  },

  image: {
    position: 'absolute',
    opacity: 0,
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    transition: `opacity 0.5s ${quart.out}`,
    willChange: 'opacity',
    objectFit: 'cover',
    objectPosition: 'top',
    fontFamily: '"object-fit: cover;"', // object-fit polyfill
    '&.lazyloaded': {
      opacity: 1
    }
  },
  containImage: {
    objectFit: 'contain'
  },
  link: {
    textDecoration: 'none'
  },
  caption: {
    position: 'absolute',
    top: '100%',
    right: 0,
    color: '#9999A7',
    fontSize: 14,
    lineHeight: 1.4,
    marginTop: 4,
    display: 'block',
    '&.left': {
      right: 'inherit',
      left: 0
    }
  },
  parallax: {
    height: '100%'
  }
}, { name: 'ResponsiveImage' })

export default ResponsiveImage
