import React from 'react'
import { createUseStyles } from 'react-jss'
import detectIt from 'detect-it'
import Link from './Link'
import theme, { span, vw } from '../style/theme'
import { quad } from '../style/eases'
import get from 'lodash/get'
import { resolveInternalLinkUrl } from '../helpers/resolveLink'

const NextPost = ({ page }) => {
  const classes = useStyles()
  const nextPost = get(page, ['next'])
  if (!nextPost) return null
  const { title, preview_snippet: copy } = nextPost
  const url = resolveInternalLinkUrl(nextPost)
  return (
    <Link className={classes.section} to={url} nonLinkTag='section'>
      <div className={classes.content}>
        <span className={classes.headline}>Up Next</span>
        <h3 className={classes.title}>{title}</h3>
        <p className={classes.copy}>{copy}</p>
      </div>
    </Link>
  )
}

const useStyles = createUseStyles({
  section: {
    display: 'block',
    backgroundColor: theme.colors.offWhite,
    position: 'relative',
    color: theme.colors.text,
    overflow: 'hidden',
    textDecoration: 'none',
    transition: `color 0.25s ${quad.inOut}, background-color 0.25s ${quad.inOut}`,
    '&:before': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: theme.colors.primary,
      transition: `transform 0.25s ${quad.inOut}`,
      transform: 'translateY(100%)'
    },
    ...(detectIt.primaryInput === 'touch'
      ? {
        '&:hover': {
          color: theme.colors.white,
          backgroundColor: theme.colors.primary
        }
      }
      : {
        '&:hover': {
          color: theme.colors.white,
          '&:before': {
            transform: 'translateY(0%)'
          },
          '& svg': {
            fill: theme.colors.white
          },
          '& $headline': {
            color: theme.colors.white
          }
        }
      }
    )
  },
  headline: {
    display: 'block',
    color: theme.colors.primary,
    marginBottom: vw(12),
    fontSize: vw(10),
    transition: `color 0.25s ${quad.inOut}`,
    [theme.breakpoints.up('md')]: {
      fontSize: vw(12, 'lg'),
      marginBottom: vw(12, 'lg')
    }
  },
  content: {
    overflow: 'hidden',
    position: 'relative',
    margin: [vw(40), theme.getMargin('min'), vw(50)],
    [theme.breakpoints.up('md')]: {
      margin: [vw(50, 'md'), theme.getMargin('md')]
    }
  },
  title: {
    extend: theme.typography.h4,
    display: 'flex',
    alignItems: 'center',
    marginBottom: vw(12),
    [theme.breakpoints.up('md')]: {
      maxWidth: vw(550, 'lg'),
      marginRight: span(2, 'md'),
      marginBottom: vw(12, 'lg')
    }
  },
  copy: {
    extend: theme.typography.bodySmall,
    marginBottom: 0,
    [theme.breakpoints.up('md')]: {
      maxWidth: vw(350, 'lg')
    }
  }
}, { name: 'NextPost' })

export default NextPost
