import React, { useRef, useEffect } from 'react'
import { createUseStyles } from 'react-jss'
import { useInView } from 'react-intersection-observer'
import cn from 'classnames'
import { useDispatch } from 'react-redux'
import { setInView } from '../../../redux/slices/homeHero'
import composeRefs from '../../../helpers/composeRefs'
import Slide from './Slide'
import size from './size'
import theme from '../../../style/theme'
import { clipPathShapesSupported } from '../../../helpers/supports'

const HomepageHero = ({ className, slice }) => {
  const { slides } = slice
  const dispatch = useDispatch()
  const classes = useStyles({ slideCount: slides.length })

  const ref = useRef()
  const [inViewRef, inView] = useInView({ threshold: 0, triggerOnce: false })

  useEffect(() => {
    if (ref.current) {
      if (inView) {
        dispatch(setInView({ inView: true }))
      } else {
        dispatch(setInView({ inView: false }))
      }
    }
  }, [dispatch, inView, inViewRef])

  useEffect(() => {
    if (ref.current && !clipPathShapesSupported()) {
      ref.current.style.height = `${100 * slides.length}vh`
    }
  }, [])

  return (
    <section className={cn(classes.container, className)} ref={composeRefs(ref, inViewRef)}>
      {slides.map((slide, index) => (
        <Slide key={index} slide={slide} index={index} zIndex={slides.length - index} slides={slides} last={index === slides.length - 1} />
      ))}
    </section>
  )
}

const useStyles = createUseStyles({
  container: {
    position: 'relative',
    width: '100%',
    overflow: 'hidden',
    height: ({ slideCount }) => `${slideCount * size.mobileHeight * 100}vh`,
    zIndex: 0,
    [theme.breakpoints.up('md')]: {
      height: ({ slideCount }) => `${slideCount * size.height * 100}vh`
    }
  }
}, { name: 'HomepageHero' })

export default HomepageHero
