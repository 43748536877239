var _clipPathShapesSupported

export function clipPathShapesSupported () {
  if (_clipPathShapesSupported === undefined) {
    _clipPathShapesSupported = false
    var base = 'clipPath'
    var prefixes = ['webkit', 'moz', 'ms', 'o']
    var properties = [base]
    var testElement = document.createElement('testelement')
    var attribute = 'polygon(50% 0%, 0% 100%, 100% 100%)'

    // Push the prefixed properties into the array of properties.
    for (var i = 0; i < prefixes.length; i++) {
      var prefixedProperty = prefixes[i] + base.charAt(0).toUpperCase() + base.slice(1)
      properties.push(prefixedProperty)
    }

    // Interate over the properties and see if they pass two tests.
    for (var j = 0; j < properties.length; j++) {
      var property = properties[j]
      // First, they need to even support clip-path (IE <= 11 does not)...
      if (testElement.style[property] === '') {
      // Second, we need to see what happens when we try to create a CSS shape...
        testElement.style[property] = attribute
        if (testElement.style[property] !== '') {
          _clipPathShapesSupported = true
        }
      }
    }
  }
  return _clipPathShapesSupported
}
