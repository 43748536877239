/*eslint-disable*/
import React, { useMemo } from 'react'
import { createUseStyles } from 'react-jss'
import theme, { span, vw } from '../../style/theme'
import { resolveInternalLink } from '../../helpers/resolveLink'
import Wedge from '../Wedge'
import ResponsiveImage from '../ResponsiveImage'
import { getForegroundColorFromImage } from '../../style/colorHelper'
import { useDarkSlice } from '../HeaderContextProvider'
import Link from '../Link'
import classNames from 'classnames'
import SaasCompassWedge from './SaasCompassWedge'

const SaasCompassSlide = ({ slice }) => {
  const { image, copy, title } = slice
  const link = resolveInternalLink(slice.cta_link[0].reference)
  const classes = useStyles()
  const showTab = title || copy
  const isDarkSlice = getForegroundColorFromImage(image) === theme.colors.white
  const ref = useDarkSlice(isDarkSlice)

  return (
    <section className={classes.section}>
      <div
        className={classNames(classes.imageSlice, classes.imageSlice)}
        ref={ref}
      >
        <Link link={link} nonLinkTag='div' className={classes.link}>
          <ResponsiveImage
            className={classes.image}
            {...image}
            parallax={theme.parallax}
          />
          {showTab && (
            <SaasCompassWedge
              className={classes.wedge}
              title={title}
              copy={copy}
              backgroundColor={theme.colors.white}
              wedgeAngle={20}
            />
          )}
        </Link>
      </div>
    </section>
  )
}

const useStyles = createUseStyles({
  section: {
    [theme.breakpoints.down('fab')]: {
      height: '300px'
    }  },
  highlight: {
    color: theme.colors.primary
  },
  image: {
    paddingBottom: '500px',
    [theme.breakpoints.up('fab')]: {
      paddingBottom: '500px'
    },
    [theme.breakpoints.up('md')]: {
      paddingBottom: '460px'
    }
  },
  imageSlice: {
    position: 'relative',
    paddingBottom: '300px',
    overflow: 'hidden',
    marginBottom: -1,
    backgroundColor: theme.colors.background,
    [theme.breakpoints.up('fab')]: {
      paddingBottom: '500px'
    },
    [theme.breakpoints.up('md')]: {
      paddingBottom: '460px'
    }
  },
  image: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 0,
    transformStyle: 'preserve-3d'
  },
  link: {
    display: 'block',
    color: 'inherit'
  },

  wedge: {
    left: '50%',
    bottom: 'unset',
    top: 0,
    right: 0,

    [theme.breakpoints.down('fab')]: {
       left: '30%'
    },

    '& > div': {
      width: '120% !important',
      transform: 'skewX(36deg)',

      [theme.breakpoints.down('xs')]: {
          width: '150% !important'
      },
      [theme.breakpoints.down('mobile')]: {
        width: '152% !important'
      }
    },

    [theme.breakpoints.up('md')]: {
      padding: [20],
      width: span(6, 'md'),
      left: 'unset'
    }
  }
})

export default SaasCompassSlide
