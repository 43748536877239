import React from 'react'
import { createUseStyles } from 'react-jss'
import SaasTeamCard from './SaasTeamCards'
import theme from '../style/theme'
import RichText from './RichText'

const SaasTeam = ({ slice }) => {
  const { saasAtElevationTeamList, cardSectionHeading } = slice
  const classes = useStyles()
  return (
    <div className={classes.saasTeamContainer}>
      <RichText content={cardSectionHeading.text} className={classes.heading} />
      <div className={classes.cardContainer}>
        {saasAtElevationTeamList.map((item, i) => (
          <SaasTeamCard
            key={i}
            name={item.teamMemberName}
            position={item.teamMemberPosition}
            profileImage={item.cardFrontImage}
            secondaryImage={item.cardBackgroundImage}
            pastCompany={
              item.teamMemberCompanyImage
            }
            teamMember={item?.teamMember}
          />
        ))}
      </div>
    </div>
  )
}
const useStyles = createUseStyles({
  heading: {
    color: '#FFF',
    textAlign: 'center',
    marginBottom: '66px'
  },
  saasTeamContainer: {
    background: '#000023',
    position: 'relative',
    padding: '95px 105px 65px 105px',
    [theme.breakpoints.down('fab')]: {
      padding: '80px 50px 125px 50px'
    },
    [theme.breakpoints.down('mobile')]: {
      padding: '80px 10px 125px 10px'
    }
  },
  cardContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '44px',
    position: 'relative',
    zIndex: '50'
  }
})

export default SaasTeam
